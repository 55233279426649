import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { combineLatest, EMPTY, Observable, of, merge, exhaustMap } from 'rxjs';
import { catchError, concatMap, map, share, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { stringifyIfNotNullOrUndefined, stringifyIfNotUndefined } from '../../../helper/helper';
import { EventEntity, EventEntityFromBackend } from '../../entities/event.entity';
import { Person2EntityEntityTypeEnum } from '../../entities/person2entity.entity';
import { UserToEventEntity, UserToEventEntityFromBackend } from '../../entities/user-to-event.entity';
import { BackendDate } from '../../helper/backend-frontend-conversion.helper';
import { HttpService } from '../../services/http.service';
import { DaveActions } from '../actions/actions';
import { BaseActionTypes } from '../actions/base.actions';
import { BookmarkActionTypes } from '../actions/bookmarks.actions';
import { EventsActionTypes } from '../actions/events.actions';
import { FilesActionTypes } from '../actions/files.actions';
import { Person2EntityActionTypes } from '../actions/person2entity.action';
import { UserToEventActionTypes } from '../actions/user-to-event.action';
import { State } from '../index';
import { getToken } from '../selectors/base.selectors';
import { getBookmarks } from '../selectors/bookmarks.selectors';
import { getAllEvents, getEventById } from '../selectors/events.selectors';
import { getFiles } from '../selectors/files.selectors';
import { getUser } from '../selectors/users.selectors';
import { LastSeenEventType } from '../../graphql-types';
import { getProcessFetched } from "../selectors/process.selector";
import { ProcessResolver } from "../../guards/process.resolver";

enum ErrorCodes {
    Add = 'Ereignis Hinzufügen fehlgeschlagen',
    Load = 'Ereignisse Abrufen fehlgeschlagen',
    Modify = 'Ereignis Bearbeiten fehlgeschlagen',
    Remove = 'Ereignis Löschen fehlgeschlagen',
    SetUserToEvent = 'Nutzer einem Ereignis zuweisen fehlgeschlagen',
    SetEventSeen = 'Event auf gelesen setzen fehlgeschlagen',
}

@Injectable()
export class EventEffects {
    constructor(private actions$: Actions<DaveActions>, private store$: Store<State>, private gatewayHttpService: HttpService, private processResolver: ProcessResolver) {}

    AddEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventsActionTypes.AddEvent),
            withLatestFrom(this.store$),
            concatMap(([action, store]) => {
                const queryString = `
            mutation {
                createEvent(
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'description')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'customerId')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'hint')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'emailId')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'personId')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'commissionId')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'eventTypeId')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'eventDate')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'eventEndDate')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'private')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'street')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'city')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'postalCode')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'country')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'name')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'isTask')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'state')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'additionalData')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'processId')}
                ) {${EventEntity.GQLFields.join(',')}}
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    withLatestFrom(this.store$.select(getFiles), this.store$.select(getProcessFetched)),
                    tap(([, , processFetched]) => {
                        if (processFetched) {
                            this.processResolver.pollUpdated();
                        }
                    }),
                    concatMap(([res, files]) => {
                        if (res && res.createEvent) {
                            let result: ({ Payload: any } & TypedAction<any>)[];

                            result = [
                                EventsActionTypes.UpdateEvent({
                                    Payload: EventEntityFromBackend(res.createEvent),
                                }),
                                EventsActionTypes.SetEventSeen({
                                    Payload: { eventIds: [res.createEvent.id] },
                                }),
                            ];
                            if (action.Payload.userIds) {
                                result.push(
                                    EventsActionTypes.SetUserToEvent({
                                        Payload: { userIds: action.Payload.userIds, eventId: res.createEvent.id },
                                    }),
                                );
                            }
                            if (res.createEvent.personId) {
                                result.push(
                                    Person2EntityActionTypes.AddPerson2Entity({
                                        Payload: { entityType: Person2EntityEntityTypeEnum.Event, entityId: res.createEvent.id, personId: res.createEvent.personId },
                                    }),
                                );

                            }
                            if (action.Payload.personIds) {
                                for (const pId of action.Payload.personIds) {
                                    if (pId !== res.createEvent.personId) {
                                        result.push(
                                            Person2EntityActionTypes.AddPerson2Entity({
                                                Payload: { entityType: Person2EntityEntityTypeEnum.Event, entityId: res.createEvent.id, personId: pId },
                                            }),
                                        );
                                    }
                                }
                            }
                            if (action.Payload.documentIds && !action.Payload.emailId) {
                                action.Payload.documentIds.forEach((dId) => {
                                    result.push(FilesActionTypes.ModifyFileRequest({ Payload: { DocumentId: dId.toString(), EventIds: [...files.find((f) => f.Id === dId).EventIds, res.createEvent.id].map(e => e.toString()), } }));
                                });
                            }
                            return result;
                        } else {
                            return [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Add } })];
                        }
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Add,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    LoadEvents$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(EventsActionTypes.Load),
                withLatestFrom(this.store$.select(getToken)),
                exhaustMap(([{ Payload }, token]) => {
                    const queryString = `
            query {
                event${Payload?.updatedSince ? `(updatedSince: "${Payload.updatedSince}")` : ''} {
                    data {${EventEntity.GQLFields.join(',')}}
                }
            }`;

                    return this.gatewayHttpService.graphQl({ query: queryString }, { token, retry: true }).pipe(
                        tap((res) => {
                            if (res && res.event && res.event.data) {
                                if (Payload?.updatedSince) {
                                    if (res.event.data.length) {
                                        this.store$.dispatch(
                                            EventsActionTypes.UpdateMany({
                                                Payload: res.event.data.map((val) => EventEntityFromBackend(val)),
                                                updateLatestUpdatedAt: true,
                                            }),
                                        );
                                    }
                                } else {
                                    this.store$.dispatch(
                                        EventsActionTypes.UpdateAll({
                                            Payload: res.event.data.map((val) => EventEntityFromBackend(val)),
                                            updateLatestUpdatedAt: true,
                                        }),
                                    );
                                }
                            } else {
                                this.store$.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Load } }));
                            }
                        }),
                        catchError((err, caught) => {
                            this.store$.dispatch(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Load,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            );
                            return EMPTY;
                        }),
                    );
                }),
            ),
        { dispatch: false },
    );

    ModifyEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventsActionTypes.ModifyEvent),
            withLatestFrom(this.store$, this.store$.select(getUser)),
            concatMap(([action, store, user]) => {
                // ${stringifyIfNotNullOrUndefined(action.Payload, 'additionalData')}
                const queryString = `
            mutation {
                changeEvent(
                    id: ${action.Payload.id}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'description')}
                    ${stringifyIfNotUndefined(action.Payload, 'customerId')}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'hint')}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'personId')}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'emailId')}
                  ${stringifyIfNotUndefined(action.Payload, 'commissionId')}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'eventTypeId')}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'eventDate')}
                  ${stringifyIfNotNullOrUndefined(action.Payload, 'eventEndDate')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'private')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'street')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'city')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'postalCode')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'country')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'name')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'isTask')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'state')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'additionalData')}
                    ${stringifyIfNotNullOrUndefined(action.Payload, 'processId')}
       ) {
                  ${EventEntity.GQLFields.join(',')}
                }
                ${createLastSeenEventQuery(user.Id, [action.Payload.id])}
            }`;

                const request = this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(shareReplay({bufferSize:1, refCount: true}));
                const ret: Observable<Action> | ((...args: any[]) => Observable<Action>) = merge([
                    request.pipe(
                        withLatestFrom(this.store$.select(getProcessFetched)),
                        tap(([, processFetched]) => {
                            if (processFetched) {
                                this.processResolver.pollUpdated();
                            }
                        }),
                        concatMap(([res]) => {
                            if (res && res.changeEvent) {
                                const ret: any[] = [
                                    EventsActionTypes.UpdateEvent({
                                        Payload: EventEntityFromBackend({...res.changeEvent, lastSeenAt: res.createLastSeenEvent.lastSeenAt}),
                                    }),
                                ];
                                if (action.Payload.userIds && JSON.stringify(action.Payload.userIds.slice().sort()) !== JSON.stringify(res.changeEvent.userIds?.sort())) {
                                    ret.push(
                                        EventsActionTypes.SetUserToEvent({
                                            Payload: { userIds: action.Payload.userIds, eventId: res.changeEvent.id },
                                        }),
                                    );
                                }
                                return ret;
                            } else {
                                return [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Modify } })];
                            }
                        }),
                        catchError((err, caught) =>
                            of(
                                BaseActionTypes.ErrorAction({
                                    Payload: {
                                        ToasterMessage: ErrorCodes.Modify,
                                        Err: err,
                                        Caught: caught,
                                    },
                                }),
                            ),
                        ),
                    ),
                    request.pipe(this.handleCreateLastSeenEvent([action.Payload.id])),
                ]).pipe(concatMap(v => v));
                return ret;
            }),
        ),
    );

    SetUserToEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventsActionTypes.SetUserToEvent),
            withLatestFrom(this.store$),
            concatMap(([{ Payload }, store]) => {
                const queryString = `
            mutation{
  createUser2Event(userIds: ${JSON.stringify(Payload.userIds)}, eventId: ${JSON.stringify(Payload.eventId)}){
    ${UserToEventEntity.GqlFields}
  }
}`;
console.log('SetUserToEvent')
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    withLatestFrom(this.store$.select(getEventById({ id: Payload.eventId }))),
                    concatMap(([res, event]) => {
                        return res && res.createUser2Event
                            ? [
                                  EventsActionTypes.UpdateEvent({
                                      Payload: event.Clone({ UserIds: Payload.userIds }),
                                  }),
                                  UserToEventActionTypes.UpdateMany({
                                      Payload: res.createUser2Event.map((val) => UserToEventEntityFromBackend(val)),
                                      updateLatestUpdatedAt: false,
                                  }),
                              ]
                            : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.SetUserToEvent } })];
                    }),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.SetUserToEvent,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );

    DeleteEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventsActionTypes.DeleteEvent),
            withLatestFrom(this.store$),
            concatMap(([action, store]) => {
                const queryString = `
            mutation {
                deleteEvent(id: ${action.Payload})
            }`;

                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(
                    map((res) =>
                        res && res.deleteEvent
                            ? EventsActionTypes.DeleteEventFromStore({ payload: action.Payload })
                            : BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.Remove } }),
                    ),
                    catchError((err, caught) =>
                        of(
                            BaseActionTypes.ErrorAction({
                                Payload: {
                                    ToasterMessage: ErrorCodes.Remove,
                                    Err: err,
                                    Caught: caught,
                                },
                            }),
                        ),
                    ),
                );
            }),
        ),
    );


    EventSeen$ = createEffect(() =>
        this.actions$.pipe(
            ofType(EventsActionTypes.SetEventSeen),
            withLatestFrom(this.store$, this.store$.select(getUser)),
            concatMap(([action, store, user]) => {
                const queryString = 'mutation {' + createLastSeenEventQuery(action.Payload.userId || user?.Id, action.Payload.eventIds) + '}';
                return this.gatewayHttpService.graphQl({ query: queryString }, { token: store.base.token }).pipe(this.handleCreateLastSeenEvent(action.Payload.eventIds));
            }),
        ),
    );
    handleCreateLastSeenEvent(eventIds: number[]) {
        console.log('handleCreateLastSeenEvent(eventIds: ', eventIds)
        return (src: Observable<{ createLastSeenEvent: LastSeenEventType }>) =>
            src.pipe(
                withLatestFrom(combineLatest([this.store$.select(getAllEvents), this.store$.select(getBookmarks)])),
                concatMap(([res, [events, bookmarks]]) => {
                    const ret: Action[] = res && res.createLastSeenEvent
                            ? []
                            : [BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: ErrorCodes.SetEventSeen } })];
                    if (res && res.createLastSeenEvent) {
                        if (bookmarks.some((b) => eventIds.includes(b.EventId))) {
                            ret.push(BookmarkActionTypes.UpdateBookmarks({
                                NewBookmarks: bookmarks.map((b) =>
                                    eventIds.includes(b.EventId) ? b.Clone({ Event: b.Event.Clone({ LastSeenAt: BackendDate(res.createLastSeenEvent.lastSeenAt) }) }) : b,
                                ),
                            }));
                        }

                        const Payload = events
                            .filter((e) => eventIds.includes(e.Id) && BackendDate(res.createLastSeenEvent.lastSeenAt).getTime() !== e.LastSeenAt?.getTime())
                            .map((e) => e.Clone({ LastSeenAt: BackendDate(res.createLastSeenEvent.lastSeenAt) }));
                        if (Payload.length) {
                            ret.push(EventsActionTypes.UpdateMany({Payload}));
                        }
                    }
                    return ret/*.length ? ret : [EMPTY]*/;
                    },
                ),
                catchError((err, caught) =>
                    of(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: ErrorCodes.SetEventSeen,
                                Err: err,
                                Caught: caught,
                            },
                        }),
                    ),
                ),
            );
    }
}
const createLastSeenEventQuery = (userId: number, eventIds: number[]) => `
                createLastSeenEvent (userId: ${userId}, eventIds: ${JSON.stringify(eventIds)}) {
                    eventId
                    lastSeenAt
                    userId
                }`;
