import { CdkDrag,CdkDragDrop,CdkDragHandle,moveItemInArray } from '@angular/cdk/drag-drop';
import { CurrencyPipe, DatePipe, DecimalPipe, formatCurrency, formatNumber, getCurrencySymbol } from '@angular/common';
import {
    AfterViewInit, ChangeDetectorRef,
    Component, DoCheck,
    ElementRef,
    HostListener, Inject, LOCALE_ID,
    OnDestroy,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    UntypedFormBuilder,
    Validators,
    ɵFormGroupRawValue,
    ɵFormGroupValue, ɵTypedOrUntyped,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute,Router } from '@angular/router';
import { Actions,ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment,{ Moment } from 'moment';
import {
BehaviorSubject,
combineLatest,
firstValueFrom,
merge,
mergeAll,
Observable,
of,
Subject,
Subscription,zip
} from 'rxjs';
import {
debounceTime,
delay,
distinctUntilChanged,
filter,
first,
map,
shareReplay,
skip,startWith,
switchMap,
take,
tap,
withLatestFrom
} from 'rxjs/operators';
import {
    AccountsReceivableLedgerEntity, AccountsReceivableLedgerTypeEnum, ARLTypeEnumNames,
    CalcAccountsReceivableLedgerAmount, CalcAccountsReceivableLedgerTaxAmount,
    CalcDiscount, isBookingPositionTypeEnum, NettoCost,
} from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import {
    determineIfIsArlEntityOrGroupInterface,
    getDueDateFromLedgerImportDocumentType, getLedgerImportTaxTypeTaxValue,
    LedgerImportDocumentTypeDateLabel,
    LedgerImportDocumentTypeNames, LedgerImportDocumentTypeNumberLabel,
    LedgerImportDocumentTypes, LedgerImportDocumentTypeShortDateLabel,
    LedgerImportEntity,
    LedgerImportStatusEnum,
    LedgerImportStatusNames, LedgerImportTaxTypeOptions, LedgerImportTaxTypes,
} from '../../../dave-data-module/entities/ledger-import.entity';
import { PartnerOfficeEntity } from '../../../dave-data-module/entities/partnerOffice.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { PublicFileTypeEnum } from '../../../dave-data-module/entities/public-file.entity';
import { QuantityTypeEntity } from '../../../dave-data-module/entities/quantity-type.entity';
import { SfdtTemplateEntity } from '../../../dave-data-module/entities/sfdt-template.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import {
    DaveMutationChangeAccountsReceivableLedgerArgs,
    DaveMutationChangeLedgerImportArgs,
    DaveMutationCreateAccountsReceivableLedgerArgs,
} from '../../../dave-data-module/graphql-types';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { PublicFileResolver } from '../../../dave-data-module/guards/public-file.resolver';
import {
    FrontendDate, gzipCompress,
    gzipCompressionIsSupported,/* HtmlToPdfRequestOptionsToGql,*/ Uint8ToString,
} from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import {
AccountsReceivableLedgerActionTypes,
LedgerImportActionTypes
} from '../../../dave-data-module/State/actions/accounting.actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import {
PublicFileActionTypes,
PublicFileUploadParams
} from '../../../dave-data-module/State/actions/public-file.actions';
import {
    getAccountsReceivableLedgerDictionary,
    getAccountsReceivableLedgers, getAccountsReceivableLedgersFetched,
    getQuantityTypes,
    getSfdtTemplates
} from "../../../dave-data-module/State/selectors/accounting.selector";
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import {
getCommissionDictionary,
getCommissions,
getCommissionsActive
} from '../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypes } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import {
getCustomerDictionary,
getNotDeletedCustomers
} from '../../../dave-data-module/State/selectors/customers.selectors';
import { getGeneratedDocuments } from '../../../dave-data-module/State/selectors/generatedDocuments.selectors';
import { getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getOfficeByCustomerId, getOffices } from '../../../dave-data-module/State/selectors/offices.selectors';
import {
getOwnPartnerOffices,
getPartner,getPartnerOffices
} from '../../../dave-data-module/State/selectors/partners.selectors';
import { getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import {
getPublicFilesActive,
getPublicFilesFetched
} from '../../../dave-data-module/State/selectors/public-file.selectors';
import { getUsers } from '../../../dave-data-module/State/selectors/users.selectors';
import { DocumentEditorComponent } from '../../../dave-document-editor-module/components/document-editor/document-editor.component';
import {
EmailEditorComponent,
EmailEditorComponentDialogConfig,
EmailEditorComponentDialogData
} from '../../../dave-email-module/components/email-editor/email-editor.component';
import { AppConfirmationDialogComponent,AppConfirmationDialogData } from '../../../dave-utils-module/app-dialog-module/app-confirmation-dialog/app-confirmation-dialog.component';
import {
IDetailListTemplateData,
IDetailListTemplateDataProperty
} from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import {
ListLayoutWithRoutingService
} from "../../../dave-utils-module/dave-shared-components-module/components/templates/list-layout-with-routing/list-layout-with-routing.service";
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import {
SelectSearchOption
} from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import {
    blobToBase64, compareEqualityAsJsonString, DEFAULT_TAX,
    getImageDimensionsFromBase64,
    isNotNullOrUndefined,
} from '../../../helper/helper';
import {
    AllCommissionMeta, AllEventsMeta, AllReportsMeta,
    BookingPositionMetaIcon, CommissionMeta, CustomerAdministrationMeta,
    DMSPageMeta, HistoryMeta,
    InvoiceEditorMeta,
    ReportsPageMeta, TaskPageMeta
} from "../../../helper/page-metadata";
import { CustomLabelService } from "../../../services/custom-label.service";
import { LoadingService } from '../../../services/loading.service';
import { SfdtTemplateService } from '../../../services/sfdt-template.service';
import { AncillaryCostsComponent,AncillaryData } from '../ancillary-costs/ancillary-costs.component';
import { ModifyBookingOptionDialogComponent,ModifyBookingOptionDialogComponentData } from '../modify-booking-option/modify-booking-option-dialog/modify-booking-option-dialog.component';
import {
NewBookingOptionComponent,
NewBookingOptionComponentReturnType,
NewOptionDialogData
} from '../new-booking-option/new-booking-option.component';
import { AppDialogService } from "../../../dave-utils-module/app-dialog-module/app-dialog.service";
import { Dictionary } from "@ngrx/entity";
import { getFetched$ } from "../../../dave-data-module/helper/helper";
import {
    DetailTasksComponent,
    DetailTasksComponentDialogData
} from "../../../dave-event-card/components/detail-tasks/detail-tasks.component";
import { getEventById, getEventFetched } from "../../../dave-data-module/State/selectors/events.selectors";
import { EventResolver } from "../../../dave-data-module/guards/event.resolver";
import {
    NewCommissionDialogComponent,
    NewCommissionDialogComponentDialogData, NewCommissionDialogComponentDialogReturnData
} from "../../../new-commission-dialog/new-commission-dialog.component";
import { CommissionActionTypes } from "../../../dave-data-module/State/actions/commission.actions";
import { ToastrService } from "ngx-toastr";
import { LedgerImportResolver } from "../../../dave-data-module/guards/ledger-import.resolver";
import {
    _getArlChildren, calculateConsolidatedAmount,
    getBookingPositionTwigVariables,
} from '../arl-calculation/arl-calculation.helper';
import { ArlTableComponent } from "../arl-calculation/arl-table/arl-table.component";
import { ArlFormDataService, arlTableDataArlForm } from '../arl-calculation/arl-form-data.service';
import { calculationArlForm } from "../arl-calculation/calculation-arl-form-data.service";
import { Permission } from '../../../helper/permission.helper';
import { Base64Logo, transparentPixelLogo } from '../../../helper/sfdt.helper';
import { LedgerImportDataService } from '../../../dave-data-module/services/ledger-import-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ArlDataService } from '../../../dave-data-module/services/arl-data.service';
import { SimpleArlTableComponent } from '../simple-arl-table/simple-arl-table.component';
import {
    NewPartialInvoiceComponent,
    NewPartialInvoiceComponentDialogData, NewPartialInvoiceComponentDialogReturnData,
} from '../new-partial-invoice/new-partial-invoice.component';
import {
    NewClosingInvoiceComponent,
    NewClosingInvoiceComponentDialogData, NewClosingInvoiceComponentDialogReturnData,
} from '../new-closing-invoice/new-closing-invoice.component';
// import { HtmlToPdfRequestOptions } from '@dave/types/dist/proto/htmlToPdf/htmlToPdf';


interface DisplayPartnerOfficeType {
    detailListData: IDetailListTemplateData;
    logoUrl: string | null;
    headline: string;
    emailSignature: string;
}
interface currencyValues {
    netto: string;
    brutto: string;
    tax: string;
    netto_number: number | null,
    brutto_number: number | null,
    tax_number: number | null,
}
const statusIsReadOnly = (Status: LedgerImportStatusEnum) =>
    Status === LedgerImportStatusEnum.Storniert || Status === LedgerImportStatusEnum.Verrechnet || Status === LedgerImportStatusEnum.AngebotAkzeptiert;

@Component({
    selector: 'app-invoice-editor',
    templateUrl: './invoice-editor.component.html',
    styleUrls: ['./invoice-editor.component.scss'],
    providers: [CurrencyPipe, DatePipe, DecimalPipe, ArlFormDataService],
})
export class InvoiceEditorComponent implements OnDestroy, ComponentCanDeactivate, AfterViewInit, DoCheck {
    @ViewChild('previewIframe') private previewIframe: ElementRef<HTMLIFrameElement>;
    @ViewChildren(CdkDragHandle) handles: QueryList<CdkDragHandle>;
    @ViewChildren(CdkDrag) drags: QueryList<CdkDrag>;
    @ViewChild('arlTableComponent') arlTableComponent: ArlTableComponent;

    ngDoCheck$ = new Subject<void>();
    public LiDocTypeOptions = Object.values(LedgerImportDocumentTypes).filter(x => typeof x === "string").map((v: LedgerImportDocumentTypes) => ({
        type: v,
        label: LedgerImportDocumentTypeNames.get(v),
    }))
    public DMSPageMeta = DMSPageMeta;
    public CommissionMeta = CommissionMeta;
    public AllCommissionMeta = AllCommissionMeta;
    public CustomerAdministrationMeta = CustomerAdministrationMeta;
    public IsMobile: boolean;
    public DisplayedColumns = [];
    public AutoColumnHeaders = {
        BookingText: 'Art-Nr.',
        Information: 'Bezeichnung',
        BaseCost: 'Kosten',
        Quantity: 'Menge',
        CompleteCost: 'Summe',
        Position: 'Pos.',
    };
    public LedgerImportDocumentTypeNames = LedgerImportDocumentTypeNames;
    public LedgerImportDocumentTypes = Object.keys(LedgerImportDocumentTypes).map(key => LedgerImportDocumentTypes[key]);
    public LedgerImportDocumentTypesEnum = LedgerImportDocumentTypes;
    public AutoColumns = ['BookingText', 'Information'];
    public EditorCreated$: Subject<void> = new Subject<void>();
    public EditorResized$: Subject<void> = new Subject<void>();
    public NewBookingPositionClick$: Subject<void> = new Subject<void>();
    public NewAncillaryCostsClick$ = new Subject<AccountsReceivableLedgerEntity[]>();
    public BookingPositionMetaIcon = BookingPositionMetaIcon;
    public ReportsPageMeta = ReportsPageMeta;
    public InvoiceEditorMeta = InvoiceEditorMeta;
    public FormData: Observable<IDetailListTemplateData>;
    public AddressFormData: IDetailListTemplateData;
    public NotesFormData: IDetailListTemplateData;
    public CustomerData: Observable<IDetailListTemplateData>;
    public AssignedUserData: Observable<IDetailListTemplateData>;
    public PartnerOfficeData$: Observable<IDetailListTemplateData>;
    public PersonData$: Observable<IDetailListTemplateData>;
    public PartnerDetailData: IDetailListTemplateData;
    public UserDetailData: IDetailListTemplateData;
    public FooterFormData: IDetailListTemplateData;
    public StatusData$: Observable<IDetailListTemplateData>;
    public SkontoData: IDetailListTemplateData;
    public DiscountData: IDetailListTemplateData;
    public ShowStatus = false;
    private subscriptions: Subscription[] = [];
    private afterViewInit$ = new BehaviorSubject(false);
    public PushValuesToSyncfusion$ = new Subject<void>();
    protected nothingDirty$: Observable<boolean>;
    // public SortedBookingPositionList$ = new BehaviorSubject<Array<AccountsReceivableLedgerEntity | groupInterface>>(null);
    public SaveClick$: Subject<void> = new Subject<void>();
    public OpenDeleteDialog$: Subject<void> = new Subject<void>();
    public DownloadPdfWithoutFile$ = new Subject<'pdf' | 'xInvoice'>();
    public DisableForms$ = new BehaviorSubject(false);
    public DownloadLink$: Observable<string>;
    public XInvoiceDownloadLink$: Observable<string>;
    public QuantityTypes$ = this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined));
    private personForm: FormControlTyped<PersonEntity> = new FormControl<PersonEntity>(null);
    ShowDocumentPreview = false
    public InfoiceFormGroup = new FormGroup({
        AutomaticNr: new FormControl<boolean | null>(null),
        CommissionId: new FormControl<SelectSearchOption<CommissionEntity> | null>(null),
        Customer: new FormControl<SelectSearchOption<CustomerEntity> | null>(null),
        AssignedUser: new FormControl<SelectSearchOption<UserEntity> | null>(null),
        PartnerOffice: new FormControl<SelectSearchOption<PartnerOfficeEntity> | null>(null),
        DeliveryDate: new FormControl<Moment | null>(null),
        DueDate: new FormControl<Moment | null>(null),
        InvoiceDate: new FormControl<Moment | null>(null, Validators.required),
        TextTop: new FormControl<string | null>(null),
        TextBottom: new FormControl<string | null>(null),
        // BookingPositions: new FormControl<Array<AccountsReceivableLedgerEntity | groupInterface> | null>(null),
        AccountsReceivableLedgerIds: new FormControl<Array<number>>([]),
        AccountsReceivableLedgerIdsFromLedgerImports: new FormControl<Array<number>>([]),
        ConsolidatedInvoiceId: new FormControl<string | null>(null, Validators.required),
        CustomerName: new FormControl<string | null>(null),
        CustomerStreet: new FormControl<string | null>(null),
        CustomerCity: new FormControl<string | null>(null),
        CustomerPostalCode: new FormControl<string | null>(null),
        CustomerCountry: new FormControl<string | null>(null),
        CustomerEmail: new FormControl<string | null>(null),
        CustomerNumber: new FormControl<string | null>(null),
        PartnerCountry: new FormControl<string | null>(null),
        PartnerEmail: new FormControl<string | null>(null),
        PartnerPhoneNumber: new FormControl<string | null>(null),
        PartnerName: new FormControl<string | null>(null),
        PartnerStreet: new FormControl<string | null>(null),
        PartnerCity: new FormControl<string | null>(null),
        PartnerPostalCode: new FormControl<string | null>(null),
        Status: new FormControl<LedgerImportStatusEnum | null>(null, Validators.required),
        Bankname: new FormControl<string | null>(null),
        Bic: new FormControl<string | null>(null),
        Blz: new FormControl<string | null>(null),
        Iban: new FormControl<string | null>(null),
        UstId: new FormControl<string | null>(null),
        UserEmail: new FormControl<string | null>(null),
        UserName: new FormControl<string | null>(null),
        LogoBase64: new FormControl<Base64Logo | null>(null),
        SkontoAvilable: new FormControl<boolean | null>(null),
        SkontoPercentage: new FormControl<number | null>(null, [Validators.required, Validators.min(0), Validators.max(100)]),
        SkontoDays: new FormControl<number | null>(null, [Validators.required, Validators.min(0)]),
        DiscountPercentage: new FormControl<number | null>(null, [Validators.min(0), Validators.max(100)]),
        Notes: new FormControl<string | null>(null),
        OurSign: new FormControl<string | null>(null), //hafer
        // DicountAmount: [null, [Validators.min(0)]],
        CeoName: new FormControl<string | null>(null),
        RegistryCourt: new FormControl<string | null>(null),
        CommercialRegister: new FormControl<string | null>(null),
        CommissionNumber: new FormControl<string | null>(null),
        HideNetto: new FormControl<boolean>(false),
        TaxType: new FormControl<LedgerImportTaxTypes | null>(null),
    });
    protected allArlsAlreadyFaveTaxType$ = merge(this.InfoiceFormGroup.controls.TaxType.valueChanges, this.InfoiceFormGroup.controls.AccountsReceivableLedgerIds.valueChanges).pipe(
        startWith(''),
        switchMap(() => {
            const taxType = this.InfoiceFormGroup.getRawValue().TaxType;
            const taxValue = taxType && getLedgerImportTaxTypeTaxValue(taxType);
            console.log({taxType, taxValue})
            if (!taxType) {
                return of(false);
            }
            const arlIds = this.InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIds;
            if (!arlIds.length) {
                return of(true);
            }
            return combineLatest(this.InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIds
                .map(id => this.arlFormDataService.getChildIds(id))
                .flat()
                .map(id => this.arlFormDataService.getForm(id))
                .filter(isNotNullOrUndefined)
                .map(form => merge(form.controls.Tax.valueChanges, form.controls.Type.valueChanges).pipe(startWith(''), map(() => form.getRawValue())))
            ).pipe(
                map((forms) => {
                    return !forms.some(value => {
                        return value.Tax !== taxValue && isBookingPositionTypeEnum(value.Type);
                    })
                })
            )
        })
    );
    protected totalFromPartialInvoiceForms$: Observable<currencyValues>;
    protected subTotalFromForms$: Observable<currencyValues>;
    protected totalFromForms$: Observable<currencyValues>;
    protected alreadyPayedSum$: Observable<currencyValues>;
    protected alreadyPayedSumDifference$: Observable<currencyValues>;
    protected showPartialInvoiceList$: Observable<boolean>;
    LedgerImport$: Observable<LedgerImportEntity>;
    FilterMaterialsByCustomerId$: Observable<number | null>;
    SfdtIsDirty$: Observable<boolean>;
    LedgerImportId: number;
    LedgerImportDocTypeName$ = new BehaviorSubject<string>('');
    public AddARLDefaultValues$: Observable<DaveMutationCreateAccountsReceivableLedgerArgs>;
    private isTemplate: boolean = false;
    @ViewChild('formWrapper') protected formWrapper: ElementRef<HTMLElement>;
    @ViewChild('partialInvoiceTable') protected partialInvoiceTable: SimpleArlTableComponent;
    // public BookingPositions$: Observable<AccountsReceivableLedgerEntity[]>;
    public ShowDeleteButton$ = combineLatest([
        this.PS.Has$(this.PS.Permissions.DeleteAccountsReceivableLedgers),
        this.PS.Has$(this.PS.Permissions.DeleteAccountsReceivableLedgerTemplates),
        this.InfoiceFormGroup.controls.Status.valueChanges.pipe(startWith(null)),
    ]).pipe( map(([permission,permission2DeleteTemplate , stateChanged]) => {
        const currState = this.InfoiceFormGroup.controls.Status.value;
        this.LedgerImport$.pipe(take(1)).subscribe(li => {
            this.isTemplate = li.IsTemplate;
        });
        return (permission && currState !== LedgerImportStatusEnum.Verrechnet && currState !== LedgerImportStatusEnum.Storniert) ||
            (permission2DeleteTemplate && this.isTemplate) ;
    }));
    public LogoUrl$ = this.InfoiceFormGroup.controls.LogoBase64.valueChanges.pipe(map(value => value?.base64));
    private newLogo$ = new Subject<{ file: string; partnerOfficeId: number }>();

    private invoiceTemplate: Observable<SfdtTemplateEntity>;
    protected arlTableValuesChanged$ = new Subject<void>();
    OnFileChange(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            reader.readAsDataURL(file);
            reader.onload = () => {
                fetch(reader.result as string)
                    .then((res) => res.blob())
                    .then((file) => {
                        firstValueFrom(this.store.select(getPublicFilesFetched).pipe(
                            filter((v) => !!v),
                            switchMap(() => this.store.select(getPublicFilesActive)),
                            map((files) => files.find((f) => f.EntityId === this.InfoiceFormGroup.value.PartnerOffice?.Id && f.Type === PublicFileTypeEnum.PartnerOfficeProfileImage)?.LastVersion),
                        )).then((oldFile) => {
                            if(!this.InfoiceFormGroup.value.PartnerOffice?.Id) {
                                return;
                            }
                            const params: PublicFileUploadParams = new Map([
                                ['entity_id', this.InfoiceFormGroup.value.PartnerOffice.Id + ''],
                                ['type', PublicFileTypeEnum.PartnerOfficeProfileImage],
                            ]);
                            if (oldFile) {
                                params.set('file_id', oldFile.FileId + '');
                            }
                            this.store.dispatch(
                                PublicFileActionTypes.Upload({
                                    Payload: {
                                        file,
                                        params,
                                    },
                                }),
                            );
                        });
                    });
            };
        }
        // const reader = new FileReader();
        // if (event.target.files && event.target.files.length) {
        //     const [file] = event.target.files;
        //     reader.readAsDataURL(file);
        //     reader.onload = () => {
        //         this.newLogo$.next({
        //             file: reader.result as string,
        //             partnerOfficeId: this.InfoiceFormGroup.value.PartnerOffice?.Id,
        //         });
        //     };
        // }
        // this.HandleLogoInput(event.target.files[0]);
    }
    protected previewPdfIsLoading$ = new BehaviorSubject<boolean>(false);
    taxOptions = LedgerImportTaxTypeOptions;
    constructor(
        private store: Store<State>,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private sfdtTemplateService: SfdtTemplateService,
        private api: HttpService,
        private currencyPipe: CurrencyPipe,
        private datePipe: DatePipe,
        public PS: PermissionService,
        private actions$: Actions,
        public LS: LoadingService,
        private fileDataService: FileDataService,
        private bs: BreakpointObserverService,
        publicFileResolver: PublicFileResolver,
        private eventResolver: EventResolver,
        protected cls: CustomLabelService,
        private appDialog: AppDialogService,
        private toastrService: ToastrService,
        private ledgerImportResolver: LedgerImportResolver,
        private arlFormDataService: ArlFormDataService,
        private cdr: ChangeDetectorRef,
        private ledgerImportDataService: LedgerImportDataService,
        private sanitizer: DomSanitizer,
        @Inject(LOCALE_ID) protected locale: string,
        private arlDataService: ArlDataService,

        ) {
        firstValueFrom(this.store.select(getPublicFilesFetched)).then((fetched) => {
            if (!fetched) {
                publicFileResolver.resolve();
            }
        });
        bs.MobileQuery.pipe(
            take(1),
            map(mobile => {
                this.IsMobile = mobile;
                if (!mobile) {
                    this.DisplayedColumns = [
                        'DragButton',
                        'Checkbox',
                        'Position',
                        'BookingText',
                        'Information',
                        'Quantity',
                        'QuantityType',
                        'BaseCost',
                        'CompleteCost',
                        'EditButton',
                    ];
                } else {
                    this.DisplayedColumns = ['DragButton', 'Information', 'CompleteCost', 'EditButton'];
                }
            }),
        ).subscribe();
        this.PS.Has$(this.PS.Permissions.ChangeLedgerImports)
            .pipe(take(1))
            .subscribe(has => {
                if (!has) {
                    this.DisableForms$.next(true);
                }
            });
        this.LedgerImport$ = route.paramMap.pipe(
            map(paramMap => {
                this.LedgerImportId = +paramMap.get('ledgerImportId');
                return +paramMap.get('ledgerImportId');
            }),
            switchMap(ledgerImportId =>
                store.select(getLedgerImports).pipe(
                    filter(isNotNullOrUndefined),
                    map(ledgerImports => ledgerImports.find(ledgerinport => ledgerinport.Id === ledgerImportId)),
                    tap(val => !val && console.error('Could not find LedgerImportEntity')),
                ),
            ),
            filter(isNotNullOrUndefined),
            tap(li => this.LedgerImportDocTypeName$.next(LedgerImportDocumentTypeNames.get(li.DocumentType))),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.showPartialInvoiceList$ = this.LedgerImport$.pipe(map(li => li.AccountsReceivableLedgerIdsFromLedgerImports?.length > 0 || li.DocumentType === LedgerImportDocumentTypes.ClosingInvoice))
        this.totalFromPartialInvoiceForms$ = this.InfoiceFormGroup.valueChanges.pipe(
            startWith(null),
            map(() => this.InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIdsFromLedgerImports),
            distinctUntilChanged(compareEqualityAsJsonString),
            switchMap(arlIds => this.getAmountSumForArls$(arlIds)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.subTotalFromForms$ = this.InfoiceFormGroup.valueChanges.pipe(
            startWith(null),
            map(() => this.InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIds),
            distinctUntilChanged(compareEqualityAsJsonString),
            switchMap(arlIds => this.getAmountSumForArls$(arlIds)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.totalFromForms$ = combineLatest([this.totalFromPartialInvoiceForms$, this.subTotalFromForms$, ]).pipe(map(([totalFromPartialInvoiceForms, subTotalFromForms]) => {
            const netto_number = (totalFromPartialInvoiceForms.netto_number || 0) + (subTotalFromForms.netto_number || 0);
            const tax_number = (totalFromPartialInvoiceForms.tax_number || 0) + (subTotalFromForms.tax_number || 0);
            const brutto_number = (totalFromPartialInvoiceForms.brutto_number || 0) + (subTotalFromForms.brutto_number || 0);
            return {
                netto: formatNumber(netto_number, this.locale, '1.2-2'),
                netto_number,
                brutto: formatNumber(brutto_number, this.locale, '1.2-2'),
                brutto_number,
                tax: formatNumber(tax_number, this.locale, '1.2-2')+ (tax_number ? ' (' + formatNumber(parseFloat(((tax_number/netto_number)*100).toFixed(4)), this.locale, '1.0-2')+' %)' : ''),
                tax_number,
            }
        }))
        this.alreadyPayedSum$ = this.afterViewInit$.pipe(
            filter(v => v),
            switchMap(() => this.partialInvoiceTable ? this.partialInvoiceTable.dataSource.data$ : of(null)),
            take(1),
            switchMap(() => this.InfoiceFormGroup.valueChanges.pipe(
                startWith(null),
                map(() => this.InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIdsFromLedgerImports),
                distinctUntilChanged(compareEqualityAsJsonString),
                switchMap(arlIds => {
                    let forms = arlIds.map(id => ({ id, form: this.arlFormDataService.getForm(id) }))
                    if (forms.some(f => !f)) {
                        console.error('form not found, maybe deleted')
                    }
                    forms = forms.filter(isNotNullOrUndefined);
                    if (forms.some(f => !f) || forms.length === 0) {
                        return of([] as { AlreadyPayed: number,  Tax: number }[]);
                    }
                    return combineLatest(forms.map(f => f.form.valueChanges.pipe(
                        startWith(null),
                        map(() => {
                            return { AlreadyPayed: isNotNullOrUndefined(f.form.getRawValue().AlreadyPayed) ? f.form.getRawValue().AlreadyPayed : (-1 * NettoCost(f.form.getRawValue().Quantity || 0, f.form.getRawValue().BaseCost || 0)), Tax: f.form.getRawValue().Tax };
                        }),
                        distinctUntilChanged(compareEqualityAsJsonString),
                    )));
                }),
                map(values => {
                    const netto_number = values.reduce((prev, curr) => curr.AlreadyPayed + prev, 0);
                    const tax_number = values.reduce((prev, curr) => CalcAccountsReceivableLedgerTaxAmount(1, curr.Tax, curr.AlreadyPayed) + prev, 0);
                    const brutto_number = netto_number + tax_number;
                    return {
                        netto: formatNumber(netto_number, this.locale, '1.2-2'),
                        netto_number,
                        brutto: formatNumber(brutto_number, this.locale, '1.2-2'),
                        brutto_number,
                        tax: formatNumber(tax_number, this.locale, '1.2-2')+ (tax_number ? ' (' + formatNumber(parseFloat(((tax_number/netto_number)*100).toFixed(4)), this.locale, '1.0-2')+' %)' : ''),
                        tax_number,
                    }
                }),
            )),
        shareReplay({ refCount: true, bufferSize: 1 }),
        )
        this.alreadyPayedSumDifference$ = combineLatest([this.alreadyPayedSum$, this.subTotalFromForms$]).pipe(
            map(([alreadyPayed, subTotalFromForms]) => {
                const netto_number = subTotalFromForms.netto_number - alreadyPayed.netto_number;
                const brutto_number = subTotalFromForms.brutto_number - alreadyPayed.brutto_number;
                const tax_number = subTotalFromForms.tax_number - alreadyPayed.tax_number;
                return {
                    netto: formatNumber(netto_number, this.locale, '1.2-2'),
                    netto_number,
                    brutto: formatNumber(brutto_number, this.locale, '1.2-2'),
                    brutto_number,
                    tax: formatNumber(tax_number, this.locale, '1.2-2')+ ' (' + formatNumber(parseFloat(((tax_number/netto_number)*100).toFixed(4)), this.locale, '1.0-2')+' %)',
                    tax_number,
                }
            })
        );
        this.FilterMaterialsByCustomerId$ = combineLatest([
            this.LedgerImport$,
            this.InfoiceFormGroup.controls.Customer.valueChanges.pipe(
                startWith(null),
                map(() => this.InfoiceFormGroup.controls.Customer.getRawValue())
            )
            ]).pipe(
            map(([li, customer]) => li.DocumentType === LedgerImportDocumentTypes.Order ? customer?.Id : null),
            );
        this.AddARLDefaultValues$ = combineLatest([this.LedgerImport$, this.InfoiceFormGroup.controls.TaxType.valueChanges.pipe(startWith(''), map(() => this.InfoiceFormGroup.getRawValue().TaxType))]).pipe(map(([li, taxType]) => ({
            ledgerImportId: li.Id,
            multiplikator: 1,
            quantity: 1,
            tax: taxType ? getLedgerImportTaxTypeTaxValue(taxType) : DEFAULT_TAX,
            currencyCode: li.ConsolidatedCurrencyCode || 'EUR',
            baseCost: 0,
            isVisible: true,
        })), shareReplay({refCount: true, bufferSize: 1}));
        this.SfdtIsDirty$ = this.LedgerImport$.pipe(map(li => li.IsDirty), distinctUntilChanged());
        this.invoiceTemplate = combineLatest([this.LedgerImport$, this.InfoiceFormGroup.controls.PartnerOffice.valueChanges.pipe(startWith(null), map(() => this.InfoiceFormGroup.getRawValue().PartnerOffice))]).pipe(
            filter(isNotNullOrUndefined),
            withLatestFrom(this.store.select(getSfdtTemplates), this.store.select(getOwnPartnerOffices), this.store.select(getPartner)),
            switchMap(([[li, poForm], templates, pos, partner]) => {
                let template: SfdtTemplateEntity;
                if (li.AdditionalData?.twigTemplateId) {
                    template = templates.find(t => t.Id === li.AdditionalData?.twigTemplateId);
                } else {
                    switch (li.DocumentType) {
                        case LedgerImportDocumentTypes.Invoice:
                        case LedgerImportDocumentTypes.PartialInvoice:
                        case LedgerImportDocumentTypes.ClosingInvoice:
                            template = templates.find(t => t.Id === pos.find(p => p.Id === poForm?.Id)?.AdditionalData?.invoiceSfdtTemplateId);
                            break;
                        case LedgerImportDocumentTypes.Offer:
                            template = templates.find(t => t.Id === pos.find(p => p.Id === poForm?.Id)?.AdditionalData?.offerSfdtTemplateId);
                            break;
                    }
                    if (!template) {
                        template = templates.find(t => t.PartnerId === partner.Id);
                    }
                    if (!template) {
                        template = templates.find(t => t.PartnerId === null);
                    }
                }
                console.log({template, templates, l: li.AdditionalData, l2: li.DocumentType})
                if (template.Html/* && template.Options*/) {
                    return of(template);
                } else {
                    this.store.dispatch(LedgerImportActionTypes.GetSFDTTemplate({ Payload: { id: template.Id }, withData: true}));
                    return this.actions$.pipe(
                        ofType(LedgerImportActionTypes.UpdateSFDTTemplate),
                        take(1),
                        map(({ Payload }) => Payload.find(t => t.Id === template.Id)),
                    );
                }
            }),
        );
        // this.offerTemplate = this.LedgerImport$.pipe(
        //     filter(isNotNullOrUndefined),
        //     withLatestFrom(this.store.select(getSfdtTemplates), this.store.select(getPartner), this.store.select(getOwnPartnerOffices)),
        //     switchMap(([li, templates, partner, pos]) => {
        //         let template: SfdtTemplateEntity;
        //         if (li.AdditionalData?.twigTemplateId) {
        //             template = templates.find(t => t.Id === li.AdditionalData?.twigTemplateId);
        //         } else {
        //             template = templates.find(t => t.Id === pos.find(p => p.Id === li.PartnerOfficeId)?.AdditionalData?.offerSfdtTemplateId);
        //             if (!template) {
        //                 template = templates.find(t => t.PartnerId === null);
        //             }
        //         }
        //         if (template.Data) {
        //             return of(template);
        //         } else {
        //             this.store.dispatch(LedgerImportActionTypes.GetSFDTTemplate({ Payload: { id: template.Id } }));
        //             // return of(template);
        //             return this.actions$.pipe(
        //                 ofType(LedgerImportActionTypes.UpdateSFDTTemplate),
        //                 take(1),
        //                 map(({ Payload }) => Payload.find(t => t.Id === template.Id)),
        //             );
        //         }
        //     }),
        // );
        // this.BookingPositions$ = combineLatest([this.LedgerImport$, this.store.select(getAccountsReceivableLedgers).pipe(filter(isNotNullOrUndefined))]).pipe(
        //     map(([ledgerImport, bookingpositions]) => {
        //         return bookingpositions
        //             .filter(
        //                 b =>
        //                     b.LedgerImportId === ledgerImport.Id ||
        //                     (!b.LedgerImportId && (ledgerImport.DocumentType === b.DocumentType || !b.DocumentType) &&
        //                         ((ledgerImport.CommissionId && ledgerImport.CommissionId === b.CommissionId) ||
        //                             (ledgerImport.CustomerId && ledgerImport.CustomerId === b.CustomerId && !b.CommissionId) ||
        //                             (!b.CommissionId && !b.CustomerId && !ledgerImport.CommissionId && !ledgerImport.CustomerId))),
        //             ).filter(b => !b.ParentId)
        //             .sort((a, b) => b.Id - a.Id);
        //     }),
        // );
        this.FormData = combineLatest([this.LedgerImport$, store.select(getCommissionsActive).pipe(filter(isNotNullOrUndefined))]).pipe(
            withLatestFrom(this.cls.getSingle$('Commission')),
            map(([[li, commissions], commissionLabel]) => {
                const Properties: IDetailListTemplateDataProperty[] = [
                    {
                        key: 'Kundennummer',
                        value: '',
                        formControl: this.InfoiceFormGroup.controls.CustomerNumber,
                    },
                    {
                        key: 'Auftragsnummer',
                        value: '',
                        formControl: this.InfoiceFormGroup.controls.CommissionNumber,
                    },
                    {
                        key: this.LedgerImportDocTypeName$.value + 'snummer/ -bezeichnung',
                        value: '',
                        formControl: this.InfoiceFormGroup.controls.ConsolidatedInvoiceId,
                    },
                    {
                        key: this.LedgerImportDocTypeName$.value + 'snummer automatisch auswählen',
                        formControl: this.InfoiceFormGroup.controls.AutomaticNr,
                        options: {
                            type: CustomPropertyType.Boolean,
                            specialInput: { boolean: true },
                            showHint: 'Erzeugt automatisch eine fortlaufende ' + this.LedgerImportDocTypeName$.value + 'snummer/ -bezeichnung',
                        },
                    },
                    {
                        key: this.getConsolidatedInvoiceDateLabel(),
                        value: '',
                        formControl: this.InfoiceFormGroup.controls.InvoiceDate,
                        options: {
                            specialInput: {
                                date: true,
                            },
                        },
                    },
                    {
                        key: 'Lieferdatum',
                        value: '',
                        formControl: this.InfoiceFormGroup.controls.DeliveryDate,
                        options: {
                            specialInput: {
                                date: true,
                            },
                        },
                    },
                    {
                        key: getDueDateFromLedgerImportDocumentType(li.DocumentType),
                        value: '',
                        formControl: this.InfoiceFormGroup.controls.DueDate,
                        options: {
                            specialInput: {
                                date: true,
                            },
                        },
                    },
                ];
                // if (li.DocumentType !== LedgerImportDocumentTypes.Offer) {
                //     Properties.push(
                //         {
                //             key: 'Lieferdatum',
                //             value: '',
                //             formControl: this.InfoiceFormGroup.controls.DeliveryDate,
                //             options: {
                //                 specialInput: {
                //                     date: true,
                //                 },
                //             },
                //         },
                //         {
                //             key: 'Fälligkeitsdatum',
                //             value: '',
                //             formControl: this.InfoiceFormGroup.controls.DueDate,
                //             options: {
                //                 specialInput: {
                //                     date: true,
                //                 },
                //             },
                //         },
                //     );
                // }
                Properties.push({
                    key: commissionLabel,
                    value: li?.CommissionId ? commissions.find(c => c.Id === li.CommissionId)?.GetDisplayName() : '',
                    formControl: this.InfoiceFormGroup.controls.CommissionId,
                    options: {
                        specialInput: {
                            singleSelectSearch: {
                                options: commissions?.filter(c =>
                                    this.InfoiceFormGroup.value.Customer?.Id ? c.CustomerId === this.InfoiceFormGroup.value.Customer?.Id || c.Id === li?.CommissionId : true,
                                ) || [],
                                compareOptions: (a, b) => a?.Id === b?.Id,
                            },
                        },
                    },
                });
                return { Properties };
            }),
        );
        this.StatusData$ = this.LedgerImport$.pipe(
            map((ledgerImport) => {
                return {
                    Properties: [
                        {
                            key: 'Status',
                            value: '',
                            formControl: this.InfoiceFormGroup.controls.Status,
                            options: {
                                specialInput: {
                                    select: ledgerImport.AvailableStates,
                                },
                            },
                        },
                    ],
                };
            }),
        );
        this.SkontoData = {
            Properties: [
                {
                    key: 'Skonto anmerken',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.SkontoAvilable,
                    options: {
                        specialInput: { boolean: true },
                    },
                },
                {
                    key: 'Höhe',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.SkontoPercentage,
                    options: {
                        specialInput: {
                            number: true,
                        },
                        suffix: '%',
                    },
                },
                {
                    key: 'Frist',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.SkontoDays,
                    options: {
                        specialInput: {
                            number: true,
                        },
                        suffix: 'Tage',
                    },
                },
            ],
        };
        this.DiscountData = {
            Properties: [
                // {
                //     key: 'Rabatt anmerken',
                //     value: '',
                //     formControl: this.InfoiceFormGroup.controls.DiscountAvilable,
                //     options: {
                //         specialInput: { boolean: true },
                //     },
                // },
                {
                    key: 'Prozentual',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.DiscountPercentage,
                    options: {
                        specialInput: {
                            number: true,
                        },
                        suffix: '%',
                    },
                },
                // {
                //     key: 'Absolut',
                //     value: '',
                //     formControl: this.InfoiceFormGroup.controls.DicountAmount,
                //     options: {
                //         specialInput: {
                //             number: true,
                //         },
                //         suffix: '€',
                //     },
                // },
            ],
        };
        this.PartnerDetailData = {
            Properties: [
                {
                    key: 'Name',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.PartnerName,
                },
                {
                    key: 'Straße, Hausnummer',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.PartnerStreet,
                },
                {
                    key: 'Postleitzahl',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.PartnerPostalCode,
                },
                {
                    key: 'Stadt',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.PartnerCity,
                },
                {
                    key: 'Land',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.PartnerCountry,
                },
                {
                    key: 'Telefonnummer',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.PartnerPhoneNumber,
                },
                {
                    key: 'E-Mail',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.PartnerEmail,
                },
            ],
        };
        this.UserDetailData = {
            Properties: [
                {
                    key: 'Name',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.UserName,
                },
                {
                    key: 'E-Mail',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.UserEmail,
                },
            ],
        };
        this.FooterFormData = {
            Properties: [
                {
                    key: 'IBAN',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.Iban,
                },
                {
                    key: 'BIC',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.Bic,
                },
                // {
                //     key: 'Blz',
                //     value: '',
                //     formControl: this.InfoiceFormGroup.controls.Blz,
                // },
                {
                    key: 'Bankname',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.Bankname,
                },
                {
                    key: 'Umsatzsteueridentifikationsnummer',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.UstId,
                },
                {
                    key: 'Geschäftsführer',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.CeoName,
                },
                {
                    key: 'Registergericht',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.RegistryCourt,
                },
                {
                    key: 'Handelsregister',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.CommercialRegister,
                },
            ],
        };
        this.PartnerOfficeData$ = combineLatest([this.LedgerImport$, store.select(getOwnPartnerOffices).pipe(filter(isNotNullOrUndefined))]).pipe(
            map(([ledgerImport, partnerOffices]) => {
                // this.InfoiceFormGroup.controls.PartnerOffice.setValue(partnerOffices.find(c => c.Id === this.InfoiceFormGroup.value.PartnerOffice?.Id));
                return {
                    Properties: [
                        {
                            key: 'Unternehmen',
                            value: ledgerImport?.PartnerOfficeId ? partnerOffices.find(c => c.Id === ledgerImport.PartnerOfficeId)?.DisplayName : '',
                            formControl: this.InfoiceFormGroup.controls.PartnerOffice,
                            options: {
                                specialInput: {
                                    singleSelectSearch: {
                                        options: partnerOffices?.map(c => Object.assign(c.Clone(), {
                                            optionLabel: c.DisplayName,
                                        })) || [],
                                        compareOptions: (a, b) => a?.Id === b?.Id,
                                    },
                                },
                            },
                        } as IDetailListTemplateDataProperty<PartnerOfficeEntity>,
                    ],
                }
            }),
        );
        this.CustomerData = combineLatest([this.LedgerImport$, store.select(getNotDeletedCustomers).pipe(filter(isNotNullOrUndefined))]).pipe(
            map(([ledgerImport, customers]) => {
                return {
                    Properties: [
                        {
                            key: 'Kunde',
                            value: ledgerImport?.CustomerId ? customers.find(c => c.Id === ledgerImport.CustomerId)?.DisplayInformation : '',
                            formControl: this.InfoiceFormGroup.controls.Customer,
                            options: {
                                specialInput: {
                                    singleSelectSearch: {
                                        options: customers || [],
                                        compareOptions: (a, b) => a?.Id === b?.Id,
                                    }
                                },
                            },
                        } as IDetailListTemplateDataProperty<CustomerEntity>,
                    ],
                };
            }),
        );
        this.AssignedUserData = combineLatest([this.LedgerImport$, store.select(getUsers).pipe(filter(isNotNullOrUndefined)), store.select(getPartner)]).pipe(
            map(([ledgerImport, users, partner]) => {
                const myUsers = users.filter(u => u.PartnerId === partner.Id);
                const Properties: IDetailListTemplateDataProperty<UserEntity>[] = [
                    {
                        key: 'Bearbeiter',
                        value: ledgerImport?.AdditionalData?.assignedUserId
                            ? users.find(u => u.Id === ledgerImport?.AdditionalData?.assignedUserId)?.DisplayName
                            : users.find(u => u.Id === ledgerImport?.UserId)?.DisplayName, //'Kein Bearbeiter ausgewählt.',
                        formControl: this.InfoiceFormGroup.controls.AssignedUser,
                        options: {
                            specialInput: {
                                singleSelectSearch: {
                                    options: myUsers?.map(c => Object.assign(c.Clone(), {
                                        optionLabel: c.DisplayName,
                                    })) || [],
                                    compareOptions: (a, b) => a?.Id === b?.Id,
                            }
                            },
                        },
                    },
                ];
                if (partner.Id === 117 || partner.Id === 40 || partner.Id === 1) {
                    // hafer id
                    Properties.push({
                        key: 'Unser Zeichen',
                        value: ledgerImport?.AdditionalData?.ourSign,
                        formControl: this.InfoiceFormGroup.controls.OurSign,
                    });
                }
                return {
                    Properties,
                };
            }),
        );
        this.PersonData$ = combineLatest([
            this.LedgerImport$,
            store.select(getPerson2Entities).pipe(filter(isNotNullOrUndefined)),
            store.select(getPersons).pipe(filter(isNotNullOrUndefined)),
            this.InfoiceFormGroup.controls.CommissionId.valueChanges,
            this.InfoiceFormGroup.controls.Customer.valueChanges,
        ]).pipe(
            map(([ledgerImport, p2e, persons, commissionId, customer]) => {
                const pId = p2e?.find(p => p.EntityType === Person2EntityEntityTypeEnum.Commission && p.EntityId === commissionId?.Id)?.PersonId;

                const personArray = persons.filter(
                    p =>
                        p.Id === pId ||
                        p2e
                            .filter(pe => pe.EntityType === Person2EntityEntityTypeEnum.Customer && pe.PersonId === p.Id)
                            .map(pe => pe.EntityId)
                            .includes(customer?.Id),
                );
                // console.log('log', persons ,  personArray ,personArray?.map(c => Object.assign(c.Clone(), {
                //     optionLabel: c.DisplayName
                // })))
                return {
                    Properties: [
                        {
                            key: 'Beteiligte Personen',
                            value: '',
                            formControl: this.personForm,
                            options: {
                                specialInput: {
                                    singleSelectSearch: {
                                        options: personArray || [],
                                        compareOptions: (a, b) => a?.Id === b?.Id,},
                                },
                            },
                        },
                    ],
                };
            }),
        );
        this.AddressFormData = {
            Properties: [
                {
                    key: 'Name',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.CustomerName,
                    options: {
                        specialInput: {
                            textArea: {
                                Fill: false,
                            },
                        },
                    },
                },
                {
                    key: 'Straße, Hausnummer',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.CustomerStreet,
                    options: {
                        specialInput: {
                            textArea: {
                                Fill: false,
                            },
                        },
                    },
                },
                {
                    key: 'Postleitzahl',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.CustomerPostalCode,
                    options: {
                        specialInput: {
                            textArea: {
                                Fill: false,
                            },
                        },
                    },
                },
                {
                    key: 'Stadt',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.CustomerCity,
                    options: {
                        specialInput: {
                            textArea: {
                                Fill: false,
                            },
                        },
                    },
                },
            ],
        };
        this.NotesFormData = {
            Properties: [
                {
                    key: 'Notiz',
                    value: '',
                    formControl: this.InfoiceFormGroup.controls.Notes,
                    options: {
                        specialInput: {
                            textArea: { Fill: true },
                        },
                    },
                },
            ],
        };
        this.DownloadLink$ = this.LedgerImport$.pipe(
            switchMap(li => li?.DocumentId && !li.IsDirty
                ? this.fileDataService.GetFileById$(li.DocumentId, true).pipe(
                    filter(isNotNullOrUndefined),
                    withLatestFrom(this.store.select(getToken)),
                    map(([file, token]) => this.api.GetUrl(file.GetLastVersion().GetDownloadLink(token), 'file')),
                    )
                : of(null)
            ),
            shareReplay({refCount: true, bufferSize: 1})
        );
        this.XInvoiceDownloadLink$ = this.LedgerImport$.pipe(
            switchMap(li => li?.DocumentIdXRechnung && !li.IsDirty
                ? this.fileDataService.GetFileById$(li.DocumentIdXRechnung, true).pipe(
                    filter(isNotNullOrUndefined),
                    withLatestFrom(this.store.select(getToken)),
                    map(([file, token]) => this.api.GetUrl(file.GetLastVersion().GetDownloadLink(token), 'file')),)
                : of(null)),
        );
        this.subscriptions.push(
            this.InfoiceFormGroup.controls.Status.valueChanges.pipe(distinctUntilChanged(), withLatestFrom(this.LedgerImport$)).subscribe( ([value, li]) => {
                if ( value !== li.Status && !!value ) {
                    this.InfoiceFormGroup.controls.Status.markAsDirty();
                    this.InfoiceFormGroup.markAsDirty();
                    this.ShowStatus = false;
                    this.SaveClick();
                }
            }),
            this.InfoiceFormGroup.controls.AutomaticNr.valueChanges.subscribe(v => {
                if (v && this.InfoiceFormGroup.controls.ConsolidatedInvoiceId.enabled) {
                    this.InfoiceFormGroup.controls.ConsolidatedInvoiceId.disable();
                    // this.InfoiceFormGroup.controls.ConsolidatedInvoiceId.setValue(null);
                } else if (!v && this.InfoiceFormGroup.controls.ConsolidatedInvoiceId.disabled) {
                    this.InfoiceFormGroup.controls.ConsolidatedInvoiceId.enable();
                    // this.InfoiceFormGroup.controls.ConsolidatedInvoiceId.setValue(null);
                }
            }),
            this.PartnerOfficeData$.subscribe(),
            // combineLatest([this.LedgerImport$, this.BookingPositions$.pipe(first())]).pipe(withLatestFrom(this.store.select(getAccountsReceivableLedgers), this.BookingPositions$)).subscribe(
            //     ([[ledgerImport, initialFilteredBookingPositions], allBookingPositions, filteredBookingPositions]) => {
            //         let ret = [];
            //         if (ledgerImport.AdditionalData?.tempBookingPosition) {
            //             ret.push(...ledgerImport.AdditionalData?.tempBookingPosition.map((id) => {
            //
            //                 console.warn('#####')
            //                 console.log(ledgerImport.AdditionalData?.tempBookingPosition)
            //                 // return ledgerImport.AdditionalData.tempBookingPosition.map(e => determineIfIsArlEntityOrGroupInterface(e))
            //                 switch (id.type) {
            //                     case 'arl':
            //                         return allBookingPositions.find((b) => !b.ParentId && b.Id === id)
            //                     case 'group':
            //                     case 'group-end':
            //                     case 'text':
            //                         let ret: groupInterface;
            //                         ret = {
            //                             name: id.name,
            //                             uniqId: id.uniqId,
            //                             type: id.type,
            //                         };
            //                         return ret;
            //                 }
            //
            //
            //
            //                 // if (id.type === 'arl') {
            //                 //     return allBookingPositions.filter(b => !b.ParentId).find((b) => b.Id === id.uniqId)
            //                 // }
            //                 // return id;
            //             }))
            //         } else {
            //             ret.push(...ledgerImport.AccountsReceivableLedgerIds.map((id) => {
            //                 return allBookingPositions.filter(b => !b.ParentId).find((b) => b.Id === id)
            //             }));
            //         }
            //         console.log('SortedBookingPositionList$', ret)
            //         this.SortedBookingPositionList$.next([
            //             ...ret.filter(isNotNullOrUndefined),
            //             ...filteredBookingPositions.filter((b) => !ledgerImport.AccountsReceivableLedgerIds.some(a => a === b.Id)),
            //         ]);
            //     }
            // ),
            // this.store.select(getAccountsReceivableLedgerDictionary).subscribe((bps) => {
            //     if (this.SortedBookingPositionList$.value?.length) {
            //         this.SortedBookingPositionList$.next(this.SortedBookingPositionList$.value.map(b => determineIfIsArlEntityOrGroupInterface(b) ? bps[b.Id] : b).filter(isNotNullOrUndefined));
            //     }
            // }),
            // this.LedgerImport$.pipe(
            //     // distinctUntilChanged((a, b) => a.AccountsReceivableLedgerIds.join(',') === b.AccountsReceivableLedgerIds.join(',')),
            //     skip(1),
            //     withLatestFrom(this.store.select(getAccountsReceivableLedgers).pipe(filter(isNotNullOrUndefined))),
            // ).subscribe(([ledgerImport, arls]) => {
            //     if (ledgerImport.AdditionalData?.tempBookingPosition) {
            //         this.InfoiceFormGroup.controls.BookingPositions.setValue(ledgerImport.AdditionalData.tempBookingPosition.map(id => {
            //             switch (id.type) {
            //                 case 'arl':
            //                     return arls.find((b) => !b.ParentId && b.Id === id.id)
            //                 case 'group':
            //                 case 'group-end':
            //                 case 'text':
            //                     let ret: groupInterface;
            //                     ret = {
            //                         name: id.name,
            //                         uniqId: id.uniqId,
            //                         type: id.type,
            //                     };
            //                     return ret;
            //             }
            //         }))
            //     } else {
            //         this.InfoiceFormGroup.controls.BookingPositions.setValue(ledgerImport.AccountsReceivableLedgerIds.map((id) => arls.find((arl) => arl.Id === id)));
            //     }
            // }),
            // this.store
            //     .select(getAccountsReceivableLedgers)
            //     .pipe(
            //         tap(arls => {
            //             if (this.InfoiceFormGroup.value.BookingPositions) {
            //                 this.InfoiceFormGroup.controls.BookingPositions.setValue(
            //                     this.InfoiceFormGroup.value.BookingPositions?.map(bp => determineIfIsArlEntityOrGroupInterface(bp) ? arls.find(arl => arl.Id === bp?.Id) : bp).filter(isNotNullOrUndefined),
            //                 );
            //             }
            //         }),
            //     )
            //     .subscribe(),

                this.InfoiceFormGroup.controls.PartnerOffice.valueChanges.pipe(
                    filter(isNotNullOrUndefined),
                    map(p => p.Id),
                    distinctUntilChanged(),
                    switchMap(id => this.store.select(getPublicFilesFetched).pipe(
                        filter((v) => !!v),
                        switchMap(() => this.store.select(getPublicFilesActive)),
                        map((files) => files.find((f) => f.EntityId === id && f.Type === PublicFileTypeEnum.PartnerOfficeProfileImage)?.LastVersion),
                    )),
                    withLatestFrom(this.store.select(getToken)),
                )
                .subscribe(([file, token]) => {
                    if (file) {
                        this.api
                            .download(file, { token })
                            .pipe(take(1))
                            .subscribe(val => this.HandleLogoInput(val));
                    } else {
                        this.InfoiceFormGroup.controls.LogoBase64.setValue(null);
                    }
                }),
            combineLatest([
                this.personForm.valueChanges.pipe(filter(isNotNullOrUndefined)),
                this.store.select(getPerson2Entities).pipe(
                    filter(isNotNullOrUndefined),
                    map(pes => pes.filter(pe => pe.EntityType === Person2EntityEntityTypeEnum.Customer)),
                ),
                this.store.select(getNotDeletedCustomers),
            ]).subscribe(([p, personEntities, c]) => {
                const cIds = personEntities.filter(pe => pe.PersonId === p.Id).map(pe => pe.EntityId);
                const officeName = p.CompanyName ? p.CompanyName : c.find(cu => cIds.includes(cu.Id))?.DisplayName;

                this.InfoiceFormGroup.controls.CustomerName.setValue((officeName ? officeName + '\n' : '') + (p.Title ? p.Title + '\n' : '') + p.Firstname + ' ' + p.Lastname);
                if (p.Street || p.PostalCode || p.City) {
                    this.InfoiceFormGroup.controls.CustomerStreet.setValue(p.Street ? p.Street : '');
                    this.InfoiceFormGroup.controls.CustomerPostalCode.setValue(p.PostalCode ? p.PostalCode : '');
                    this.InfoiceFormGroup.controls.CustomerCity.setValue(p.City ? p.City : '');
                }
            }),
            this.InfoiceFormGroup.controls.AssignedUser.valueChanges.pipe(filter(isNotNullOrUndefined)).subscribe(u => {
                let newUser = null;
                this.store
                    .select(getUsers)
                    .pipe(
                        map(users => users.find(user => user.Id === u.Id)),
                        take(1),
                    )
                    .subscribe(val => {
                        newUser = val;
                        this.InfoiceFormGroup.controls.UserName.setValue(newUser.DisplayName);
                        this.InfoiceFormGroup.controls.UserEmail.setValue(newUser.Email);
                    });
            }),
            this.SaveClick$.pipe(
                // filter(() => this.InfoiceFormGroup.valid),
            ).subscribe(() => this.SaveClick()),
            combineLatest([
                // this.EditorCreated$,
                merge(this.InfoiceFormGroup.valueChanges, this.arlTableValuesChanged$),
                this.store.select(getNotDeletedCustomers).pipe(filter(isNotNullOrUndefined)),
                this.store.select(getAccountsReceivableLedgers).pipe(filter(isNotNullOrUndefined)),
            ])
                .pipe(
                    debounceTime(300),
                    tap(() => {
                        this.PushValuesToSyncfusion$.next();
                    }),
                )
                .subscribe(),

            combineLatest([
                combineLatest([this.LedgerImport$, this.store.select(getAccountsReceivableLedgers)]).pipe(
                    map(([li, arls]) => {
                        let ret = li;
                        [...(li.AccountsReceivableLedgerIds || []), ...(li.AccountsReceivableLedgerIdsFromLedgerImports || [])].forEach(id => {
                            if (!arls.find(arl => arl.Id === id)) {
                                console.error('ARL mit Id ' + id + ' von LedgerImport ' + li.Id + ' konnte nicht gefunden werden');
                                // ret = undefined;
                            }
                        });
                        return ret;
                    }),
                    filter(isNotNullOrUndefined),
                ),
                store.select(getGeneratedDocuments).pipe(filter(isNotNullOrUndefined)),
                store.select(getCustomerDictionary),
                store.select(getOwnPartnerOffices).pipe(filter(isNotNullOrUndefined)),
                this.store.select(getAccountsReceivableLedgers),

                store.select(getCommissionDictionary),
            ])
                .pipe(
                    distinctUntilChanged(([a1], [b1]) => a1?.Id === b1?.Id && a1.Status === b1.Status && a1.ConsolidatedInvoiceId === b1.ConsolidatedInvoiceId),
                    withLatestFrom( this.store.select(getUsers), this.store.select(getAccountsReceivableLedgerDictionary),),
                    tap(([[ledgerImport, genDocs, customers, partnerOffices, _bookingPositions, /*editorCreated,*/ commissions], users, bookingPositions]) => {
                        if (ledgerImport) {
                            const customer = ledgerImport.CustomerId && customers[ledgerImport.CustomerId];
                            const commission = ledgerImport.CommissionId && commissions[ledgerImport.CommissionId];
                            const partnerOffice = ledgerImport.PartnerOfficeId && partnerOffices.find(p => p.Id === ledgerImport.PartnerOfficeId);
                            const assignedUser = ledgerImport.AdditionalData?.assignedUserId
                                    ? users.find(u => u.Id === ledgerImport?.AdditionalData?.assignedUserId)
                                    : users.find(u => u.Id === ledgerImport?.UserId);
                            this.InfoiceFormGroup.setValue({
                                AutomaticNr: ledgerImport.AutomaticInvoiceId || (!ledgerImport.ConsolidatedInvoiceId && ledgerImport.UpdatedAt <= ledgerImport.CreatedAt),
                                CommissionId: commission? commission : null,
                                Customer: customer ? customer : null,
                                AssignedUser: assignedUser ? Object.assign(assignedUser.Clone(), {optionLabel: assignedUser.DisplayName}) : null,
                                PartnerOffice: partnerOffice ? Object.assign(partnerOffice.Clone(), {optionLabel: partnerOffice.DisplayName}) : null,
                                DeliveryDate: ledgerImport.ConsolidatedDeliveryDate && moment(ledgerImport.ConsolidatedDeliveryDate),
                                DueDate: ledgerImport.DueDate && moment(ledgerImport.DueDate),
                                InvoiceDate: ledgerImport.ConsolidatedDate && moment(ledgerImport.ConsolidatedDate),
                                TextTop: ledgerImport.TextTop,
                                TextBottom: ledgerImport.TextBottom,
                                // BookingPositions: ledgerImport.AccountsReceivableLedgerIds?.map(id => bookingPositions.find(bp => bp.Id === id)).filter(isNotNullOrUndefined) || [],
                                ConsolidatedInvoiceId: ledgerImport.ConsolidatedInvoiceId,
                                CustomerName: ledgerImport.CustomerName,
                                CustomerStreet: ledgerImport.CustomerStreet,
                                CustomerPostalCode: ledgerImport.CustomerPostalCode,
                                CustomerCity: ledgerImport.CustomerCity,
                                CustomerCountry: ledgerImport.CustomerCountry,
                                CustomerEmail: ledgerImport.CustomerEmail,
                                CustomerNumber: ledgerImport.AdditionalData?.customerNumber || '',
                                PartnerCountry: ledgerImport.PartnerCountry,
                                PartnerEmail: ledgerImport.PartnerEmail,
                                PartnerPhoneNumber: ledgerImport.PartnerPhoneNumber,
                                PartnerName: ledgerImport.PartnerName,
                                PartnerStreet: ledgerImport.PartnerStreet,
                                PartnerCity: ledgerImport.PartnerCity,
                                PartnerPostalCode: ledgerImport.PartnerPostalCode,
                                Status: ledgerImport.Status,
                                Bankname: ledgerImport.Bankname,
                                Bic: ledgerImport.Bic,
                                Blz: ledgerImport.Blz,
                                Iban: ledgerImport.Iban,
                                UstId: ledgerImport.UstId,
                                UserEmail: ledgerImport.UserEmail,
                                UserName: ledgerImport.UserName,
                                LogoBase64: this.InfoiceFormGroup.value.LogoBase64 || null,
                                SkontoAvilable: !!ledgerImport.DiscountPercentage,
                                SkontoPercentage: ledgerImport.DiscountPercentage || 0,
                                SkontoDays:
                                    (ledgerImport.DiscountPaymentDate &&
                                        ledgerImport.ConsolidatedDate &&
                                        moment(ledgerImport.DiscountPaymentDate).diff(moment(ledgerImport.ConsolidatedDate), 'days')) ||
                                    0,
                                DiscountPercentage: ledgerImport.GuaranteedDiscountPercentage,
                                Notes: ledgerImport.AdditionalData?.notes ? ledgerImport.AdditionalData?.notes : '',
                                OurSign: ledgerImport.AdditionalData?.ourSign ? ledgerImport.AdditionalData?.ourSign : '',
                                // DicountAmount: ledgerImport.DicountAmount
                                CeoName: ledgerImport.CeoName || '',
                                RegistryCourt: ledgerImport.RegistryCourt || '',
                                CommercialRegister: ledgerImport.CommercialRegister || '',
                                CommissionNumber: ledgerImport.CommissionNumber || '',
                                AccountsReceivableLedgerIds: ledgerImport.AccountsReceivableLedgerIds || [],
                                HideNetto: !!ledgerImport.HideNetto,
                                AccountsReceivableLedgerIdsFromLedgerImports: ledgerImport.AccountsReceivableLedgerIdsFromLedgerImports?.filter(id => bookingPositions[id]) || [],
                                TaxType: ledgerImport.TaxType,
                            });
                            this.InfoiceFormGroup.markAsPristine();
                            if (statusIsReadOnly(ledgerImport.Status)) {
                                this.DisableForms$.next(true);
                            }
                        } else {
                            this.InfoiceFormGroup.reset();
                        }
                        this.PushValuesToSyncfusion$.next();
                    }),
                )
                .subscribe(),

            // this.EditorCreated$.subscribe(() => this.PushValuesToSyncfusion$.next()),
            this.NewBookingPositionClick$.pipe(
                withLatestFrom(this.LedgerImport$, this.store.select(getCommissions), this.store.select(getAccountsReceivableLedgerDictionary)),
                tap(([, ledgerImport, commissions, arlDict]) => {
                        this.dialog
                            .open<NewBookingOptionComponent, NewOptionDialogData, NewBookingOptionComponentReturnType>(NewBookingOptionComponent, {
                                ...NewBookingOptionComponent.DefaultConfig,
                                data: ledgerImport
                                    ? {
                                          newOptionDefaultValues: {
                                              LedgerImportId: ledgerImport.Id,
                                          },
                                          accountsReceivableLedgerTemplateFilter: {
                                              CommissionTypeId: commissions.find((c) => c.Id === ledgerImport.CommissionId)?.CommissionTypeId,
                                          },
                                          ListView: false,
                                          addedTemplateIds: (this.InfoiceFormGroup.value.AccountsReceivableLedgerIds || []).map(bpId => _getArlChildren(bpId, arlDict)).flat()
                                              .map((b) => {
                                                  return b.CustomProperties?.rootTemplateId;
                                              })
                                              .filter(isNotNullOrUndefined),
                                          addToLedgerImport: true,
                                      }
                                    : { ListView: false, addedTemplateIds: [] },
                            })
                            .afterClosed()
                            .subscribe(async (NewArls) => {
                                if (NewArls) {
                                    if (this.arlTableComponent) {
                                        await this.arlTableComponent.addArlsToTable(NewArls);
                                    }
                                    this.InfoiceFormGroup.markAsDirty();
                                    // this.InfoiceFormGroup.controls.AccountsReceivableLedgerIds.setValue([...this.InfoiceFormGroup.value.AccountsReceivableLedgerIds, ...NewArls.map(a => a.Id),])
                                    // sort new Arls below the last clicked Arl
                                    // const list = this.SortedBookingPositionList$.value;
                                    // const invertedLastSelected  = list.reverse().findIndex(arl => this.IsSelected(arl))
                                    // list.reverse();
                                    // let lastSelected = -1
                                    // if (invertedLastSelected > -1) {
                                    //        lastSelected = list.length - invertedLastSelected
                                    //     }
                                    // this.SortedBookingPositionList$.next([
                                    //     ...list.filter((arl, i) => i < lastSelected),
                                    //     ...NewArls,
                                    //     ...list.filter((arl, i) => i >= lastSelected),
                                    // ])

                                    // sort all clicked Arls to the top of the list with the newest at last
                                    // this.SortedBookingPositionList$.next([
                                    //     ...this.SortedBookingPositionList$.value.filter(x => this.IsSelected(x)/* && !NewArls.some(a => a.Id === x.Id)*/),
                                    //     // ...this.SortedBookingPositionList$.value.filter(x => this.IsSelected(x) && NewArls.some(a => a.Id === x.Id)),
                                    //     ...NewArls,
                                    //     ...this.SortedBookingPositionList$.value.filter(x => !this.IsSelected(x))
                                    // ]);

                                    NewArls.forEach((x) => {
                                        if (!this.arlValidCheck(x)) {
                                            return;
                                        }
                                        // todo fix new booking position
                                        // this.CilickItem(x)
                                    });
                                    this.PushValuesToSyncfusion$.next();
                                    this.cdr.detectChanges();
                                }
                            });
                        // })
                }),
            ).subscribe(),
            this.NewAncillaryCostsClick$.subscribe(val => {
                this.dialog
                    .open<AncillaryCostsComponent, AncillaryData>(AncillaryCostsComponent, {
                        panelClass: 'custom-dialog-class-without-padding',
                        data: {
                            SortedBookingPositionList: val,
                            InfoiceFormGroup: this.InfoiceFormGroup,
                            LedgerImportId: this.LedgerImportId,
                        },
                    })
                    .afterClosed()
                    .subscribe(() => this.PushValuesToSyncfusion$.next());
            }),
            this.DisableForms$.pipe(
                tap(disable => {
                    if (disable) {
                        this.InfoiceFormGroup.disable();
                        this.personForm.disable();
                        this.InfoiceFormGroup.controls.Status.enable();
                        this.InfoiceFormGroup.controls.CommissionId.enable();
                    } else {
                        this.InfoiceFormGroup.enable();
                        this.personForm.enable();
                    }
                }),
            ).subscribe(),
            this.InfoiceFormGroup.controls.SkontoAvilable.valueChanges
                .pipe(
                    tap(skonto => {
                        if (skonto) {
                            this.InfoiceFormGroup.controls.SkontoDays.enable();
                            this.InfoiceFormGroup.controls.SkontoPercentage.enable();
                        } else {
                            this.InfoiceFormGroup.controls.SkontoDays.disable();
                            this.InfoiceFormGroup.controls.SkontoPercentage.disable();
                        }
                    }),
                )
                .subscribe(),
            this.InfoiceFormGroup.controls.CommissionId.valueChanges.subscribe(cid => {
                if (cid && cid.Auftragsnummer !== this.InfoiceFormGroup.value.CommissionNumber) {
                    this.InfoiceFormGroup.controls.CommissionNumber.setValue(cid.Auftragsnummer);
                }
            }),
            this.InfoiceFormGroup.controls.Customer.valueChanges
                .pipe(
                    skip(1), // dirty fix for D277-3741 must be deleted when D277-3753
                    filter(isNotNullOrUndefined),
                    distinctUntilChanged((a,b)=> a.Id === b.Id), // wurde immer nach dem speicher getriggert und ich konnt nicht herausfinden woher deshalb nur noch beim ändern des kunden D277-2955
                    withLatestFrom(this.store.select(getOffices), this.store.select(getPartnerOffices), this.store.select(getNotDeletedCustomers)),
                    tap(([customerForm, offices, partnerOffices, customers]) => {
                        const customer = customers.find(c => c.Id === customerForm.Id);
                        const office = offices.find(o => o.CustomerId === customer.Id);
                        this.InfoiceFormGroup.controls.CustomerName.setValue(customer.Name);
                        this.InfoiceFormGroup.controls.CustomerStreet.setValue(office?.Street);
                        this.InfoiceFormGroup.controls.CustomerPostalCode.setValue(office?.PostalCode);
                        this.InfoiceFormGroup.controls.CustomerCity.setValue(office?.City);
                        this.InfoiceFormGroup.controls.CustomerNumber.setValue(customer.CustomerNo);
                        if (customer.PaymentDiscount) {
                            this.InfoiceFormGroup.controls.SkontoAvilable.setValue(true);
                            this.InfoiceFormGroup.controls.SkontoPercentage.setValue(customer.PaymentDiscount);
                        } else {
                            this.InfoiceFormGroup.controls.SkontoAvilable.setValue(false);
                            this.InfoiceFormGroup.controls.SkontoPercentage.setValue(0);
                        }
                        if (isNotNullOrUndefined(customer.PaymentTargetInDays) && this.InfoiceFormGroup.value.InvoiceDate) {
                            this.InfoiceFormGroup.controls.DueDate.setValue(moment(this.InfoiceFormGroup.value.InvoiceDate).add(customer.PaymentTargetInDays, 'days'))
                        }

                        if (customer.PartnerOfficeId) {
                            const partnerOffice =  partnerOffices.find(po => po.Id === customer.PartnerOfficeId);
                            this.InfoiceFormGroup.controls.PartnerOffice.setValue(partnerOffice ? Object.assign(partnerOffice.Clone(), {optionLabel: partnerOffice.DisplayName}) : null);
                        }

                        this.InfoiceFormGroup.controls.PartnerOffice.disable();
                        this.InfoiceFormGroup.controls.PartnerOffice.markAsPristine();
                    }),
                )
                .subscribe(),
            this.InfoiceFormGroup.controls.PartnerOffice.valueChanges
                .pipe(
                    filter(isNotNullOrUndefined),
                    tap(p => {
                        this.InfoiceFormGroup.patchValue({
                            Iban: p?.IBAN,
                            Bic: p?.Bic,
                            Bankname: p?.BankName,
                            UstId: p?.UstID,

                            PartnerName: p.Name,
                            PartnerStreet: p.Street,
                            PartnerPostalCode: p.PostalCode,
                            PartnerCity: p.City,
                            PartnerPhoneNumber: p.PhoneNumber,
                            PartnerEmail: p.Email,
                        });
                    }),
                )
                .subscribe(),
            this.DownloadPdfWithoutFile$.pipe(
                tap(type => {
                    this.InfoiceFormGroup.markAsDirty();
                    this.SaveClick();
                }),
                withLatestFrom(this.LedgerImport$),
                switchMap(([type, li]) => {
                    console.log(type, li)
                    if (type === 'pdf') {
                        return this.DownloadLink$.pipe(
                            filter(isNotNullOrUndefined),
                            take(1),
                            withLatestFrom(this.LedgerImport$),
                            tap(([link, invoice]) => {
                                const btn = document.createElement('a');
                                btn.download = this.GetFilename(invoice.ConsolidatedInvoiceId);
                                btn.href = link;
                                document.body.appendChild(btn);
                                btn.click();
                                document.body.removeChild(btn);
                            }),
                        );
                    } else if (type === 'xInvoice') {
                        this.store.dispatch(LedgerImportActionTypes.CreateXRechnungFromLedgerImport({ Payload: { id: li.Id } }));
                        return this.XInvoiceDownloadLink$.pipe(
                            skip(1),
                            take(1),
                            withLatestFrom(this.LedgerImport$),
                            tap(([link, invoice]) => {
                                const btn = document.createElement('a');
                                btn.download = this.GetFilename(invoice.ConsolidatedInvoiceId);
                                btn.href = link;
                                document.body.appendChild(btn);
                                btn.click();
                                document.body.removeChild(btn);
                            }),
                        );
                    }
                }),
            ).subscribe(),

            this.OpenDeleteDialog$.pipe(
                withLatestFrom(this.LedgerImport$),
                tap(([, ledgerImport]) => {
                    this.dialog
                        .open<AppConfirmationDialogComponent, AppConfirmationDialogData>(AppConfirmationDialogComponent, {
                            data: {
                                paragraph: ledgerImport.IsTemplate
                                    ? 'Vorlage wirklich löschen?'
                                    : this.LedgerImportDocumentTypeNames.get(ledgerImport.DocumentType) + ' wirklich löschen?',
                                styleDelete: true,
                            },
                        })
                        .afterClosed()
                        .pipe(
                            filter(([response]) => !!response),
                            withLatestFrom(this.LedgerImport$),
                            tap(([, ledgerImport]) => {
                                this.store.dispatch(LedgerImportActionTypes.DeleteLedgerImport({ Payload: { id: ledgerImport.Id } }));
                            }),
                            switchMap(() => this.store.select(getLedgerImports)),
                            skip(1),
                            take(1),
                            tap(() => this.router.navigate(['../../'], { relativeTo: this.route })),
                        )
                        .subscribe();
                }),
            ).subscribe(),
        );
    }

    ngAfterViewInit() {
        // setTimeout(() => this.EditorCreated$.next(), 3000)
        this.afterViewInit$.next(true);
        this.subscriptions.push(
            // fix for finding dragHandle in mat-table https://github.com/angular/components/issues/13770
            this.bs.TouchQuery
                .pipe(
                    filter(touch => touch),
                    switchMap(() => combineLatest([this.drags.changes, this.handles.changes]).pipe(
                        startWith([]),
                        map(() => zip(this.drags.toArray(), this.handles.toArray())),
                        delay(100), // waits for cdk to finish his work
                        mergeAll()
                    )),
                ).subscribe(([drag, handle]) => {
                    drag._dragRef.withHandles([handle.element]);
                }),
        );
        this.nothingDirty$ = combineLatest([
            this.ngDoCheck$.pipe(
                startWith(null),
                map(() => this.InfoiceFormGroup.dirty),
            ),
            this.InfoiceFormGroup.valueChanges.pipe(startWith(null), map(() => this.InfoiceFormGroup.dirty)),
            this.SfdtIsDirty$,
            this.arlTableComponent.SomeFormDirty$,
            this.InfoiceFormGroup.valueChanges.pipe(
                startWith(null),
                map(() => this.InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIdsFromLedgerImports),
                    distinctUntilChanged(compareEqualityAsJsonString),
                    switchMap(AccountsReceivableLedgerIdsFromLedgerImports => {
                        const forms = AccountsReceivableLedgerIdsFromLedgerImports.map(id => this.arlFormDataService.getForm(id))

                        if (forms.some(f => !f)) {
                            console.error('form not found')
                        }
                        if (forms.some(f => !f) || forms.length === 0) {
                            return of(false);
                        }
                        return combineLatest(forms.map(f => this.ngDoCheck$.pipe(
                            startWith(null),
                            map(() => {
                                return f.dirty;
                            }),
                            distinctUntilChanged(),
                        ))).pipe(
                            map(dirties => dirties.some(d => d)),
                        )
                })),
        ]).pipe(
            map(dirties => dirties.every(d => !d)),
                // shareReplay({refCount: true, bufferSize: 1}), ont share so you can check synchron with firstValueFrom
            );
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    ngDoCheck(): void {
        this.ngDoCheck$.next();
    }
    private getAmountSumForArls$(arlIds: number[]) {
        return this.afterViewInit$.pipe(
            filter(v => v),
            switchMap(() => this.showPartialInvoiceList$.pipe(
                switchMap(show => show ? this.partialInvoiceTable.dataSource.getAmountSumForArls$(arlIds) : of({
                    netto: null,
                    netto_number: null,
                    brutto: null,
                    brutto_number: null,
                    tax: null,
                    tax_number: null,
                }))
            )),
        )
    }
    private generateSfdt = (useDirtyValuesFromArlForms = false) => firstValueFrom(combineLatest([
        this.invoiceTemplate.pipe(filter(isNotNullOrUndefined)),
        this.LedgerImport$,
        this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
        getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary),
        this.store.select(getLedgerImports).pipe(filter(isNotNullOrUndefined)),
        // this.arlTableComponent ? this.arlTableComponent.AllFormValueChanges$ : of(null),
        // useDirtyValuesFromArlForms ? this.arlTableComponent?.getArlCalculationForms() : of(null), // todo wait in view init
        this.afterViewInit$.pipe(filter(init => init), switchMap(() => useDirtyValuesFromArlForms ? this.arlTableComponent?.getArlCalculationForms() : of(null))),
        this.AddARLDefaultValues$,
        this.subTotalFromForms$,
        this.totalFromForms$,
        this.alreadyPayedSum$,
        this.alreadyPayedSumDifference$,
    ])).then(([
        template,
        li,
        quantityTypes,
        arls,
        ledgerImports,
        calculationFormMap,
        defaultValuesForAddedArls,
        subTotalFromForms,
        totalFromForms,
        alreadyPayedSum,
        alreadyPayedSumDifference,
     ]) => {
        const defaultForNewArls = {
            Tax: defaultValuesForAddedArls.tax,
            IsVisible: defaultValuesForAddedArls.isVisible,
            CurrencyCode: defaultValuesForAddedArls.currencyCode,
        }
        const variables = this.getTwigVariables(li, quantityTypes, arls,
            subTotalFromForms,
            totalFromForms,
            alreadyPayedSum,
            alreadyPayedSumDifference,
            useDirtyValuesFromArlForms, calculationFormMap, defaultForNewArls);
        console.log({...variables})
        const body = this.sfdtTemplateService.FillTemplateByTwig(template.Html, variables);
        return {body};
    })
    public pdfPreview$: Observable<any> = this.PushValuesToSyncfusion$.pipe(
        startWith(null),
        switchMap(() => this.generateSfdt(true)),
        distinctUntilChanged((a, b) => a.body === b.body /*&& a.footer === b.footer && a.header === b.header*/),
        tap(() => this.previewPdfIsLoading$.next(true)),
        switchMap(templates => {
            let compressedBodyTemplate: Promise<string>;

            let compress = false;

            if (gzipCompressionIsSupported() && window['env']["useGzip"]) {
                compress = true;
                compressedBodyTemplate = gzipCompress(templates.body)
                    .then(blob =>  btoa(Uint8ToString(blob)))
                    .catch(() => {
                        compress = false;
                        return '';
                    })
            }
            return (compress ? compressedBodyTemplate : firstValueFrom(of(''))).then((compressedBody) => {
                return this.ledgerImportDataService.getPdfFromHtml({
                    Html: compress ? compressedBody : templates.body,
                    IsGzip: compress,
                })
            });
        }),
        tap(blob => {
            if (!blob) {
                this.previewPdfIsLoading$.next(false);
            }
        }),
        filter(blob => !!blob),
        switchMap((file: Blob) => new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
        })),
        map(v => {
            // const str = 'data:application/pdf;base64,' + v.split(',')[1]
            if (this.previewIframe) {
                this.previewIframe.nativeElement.contentWindow.location.replace(v)
                return 'already-replaced'
            }
            return this.sanitizer.bypassSecurityTrustResourceUrl(v)
        }),
        tap(() => this.previewPdfIsLoading$.next(false)),
        filter(v => v !== 'already-replaced')
    );
    async HandleLogoInput(blob: any) {
        const base64 = (await blobToBase64(blob)) as string;
        const dimensions = await getImageDimensionsFromBase64(base64);
        this.InfoiceFormGroup.controls.LogoBase64.setValue({
            base64,
            width: dimensions.width,
            height: dimensions.heigth,
        });
    }
    private submitAccountsReceivableLedgerIdsFromLedgerImports(): Promise<{ someWasChanged: boolean; arls: AccountsReceivableLedgerEntity[] }> {
        const ids = this.InfoiceFormGroup.getRawValue().AccountsReceivableLedgerIdsFromLedgerImports;
        if (!ids?.length) {
            return firstValueFrom(of({someWasChanged: false, arls: []}))
        }
        const dirtyForms: Array<{id: number, form: ReturnType<typeof this.arlFormDataService.getForm>}> = [];
        const pristineIds: number[] = [];
        ids.forEach(id => {
            const form = this.arlFormDataService.getForm(id);
            if (form.dirty) {
                dirtyForms.push({id, form});
            } else {
                pristineIds.push(id);
            }
        })
        const getFromState$ = (arlIds: number[]) => firstValueFrom(this.store.select(getAccountsReceivableLedgerDictionary).pipe(map(arls => arlIds.map(id => arls[id]))))
        const submit$ = (arlForms: typeof dirtyForms) => {
            if (!arlForms.length) {
                return firstValueFrom(of([]));
            }
            const mutation = arlForms.map<DaveMutationChangeAccountsReceivableLedgerArgs>(({id, form}) => {
                const formValues = form.getRawValue();
                return {
                    id,
                    information: formValues.Information,
                    baseCost: formValues.BaseCost,
                    bookingText: formValues.BookingText,
                    tax: formValues.Tax,
                    date: formValues.Date ? FrontendDate(formValues.Date.toDate(), true) : null,
                    payedAmount: formValues.AlreadyPayed,
                    multiplikator: 1,
                }
            });
            return this.arlDataService.changeArls(mutation).then(arls => {
                dirtyForms.forEach(f => f.form.markAsPristine())
                return arls;
            });
        }
        return firstValueFrom(combineLatest([getFromState$(pristineIds), submit$(dirtyForms)]).pipe(
            map(([arlsFromState, arlsChanged]) => ({
                someWasChanged: !!arlsChanged.length,
                arls: [...arlsFromState, ...arlsChanged],
            }))
        ));
    }

    /**
     * Submit ledgerImport and all arls
     * @param force Submit even when nothing dirty
     */
    public SaveClick(force = false): Promise<'no-content' | 'success' | 'failure'> {
        console.log('SaveClick')
        if (!this.InfoiceFormGroup.getRawValue().InvoiceDate && this.InfoiceFormGroup.getRawValue().Status === LedgerImportStatusEnum.Verrechnet) {
            this.toastrService.error('Zum verrechnen muss "' + this.getConsolidatedInvoiceDateLabel() + '" ausgefüllt werden');
            this.InfoiceFormGroup.controls.InvoiceDate.markAsTouched({ onlySelf: true });
            this.InfoiceFormGroup.controls.InvoiceDate.updateValueAndValidity({onlySelf: true});
            return;
        }
        return firstValueFrom(this.nothingDirty$).then(pristine => {
            if (pristine && !force) {
                return 'no-content';
            }
            return firstValueFrom(combineLatest([
                this.LedgerImport$,
                this.invoiceTemplate,
                this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
                getFetched$(this.store, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary),
                this.store.select(getLedgerImports).pipe(filter(isNotNullOrUndefined)),
                this.SfdtIsDirty$,
                this.arlTableComponent.Submit(),
                this.arlTableComponent ? this.arlTableComponent.SomeFormDirty$.pipe(first()) : of(null),
                this.submitAccountsReceivableLedgerIdsFromLedgerImports(),
                this.subTotalFromForms$,
                this.totalFromForms$,
                this.alreadyPayedSum$,
                this.alreadyPayedSumDifference$,
                // of([])
            ])).then(([
                ledgerImport,
                template,
                quantityTypes,
                arls,
                ledgerImports,
                sfdtDirty,
                submittedBookingPositions,
                someDirty,
                submittedBookingPositionsFromLedgerImports,
                subTotalFromForms,
                totalFromForms,
                alreadyPayedSum,
                alreadyPayedSumDifference,
             ]) => {

                console.log('SaveClick')

                const InfoiceFormGroupValue = this.InfoiceFormGroup.getRawValue();
                const arlDict: Dictionary<AccountsReceivableLedgerEntity> = {};
                submittedBookingPositions.arls.forEach((curr) => arlDict[curr.Id] = curr);
                submittedBookingPositionsFromLedgerImports.arls.forEach((curr) => arlDict[curr.Id] = curr);
                let amount = 0;
                [...InfoiceFormGroupValue.AccountsReceivableLedgerIds, ...InfoiceFormGroupValue.AccountsReceivableLedgerIdsFromLedgerImports].map(bpId => _getArlChildren(bpId, arlDict)).flat().forEach(bp => {
                    amount = +(amount + bp.CompleteCost).toFixed(4);
                });

                amount = calculateConsolidatedAmount(arlDict, [...InfoiceFormGroupValue.AccountsReceivableLedgerIds, ...InfoiceFormGroupValue.AccountsReceivableLedgerIdsFromLedgerImports], InfoiceFormGroupValue.DiscountPercentage)
                    const variables = this.getTwigVariables(
                        ledgerImport,
                        quantityTypes,
                        arls,
                        subTotalFromForms,
                        totalFromForms,
                        alreadyPayedSum,
                        alreadyPayedSumDifference,
            );
                    if (!ledgerImport.AutomaticInvoiceId && InfoiceFormGroupValue.AutomaticNr) {
                        variables.InvoiceForm.ConsolidatedInvoiceId = '###ConsolidatedInvoiceId###';
                }
                // const header = this.sfdtTemplateService.FillTemplateByTwig(template.Options.HeaderTemplate, variables);
                // const footer = this.sfdtTemplateService.FillTemplateByTwig(template.Options.FooterTemplate, variables);
                const body = this.sfdtTemplateService.FillTemplateByTwig(template.Html, variables);

                const values: DaveMutationChangeLedgerImportArgs = {
                    id: 0,
                    isDirty: this.InfoiceFormGroup.dirty || sfdtDirty || someDirty,
                    commissionId: InfoiceFormGroupValue.CommissionId?.Id || null,
                    customerId: InfoiceFormGroupValue.Customer?.Id || null,
                    partnerOfficeId: this.InfoiceFormGroup.controls.PartnerOffice.getRawValue()?.Id,
                    // InfoiceFormGroupValue.Skonto, // ToDo
                    // InfoiceFormGroupValue.SkontoDate, // ToDo
                    consolidatedDate: InfoiceFormGroupValue.InvoiceDate && FrontendDate(InfoiceFormGroupValue.InvoiceDate.toDate()),
                    textTop: InfoiceFormGroupValue.TextTop,
                    textBottom: InfoiceFormGroupValue.TextBottom,
                    accountsReceivableLedgerIds: InfoiceFormGroupValue.AccountsReceivableLedgerIds,
                    accountsReceivableLedgerIdsFromLedgerImports: InfoiceFormGroupValue.AccountsReceivableLedgerIdsFromLedgerImports,
                    consolidatedAmount: amount,
                    consolidatedInvoiceId: InfoiceFormGroupValue.ConsolidatedInvoiceId,
                    //replacing causes trouble here
                    customerName: InfoiceFormGroupValue.CustomerName,
                    customerStreet: InfoiceFormGroupValue.CustomerStreet,
                    customerCity: InfoiceFormGroupValue.CustomerCity,
                    customerPostalCode: InfoiceFormGroupValue.CustomerPostalCode,
                    customerCountry: InfoiceFormGroupValue.CustomerCountry,
                    customerEmail: InfoiceFormGroupValue.CustomerEmail,
                    partnerCountry: InfoiceFormGroupValue.PartnerCountry,
                    partnerEmail: InfoiceFormGroupValue.PartnerEmail,
                    partnerPhoneNumber: InfoiceFormGroupValue.PartnerPhoneNumber,
                    partnerName: InfoiceFormGroupValue.PartnerName,
                    partnerStreet: InfoiceFormGroupValue.PartnerStreet,
                    partnerCity: InfoiceFormGroupValue.PartnerCity,
                    partnerPostalCode: InfoiceFormGroupValue.PartnerPostalCode,
                    status: this.InfoiceFormGroup.controls.Status.value,
                    bankname: InfoiceFormGroupValue.Bankname,
                    bic: InfoiceFormGroupValue.Bic,
                    blz: InfoiceFormGroupValue.Blz,
                    iban: InfoiceFormGroupValue.Iban,
                    ustId: InfoiceFormGroupValue.UstId,
                    userName: InfoiceFormGroupValue.UserName,
                    userEmail: InfoiceFormGroupValue.UserEmail,
                    consolidatedDeliveryDate: InfoiceFormGroupValue.DeliveryDate && FrontendDate(InfoiceFormGroupValue.DeliveryDate.toDate()),
                    dueDate: InfoiceFormGroupValue.DueDate && FrontendDate(InfoiceFormGroupValue.DueDate.toDate()),
                    html: body, //todo
                // options: {
                //     ...HtmlToPdfRequestOptionsToGql(template.Options),
                //     headerTemplate: header,
                //     footerTemplate: footer,
                // },
                    discountPaymentDate:
                        InfoiceFormGroupValue.InvoiceDate && InfoiceFormGroupValue.SkontoDays
                            ? FrontendDate(
                                moment(InfoiceFormGroupValue.InvoiceDate)
                                    .add(InfoiceFormGroupValue.SkontoDays, 'days')
                                    .toDate(),
                            )
                            : null,
                    // discountPaymentDate2: null,
                    discountPercentage: InfoiceFormGroupValue.SkontoPercentage,
                    // discountPercentage2: null,
                    guaranteedDiscountPercentage: InfoiceFormGroupValue.DiscountPercentage,
                    automaticInvoiceId: !ledgerImport.AutomaticInvoiceId && InfoiceFormGroupValue.AutomaticNr,
                    additionalData: JSON.stringify({
                        ...ledgerImport.AdditionalData,
                        customerNumber: InfoiceFormGroupValue.CustomerNumber,
                        twigTemplateId: template.Id,
                        notes: InfoiceFormGroupValue.Notes,
                        ourSign: InfoiceFormGroupValue.OurSign,
                        assignedUserId: InfoiceFormGroupValue.AssignedUser?.Id,
                    }),
                    registryCourt: InfoiceFormGroupValue.RegistryCourt,
                    commercialCourt: InfoiceFormGroupValue.CommercialRegister,
                    ceoName: InfoiceFormGroupValue.CeoName,
                    commissionNumber: InfoiceFormGroupValue.CommissionNumber,
                    hideNetto: InfoiceFormGroupValue.HideNetto,
                    taxType: InfoiceFormGroupValue.TaxType,
                };
                if (ledgerImport) {
                    this.LS.startLoading('invoice-editor_submit-ledger-import');
                    this.store.dispatch(
                        LedgerImportActionTypes.ChangeLedgerImports({
                            Payload: {
                                ...values,
                                id: ledgerImport.Id,
                            },
                        }),
                    );
                    this.InfoiceFormGroup.reset(this.InfoiceFormGroup.getRawValue());
                    return firstValueFrom(this.actions$.pipe(ofType(LedgerImportActionTypes.UpdateAll, BaseActionTypes.ErrorAction)))
                        .then(action => {
                            this.LS.endLoading('invoice-editor_submit-ledger-import');
                            if (action.type === LedgerImportActionTypes.UpdateAll.type){
                                this.InfoiceFormGroup.markAsPristine();
                                return 'success'
                            }
                        });
                } else {
                    console.error('es ist nicht vorgesehen hier eine neue Rechnung zu erzeugen');
                    return 'failure'
                    // this.store.dispatch(
                    //     LedgerImportActionTypes.AddLedgerImports({
                    //         Payload: {
                    //             ...values,
                    //         },
                    //     }),
                    // );
                }
            })
        })
    }
    // public AddBookingPositionClick(index: number = 0) {
    //     this.LS.startLoading('invoice-editor-add-arl');
    //
    //     firstValueFrom(this.actions$.pipe(ofType(AccountsReceivableLedgerActionTypes.UpdateSingleAccountsReceivableLedger, BaseActionTypes.ErrorAction))).then(action => {
    //         this.LS.endLoading('invoice-editor-add-arl');
    //         if (action.type === AccountsReceivableLedgerActionTypes.UpdateSingleAccountsReceivableLedger.type) {
    //             this.InfoiceFormGroup.markAsDirty();
    //             const list = this.SortedBookingPositionList$.value;
    //             this.SortedBookingPositionList$.next([
    //                 ...list.filter((arl, i) => i < index),
    //                 action.Payload,
    //                 ...list.filter((arl, i) => i >= index),
    //             ]);
    //             if (!this.arlValidCheck(action.Payload)){
    //                 return;
    //             }
    //             this.PushValuesToSyncfusion$.next();
    //         }
    //
    //     })
    //     firstValueFrom(this.AddARLDefaultValues$).then(pl=> {
    //         this.store.dispatch(AccountsReceivableLedgerActionTypes.AddAccountsReceivableLedger({Payload: {
    //                 ...pl,
    //             }}))
    //     })
    // }
    public BookingPositionClicked(s: AccountsReceivableLedgerEntity) {
        this.dialog.open<ModifyBookingOptionDialogComponent, ModifyBookingOptionDialogComponentData>(ModifyBookingOptionDialogComponent, {
            ...ModifyBookingOptionDialogComponent.DefaultConfig,
            data: { BookingOptionId: s.Id,  },
        });
    }
    GetQuantityTypeById(id: number, quantityTypes: QuantityTypeEntity[]) {
        return quantityTypes?.find(q => q.Id === id)?.Name;
    }
    arlValidCheck(arl: AccountsReceivableLedgerEntity): boolean {
        if (false/*!!this.InfoiceFormGroup.value.BookingPositions.find((bp) => determineIfIsArlEntityOrGroupInterface(bp) && bp.CurrencyCode !== arl.CurrencyCode)*/) {
            this.store.dispatch(
                BaseActionTypes.ErrorAction({
                    Payload: {
                        ToasterMessage: 'Verschiedene Währungen können nicht in einem Dokument verwendet werden',
                    },
                }),
            );
            return false;
        }
        return true;
    }
    // CilickItem(x: AccountsReceivableLedgerEntity) {
    //     if (!this.arlValidCheck(x)){
    //         return;
    //     }
    //     if (!!this.InfoiceFormGroup.value.BookingPositions.find(bp => bp.Id === x.Id)) {
    //         this.InfoiceFormGroup.controls.BookingPositions.setValue(
    //             this.InfoiceFormGroup.value.BookingPositions.slice()
    //                 .filter(bp => bp.Id !== x.Id)
    //                 .filter(isNotNullOrUndefined),
    //         );
    //     } else {
    //         this.InfoiceFormGroup.controls.BookingPositions.setValue([...this.InfoiceFormGroup.value.BookingPositions, x].filter(isNotNullOrUndefined));
    //     }
    //     this.OnListDrop();
    //     // this.PushValuesToSyncfusion$.next();
    // }
    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> | boolean {
        const notDirty = !this.InfoiceFormGroup.dirty;
        if (!notDirty) {
            return of(notDirty);
        }
        return this.arlTableComponent ? this.arlTableComponent.SomeFormDirty$.pipe(first(), tap(dirty => console.warn('deactivate is dirty', dirty)), map((dirty) => !dirty)) : of(true);
    }
    // IsSelected(x: AccountsReceivableLedgerEntity | groupInterface) {
    //     return determineIfIsArlEntityOrGroupInterface(x) ? !!this.InfoiceFormGroup.getRawValue().BookingPositions?.find(bp =>  determineIfIsArlEntityOrGroupInterface(bp) && bp.Id === x?.Id) : !!this.InfoiceFormGroup.getRawValue()?.BookingPositions.some(bp => !determineIfIsArlEntityOrGroupInterface(bp) && bp.uniqId === x?.uniqId);
    // }
    // GetPosition(x: AccountsReceivableLedgerEntity) {
    //     const index = this.InfoiceFormGroup.getRawValue().BookingPositions?.map(bp => bp?.Id).indexOf(x.Id);
    //     return index > -1 ? index + 1 : '';
    // }
    // OnListDrop(event?: CdkDragDrop<any>) {
    //     this.InfoiceFormGroup.markAsDirty();
    //     const array = this.SortedBookingPositionList$.value.slice().filter(isNotNullOrUndefined);
    //     if (event) {
    //         moveItemInArray(array, event.previousIndex, event.currentIndex);
    //     }
    //     this.SortedBookingPositionList$.next(array);
    //     this.InfoiceFormGroup.controls.BookingPositions.setValue(array.filter(bp => this.IsSelected(bp)));
    // }
    // protected getSelectedArlIds() {
    //     return this.InfoiceFormGroup.value.BookingPositions?.filter(determineIfIsArlEntityOrGroupInterface).map(v => v.Id) || [];
    // }
    // protected getSelected() {
    //     const ret =(this.InfoiceFormGroup.value.BookingPositions || []).reduce((previousValue, currentValue) => {
    //         if (determineIfIsArlEntityOrGroupInterface(currentValue)) {
    //             previousValue.arlIds.push(currentValue.Id);
    //         } else {
    //             previousValue.groupIds.push(currentValue.uniqId);
    //         }
    //         return previousValue;
    //     }, {arlIds: new Array<number>(), groupIds: new Array<number>()});
    //     console.log('getSelected', ret, this.InfoiceFormGroup.value.BookingPositions);
    //     return ret
    // }
    // onSelectionChange({arlIds, groupIds}: {arlIds: number[], groupIds: number[]}) {
    //     this.InfoiceFormGroup.markAsDirty();
    //     const array = this.SortedBookingPositionList$.value.slice().filter(bp => isNotNullOrUndefined(bp) && determineIfIsArlEntityOrGroupInterface(bp) ? arlIds.includes(bp.Id) : groupIds.includes(bp.uniqId));
    //     this.InfoiceFormGroup.controls.BookingPositions.setValue(array);
    // }
    // protected onSelectedArlsChange(ids: number[]) {
    //     this.InfoiceFormGroup.markAsDirty();
    //     const array = this.SortedBookingPositionList$.value.slice().filter(isNotNullOrUndefined).filter(determineIfIsArlEntityOrGroupInterface).filter(bp => ids.includes(bp.Id));
    //     this.InfoiceFormGroup.controls.BookingPositions.setValue(array);
    // }
    private getPartialInvoiceText(arl: AccountsReceivableLedgerEntity, ledgerImports: LedgerImportEntity[], ledgerImport: LedgerImportEntity) {
        const liFromArl = ledgerImports.find(l => l.Id === arl.CustomProperties?.partialInvoiceId);
        if (!liFromArl) {
            const err = { name: 'partialInvoice Not found', arl, ledgerImports };
            throw Error(JSON.stringify(err));
        }
        return 'Auflistung Abschlagsrechnungen:' +
                '\n' +
                '1. ' +
                arl.Information +
                '\n' +
                `\tRechnung Nr.: ${liFromArl.ConsolidatedInvoiceId} vom ${this.datePipe.transform(liFromArl.ConsolidatedDate)}` +
                ` (Netto: ${this.currencyPipe.transform(
                    (ledgerImport.DocumentType === LedgerImportDocumentTypes.ClosingInvoice ? arl.Amount * -1 : liFromArl.ConsolidatedAmount) -
                        arl.CustomProperties.taxesFromPartialInvoice?.reduce((sum, cur) => sum + cur.amount / 100, 0),
                    liFromArl.ConsolidatedCurrencyCode,
                )}` +
                (arl.CustomProperties.taxesFromPartialInvoice
                    ? arl.CustomProperties.taxesFromPartialInvoice
                          ?.map(t => ` zzgl. ${t.tax} % MwSt. ${this.currencyPipe.transform(t.amount / 100, liFromArl.ConsolidatedCurrencyCode)}`)
                          .join(', ')
                    : '') +
                ` = Brutto ${this.currencyPipe.transform(
                    ledgerImport.DocumentType === LedgerImportDocumentTypes.ClosingInvoice ? arl.Amount * -1 : liFromArl.ConsolidatedAmount,
                    liFromArl.ConsolidatedCurrencyCode,
                )})`

    }
    GetFilename = (consolidatedInvoiceId: string) => this.LedgerImportDocTypeName$.value + ' - ' + consolidatedInvoiceId;
    // BookingPositionVariables = (BookingPositions: Array<AccountsReceivableLedgerEntity | groupInterface> = [], quantityTypes: QuantityTypeEntity[], discountPercentage: number, arlDict: Dictionary<AccountsReceivableLedgerEntity>) => {
    //     const DiscountPercentage = discountPercentage || '';
    //     let DiscountAmount = '0';
    //     let SumNetto = '0';
    //     let SumNettoWithDiscount = '0';
    //     let Sum = '0';
    //     let SumWithDiscount = '0';
    //     let Taxes = [];
    //     BookingPositions.forEach(bp => {
    //         if (bp && determineIfIsArlEntityOrGroupInterface(bp) && bp?.CompleteTaxCost !== undefined) {
    //             SumNetto = (+SumNetto + bp.CompleteCost - bp.CompleteTaxCost).toFixed(2);
    //             Sum = (+Sum + bp.CompleteCost).toFixed(2);
    //             SumNettoWithDiscount = (+SumNettoWithDiscount + CalcDiscount(bp.CompleteCost - bp.CompleteTaxCost, discountPercentage)).toFixed(2);
    //             SumWithDiscount = (+SumWithDiscount + CalcDiscount(bp.CompleteCost, discountPercentage)).toFixed(2);
    //             if (Taxes.find(([tax, sum]) => tax === bp.Tax)) {
    //                 const index = Taxes.indexOf(Taxes.find(([tax, sum]) => tax === bp.Tax));
    //                 Taxes[index][1] = (+Taxes[index][1] + CalcDiscount(bp.CompleteTaxCost, discountPercentage)).toFixed(2);
    //             } else {
    //                 Taxes.push([bp.Tax, CalcDiscount(bp.CompleteTaxCost, discountPercentage).toFixed(2)]);
    //             }
    //         }
    //     });
    //     // ja hier wird einfach der currencyCode qus dem ersten arl genommen, das BE stellt sicher das es keine verschiedenen in einer Rechnung gibt
    //     const CurrencyCode = (BookingPositions.find(bp => determineIfIsArlEntityOrGroupInterface(bp)) as AccountsReceivableLedgerEntity | undefined)?.CurrencyCode;
    //     if (CurrencyCode) {
    //         // const CurrencyCode = BookingPositions[0].CurrencyCode;
    //         DiscountAmount = this.currencyPipe.transform((+SumNettoWithDiscount - +SumNetto).toFixed(2), CurrencyCode);
    //
    //         SumNetto = this.currencyPipe.transform(SumNetto, CurrencyCode);
    //         SumWithDiscount = this.currencyPipe.transform(SumWithDiscount, CurrencyCode);
    //         Sum = this.currencyPipe.transform(Sum, CurrencyCode);
    //         Taxes = Taxes.slice().map(([tax, sum]) => [tax, this.currencyPipe.transform(sum, CurrencyCode)]);
    //     }
    //     const getBPVar = (b: AccountsReceivableLedgerEntity | groupInterface) => {
    //         if (determineIfIsArlEntityOrGroupInterface(b)) {
    //             return {
    //                 BookingText: sfdtSafeString(b.BookingText) || '',
    //                 Information: sfdtSafeString(b.Information) || '',
    //                 Quantity: b.Quantity && this.decimalPipe.transform(b.Quantity) + '',
    //                 QuantityType: quantityTypes.find(q => q.Id === b.QuantityTypeId)?.Name  || '',
    //                 BaseCost: this.currencyPipe.transform(b.BaseCost, b.CurrencyCode) || '',
    //                 CompleteCost: b.CompleteCost - b.CompleteTaxCost ? this.currencyPipe.transform((b.CompleteCost - b.CompleteTaxCost).toFixed(2), b.CurrencyCode) : '',
    //             }
    //         } else {
    //             return {
    //                 Information: sfdtSafeString(b.name) || ''
    //             }
    //         }
    //     };
    //     // todo auf neue logik umbauen
    //     const positions = calcPositionStringsLegacy<AccountsReceivableLedgerEntity, groupInterface>(BookingPositions, determineIfIsArlEntityOrGroupInterface, (a) => true, (e) => e.type === 'group-end', (e) => e.type === 'group');
    //     const bps = [];
    //     BookingPositions.forEach((b, index) => {
    //         if (!determineIfIsArlEntityOrGroupInterface(b) || b.IsVisible || !b.ArlIds || !b.ArlIds.length) {
    //             if (determineIfIsArlEntityOrGroupInterface(b)) {
    //                 const arl = arlDict[b.Id];
    //                 if (!arl) {
    //                     throw new Error('ARL not found');
    //                 }
    //             }
    //             if (determineIfIsArlEntityOrGroupInterface(b) || b.type !== 'group-end') {
    //                 bps.push({
    //                     Position: positions[index],
    //                     ...getBPVar(b),
    //                 });
    //             }
    //         }
    //         console.log({b, BookingPositions})
    //         if (determineIfIsArlEntityOrGroupInterface(b) && b.ArlIds?.length) {
    //             b.ArlIds.forEach((arlId, index2) => {
    //                 const arl = arlDict[arlId];
    //                 if (!arl) {
    //                     throw new Error('ARL not found');
    //                 }
    //                 if (arl.IsVisible) {
    //                     bps.push({
    //                         Position: positions[index] + '.' + (index2 + 1),
    //                         ...getBPVar(arl),
    //                     });
    //                 }
    //             })
    //         }
    //
    //     });
    //     console.log({bps})
    //     return {
    //         SumNetto,
    //         SumNettoWithDiscount,
    //         Sum,
    //         SumWithDiscount,
    //         Taxes: Taxes.map(t => ({ tax: t[0], sum: t[1] })),
    //         DiscountAmount,
    //         DiscountPercentage,
    //         BookingPositions: bps,
    //     };
    // };
    getTwigVariables(
        li: LedgerImportEntity,
        quantityTypes: QuantityTypeEntity[],
        arls: Dictionary<AccountsReceivableLedgerEntity>,
        subTotalFromForms: currencyValues,
        totalFromForms: currencyValues,
        alreadyPayedSum: currencyValues,
        alreadyPayedSumDifference: currencyValues,
        useFormValues = false,
        calculationFormMap?: Map<number, Array<ɵFormGroupValue<calculationArlForm>&{getBaseCost: () => number}>>,
        defaultValuesForAddedArls?: {CurrencyCode: string, IsVisible: boolean, Tax: number}
    ) {
        const values = this.InfoiceFormGroup.getRawValue();
        // const bookingPositions: Array<AccountsReceivableLedgerEntity> =  values.AccountsReceivableLedgerIds?.map(id => arls[id]);
        // const bookingPositionsFromLedgerImports: Array<AccountsReceivableLedgerEntity> =  values.AccountsReceivableLedgerIdsFromLedgerImports?.map(id => arls[id]);
        //
        // const arlFromPartialInvoice =
        //     (li.DocumentType === LedgerImportDocumentTypes.PartialInvoice || li.DocumentType === LedgerImportDocumentTypes.ClosingInvoice) &&
        //     bookingPositions?.find(bp => !!(bp as AccountsReceivableLedgerEntity).CustomProperties?.partialInvoiceId) as AccountsReceivableLedgerEntity;

        return {
            LedgerImportDocTypeName: this.LedgerImportDocTypeName$.value,
            ...getBookingPositionTwigVariables(values.AccountsReceivableLedgerIds || [], values.AccountsReceivableLedgerIdsFromLedgerImports || [], quantityTypes, values.DiscountPercentage, arls, useFormValues ? (id) => this.arlFormDataService.getForm(id) : undefined, calculationFormMap, defaultValuesForAddedArls),//this.BookingPositionVariables(bookingPositions || [], quantityTypes, values.DiscountPercentage, arls),
            OurSign: values.OurSign,
            BPTableShowPosition: li.DocumentType !== LedgerImportDocumentTypes.DeliveryNote && li.DocumentType !== LedgerImportDocumentTypes.Order,
            BPTableShowCost: li.DocumentType !== LedgerImportDocumentTypes.DeliveryNote,
            BPTableShowTotal: li.DocumentType !== LedgerImportDocumentTypes.DeliveryNote,
            BPTableShowTotalSum: li.DocumentType !== LedgerImportDocumentTypes.DeliveryNote && !values.AccountsReceivableLedgerIdsFromLedgerImports.length,
            BPTableShowBookingText: li.DocumentType === LedgerImportDocumentTypes.DeliveryNote || li.DocumentType === LedgerImportDocumentTypes.Order || li.DocumentType === LedgerImportDocumentTypes.OrderConfirmation,
            InvoiceForm: {
                ...values,
                CommissionNumber: values.CommissionNumber,
                CustomerName: values.CustomerName,
                CustomerStreet: values.CustomerStreet,
                CustomerPostalCode: values.CustomerPostalCode,
                CustomerCity: values.CustomerCity,
                LogoBase64: values.LogoBase64 || transparentPixelLogo,
                TextTop: values.TextTop,
                TextBottom: values.TextBottom,
                PartnerPhoneNumberPrefix: 'Zentrale Tel.',
                PartnerEmailPrefix: 'Zentrale E-Mail',
                DeliveryDatePrefix: values.DeliveryDate ? 'Lieferdatum:' : '',
                // DeliveryDatePrefix:
                //     values.DeliveryDate && li.IsInvoiceOrOffer === 'invoice' ? 'Lieferdatum:' : null,
                LedgerImportNumberPrefix: LedgerImportDocumentTypeNumberLabel.get(li.DocumentType),
                LedgerImportDatePrefix: values.InvoiceDate
                    ? LedgerImportDocumentTypeDateLabel.get(li.DocumentType).length >= 15 ? LedgerImportDocumentTypeShortDateLabel.get(li.DocumentType) : LedgerImportDocumentTypeDateLabel.get(li.DocumentType)
                    : null,
                DueDatePrefix: values.DueDate ? getDueDateFromLedgerImportDocumentType(li.DocumentType) + ':' : '',
                // DueDatePrefix:
                //     values.DueDate && li.IsInvoiceOrOffer === 'invoice' ? 'Fälligkeitsdatum:' : null,
                DeliveryDate: values.DeliveryDate && li.DocumentType !== LedgerImportDocumentTypes.Offer ? values.DeliveryDate.toDate() : null,
                // DeliveryDate:
                //     values.DeliveryDate && li.IsInvoiceOrOffer === 'invoice'
                //         ? values.DeliveryDate.toDate()
                //         : null,
                DueDate: values.DueDate ? values.DueDate.toDate() : null,
                // DueDate:
                //     values.DueDate && li.IsInvoiceOrOffer === 'invoice' ? values.DueDate.toDate() : null,
                InvoiceDate: values.InvoiceDate?.toDate(),
                // PartialInvoiceText: arlFromPartialInvoice ? this.getPartialInvoiceText(arlFromPartialInvoice, ledgerImports, li) : '',
                SkontoText: values.SkontoAvilable ? 'Zahlbar innerhalb von ' + values.SkontoDays + ' Tagen abzüglich ' + values.SkontoPercentage + ' % Skonto.' : '', // todo ins template verschieben
            },
            Subtotal: {
                Netto: subTotalFromForms.netto,
                Tax: subTotalFromForms.tax,
                Brutto: subTotalFromForms.brutto,
            },
            AlreadyPayedSum:  alreadyPayedSum.netto,
            AmountSumFromARLSFromOtherLedgerImports: {
                Netto: totalFromForms.netto,
                Tax: totalFromForms.tax,
                Brutto: totalFromForms.brutto,
            },
            AlreadyPayedSumDifference:  {
                Netto: alreadyPayedSumDifference.netto,
                Tax: alreadyPayedSumDifference.tax,
                Brutto:  alreadyPayedSumDifference.brutto,
            }
        };
    }
    CreateDocument(type: LedgerImportDocumentTypes | "template") {
        switch (type) {
            case LedgerImportDocumentTypes.PartialInvoice:
                this.dialog.open<NewPartialInvoiceComponent, NewPartialInvoiceComponentDialogData, NewPartialInvoiceComponentDialogReturnData>(NewPartialInvoiceComponent, {
                    ...NewPartialInvoiceComponent.DefaultConfig,
                    data: {
                        FilterLedgerImports: {
                            CommissionId: this.InfoiceFormGroup.getRawValue().CommissionId?.Id,
                            CustomerId: this.InfoiceFormGroup.getRawValue().Customer?.Id,
                        }
                    }
                }).afterClosed().subscribe(ret => {
                    if (ret?.LedgerImportCreated) {
                        this.router.navigate(['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, ret.LedgerImportCreated]);
                    }
                });
                break;
            case LedgerImportDocumentTypes.ClosingInvoice:
                this.dialog.open<NewClosingInvoiceComponent, NewClosingInvoiceComponentDialogData, NewClosingInvoiceComponentDialogReturnData>(NewClosingInvoiceComponent, {
                    ...NewClosingInvoiceComponent.DefaultConfig,
                    data: {
                        FilterLedgerImports: {
                            CommissionId: this.InfoiceFormGroup.getRawValue().CommissionId?.Id,
                            CustomerId: this.InfoiceFormGroup.getRawValue().Customer?.Id,
                        }
                    }
                }).afterClosed().subscribe(ret => {
                    if (ret?.LedgerImportCreated) {
                        this.router.navigate(['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, ret.LedgerImportCreated]);
                    }
                });
                break;
            default:
                this.dialog
                    .open<AppConfirmationDialogComponent, AppConfirmationDialogData>(AppConfirmationDialogComponent, {
                        data: {
                            heading: (this.LedgerImportDocumentTypeNames.get(type as LedgerImportDocumentTypes) || 'Vorlage') + ' aus ' + this.InfoiceFormGroup.controls.ConsolidatedInvoiceId.value + ' erstellen?',
                            inputText: 'Name der ' + (type === 'template' ? 'Vorlage' : this.LedgerImportDocumentTypeNames.get(type as LedgerImportDocumentTypes)),
                            placeholder: (type === 'template' ? 'Vorlage' : this.LedgerImportDocumentTypeNames.get(type as LedgerImportDocumentTypes)) + ' aus ' + this.InfoiceFormGroup.controls.ConsolidatedInvoiceId.value,
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter(response => response[0]),
                        withLatestFrom(this.LedgerImport$),
                    ).subscribe(([[, txt], ledgerImport]) => {
                    this.LS.startLoading('addLedgerImport');
                    firstValueFrom(this.actions$.pipe(ofType(AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger, BaseActionTypes.ErrorAction))).then((val) => {
                        if (val.type === AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger.type) {
                            firstValueFrom(this.store.select(getLedgerImports)).then((li) => {
                                const newGenFileNo = li.reduce((highestId, entity) => (entity.Id > highestId ? entity.Id : highestId), -1);
                                this.LS.endLoading('addLedgerImport');
                                this.router.navigate(['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, newGenFileNo]);
                            });
                        } else {
                            this.LS.endLoading('addLedgerImport');
                        }
                    });
                    this.store.dispatch(
                        LedgerImportActionTypes.CreateLedgerImportFromLedgerImport({
                            Payload: {
                                rootId: ledgerImport.Id,
                                isTemplate: type === 'template',
                                documentType: type === 'template' ? ledgerImport.DocumentType : type,
                                templateName: txt,
                            },
                        }),
                    )
                });
        }
    }

    protected readonly TaskPageMeta = TaskPageMeta;

    onOpenEventClick(eventId: number) {
        firstValueFrom(getFetched$(this.store, getEventFetched, getEventById({id: eventId}), this.eventResolver)).then(event => {
            if (!event) {
                this.store.dispatch(BaseActionTypes.ErrorAction({Payload: { ToasterMessage: 'Ereignis nicht verfügbar' }}))
            } else if (event.IsTask) {
                this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
                    ...DetailTasksComponent.DefaultConfig,
                    data: {
                        EventId: eventId,
                    }
                })
            } else {
                this.router.navigate(['/', HistoryMeta.Path, AllEventsMeta.Path, eventId]);
            }
        })
        }

    onCreateCommissionClick() {
        firstValueFrom(this.LedgerImport$).then(ledgerImport => {
            this.dialog.open<NewCommissionDialogComponent, NewCommissionDialogComponentDialogData, NewCommissionDialogComponentDialogReturnData>(NewCommissionDialogComponent, {
                data: {
                    CreateCommissionPayload: {
                        auftragseingangDurchAuftraggeber: new Date().toDateString(),
                        addResourcesFromLedgerImportId: this.LedgerImportId,
                        customerId: ledgerImport.CustomerId,
                    },
                    PreventRouting: true,
                },
            }).afterClosed().subscribe(({Commission}) => {
                if (Commission) {
                    this.InfoiceFormGroup.controls.CommissionId.setValue(Commission);
                    this.InfoiceFormGroup.controls.CommissionId.markAsDirty();
                    this.SaveClick();
                }
            })
        });
    }

    onAddToResToCommissionClick(commissionId: number) {
        this.LS.startLoading('modify-commission')
        firstValueFrom(this.actions$.pipe(ofType(BaseActionTypes.ErrorAction, CommissionActionTypes.AddResourcesSuccess))).then(action => {
            this.LS.endLoading('modify-commission');
            if (action.type === CommissionActionTypes.AddResourcesSuccess.type) {
                this.toastrService.success('Ressourcen erfolgreich angelegt')
                this.ledgerImportResolver.pollUpdated();
            }
        })
        this.store.dispatch(CommissionActionTypes.AddResources({Payload: {ledgerImportId: this.LedgerImportId, commissionId }}));
    }
    onArlDeleteClick(entity: AccountsReceivableLedgerEntity) {
        if (entity && !entity.DeletedAt) {
            this.appDialog.OpenConfirmationDialog({ paragraph: `${entity.Type ? ARLTypeEnumNames.get(entity.Type) : 'Buchungsposition'} ${entity.Information || entity.BookingText} wirklich löschen?`, styleDelete: true }).subscribe(([res]) => {
               if (res) {
                   this.LS.startLoading('invoice-editor-delete-arl');

                   const arlsToDelete = this.arlFormDataService.removeArlWithChildren(entity.Id);
                    console.log('arlsToDelete' ,arlsToDelete)
                   firstValueFrom(this.actions$.pipe(ofType(BaseActionTypes.ErrorAction, AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger), skip(arlsToDelete.length -1))).then(async () => {
                       if (this.arlTableComponent) {
                           await this.arlTableComponent.removeArlsFromTable(arlsToDelete);
                       } else {
                           this.InfoiceFormGroup.controls.AccountsReceivableLedgerIds.setValue(this.InfoiceFormGroup.value.AccountsReceivableLedgerIds.filter(id => id !== entity.Id));
                       }
                       this.InfoiceFormGroup.controls.AccountsReceivableLedgerIds.markAsDirty();
                       this.LS.endLoading('invoice-editor-delete-arl');
                   })

                   arlsToDelete.forEach(id => {
                       this.store.dispatch(AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedger({Payload: {id}}));
                   })
               }
            })
        }
    }
    // onArlTableChange(arls: Array<AccountsReceivableLedgerEntity | groupInterface>) {
    //     console.log('onArlTableChange')
    //     this.SortedBookingPositionList$.next(arls);
    //     this.InfoiceFormGroup.markAsDirty();
    //     // const selected = this.InfoiceFormGroup.value.BookingPositions;
    //     const array = arls.slice().filter(a => this.IsSelected(a)/*selected?.some(s => determineIfIsArlEntityOrGroupInterface(s) && s.Id === a.Id)*/);
    //     this.InfoiceFormGroup.controls.BookingPositions.setValue(array);
    // }
    // getArlIds() {
    //     return this.InfoiceFormGroup.value.BookingPositions.filter(a => determineIfIsArlEntityOrGroupInterface(a)).map(a => determineIfIsArlEntityOrGroupInterface(a) && a.Id);
    // }
    protected readonly print = print;
    getConsolidatedInvoiceDateLabel() {
        return this.LedgerImportDocTypeName$.value + 'sdatum';
    }
    onToggleStatusClick() {
        this.ShowStatus = !this.ShowStatus;
        if (!this.ShowStatus) {
            this.SaveClick();
        }
    }
    onOpenMailDialog(type: 'pdf' | 'xRechnung') {
        firstValueFrom(this.LedgerImport$).then(async li => {
            if (!statusIsReadOnly(li.Status)) {
                const force = type === 'pdf' && !li.DocumentId
                await this.SaveClick(force);
            }
            if (type === 'xRechnung') {
                this.store.dispatch(LedgerImportActionTypes.CreateXRechnungFromLedgerImport({ Payload: { id: li.Id } }));
                let error = false;
                await firstValueFrom(merge(this.LedgerImport$.pipe(skip(1)), this.actions$.pipe(ofType(BaseActionTypes.ErrorAction), tap(() => error = true))))
                if (error) {
                    return;
                }
            }
            firstValueFrom(this.SfdtIsDirty$.pipe(
                tap(dirty => {
                    if (dirty) {
                        this.SaveClick();
                    }
                }),
                filter(dirty => !dirty),
                withLatestFrom(this.LedgerImport$, (this.InfoiceFormGroup.value.Customer?.Id ? this.store.select(getOfficeByCustomerId({id: this.InfoiceFormGroup.value.Customer.Id})) : of(null))),
            )).then(([dirty, ledgerImport, office]) => {
                const targetEmail = [LedgerImportDocumentTypes.Invoice, LedgerImportDocumentTypes.ClosingInvoice, LedgerImportDocumentTypes.PartialInvoice, LedgerImportDocumentTypes.Cancellation, LedgerImportDocumentTypes.Credit].includes(ledgerImport.DocumentType) && office?.InvoiceEmail ? office.InvoiceEmail : this.InfoiceFormGroup.value.CustomerEmail;
                this.dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                    ...EmailEditorComponentDialogConfig,
                    data: {
                        EmailSubject: this.GetFilename(this.InfoiceFormGroup.getRawValue().ConsolidatedInvoiceId),
                        TargetEmails: targetEmail ? [targetEmail] : [],
                        CustomerId: this.InfoiceFormGroup.value.Customer?.Id || null,
                        FileAttachmntFileIds: [type === 'xRechnung' ? ledgerImport.DocumentIdXRechnung : ledgerImport.DocumentId],
                    },
                });
            })
        })
    }
    setAllTax() {
        const taxType = this.InfoiceFormGroup.getRawValue().TaxType;
        if (!this.arlTableComponent || !isNotNullOrUndefined(taxType)) {
            throw 'cant set tax'
        }
        this.arlTableComponent.setAllTax(getLedgerImportTaxTypeTaxValue(taxType));
        // this.taxOptionValue = null;
    }
}
