<section #wrapperElement class="wrapper">
    <header class="header">
        <ng-container *ngIf="!(Mobile$ | async)">
            <fa-icon class="icon" [icon]="HeadlineIcon" *ngIf="HeadlineIcon"></fa-icon>
            <div class="icon-spacer" *ngIf="!HeadlineIcon"></div>
        </ng-container>

        <div class="dark-theme title" *ngIf="ShowSearchBar$ | async; else searchBarInactive">
            <!-- search bar -->
            <mat-form-field floatLabel="never" color="accent" [ngStyle]="{ width: (IsSmallWidth$ | async) ? '100%' : '50%' }">
                <mat-label>Suche</mat-label>
                <input type="text" [formControl]="SearchForm" matInput #input />
                <button class="clear-search" (click)="SearchForm.setValue('')" mat-icon-button *ngIf="SearchForm.value">
                    <fa-icon icon="times"></fa-icon>
                </button>
            </mat-form-field>
        </div>
        <ng-content select="[headerButtonPrefix]"></ng-content>
        <ng-template #searchBarInactive>
            <h3 class="headline" *ngIf="!(Mobile$ | async)">{{ Headline }}</h3>
        </ng-template>
        <button app-round-button Inverted matTooltipPosition="left" [matTooltip]="(ShowSearchBar$ | async) ? 'Ausblenden' : 'Suche'" (click)="ShowSearchBar$.next(!ShowSearchBar$.value)">
            <fa-icon [icon]="(ShowSearchBar$ | async) ? 'times' : 'search'"></fa-icon>
        </button>
        <mat-divider *ngIf="Multiselect" [vertical]="true"></mat-divider>
        <button app-round-button *ngIf="MultiselectButtons" Inverted (click)="ToggleMultiselect()" matTooltip="Mehrfachauswahl">
            <fa-icon [icon]="Multiselect ? 'times' : 'check-square'"></fa-icon>
        </button>
        <ng-container *ngIf="Multiselect">
            <ng-content select="[headerSectionMultiselect]"></ng-content>
            <button app-round-button *ngFor="let button of MultiselectButtons" Inverted [Color]="button.color" (click)="MultiselectButtonClick(button, $event)" [matTooltip]="button.tooltip" [Disabled]="!MultiselectedEntryIds.length">
                <fa-icon [icon]="button.icon"></fa-icon>
            </button>
            <mat-divider [vertical]="true"></mat-divider>
        </ng-container>
        <button *ngIf="FilterSettings" app-round-button Inverted [matBadge]="FilterCount" matTooltip="Filter" [matBadgeOverlap]="true" (click)="filter.Open()">
            <fa-icon icon="filter"></fa-icon>
        </button>
        <a app-round-button Inverted [matTooltipDisabled]="NewEntryToolTip === ''" [matTooltip]="NewEntryToolTip" matTooltipPosition="left" [routerLink]="NewEntryUrl" *ngIf="NewEntryUrl !== ''">
            <app-double-icon [Icon]="NewEntryIcon === 'plus' ? HeadlineIcon : NewEntryIcon" Icon2="plus"></app-double-icon>
        </a>
        <ng-container [ngTemplateOutlet]="AdditionalHeaderButtons"></ng-container>
        <ng-content select="[headerButtonSuffix]"></ng-content>
    </header>
    <table class="mat-table" *ngIf="AdditionalTableRows?.length">
        <tr class="mat-row clickable-list-item" *ngFor="let row of AdditionalTableRows" [routerLink]="row.routerLink">
            <td class="mat-cell">{{ row.label }}</td>
        </tr>
    </table>
    <div style="position: relative; height: 100%;">
        <!--    tvsItemSize has to be the same in scss -->
        <cdk-virtual-scroll-viewport [tvsItemSize]="56" [headerHeight]="56" [bufferMultiplier]="1">
            <table #table mat-table [dataSource]="Data$ | async" matSort class="table" [matSortActive]="DefaultSortActive" [matSortDirection]="DefaultSortDirection" (matSortChange)="SortChange.emit($event)">
                <tr *matHeaderRowDef="wrapperElement.clientWidth < 640 ? DisplayedColumnsSmall : DisplayedColumns; sticky: true" mat-header-row class="table-row"></tr>
                <tr
                    *matRowDef="let rowData; columns: wrapperElement.clientWidth < 640 ? DisplayedColumnsSmall : DisplayedColumns"
                    mat-row
                    class="table-row"
                    [matTooltip]="rowData.tooltip"
                    [matTooltipPosition]="'left'"
                    [ngClass]="rowData.cssClass"
                    [style]="rowData.cssStyle || ''"
                    (click)="ClickEntry(rowData)"
                    [routerLink]="rowData.routerLink"
                    routerLinkActive="highlight"
                    [class.clickable-list-item]="rowData.clickable"
                ></tr>
                <ng-container *ngFor="let column of ColumnConfig" [matColumnDef]="column.name">
                    <th mat-header-cell *matHeaderCellDef [mat-sort-header]="column.name" [style.width]="column.width ? column.width : 'unset'">{{ column.header }}</th>
                    <td mat-cell *matCellDef="let rowData">
                        <div class="table-content" *ngIf="!CustomCellTemplates[column.name]">
                            <div>{{ rowData[column.name] }}</div>
                        </div>
                        <ng-container *ngIf="CustomCellTemplates[column.name]">
                            <ng-container *ngTemplateOutlet="CustomCellTemplates[column.name]; context: { $implicit: rowData }"></ng-container>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="chevron">
                    <th *matHeaderCellDef mat-header-cell [style.width.px]="9"></th>

                    <td mat-cell *matCellDef="let rowData">
                        <div class="table-cell-wrapper">
                            <fa-icon class="chevron-right table-cell-content" icon="chevron-right"></fa-icon>
                        </div>
                    </td>
                </ng-container>
                <!-- Checkbox Column -->
                <ng-container [matColumnDef]="CheckboxColumnName">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            [checked]="allSelected"
                            [indeterminate]="someComplete()"
                            (change)="setAll($event.checked)"
                            color="primary">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" style="width: 2rem">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? ClickEntry(row) : null" [checked]="IsSelected(row.id)" color="primary"> </mat-checkbox>
                    </td>
                </ng-container>
            </table>
        </cdk-virtual-scroll-viewport>
        <table *ngIf="!Loading && (Data$ | async)?.data?.length === 0" class="no-content-table">
            <tr class="table-row">
                <div class="table-cell-wrapper" style="justify-content: center">
                    <td class="empty-table-text">
                        <div class="table-cell-content">{{ EmptyText }}</div>
                    </td>
                </div>
            </tr>
        </table>
    </div>
    <app-loading *ngIf="Loading"> </app-loading>
</section>
<app-filter-card UnlimitedHeight #filter [Settings]="FilterSettings" [Values]="FilterValues" (ValuesChange)="FilterValuesChange.emit($event)"></app-filter-card>
