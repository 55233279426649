import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppButtonColor } from '../../../dave-utils-module/app-button-module/app-button/app-button.component';
import { DetailViewButtonsDirective } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-view-template/detail-view-buttons.directive';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SearchQueriesDebounceTime, TableColumnConfig } from '../../../helper/helper';
import { FilterOption } from './../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
export interface MultiselectButton<T extends { id: number | string }> {
    icon: IconProp;
    /**
     *
     * @param selectedEntryIds
     * @param clickEvent
     * @returns true, if the multiselect should be reseted
     */
    onClick: (selectedEntryIds: Array<number | string>, clickEvent) => boolean | Promise<boolean>;
    tooltip: string;
    color?: AppButtonColor;
}
export interface DaveListTableData {
    id: number | string;
    clickable: boolean;
    cssClass?: string | string[] | Set<string> | { [klass: string]: any };
    tooltip?: string;
    routerLink?: string | any[];
}
const checkboxColumnName = 'checkbox';
@Component({
    selector: 'app-dave-list',
    templateUrl: './dave-list.component.html',
    styleUrls: ['./dave-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaveListComponent<T extends DaveListTableData> implements OnDestroy, AfterViewChecked, AfterViewInit {
    @ContentChild(DetailViewButtonsDirective, { read: TemplateRef })
    public AdditionalHeaderButtons: DetailViewButtonsDirective['templateRef'];

    allSelected: boolean = false;


    @ViewChild('wrapperElement') WrapperElement: ElementRef;

    /** Konfiguration der Spalten der MatTable */
    @Input() ColumnConfig: TableColumnConfig<T>[];
    @Input() EmptyText = 'Keine Einträge vorhanden';
    @Input() set Columns(v: Array<keyof T>) {
        if (this.DisplayedColumns.includes(checkboxColumnName)) {
            this.DisplayedColumns = v;
            this.DisplayedColumns.unshift(checkboxColumnName);
        } else {
            this.DisplayedColumns = v;
        }
    }
    @Input() set ColumnsSmall(v: Array<keyof T>) {
        if (this.DisplayedColumnsSmall.includes(checkboxColumnName)) {
            this.DisplayedColumnsSmall = v;
            this.DisplayedColumnsSmall.unshift(checkboxColumnName);
        } else {
            this.DisplayedColumnsSmall = v;
        }
    }
    @Input() Headline: string;
    @Input() HeadlineIcon: IconProp;
    @Input() NewEntryUrl = '';
    @Input() NewEntryToolTip = '';
    @Input() NewEntryIcon: IconProp = 'plus';

    @Input() FilterValues: any;
    @Output() FilterValuesChange = new EventEmitter<any>();
    @Input() FilterSettings: FilterOption[];
    @Input() FilterCount;

    @Input() MultiselectButtons: MultiselectButton<T>[];

    @Output() ItemClicked = new EventEmitter<T>();

    @Input() DefaultSortActive: string & keyof T;
    @Input() DefaultSortDirection: SortDirection = 'asc';
    @Output() SortChange = new EventEmitter<Sort>();
    @Input() AdditionalTableRows: Array<{ label: string; routerLink: string }> = [];
    @Input() CustomCellTemplates: { [key in keyof T]?: TemplateRef<any> } = {};

    public Multiselect = false;
    public MultiselectedEntryIds: Array<number | string>;
    @Output() MultiselectedEntryIdsChange = new EventEmitter<Array<number | string>>();
    public DisplayedColumns: Array<keyof T | typeof checkboxColumnName> = [];
    public DisplayedColumnsSmall: Array<keyof T | typeof checkboxColumnName> = [];
    public Mobile$ = this.breakpointObserverService.MobileQuery;
    public Loading = true;
    public SearchForm = new FormControl<string>('');
    public SelectedRowIndex: number;
    public CheckboxColumnName = checkboxColumnName;

    /** Ob die Komponente unter einer bestimmten Breite ist */
    public IsSmallWidth$ = new BehaviorSubject(false);

    public ShowSearchBar$ = new BehaviorSubject(false);

    /** Daten der Tabelle */
    public Data$: BehaviorSubject<TableVirtualScrollDataSource<T>> = new BehaviorSubject<TableVirtualScrollDataSource<T>>(new TableVirtualScrollDataSource<T>([]));
    /** Gibt ein Mal einen Wert aus, wenn die Komponente zerstört wird */
    private onDestroy$ = new Subject<void>();
    private afterViewInit$ = new BehaviorSubject<boolean>(false);

    private subscription: Subscription;

    @Input()
    set DataSource$(v: Observable<TableVirtualScrollDataSource<T>>) {
        this.subscription?.unsubscribe();
        this.subscription = combineLatest([v, this.afterViewInit$.pipe(filter((v) => !!v))])
            .pipe(
                // this.matSort sollte hier immer gesetzt sein (da ngAfterViewInit gefeuert haben muss),
                // aber das weiß TypeScript natürlich nicht.
                tap(([dataSource]) => {
                    if (this.matSort) {
                        dataSource.sort = this.matSort;
                    } else {
                        console.warn('no matSort');
                    }
                }),

                // Sucheingaben an die DataSource weitergeben
                switchMap(([dataSource]) =>
                    this.SearchForm.valueChanges.pipe(
                        debounceTime(SearchQueriesDebounceTime),
                        startWith(''),
                        tap((searchTerm) => (dataSource.filter = searchTerm.trim().toLowerCase())),
                        map(() => dataSource),
                    ),
                ),
            )
            .subscribe((data) => {
                this.Loading = false;
                this.Data$.next(data);
                this.cdr.detectChanges();
            });
    }
    /** Der `MatSort` der Tabelle */
    @ViewChild(MatSort) private matSort?: MatSort;

    /** Das Suchfeld-Element */
    @ViewChild('input') private inputRef?: ElementRef<HTMLInputElement>;

    constructor(private breakpointObserverService: BreakpointObserverService, public PS: PermissionService, public CS: CustomerNameService, public ActivatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef) {
        globalThis.test = () => this.cdr.detectChanges();
        // Seiteneffekt: wenn das Suchfeld eingeblendet wird, fokussieren - asynchron, damit Angular
        // das Suchfeld erst anzeigt und danach fokussiert. Wenn ausgeblendet, den Suchwert löschen.
        this.ShowSearchBar$.pipe(takeUntil(this.onDestroy$)).subscribe((isSearching) => (isSearching ? setTimeout(() => this.inputRef && this.inputRef.nativeElement.focus()) : this.SearchForm.setValue('')));
    }

    ngAfterViewInit() {
        this.afterViewInit$.next(true);
    }
    ngOnDestroy() {
        this.subscription?.unsubscribe();
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    ngAfterViewChecked() {
        this.sizing();
    }

    @HostListener('window:resize')
    sizing() {
        const smallWidth = this.WrapperElement?.nativeElement.offsetWidth < 640;
        if (this.IsSmallWidth$.value !== smallWidth) {
            this.IsSmallWidth$.next(smallWidth);
        }
    }
    async MultiselectButtonClick(button: MultiselectButton<T>, event) {
        const ret = button.onClick(this.MultiselectedEntryIds, event);
        let resetSelectet;
        if (typeof ret === 'boolean') {
            resetSelectet = ret;
        } else {
            resetSelectet = await ret;
        }
        if (resetSelectet) {
            this.setMultiselect(false);
        }
    }
    IsSelected(entryId: number | string) {
        return this.MultiselectedEntryIds.some((id) => id === entryId);
    }
    ClickEntry(entry: T) {
        if (this.Multiselect) {
            if (this.IsSelected(entry.id)) {
                this.MultiselectedEntryIds.splice(this.MultiselectedEntryIds.indexOf(entry.id), 1);
            } else {
                this.MultiselectedEntryIds.push(entry.id);
            }
            this.MultiselectedEntryIdsChange.emit(this.MultiselectedEntryIds.slice());
            this.updateAllComplete();
        } else {
            this.ItemClicked.emit(entry);
        }
    }
    ToggleMultiselect() {
        this.setMultiselect(!this.Multiselect);
    }
    setMultiselect(v) {
        this.Multiselect = v;
        if (this.MultiselectedEntryIds?.length !== 0) {
            this.MultiselectedEntryIds = [];
            this.MultiselectedEntryIdsChange.emit([]);
        }

        if (v && !this.DisplayedColumns.includes(checkboxColumnName)) {
            this.DisplayedColumns.unshift(checkboxColumnName);
            this.DisplayedColumnsSmall.unshift(checkboxColumnName);
        } else if (this.DisplayedColumns.includes(checkboxColumnName)) {
            this.DisplayedColumns.splice(this.DisplayedColumns.indexOf(checkboxColumnName), 1);
            this.DisplayedColumnsSmall.splice(this.DisplayedColumnsSmall.indexOf(checkboxColumnName), 1);
        }
    }
    updateAllComplete() {
        this.allSelected = this.MultiselectedEntryIds?.length === this.Data$.value.data.length;
    }

    someComplete(): boolean {
        if (!this.MultiselectedEntryIds) {
            return false;
        }
        return this.MultiselectedEntryIds.length > 0 && !this.allSelected;
    }

    setAll(completed: boolean) {
        this.allSelected = completed;
        if (!completed) {
            this.MultiselectedEntryIds = [];
        } else {
            this.MultiselectedEntryIds = this.Data$.value.data.map((d) => d.id);
        }
        this.MultiselectedEntryIdsChange.emit(this.MultiselectedEntryIds.slice());
    }
}
