import { PartnerType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import { LedgerImportDocumentTypes } from './ledger-import.entity';
import {
    CommissionListColumn
} from '../../dave-commission-module/components/commission-list/commission-list.component';
import { DashboardItemsDataType } from '../../mocks/dashboard-items-data';
import {
    CKEditorDefaultConfigEmailFontFamilyOptions,
    CKEditorDefaultConfigEmailFontSizeOptions,
} from '../../helper/ckeditor.helper';

export enum PartnerSpecificationType {
    Construction = 1,
    Appraiser = 2,
}
export enum PartnerTypeEnum {
    Dave = 1,
    Obligo = 2,
    Zert = 3,
    Kagetec = 4,
    TSB = 5,
    YustInfra = 6,
}
export interface PartnerEntityAdditionalData {
    /**
     * @deprecated
     */
    createCustomerNumberAutomaticDefault?: boolean;
    defaultLedgerImportDocumentType?: LedgerImportDocumentTypes;
    commissionListPreferredColumns?: CommissionListColumn[];
    dashboardItemsOrder?: DashboardItemsDataType[];
    emailEditorDefaultFontSize?: CKEditorDefaultConfigEmailFontSizeOptions,
    emailEditorDefaultFontFamily?: CKEditorDefaultConfigEmailFontFamilyOptions,
}

export class PartnerEntity {
    constructor(
        public Id: number = null,
        public Deleted: boolean = null,
        public CreatedAt: Date = null,
        public MandateReference: string = null,
        public PartnerNo: string = null,
        public PartnerSpecificationTypeId: PartnerSpecificationType = null,
        public PartnerTypeId: PartnerTypeEnum = null,
        public VacationCalculationDefaultProvince: string = null,
        public AdditionalData: PartnerEntityAdditionalData = null,
    ) {}
    public Clone(override: Partial<PartnerEntity> = {}): PartnerEntity {
        const attr = { ...this, ...override };
        return Object.assign(new PartnerEntity(), attr);
    }
    public static readonly GqlFields: Array<keyof PartnerType> = [
        'createdAt',
        'deleted',
        'id',
        'mandateReference',
        'partnerNo',
        'partnerSpecificationTypeId',
        'partnerTypeId',
        'vacationCalculationDefaultProvince',
        'additionalData',
    ];
}

export function PartnerEntityFromBackend(res: PartnerType): PartnerEntity {
    return new PartnerEntity(
        res.id,
        res.deleted,
        BackendDate(res.createdAt),
        res.mandateReference,
        res.partnerNo,
        res.partnerSpecificationTypeId,
        res.partnerTypeId,
        res.vacationCalculationDefaultProvince,
        res.additionalData as any,
    );
}
