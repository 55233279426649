<div class="scroll-wrapper" cdkDropListGroup [cdkDropListGroupDisabled]="_disabled || (ls.IsLoading$ | async)">
    <div cdkDropList [cdkDropListData]="addButtons" class="add-button-list" >
        <button cdkDrag mat-button cdkDragPreviewContainer="parent" class="grab-button" [class.is-loading]="ls.IsLoading$ | async" cdkDragPreviewClass="grabbing" (cdkDragStarted)="onCdkDragStarted($event)" (cdkDragEnded)="onCdkDragEnded($event)" [cdkDragData]="b.type" *ngFor="let b of addButtons">
            <span>
                <fa-icon icon="bars" style="margin-right: 0.5rem;"></fa-icon>
                <span>{{ b.label }}</span>
            </span>
        </button>
    </div>
<!--    <p>{{(tableData$ | async)?.length}}</p>-->
    <mat-table *ngIf="tableData$ | async as tableData" [cdkDropListData]="tableData" cdkDropList (cdkDropListDropped)="OnListDrop($event)" [dataSource]="tableData" #bookingPositionTable multiTemplateDataRows>
        <ng-container matColumnDef="QuantityType">
            <mat-header-cell *matHeaderCellDef class="{{AutoColumnWithClass.QuantityType}}">
                {{ AutoColumnHeaders['QuantityType'] }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData" class="{{AutoColumnWithClass.QuantityType}}">
                <ng-container *ngIf="determineIfIsArlTableDataOrGroupEnd(rowData) && rowData.entity.isBookingPosition">
                    <mat-form-field *ngIf="rowData.isExpanded$ | async; else value">
                        <mat-select [placeholder]="AutoColumnHeaders['QuantityType']" [formControl]="rowData.form.controls.QuantityTypeId">
                            <mat-option *ngFor="let t of arlCalculationDataService.quantityTypes$ | async" [value]="t.Id">{{ t.Name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-template #value>
                        {{ (getQuantityType$ | async)(rowData) }}
                    </ng-template>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container *ngFor="let collumName of AutoColumns" [matColumnDef]="collumName">
            <mat-header-cell *matHeaderCellDef class="{{AutoColumnWithClass[collumName]}}">
                {{ AutoColumnHeaders[collumName] }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData" class="{{AutoColumnWithClass[collumName]}}">
                <ng-container *ngIf="rowData.isExpanded$ && rowData.isExpanded$ | async; else value">
                    <ng-container [ngSwitch]="collumName" *ngIf="rowData.entity.isBookingPosition;">
                        <ng-container *ngSwitchCase="'Position'" [ngTemplateOutlet]="value"></ng-container>
<!--                        <ng-container *ngSwitchCase="'CompleteCost'">{{ rowData.amount$ | async | currency: rowData.entity.CurrencyCode }}</ng-container>-->
                        <ng-container *ngSwitchCase="'BaseCost'">
                            <ng-container *ngIf="rowData.form.value.InheritFromChildren; else form">
<!--                                {{ rowData.calculatedAmount.value | currency: rowData.entity.CurrencyCode }}-->
                                {{ rowData.form.value.BaseCost | currency: rowData.entity.CurrencyCode }}
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchDefault [ngTemplateOutlet]="form"></ng-container>
                    </ng-container>
                </ng-container>
                <ng-template #form>
                    <mat-form-field>
                        <mat-label>{{ AutoColumnHeaders[collumName] }}</mat-label>
                        <ng-container [ngSwitch]="collumName">
                            <input *ngSwitchCase="'Quantity'" type="number" matInput [formControl]="rowData.form.controls.Quantity" />
                            <!--                        <app-number-transform-input *ngSwitchCase="'BaseCost'" [formControl]="rowData.form.controls.BaseCost"></app-number-transform-input>-->
                            <app-number-transform-input *ngSwitchCase="'BaseCost'" [factor]="1" [formControl]="rowData.form.controls.BaseCost"></app-number-transform-input>
                            <!--                        <ng-container *ngSwitchCase="'CompleteCost'">-->
                            <!--                            <app-number-transform-input *ngIf="!rowData.form.value.InheritFromChildren" [formControl]="rowData.form.controls.Amount"></app-number-transform-input>-->
                            <!--                            <span *ngIf="rowData.form.value.InheritFromChildren">{{rowData.calculatedAmount.value | number: '1.2-2' }}</span>-->
                            <!--                        </ng-container>-->
                            <!--                        <input *ngSwitchCase="'Position'" matInput>-->
                            <input *ngSwitchCase="'BookingText'" matInput [formControl]="rowData.form.controls.BookingText" />
<!--                            <input *ngSwitchCase="'Information'" matInput [formControl]="rowData.form.controls.Information" />-->
                            <input *ngSwitchDefault matInput disabled [value]="dataAccessor(rowData, collumName)" />
                        </ng-container>
                    </mat-form-field>
                </ng-template>
                <ng-template #value>
                    {{ dataAccessor(rowData, collumName) }}
                </ng-template>
                <ng-container *ngIf="collumName === 'BaseCost' && rowData.form?.controls.Type.value === ARLTypeEnum.MaterialArl">
                    <button mat-icon-button matTooltip="Preisgestaltung" (click)="onPriceInformationClick(rowData)" [disabled]="!rowData.form.value.MaterialId">
                        <fa-icon icon="info-circle"></fa-icon>
                    </button>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Information">
            <mat-header-cell *matHeaderCellDef class="{{AutoColumnWithClass.Information}}">
                {{ AutoColumnHeaders.Information }}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData" class="{{AutoColumnWithClass.Information}}">
<!--                <mat-form-field *ngIf="rowData.isExpanded$ && rowData.isExpanded$ | async; else value">-->
<!--                    <mat-label>{{ AutoColumnHeaders.Information }}</mat-label>-->
<!--                    <input matInput [formControl]="rowData.form?.controls.Information" />-->
<!--                </mat-form-field>-->

                <div class="theme-override {{ResourcePageMeta.Theme}}" *ngIf="false && rowData.form?.value.ResourceId">
                    <button mat-icon-button [disabled]="_disabled" color="primary" (click)="onSetEntityId(rowData, null)" [matTooltip]="(rowData.resource$ | async)?.DisplayName + ' entfernen'"><app-double-icon [Icon]="ResourcePageMeta.Icon" Icon2="link"></app-double-icon></button>
                </div>
                <div *ngIf="rowData.isExpanded$ && rowData.isExpanded$ | async; else value" class="flex-col cell-wrapper">
                    <app-arl-information-input
                        [informationControl]="rowData.form.controls.Information"
                        [FilterMaterialsByCustomerId]="FilterMaterialsByCustomerId"
                        [ARLType]="rowData.form.controls.Type.value"
                        [inputMode]="rowData.form.controls.Type.value === ARLTypeEnum.ResourceArl || rowData.form.controls.Type.value === ARLTypeEnum.MaterialArl ? 'selectSearch' : 'autocomplete'"
                        [entityId]="rowData.form.controls.Type.value === ARLTypeEnum.ResourceArl ? rowData.form.controls.ResourceId.value : rowData.form.controls.Type.value === ARLTypeEnum.MaterialArl ?  rowData.form.controls.MaterialId.value : null"
                        (entitySelected)="onSetEntityId(rowData, $event)"
                    ></app-arl-information-input>
                    <div class="long-text-wrapper">
                        <mat-form-field>
                            <mat-label>Langtext</mat-label>
                            <textarea matInput  cdkTextareaAutosize
                                      [cdkAutosizeMinRows]="3" [formControl]="rowData.form.controls.Longtext"></textarea>
                        </mat-form-field>
                        <mat-checkbox [formControl]="rowData.form.controls.ShowLongtext" matTooltip="Langtext anzeigen"></mat-checkbox>
                    </div>
                </div>
                <ng-template #value>
<!--                    {{ rowData.entity?.Information }}-->
                    {{ rowData.form?.getRawValue().Information }}
                </ng-template>
                <ng-container *ngIf="!determineIfIsArlTableDataOrGroupEnd(rowData)">
                    <strong>Summe {{rowData.information$ | async}}</strong>
                </ng-container>
            </mat-cell>

        </ng-container>
        <ng-container matColumnDef="CompleteCost">
            <mat-header-cell *matHeaderCellDef class="{{AutoColumnWithClass.CompleteCost}}">
                Summe
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData" [style.overflow]="determineIfIsArlTableDataOrGroupEnd(rowData) ? '' : 'visible'" class="{{AutoColumnWithClass.CompleteCost}}">
                <ng-container *ngIf="rowData.isExpanded$ && (rowData.isExpanded$ | async) && rowData.entity.isBookingPosition; else notExpanded">
                    {{ rowData.amount$ | async | currency: rowData.entity.CurrencyCode }}
                </ng-container>
                <ng-template #notExpanded>
                    <ng-container *ngIf="determineIfIsArlTableDataOrGroupEnd(rowData) && rowData.entity.isBookingPosition">
                        <!--                        (id:{{rowData.entity.Id}})-->
                        <!--                        {{ (rowData.entity.CompleteCost - rowData.entity.CompleteTaxCost) | currency: rowData.entity.CurrencyCode }}-->
                        {{ rowData.amount$ | async | currency: rowData.entity.CurrencyCode }}
                    </ng-container>
                    <!--                    <ng-container *ngIf="determineIfIsArlTableDataOrGroupEnd(rowData) && !rowData.entity.isBookingPosition">-->
                    <!--                        (id:{{rowData.entity.Id}})-->
                    <!--                    </ng-container>-->
                    <ng-container *ngIf="!determineIfIsArlTableDataOrGroupEnd(rowData)">
                        <!--                        (id:{{rowData.groupId}})-->
                        {{ rowData.amount$ | async | currency }}
                    </ng-container>
                </ng-template>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="CompleteCostWithoutTax">
            <mat-header-cell *matHeaderCellDef class="{{AutoColumnWithClass.CompleteCostWithoutTax}}">
                Summe (Brutto)
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData" [style.overflow]="determineIfIsArlTableDataOrGroupEnd(rowData) ? '' : 'visible'" class="{{AutoColumnWithClass.CompleteCostWithoutTax}}">
                <ng-container *ngIf="rowData.isExpanded$ && (rowData.isExpanded$ | async) && rowData.entity.isBookingPosition; else notExpanded">
                    {{ rowData.amountWithTax$ | async | currency: rowData.entity.CurrencyCode }}
                </ng-container>
                <ng-template #notExpanded>
                    <ng-container *ngIf="determineIfIsArlTableDataOrGroupEnd(rowData) && rowData.entity.isBookingPosition">
                        <!--                        (id:{{rowData.entity.Id}})-->
                        <!--                        {{ (rowData.entity.CompleteCost - rowData.entity.CompleteTaxCost) | currency: rowData.entity.CurrencyCode }}-->
                        {{ rowData.amountWithTax$ | async | currency: rowData.entity.CurrencyCode }}
                    </ng-container>
                    <!--                    <ng-container *ngIf="determineIfIsArlTableDataOrGroupEnd(rowData) && !rowData.entity.isBookingPosition">-->
                    <!--                        (id:{{rowData.entity.Id}})-->
                    <!--                    </ng-container>-->
                    <ng-container *ngIf="!determineIfIsArlTableDataOrGroupEnd(rowData)">
                        <!--                        (id:{{rowData.groupId}})-->
                        {{ rowData.amountWithTax$ | async | currency }}
                    </ng-container>
                </ng-template>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Position">
            <mat-header-cell *matHeaderCellDef class="{{AutoColumnWithClass.Position}}">
                {{AutoColumnHeaders.Position}}
            </mat-header-cell>
            <mat-cell *matCellDef="let rowData" class="{{AutoColumnWithClass.Position}}">
                <ng-container *ngIf="rowData.position$">
                    {{ rowData.position$ | async }}
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="DragButton">
            <mat-header-cell class="{{AutoColumnWithClass.DragButton}} jsut-center" *matHeaderCellDef>
                <fa-icon icon="arrows-alt-v" style="margin: 0 1rem"></fa-icon>
            </mat-header-cell>
            <mat-cell class="{{AutoColumnWithClass.DragButton}} jsut-center" *matCellDef="let rowData">
                <button class="drag-button" [disabled]="_disabled" [class.is-loading]="ls.IsLoading$ | async" mat-icon-button>
                    <fa-icon icon="bars"></fa-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="IsVisible">
            <mat-header-cell class="{{AutoColumnWithClass.IsVisible}} jsut-center" *matHeaderCellDef></mat-header-cell>
            <mat-cell class="{{AutoColumnWithClass.IsVisible}} jsut-center" *matCellDef="let element" [attr.colspan]="determineIfIsArlTableDataOrGroupEnd(element) ? 1 : 0">
                <ng-container *ngIf="determineIfIsArlTableDataOrGroupEnd(element) && element.entity.isBookingPosition">
                    <button
                        mat-icon-button
                        (click)="element.form.controls.childrenVisible.setValue(!element.form.value.childrenVisible); element.form.controls.childrenVisible.markAsDirty()"
                        *ngIf="element.isExpanded$ | async; else icon"
                        [matTooltip]="'unterpositionen ' + (element.form.value.childrenVisible ? 'ausblenden' : 'einblenden')"
                        [disabled]="element.form.controls.childrenVisible.disabled"
                    >
                        <fa-icon [icon]="element.form.value.childrenVisible ? 'eye' : 'eye-slash'"></fa-icon>
                    </button>
                    <ng-template #icon>
                        <fa-icon *ngIf="element.form.controls.childrenVisible.enabled && !element.form.value.childrenVisible" [icon]="element.form.value.childrenVisible ? 'eye' : 'eye-slash'"></fa-icon>
                    </ng-template>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="InheritFromChildren">
            <mat-header-cell class="{{AutoColumnWithClass.InheritFromChildren}} jsut-center" *matHeaderCellDef></mat-header-cell>
            <mat-cell class="{{AutoColumnWithClass.InheritFromChildren}} jsut-center" *matCellDef="let element" [attr.colspan]="determineIfIsArlTableDataOrGroupEnd(element) ? 1 : 0">
                <ng-container *ngIf="determineIfIsArlTableDataOrGroupEnd(element) && element.entity.isBookingPosition">
                    <span class="inherit-from-children-button" *ngIf="element.form.value.InheritFromChildren">
                        <strong>K</strong>
                    </span>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="EditButton">
            <mat-header-cell class="{{AutoColumnWithClass.EditButton}}" *matHeaderCellDef></mat-header-cell>
            <mat-cell class="{{AutoColumnWithClass.EditButton}}" *matCellDef="let element">
                <ng-container *ngIf="determineIfIsArlTableDataOrGroupEnd(element)">
                    <button mat-icon-button aria-label="expand row" (click)="expandRow(element); $event.stopPropagation()">
                        <fa-icon *ngIf="!(element.isExpanded$ | async)" icon="chevron-down"></fa-icon>
                        <fa-icon *ngIf="element.isExpanded$ | async" icon="chevron-up"></fa-icon>
                    </button>
                </ng-container>
            </mat-cell>
        </ng-container>
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let element" [attr.colspan]="DisplayedColumns$ | async | length">
                <!--                <div class="element-detail" [@detailExpand]="element.animationTrigger$ | async">-->
                <ng-container *ngIf="element.isExpanded$">
                    <div class="element-detail" [class.collapsed]="!(element.isExpanded$ | async)" *ngIf="!element.entity.isBookingPosition">
                        <!--                    {{ element.form?.value.name }}-->
                        <!--                    <mat-form-field *ngIf="element.form?.controls.name">-->
                        <!--                        <mat-label>Name</mat-label>-->
                        <!--                        <input matInput type="text" [formControl]="element.form.controls.name">-->
                        <!--                    </mat-form-field>-->

                        <div class="action-row">
                            <button app-button Color="cancel" [IsLoading]="ls.IsLoading$ | async" [Disabled]="!element.form?.dirty" Inverted (click)="onAbortGroupClick(element)">abbrechen</button>
                            <span class="spacer"></span>
                            <button app-button Color="red" [disabled]="_disabled" [IsLoading]="ls.IsLoading$ | async" (click)="onDeleteClick(element)">löschen</button>
<!--                            <span class="spacer"></span>-->
                            <!--                        <button app-button Color="green" [IsLoading]="ls.IsLoading$ | async" [Disabled]="someFormDirty$ | async" (click)="onDuplicateClick()">duplizieren</button>-->
<!--                            <button app-button Color="green" [IsLoading]="ls.IsLoading$ | async" [Disabled]="!element.form?.dirty || element.form?.invalid" (click)="onSaveGroupClick(element)">speichern</button>-->
                        </div>
                    </div>
                    <div class="element-detail" [class.collapsed]="!(element.isExpanded$ | async)" *ngIf="element.entity.isBookingPosition">
<!--                        {{ element.entity?.Id }}-->
                        <app-arl-table-detail-row
                            style="width: 100%"
                            [position]="element.position$ | async"
                            [arl]="element.entity"
                            (SaveClick)="onSaveClick(element)"
                            (AbortClick)="onAbortClick(element)"
                            (DeleteClick)="onDeleteClick(element)"
                            (DuplicateClick)="onDuplicateClick(element)"
                            [ArlFormDirty]="element.form.dirty"
                            [ArlFormInvalid]="element.form.invalid"
                            [inheritFromChildrenForm]="element.form.controls.InheritFromChildren"
                            [childrenVisibleForm]="element.form.controls.childrenVisible"
                            [ZuschlaegeResourcen]="element.form.getRawValue().ZuschlaegeResourcen"
                            [ZuschlaegeDefault]="element.form.getRawValue().ZuschlaegeDefault"
                            [ZuschlaegeEmployee]="element.form.getRawValue().ZuschlaegeEmployee"
                            [ZuschlaegeExternalService]="element.form.getRawValue().ZuschlaegeExternalService"
                            [ZuschlaegeOther]="element.form.getRawValue().ZuschlaegeOther"
                            (calculatedAmount)="onAmountCalculated(element, $event)"
                            [disabled]="_disabled"
                        ></app-arl-table-detail-row>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="group">
            <mat-cell *matCellDef="let element" [attr.colspan]="DisplayedColumns$ | async | length">
                <strong>{{ element.group?.name }}</strong>
            </mat-cell>
        </ng-container>
        <mat-header-row [class.hide]="!(tableData$ | async)?.length" *matHeaderRowDef="DisplayedColumns$ | async; sticky: !(expandedElement$ | async)"></mat-header-row>
        <mat-row
            [cdkDragDisabled]="row.isExpanded$ && row.isExpanded$ | async"
            cdkDrag
            [cdkDragData]="row"
            cdkDragPreviewContainer="parent"
            class="element-row"
            [class.expanded-row]="row.isExpanded$ && row.isExpanded$ | async"
            [class.is-booking-position]="row.entity?.isBookingPosition"
            [class.mat-elevation-z8]="row.isExpanded$ && row.isExpanded$ | async"
            *matRowDef="let row; columns: DisplayedColumns$ | async"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row id-{{ row.entity?.Id }}"></mat-row>
        <tr class="mat-row" style="display: flex" *matNoDataRow>
            <td class="mat-cell" style="flex-grow: 1; display: flex; align-items: center;" [attr.colspan]="(DisplayedColumns$ | async)?.length">
                <span>Keine Abrechnungspositionen vorhanden</span>
            </td>
        </tr>
    </mat-table>
</div>
