import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { catchError, map, tap, withLatestFrom } from "rxjs/operators";
import { isNotNullOrUndefined,stringifyIfNotNullOrUndefined,stringifyIfNotUndefined } from "../../helper/helper";
import { AccountsReceivableLedgerEntity,AccountsReceivableLedgerEntityFromBackend } from '../entities/accounts-receivable-ledger.entity';

import { BusinessVolumeResolver } from '../guards/business-volume.resolver';
import { LedgerImportResolver } from '../guards/ledger-import.resolver';
import { State } from '../State';
import { AccountsReceivableLedgerActionTypes } from '../State/actions/accounting.actions';
import { BaseActionTypes } from '../State/actions/base.actions';
import { getBusinessVolumeFetched } from '../State/selectors/business-volume.selector';
import { HttpService } from './http.service';
import {
    DaveMutationChangeAccountsReceivableLedgerArgs,
    DaveMutationCreateAccountsReceivableLedgerArgs
} from "../graphql-types";
import {
    getAccountsReceivableLedgerDictionary,
    getAccountsReceivableLedgers,
    getAccountsReceivableLedgersFetched,
} from '../State/selectors/accounting.selector';
import { getLedgerImportById, getLedgerImportsFetched } from '../State/selectors/ledger-import.selector';
import { getFetched$ } from '../helper/helper';
import { Actions, ofType } from '@ngrx/effects';

enum ErrorCodes {
    Add = 'Buchungsposition hinzufügen fehlgeschlagen',
    Modify = 'Buchungsposition ändern fehlgeschlagen',
    Delete = 'Buchungsposition löschen fehlgeschlagen',
}
@Injectable({
    providedIn: 'root',
})
export class ArlDataService {
    constructor(private store$: Store<State>, private gatewayHttpService: HttpService, private bvResolver: BusinessVolumeResolver, private liResolver: LedgerImportResolver, private actions:Actions) {}
    public getArlsFromLedgerImport$(ledgerImportId: number): Observable<AccountsReceivableLedgerEntity[]> {
        return getFetched$(this.store$, getLedgerImportsFetched, getLedgerImportById({id: ledgerImportId})).pipe(switchMap(ledgerImport => {
            if (!ledgerImport?.AccountsReceivableLedgerIds.length) {
                if (!ledgerImport) {
                    throw 'LedgerImport with id ' + ledgerImportId + ' not found';
                }
                return of([] as AccountsReceivableLedgerEntity[])
            }
            return getFetched$(this.store$, getAccountsReceivableLedgersFetched, getAccountsReceivableLedgerDictionary).pipe(
                map(arls => {
                    // const getChildren = (arlId: number) : number[] => {
                    //     const arl = arls[arlId];
                    //     if (arl.ArlIds?.length) {
                    //         return [arl.Id, ...arl.ArlIds.map(aId => getChildren(aId)).flat()];
                    //     } else {
                    //         return [arl.Id];
                    //     }
                    // };
                    const getChildren = (arl: AccountsReceivableLedgerEntity) : AccountsReceivableLedgerEntity[] => {
                        if (arl.ArlIds?.length) {
                            return [arl, ...arl.ArlIds.map(aId => getChildren(arls[aId])).flat()];
                        } else {
                            return [arl];
                        }
                    };
                    return ledgerImport.AccountsReceivableLedgerIds.map(aId => getChildren(arls[aId])).flat();
                })
            )
        }))
    }

    public addArls(payload: DaveMutationCreateAccountsReceivableLedgerArgs[], currencyCalcFactor  = 100): Promise<AccountsReceivableLedgerEntity[]> {
        // alert('add Arls triggered');
        console.log({payload, currencyCalcFactor})
//         const requestNamePrefix = 'createGeneratedDocumentsDiary';
//
//         const query = `mutation($commissionId: Int, $from: DateTime, $to: DateTime, $eventIds: [Int], ${payload.map((_, i) => '$generatedDocumentsDiaryTypeSlug' + i + ': GeneratedDocumentsDiaryTypeSlug').join(', ')}){
//             ${payload.map(
//             (_, i) => `${requestNamePrefix + i}: createGeneratedDocumentsDiary(commissionId: $commissionId, eventIds: $eventIds, from: $from, to: $to, generatedDocumentsDiaryTypeSlug: $generatedDocumentsDiaryTypeSlug${i}) {
//             ...genDocFields
//             }`,
//         )}
//         }
//         fragment genDocFields on GeneratedDocumentsType {
//   ${AccountsReceivableLedgerEntity.GqlFields.join(',')}
// }`;
//         const variables = { eventIds, commissionId, from: fromDate && FrontendDate(fromDate), to: toDate && FrontendDate(toDate) };
//         typesToGenerate.forEach((generatedDocumentsDiaryTypeSlug, i) => {
//             variables['generatedDocumentsDiaryTypeSlug' + i] = generatedDocumentsDiaryTypeSlug;
//         });







        // const Payload = payload[0];
        return firstValueFrom(combineLatest(payload.map(Payload => {
            const queryString = `mutation {
          createAccountsReceivableLedger(
    ${stringifyIfNotNullOrUndefined(Payload, 'date')}
    ${isNotNullOrUndefined(Payload.amount) ? 'amount:' + (Payload.amount * currencyCalcFactor).toFixed(0) : ''}
    ${stringifyIfNotNullOrUndefined(Payload, 'accountNo')}
    ${stringifyIfNotNullOrUndefined(Payload, 'buCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'costCategoryId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'costCategoryId2')}
    ${stringifyIfNotNullOrUndefined(Payload, 'tax')}
    ${stringifyIfNotNullOrUndefined(Payload, 'information')}
    ${stringifyIfNotNullOrUndefined(Payload, 'currencyCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'invoiceId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bookingText')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partyId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'paidAt')}
    ${stringifyIfNotNullOrUndefined(Payload, 'iban')}
    ${stringifyIfNotNullOrUndefined(Payload, 'dueDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerCity')}
    ${isNotNullOrUndefined(Payload.costAmount) ? 'costAmount:' + (Payload.costAmount * currencyCalcFactor).toFixed(0) : ''}
    ${isNotNullOrUndefined(Payload.discountAmount) ? 'discountAmount:' + (Payload.discountAmount * currencyCalcFactor).toFixed(0) : ''}
    ${stringifyIfNotNullOrUndefined(Payload, 'internalInvoiceId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'vatId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'exchangeRate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bankCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bankAccount')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bankCountry')}
    ${stringifyIfNotNullOrUndefined(Payload, 'swiftCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'accountName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'paymentConditionsId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPercentage')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPaymentDate')}
    ${isNotNullOrUndefined(Payload.discountAmount2) ? 'discountAmount2:' + (Payload.discountAmount2 * currencyCalcFactor).toFixed(0) : ''}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPercentage2')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPaymentDate2')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bpAccountNo')}
    ${stringifyIfNotNullOrUndefined(Payload, 'deliveryDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'orderId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'ledgerImportId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'eventId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'generatedDocumentId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'canBeEdited')}
    ${stringifyIfNotNullOrUndefined(Payload, 'quantity')}
    ${isNotNullOrUndefined(Payload.baseCost) ? 'baseCost:' + (Payload.baseCost * currencyCalcFactor).toFixed(0) : ''}
    ${stringifyIfNotNullOrUndefined(Payload, 'quantityTypeId')}
    multiplikator: ${JSON.stringify(Payload.multiplikator)}
    ${stringifyIfNotNullOrUndefined(Payload, 'customProperties')}
    ${stringifyIfNotNullOrUndefined(Payload, 'documentType')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerOfficeId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'parentId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'isVisible')}
    ${stringifyIfNotNullOrUndefined(Payload, 'resourceId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'longInformation')}
    ${stringifyIfNotNullOrUndefined(Payload, 'showLongInformation')}
    ${stringifyIfNotNullOrUndefined(Payload, 'inheritFromChildren')}
    ${stringifyIfNotNullOrUndefined(Payload, 'jobSpecificationId')}
    ${Payload.type ? `type: ${Payload.type}` : ''}

          ) {${AccountsReceivableLedgerEntity.GqlFields.join(',')}}
        }
        `;
            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                // map(res => res?.createAccountsReceivableLedger ? [res.createAccountsReceivableLedger] : null),

            );
        })).pipe(
            withLatestFrom(this.store$.select(getBusinessVolumeFetched)),
            map(([res, bvFetched]) => {
                if (bvFetched) {
                    this.bvResolver.pollUpdated();
                }
                if (res?.every(r => r?.createAccountsReceivableLedger)) {
                    const entitys = res.map(r => AccountsReceivableLedgerEntityFromBackend(r.createAccountsReceivableLedger));
                    entitys.forEach(e => {
                        this.store$.dispatch(AccountsReceivableLedgerActionTypes.UpdateSingleAccountsReceivableLedger({
                            Payload: e,
                        }))
                    });
                    return entitys;
                } else {
                    throw 'wrong response';
                }
            }),
            catchError((err, caught) => {
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Add,
                            Err: err,
                            Caught: caught,
                        },
                    }),
                );
                return EMPTY;
            }),
        ))

    }

    public changeArls(payload: DaveMutationChangeAccountsReceivableLedgerArgs[], currencyCalcFactor  = 100): Promise<AccountsReceivableLedgerEntity[]> {
                // const Payload = payload[0];
        return firstValueFrom(combineLatest(payload.map(Payload => {
            const queryString = `mutation {
          changeAccountsReceivableLedger(
    id: ${Payload.id}
    ${stringifyIfNotNullOrUndefined(Payload, 'date')}
    ${isNotNullOrUndefined(Payload.amount) ? 'amount:' + (Payload.amount * currencyCalcFactor).toFixed(0) : ''}
    ${stringifyIfNotNullOrUndefined(Payload, 'accountNo')}
    ${stringifyIfNotNullOrUndefined(Payload, 'buCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'costCategoryId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'costCategoryId2')}
    ${stringifyIfNotNullOrUndefined(Payload, 'tax')}
    ${stringifyIfNotNullOrUndefined(Payload, 'information')}
    ${stringifyIfNotNullOrUndefined(Payload, 'currencyCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'invoiceId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bookingText')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partyId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'paidAt')}
    ${stringifyIfNotNullOrUndefined(Payload, 'iban')}
    ${stringifyIfNotNullOrUndefined(Payload, 'dueDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerCity')}
    ${isNotNullOrUndefined(Payload.costAmount) ? 'costAmount:' + (Payload.costAmount * currencyCalcFactor).toFixed(0) : ''}
    ${isNotNullOrUndefined(Payload.discountAmount) ? 'discountAmount:' + (Payload.discountAmount * currencyCalcFactor).toFixed(0) : ''}
    ${stringifyIfNotNullOrUndefined(Payload, 'internalInvoiceId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'vatId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'exchangeRate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bankCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bankAccount')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bankCountry')}
    ${stringifyIfNotNullOrUndefined(Payload, 'swiftCode')}
    ${stringifyIfNotNullOrUndefined(Payload, 'accountName')}
    ${stringifyIfNotNullOrUndefined(Payload, 'paymentConditionsId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPercentage')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPaymentDate')}
    ${Payload.type ? `type: ${Payload.type}` : ''}
    ${
                isNotNullOrUndefined(Payload.discountAmount2)
                    ? 'discountAmount2:' + (Payload.discountAmount2 * currencyCalcFactor).toFixed(0)
                    : ''
            }
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPercentage2')}
    ${stringifyIfNotNullOrUndefined(Payload, 'discountPaymentDate2')}
    ${stringifyIfNotNullOrUndefined(Payload, 'bpAccountNo')}
    ${stringifyIfNotNullOrUndefined(Payload, 'deliveryDate')}
    ${stringifyIfNotNullOrUndefined(Payload, 'orderId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'ledgerImportId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'customerId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'commissionId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'eventId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'generatedDocumentId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'canBeEdited')}
    ${stringifyIfNotNullOrUndefined(Payload, 'quantity')}
    ${isNotNullOrUndefined(Payload.baseCost) ? 'baseCost:' + (Payload.baseCost * currencyCalcFactor).toFixed(0) : ''}
    ${isNotNullOrUndefined(Payload.payedAmount) ? 'payedAmount:' + (Payload.payedAmount * currencyCalcFactor).toFixed(0) : ''}
    multiplikator: ${JSON.stringify(Payload.multiplikator)}
    ${stringifyIfNotNullOrUndefined(Payload, 'customProperties')}
    ${stringifyIfNotNullOrUndefined(Payload, 'documentType')}
    ${stringifyIfNotNullOrUndefined(Payload, 'partnerOfficeId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'arlIds')}
    ${stringifyIfNotNullOrUndefined(Payload, 'parentId')}
    ${stringifyIfNotUndefined(Payload, 'quantityTypeId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'inheritFromChildren')}
    ${stringifyIfNotNullOrUndefined(Payload, 'isVisible')}
    ${stringifyIfNotUndefined(Payload, 'resourceId')}
    ${stringifyIfNotNullOrUndefined(Payload, 'longInformation')}
    ${stringifyIfNotNullOrUndefined(Payload, 'showLongInformation')}
    ${stringifyIfNotNullOrUndefined(Payload, 'jobSpecificationId')}
    ${stringifyIfNotUndefined(Payload, 'materialId')}
          ) {${AccountsReceivableLedgerEntity.GqlFields.join(',')}}
        }
        `;
            return this.gatewayHttpService.graphQl({ query: queryString }).pipe(
                // map(res => res?.createAccountsReceivableLedger ? [res.createAccountsReceivableLedger] : null),

            );
        })).pipe(
            withLatestFrom(this.store$.select(getBusinessVolumeFetched), this.store$.select(getLedgerImportsFetched)),
            tap(([, bvFetched, liFetched]) => {
                if(bvFetched) {
                    this.bvResolver.resolve();
                }
                if (liFetched) {
                    this.liResolver.pollUpdated();
                }
            }),
            map(([res]) => {
                if (res?.every(r => r?.changeAccountsReceivableLedger)) {
                    const entitys = res.map(r => AccountsReceivableLedgerEntityFromBackend(r.changeAccountsReceivableLedger));
                    entitys.forEach(e => {
                        this.store$.dispatch(AccountsReceivableLedgerActionTypes.UpdateSingleAccountsReceivableLedger({
                            Payload: e,
                        }))
                    });
                    return entitys;
                } else {
                    throw 'wrong response';
                }
            }),
            catchError((err, caught) => {
                this.store$.dispatch(
                    BaseActionTypes.ErrorAction({
                        Payload: {
                            ToasterMessage: ErrorCodes.Modify,
                            Err: err,
                            Caught: caught,
                        },
                    }),
                );
                return EMPTY;
            }),
        ))

    }
    public removeArl(id:number) {
        const resolved = firstValueFrom(this.actions.pipe(ofType(AccountsReceivableLedgerActionTypes.UpdateAccountsReceivableLedger, BaseActionTypes.ErrorAction))).then(action => {
            if (action.type === BaseActionTypes.ErrorAction.type) {
                throw ErrorCodes.Delete;
            }
            return {deletedId: id}
        });
        this.store$.dispatch(AccountsReceivableLedgerActionTypes.DeleteAccountsReceivableLedger({Payload: {id}}));
        return resolved;
    }
}
