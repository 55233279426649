import { DatePipe, formatCurrency, formatDate } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '@dave/types/dist/proto/global/permissions';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, skip, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { FrontendDate } from 'src/app/dave-data-module/helper/backend-frontend-conversion.helper';
import { EmployeeToVacationEntity, GetVacationStatusName, VacationStatusEnum, VacationTypeEnum, VacationTypeEnumNameMap } from '../../../dave-data-module/entities/employee-to-vacation.entity';
import { EmployeeEntity, EmployeeStatusEnum, EmployeeStatusNames, EmploymentTypeNames, EmploymentTypes, SalaryTypeNames, SalaryTypes } from '../../../dave-data-module/entities/employee.entity';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { JobSpecificationEntity } from '../../../dave-data-module/entities/job-specification.entity';
import { LicenseEntity } from '../../../dave-data-module/entities/license.entity';
import { ProductEntity, ProductEntityIds } from '../../../dave-data-module/entities/product.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { EmployeeActionTypes } from '../../../dave-data-module/State/actions/employee.actions';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { JobSpecificationActionTypes } from '../../../dave-data-module/State/actions/job-specification.action';
import { PermissionsActionTypes } from '../../../dave-data-module/State/actions/permissions.actions';
import { UsersActionTypes } from '../../../dave-data-module/State/actions/users.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getEmployeeToVacations } from '../../../dave-data-module/State/selectors/employee-to-vacation.selectors';
import { getEmployeeById, getEmployeesSortedByNameStartWithMe, getUserByEmployeeId } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getFiles } from '../../../dave-data-module/State/selectors/files.selectors';
import { getJobSpecification, getJobSpecificationById, getJobSpecificationDictionary } from '../../../dave-data-module/State/selectors/job-specification.selector';
import { getPartner, getPartnerMainOffice } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getRoles } from '../../../dave-data-module/State/selectors/permissions.selectors';
import { getLicenses, getProductsWithMaxUser } from '../../../dave-data-module/State/selectors/products.selectors';
import { getManagedUsers, getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { NewVacationComponent, NewVacationComponentDialogData } from '../../../dave-employee-administration-module/components/vacation/new-vacation/new-vacation.component';
import { SelectUserEmployeeDialogComponent, SelectUserEmployeeDialogComponentDialogData } from '../../../dave-select-user/components/select-user-dialogs/select-user-employee-dialog/select-user-employee-dialog.component';
import { HourListComponent, HourListComponentDialogData } from '../../../dave-time-tracker/components/hour-list/hour-list.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { IDetailListTemplateData } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType, IProfileTemplateData, IProfileTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { InfoDialogComponent, InfoDialogData } from '../../../dave-utils-module/dave-shared-components-module/components/dialogs/info-dialog/info-dialog.component';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { GoogleMapsService } from '../../../dave-utils-module/dave-shared-components-module/services/google-maps.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { RoutingHistoryService } from '../../../dave-utils-module/dave-shared-components-module/services/routing-history.service';
import { SelectSearchOption } from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import { Address, getAddressString, isNotNullOrUndefined } from '../../../helper/helper';
import { LicenseCost, PricePerUser } from '../../../helper/license-cost.helper';
import { AbsentMetaIcon, DMSPageMeta, VacationMetaIcon } from '../../../helper/page-metadata';
import { Permission } from '../../../helper/permission.helper';
import { userRequiredFieldValidator } from '../../../helper/validation.helper';
import { LoadingService } from '../../../services/loading.service';
import { DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData } from '../../templates/new-document-view/component/dave-file-upload-dialog/dave-file-upload-dialog.component';
import { OpenEditJobspecificationPopup, OpenNewJobspecificationPopup } from './employee-helper';
import { User2ProductDialogComponent, User2ProductDialogData } from './user2product-dialog/user2-product-dialog.component';
import { isNullOrUndef } from 'chart.js/helpers';

const availableIsNumber = (available: 'Unbegrenzt' | number) => available !== 'Unbegrenzt' && isNotNullOrUndefined(available);

interface RoleFormated {
    key: string;
    name: string;
    info: string;
    selected: boolean;
}

@Component({
    selector: 'app-detail-employee',
    templateUrl: './detail-employee.component.html',
    styleUrls: ['./detail-employee.component.scss'],
    providers: [DatePipe],
})
export class DetailEmployeeComponent implements OnInit, OnDestroy, ComponentCanDeactivate, AfterViewInit {
    @ViewChild('jobSpecificationTemplate') jobSpecificationTemplate: TemplateRef<any>;
    public ShownButtons = true;
    public UploadOpen = false;
    public readonly Permissions = Permissions;
    public PreviewOpen = false;
    public PreviewedFileId$ = new BehaviorSubject<number | null>(null);
    public ImageIds : number[];
    public SmallView$: Observable<boolean>;
    public RolesFormated$: Observable<RoleFormated[]>;
    public RolesFormatedFromEmployee$: Observable<RoleFormated[]>;
    public User: UserEntity;
    public Products: ReturnType<typeof getProductsWithMaxUser>;
    public Editing = false;
    public VacationMetaIcon = VacationMetaIcon;
    public AbsentMetaIcon = AbsentMetaIcon;
    public DMSPath = DMSPageMeta.Path;
    public DMSIcon = DMSPageMeta.Icon;
    public User$ = this.store.select(getUser);
    public ShowStatus = false;
    public JobspecificationId: JobSpecificationEntity & { optionLabel: string };
    ProductEntityIds = ProductEntityIds;
    public EmployeeForm = new FormGroup(
        {
            Firstname: new FormControl<string>(''),
            Lastname: new FormControl<string>(''),
            JobSpecificationId: new FormControl<SelectSearchOption<JobSpecificationEntity>>(null),
            Email: new FormControl<string>('', Validators.email),
            PhoneNumber: new FormControl<string>(''),
            Mobile: new FormControl<string>(''),
            FaxNumber: new FormControl<string>(''),
            LocationForm: this.fb.group<Address>({
                Street: null,
                PostalCode: null,
                City: null,
                Country: null,
            }),
            EmployeeId: new FormControl<string>(''),
            RemainingVacationDaysFromPastYears: new FormControl<number>(null),
            RemainingVacationDaysFromCurrentYear: new FormControl<number>(null),
            MinimumVoteCount: new FormControl<number>(null, Validators.min(1)),
            AnnualVacationDays: new FormControl<number>(null),
            Overtime: new FormControl<number>(null),
            WorkingDays: new FormControl<number[]>(null),
            SalaryType: new FormControl<SalaryTypes>(null),
            EmploymentType: new FormControl<EmploymentTypes>(null),
            EmployeeStatus: new FormControl<EmployeeStatusEnum>(EmployeeStatusEnum.Aktiv, Validators.required),
            Salary: new FormControl<number>(null),
            Department: new FormControl<string>(null),
            IBAN: new FormControl<string>(null),
            BIC: new FormControl<string>(null),
            CancellationPeriod: new FormControl<number>(null),
            DateOfEmployment: new FormControl<Date>(null),
            Birthdate: new FormControl<Date>(null),
            EndOfContract: new FormControl<Date>(null),
            DurationTrial: new FormControl<Date>(null),
        },
        userRequiredFieldValidator,
    );
    private directChangeStatusForm = new FormControl<EmployeeStatusEnum | null>(null, Validators.required);
    public StatusData: IDetailListTemplateData = {
        Properties: [
            {
                key: 'Mitarbeiter Status',
                value: '',
                formControl: this.directChangeStatusForm,
                options: {
                    specialInput: {
                        select: Object.values(EmployeeStatusEnum).map((val) => ({
                            optionValue: val,
                            optionLabel: EmployeeStatusNames.get(val),
                        })),
                    },
                },
            },
        ],
    };
    public LicensesForm = new UntypedFormControl(null);
    public LicenseList: LicenseEntity[];

    public SigneFile$ = this.store.select(getUser).pipe(
        map((user) => user?.DocumentId),
        filter(isNotNullOrUndefined),
        switchMap((id) => this.fileDataService.GetFileById$(id)),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public SigneUrls$ = this.SigneFile$.pipe(
        filter(isNotNullOrUndefined),
        withLatestFrom(this.store.select(getToken)),
        map(([file, token]) => ({
            download: this.api.GetUrl(file.GetLastVersion().GetDownloadLink(token), 'file'),
        })),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    /** (behavior, NN) Der anzuzeigende Mitarbeiter */
    public Employee$: Observable<EmployeeEntity> = this.route.paramMap.pipe(
        map((paramMap) => +paramMap.get('employeeId')),
        switchMap((employeeId) => this.store.select(getEmployeeById({ id: employeeId }))),
        tap((employee) => !employee && console.error('Could not find EmployeeEntity')),
        filter(isNotNullOrUndefined),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public IsMe$ = combineLatest([this.Employee$, this.store.select(getUser)]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([employee, user]) => employee.UserId === user.Id),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    public AssignedToEmployee$ = combineLatest([this.Employee$, this.store.select(getUser)]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([employee, user]) => employee.UserIds.includes(user.Id)),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    public Vacations$ = combineLatest([this.Employee$, this.store.select(getEmployeeToVacations).pipe(map((vs) => vs?.filter((v) => v.Type === VacationTypeEnum.Vacation)))]).pipe(
        map(
            ([employee, e2v]) =>
                new MatTableDataSource(
                    e2v
                        ?.filter((e) => e.EmployeeId === employee?.Id)
                        .map((e) => ({
                            From: this.datePipe.transform(e.StartDate),
                            To: this.datePipe.transform(e.EndDate),
                            Days: e.VacationDays,
                            State: GetVacationStatusName(e),
                            cssClass: e.VacationStatus === VacationStatusEnum.Approved ? 'app-color-approved' : e.VacationStatus === VacationStatusEnum.Declined ? 'app-color-declined' : null,
                            vacation: e,
                        }))
                        .sort((a, b) => (a.vacation.CreatedAt > b.vacation.CreatedAt ? -1 : 1)),
                ),
        ),
        tap(
            (dataSource) =>
                (dataSource.sortingDataAccessor = (object, key) => {
                    switch (key) {
                        case 'From':
                            return object.vacation.StartDate;
                        case 'To':
                            return object.vacation.EndDate;
                        case 'Days':
                            return object.vacation.VacationDays;
                        case 'State':
                            return object.vacation.VacationStatus;
                        default:
                            return object[key];
                    }
                }),
        ),
    );
    public Absents$ = combineLatest([this.Employee$, this.store.select(getEmployeeToVacations).pipe(map((vs) => vs?.filter((v) => v.Type !== VacationTypeEnum.Vacation)))]).pipe(
        map(
            ([employee, e2v]) =>
                new MatTableDataSource(
                    e2v
                        ?.filter((e) => e.EmployeeId === employee?.Id)
                        .map((e) => ({
                            From: this.datePipe.transform(e.StartDate),
                            To: this.datePipe.transform(e.EndDate),
                            Days: e.VacationDays,
                            Type: VacationTypeEnumNameMap.get(e.Type),
                            vacation: e,
                        }))
                        .sort((a, b) => (a.vacation.CreatedAt > b.vacation.CreatedAt ? -1 : 1)),
                ),
        ),
        tap(
            (dataSource) =>
                (dataSource.sortingDataAccessor = (object, key) => {
                    switch (key) {
                        case 'From':
                            return object.vacation.StartDate;
                        case 'To':
                            return object.vacation.EndDate;
                        case 'Days':
                            return object.vacation.VacationDays;
                        case 'Type':
                            return object.vacation.VacationStatus;
                        default:
                            return object[key];
                    }
                }),
        ),
    );
    public FolderId$ = this.Employee$.pipe(
        switchMap((employee) => this.folderDataService.getFolderFromEntity(employee.Id, FolderTypes.employee)),
        tap(() => this.FolderLoaded$.next(true)),
        map((folder) => folder?.Id),
        distinctUntilChanged(),
        shareReplay({refCount: true, bufferSize: 1 }),
    );
    public UploadFolderId: number;
    public JobSpecifications: Array<JobSpecificationEntity & { optionLabel: string }>;

    /** (behavior, NN) Der Partner aus dem Store */
    public Partner$ = this.store.select(getPartner).pipe(filter(isNotNullOrUndefined));

    /** (behavior, NN) `Data` für `ProfileTemplateComponent` */
    public ProfileData$: Observable<IProfileTemplateData>;
    public UserFromEmployee$: Observable<UserEntity>;
    public Products$: Observable<ReturnType<typeof getProductsWithMaxUser>>;
    public DisplayUser$: Observable<UserEntity & { products: ReturnType<typeof getProductsWithMaxUser> }>;
    public LicenseList$: Observable<LicenseEntity[]>;
    public Users$: Observable<{ User: EmployeeEntity; ItsMe: boolean }[]>;
    private subscriptions: Subscription[] = [];

    public FolderLoaded$ = new BehaviorSubject(false);
    constructor(
        private appDialog: AppDialogService,
        private store: Store<State>,
        private breakpointObserverService: BreakpointObserverService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private routingHistoryService: RoutingHistoryService,
        private cdr: ChangeDetectorRef,
        private fb: FormBuilder,
        private googleGeocoderService: GoogleMapsService,
        public PS: PermissionService,
        private datePipe: DatePipe,
        private api: HttpService,
        private fileDataService: FileDataService,
        public LS: LoadingService,
        private actions$: Actions,
        private quantityTypeRes: QuantityTypeResolver,
        private folderDataService: FolderDataService,
    ) {
        this.SmallView$ = this.breakpointObserverService.DetailViewWidthQuery;
    }

    ngAfterViewInit(): void {
        this.ProfileData$ = combineLatest([
            this.Employee$,
            this.store.select(getPartnerMainOffice).pipe(filter(isNotNullOrUndefined)),
            this.PS.Has(Permission.EditEmployee),
            this.store.select(getUser),
            this.store.select(getJobSpecificationDictionary),
        ]).pipe(
            map(([employee, partnerOffice, editEmployee, user, JobSpecifications]): IProfileTemplateData => {
                const Properties: IProfileTemplateDataProperty[] = [
                    {
                        icon: 'building',
                        options: { type: CustomPropertyType.Subtitle },
                        value: partnerOffice.Name,
                    },
                    {
                        icon: 'trash',
                        options: { type: CustomPropertyType.Title },
                        value: `${employee.DisplayName}${employee.Deleted ? ' (gelöscht)' : ''}`,
                    },
                    {
                        icon: 'trash',
                        formControl: this.FirstnameForm as UntypedFormControl,
                        key: 'Vorname',
                    },
                    {
                        icon: 'trash',
                        formControl: this.LastnameForm as UntypedFormControl,
                        key: 'Nachname',
                    },
                    {
                        icon: 'hashtag',
                        formControl: this.EmployeeIdForm as UntypedFormControl,
                        key: 'Mitarbeiternummer',
                        value: employee.EmployeeNo,
                    },
                    {
                        icon: 'id-card',
                        formControl: this.EmployeeForm.controls.JobSpecificationId,
                        key: 'Jobbezeichnung',
                        value: employee.JobSpecificationId ? JobSpecifications[employee.JobSpecificationId].Name : null,
                        options: {
                            specialInput: {
                                customTemplate: this.jobSpecificationTemplate,
                                // select:
                                //     Object.values(JobSpecifications)
                                //         .map(c => ({
                                //             optionValue: c.Id,
                                //             optionLabel: c.Name,
                                //         })) || [],
                            },
                        },
                    },
                    {
                        icon: 'envelope',
                        formControl: this.EmailForm as UntypedFormControl,
                        key: 'E-Mail',
                        options: { type: CustomPropertyType.Mailto },
                        value: employee.Email,
                    },
                    {
                        icon: 'phone',
                        formControl: this.PhoneNumberForm as UntypedFormControl,
                        key: 'Telefonnummer',
                        options: { type: CustomPropertyType.Tel },
                        value: employee.Phonenumber,
                    },
                    {
                        icon: 'mobile-alt',
                        formControl: this.MobileForm as UntypedFormControl,
                        key: 'Mobilnummer',
                        options: { type: CustomPropertyType.Tel },
                        value: employee.Mobile,
                    },
                    {
                        icon: 'fax',
                        formControl: this.FaxNumberForm as UntypedFormControl,
                        key: 'Faxnummer',
                        options: { type: CustomPropertyType.Tel },
                        value: employee.Faxnumber,
                    },
                    {
                        icon: 'map-marker-alt',
                        key: 'Standort',
                        options: {
                            type: CustomPropertyType.Location,
                            specialInput: {
                                location: {
                                    value: employee,
                                    formGroup: this.EmployeeForm.controls.LocationForm as FormGroupTyped<Address>,
                                },
                            },
                        },
                        value: getAddressString(employee),
                    },
                ];
                let workingHoursPerWeek = 0;
                employee.WeeklyWorkingHours?.forEach((h) => (workingHoursPerWeek += h));
                const workingDays = employee.WeeklyWorkingHours?.map((v, i) => (v ? ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'][i] : null))
                    .filter(isNotNullOrUndefined)
                    .join(', ');
                if (editEmployee && employee.CanEdit) {
                    Properties
                        .push
                        // {
                        //     icon: VacationMetaIcon,
                        //     key: 'Urlaubstage',
                        //     value:
                        //         employee.RemainingVacationDaysFromPastYears +
                        //         employee.RemainingVacationDaysFromCurrentYear +
                        //         ' Tage',
                        //     options: {
                        //         specialInput: {
                        //             number: true,
                        //         }
                        //     }
                        // },
                        ();
                    if (employee.CanSeeOvertime) {
                        Properties.push(
                            {
                                key: 'Lohnart',
                                formControl: this.EmployeeForm.controls.SalaryType,
                                value: SalaryTypeNames.get(employee.SalaryType),
                                options: {
                                    specialInput: {
                                        select: Object.values(SalaryTypes).map((val) => ({
                                            optionValue: val,
                                            optionLabel: SalaryTypeNames.get(val),
                                        })),
                                    },
                                },
                            },
                        )
                        let indexSalaryType = Properties.findIndex(i => i.formControl === this.EmployeeForm.controls.SalaryType);
                        Properties.push(
                            {
                                key: (Properties[indexSalaryType]?.value)?.toString() || "Gehalt",
                                formControl: this.EmployeeForm.controls.Salary,
                                options: {
                                    showHint: 'bei Lohn, Stundenlohn eintragen',
                                    suffix: '€',
                                    specialInput: {
                                        number: true,
                                    },
                                },
                                value: employee.Salary != null ? formatCurrency(employee.Salary, 'de-DE', 'EUR') : null,
                            },
                            {
                                // icon: VacationMetaIcon,
                                key: 'Urlaubstage aus den Vorjahren',
                                formControl: this.EmployeeForm.controls.RemainingVacationDaysFromPastYears,
                                value: employee.RemainingVacationDaysFromPastYears ? employee.RemainingVacationDaysFromPastYears + '' : null,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                },
                            },
                            {
                                // icon: VacationMetaIcon,
                                key: 'Urlaubstage aus diesem Jahr',
                                formControl: this.EmployeeForm.controls.RemainingVacationDaysFromCurrentYear,
                                value: employee.RemainingVacationDaysFromCurrentYear ? employee.RemainingVacationDaysFromCurrentYear + '' : null,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                },
                            },
                            {
                                // icon: VacationMetaIcon,
                                key: 'Urlaubstage pro Jahr',
                                formControl: this.EmployeeForm.controls.AnnualVacationDays,
                                value: employee.AnnualVacationDays ? employee.AnnualVacationDays + '' : null,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                },
                            },
                            {
                                // icon: VacationMetaIcon,
                                key: 'Anzahl der nötigen Genehmigungen für Urlaubsanträge',
                                formControl: this.EmployeeForm.controls.MinimumVoteCount,
                                value: employee.MinimumVoteCount ? employee.MinimumVoteCount + '' : null,
                                options: {
                                    specialInput: {
                                        number: true,
                                    },
                                },
                            },
                            {
                                key: 'Anstellungsverhältnis',
                                formControl: this.EmployeeForm.controls.EmploymentType,
                                value: EmploymentTypeNames.get(employee.EmploymentType),
                                options: {
                                    specialInput: {
                                        select: Object.values(EmploymentTypes).map((val) => ({
                                            optionValue: val,
                                            optionLabel: EmploymentTypeNames.get(val),
                                        })),
                                    },
                                },
                            },
                            {
                                key: 'Überstundenkonto',
                                formControl: this.EmployeeForm.value.SalaryType != SalaryTypes.PerHour ? this.EmployeeForm.controls.Overtime : null,
                                value: this.EmployeeForm.value.SalaryType != SalaryTypes.PerHour && employee.Overtime != null ? Math.round((employee.Overtime + Number.EPSILON) * 100) / 100 + ' Stunden' : null,
                                options: {
                                    suffix: 'h',
                                    specialInput: {
                                        number: true,
                                    },
                                },
                            },
                        );
                        this.EmployeeForm.controls.SalaryType.valueChanges.subscribe((value) => {
                            if(isNotNullOrUndefined) {
                                let indexSalaryType = Properties.findIndex(i => i.formControl === this.EmployeeForm.controls.SalaryType);
                                let indexSalary = Properties.findIndex(i => i.formControl === this.EmployeeForm.controls.Salary);
                                if(this.Editing) {
                                    Properties[indexSalary].key = value;
                                }
                                else {
                                    Properties[indexSalary].key = (Properties[indexSalaryType].value).toString();
                                }
                            }
                        })
                    }
                    Properties.push(
                        {
                            key: 'Arbeitstage',
                            formControl: this.EmployeeForm.controls.WorkingDays,
                            value: workingDays,
                            options: {
                                specialInput: {
                                    weekDaySelector: 'number',
                                },
                                suffix: 'h',
                            },
                        },
                        {
                            key: 'Arbeitsstunden Pro Woche',
                            value: workingHoursPerWeek ? workingHoursPerWeek + ' Stunden' : null,
                            options: {
                                suffix: 'h',
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: 'Status',
                            formControl: this.EmployeeForm.controls.EmployeeStatus,
                            value: EmployeeStatusNames.get(employee.EmployeeStatus),
                            options: {
                                specialInput: {
                                    select: Object.values(EmployeeStatusEnum).map((val) => ({
                                        optionValue: val,
                                        optionLabel: EmployeeStatusNames.get(val),
                                    })),
                                },
                            },
                        },
                        {
                            key: 'Abteilung',
                            formControl: this.EmployeeForm.controls.Department,
                            value: employee.Department,
                        },
                        {
                            key: 'IBAN',
                            formControl: this.EmployeeForm.controls.IBAN,
                            value: employee.IBAN,
                        },
                        {
                            key: 'BIC',
                            formControl: this.EmployeeForm.controls.BIC,
                            value: employee.BIC,
                        },
                        {
                            key: 'Kündigungsfrist in Tagen',
                            formControl: this.EmployeeForm.controls.CancellationPeriod,
                            value: employee.CancellationPeriod ? employee.CancellationPeriod + '' : '',
                            options: {
                                specialInput: {
                                    number: true,
                                },
                            },
                        },
                        {
                            key: 'Anstellungsdatum',
                            formControl: this.EmployeeForm.controls.DateOfEmployment,
                            value: employee.DateOfEmployment ? formatDate(employee.DateOfEmployment, 'dd.MM.yyyy', 'de-DE') : null,
                            options: {
                                type: CustomPropertyType.Datum,
                                specialInput: {
                                    date: true,
                                },
                            },
                        },
                        {
                            key: 'Geburtstag',
                            formControl: this.EmployeeForm.controls.Birthdate,
                            value: employee.Birthdate ? formatDate(employee.Birthdate, 'dd.MM.yyyy', 'de-DE') : null,

                            options: {
                                type: CustomPropertyType.Datum,
                                specialInput: {
                                    date: true,
                                },
                            },
                        },
                        {
                            key: 'Vertragsende',
                            formControl: this.EmployeeForm.controls.EndOfContract,
                            value: employee.EndOfContract ? formatDate(employee.EndOfContract, 'dd.MM.yyyy', 'de-DE') : null,

                            options: {
                                type: CustomPropertyType.Datum,
                                specialInput: {
                                    date: true,
                                },
                            },
                        },
                        {
                            key: 'Probezeit Ende',
                            formControl: this.EmployeeForm.controls.DurationTrial,
                            value: employee.DurationTrial ? formatDate(employee.DurationTrial, 'dd.MM.yyyy', 'de-DE') : null,

                            options: {
                                type: CustomPropertyType.Datum,
                                specialInput: {
                                    date: true,
                                },
                            },
                        },
                    );
                } else if (user?.Id === employee?.UserId) {
                    Properties.push(
                        {
                            // icon: VacationMetaIcon,
                            key: 'Urlaubstage',
                            value: employee.RemainingVacationDaysFromPastYears + employee.RemainingVacationDaysFromCurrentYear + ' Tage',
                        },
                        {
                            // icon: VacationMetaIcon,
                            key: 'Urlaubstage pro Jahr',
                            value: employee.AnnualVacationDays + ' Tage',
                        },
                        {
                            key: 'Lohnart',
                            value: SalaryTypeNames.get(employee.SalaryType),
                        },
                    );
                    if (employee.CanSeeOvertime) {
                        Properties.push({
                            key: 'Überstundenkonto',
                            value: employee.SalaryType != SalaryTypes.PerHour && employee.Overtime != null ? Math.round((employee.Overtime + Number.EPSILON) * 100) / 100 + ' Stunden' : null,
                        });
                    }
                    Properties.push(
                        {
                            key: 'Arbeitstage',
                            value: workingDays,
                        },
                        {
                            key: 'Arbeitsstunden Pro Woche',
                            value: workingHoursPerWeek ? workingHoursPerWeek + ' Stunden' : null,
                        },
                    );
                }
                return {
                    Icon: employee.Deleted ? 'user-slash' : 'user',
                    Properties,
                };
            }),
        );
    }

    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> | boolean {
        return !this.Editing || !this.EmployeeForm.dirty;
    }
    get EmployeeIdForm() {
        return this.EmployeeForm.get('EmployeeId');
    }

    get FirstnameForm() {
        return this.EmployeeForm.get('Firstname');
    }

    get LastnameForm() {
        return this.EmployeeForm.get('Lastname');
    }

    get EmailForm() {
        return this.EmployeeForm.get('Email');
    }

    get PhoneNumberForm() {
        return this.EmployeeForm.get('PhoneNumber');
    }

    get MobileForm() {
        return this.EmployeeForm.get('Mobile');
    }

    get FaxNumberForm() {
        return this.EmployeeForm.get('FaxNumber');
    }

    get StreetForm() {
        return this.EmployeeForm.controls.LocationForm.get<string>('Street');
    }

    get PostalCodeForm() {
        return this.EmployeeForm.controls.LocationForm.get<string>('PostalCode');
    }

    get CityForm() {
        return this.EmployeeForm.controls.LocationForm.get<string>('City');
    }

    get CountryForm() {
        return this.EmployeeForm.controls.LocationForm.get<string>('Country');
    }

    ngOnInit() {
        this.UserFromEmployee$ = this.store.select(getUserByEmployeeId({ id: +this.route.snapshot.paramMap.get('employeeId') }));
        this.Products$ = this.store.select(getProductsWithMaxUser);
        this.LicenseList$ = this.store.select(getLicenses);
        this.DisplayUser$ = combineLatest([this.UserFromEmployee$, this.Products$]).pipe(
            map(([user, products]) => {
                if (user !== null) {
                    return Object.assign(user.Clone(), {
                        products: user && user.ProductIds && products ? products.filter((p) => user.ProductIds.find((pId) => pId === p.Id)) : [],
                    });
                } else {
                    return null;
                }
            }),
        );

        this.subscriptions.push(
            this.FolderId$.subscribe(),
            this.store.select(getJobSpecification).subscribe((js) => {
                this.JobSpecifications = js.map((j) => Object.assign(j.Clone(), { optionLabel: j.Name }));
            }),
            // wenn das status form geändert wird muss die employee entity bearbeitet und das statusfeld eingeklappt werden
            this.directChangeStatusForm.valueChanges.pipe(distinctUntilChanged()).subscribe((status) => {
                firstValueFrom(this.Employee$).then((employee) => {
                    this.ShowStatus = false;
                    this.store.dispatch(EmployeeActionTypes.ModifyEmployeeRequest({ Payload: { id: employee.Id, employeeStatus: status } }));
                });
            }),
            // wenn sich der mitarbeiter ändert muss ggf das Status form aktualisiert werden
            this.Employee$.subscribe((employee) => {
                if (employee && employee.EmployeeStatus !== this.directChangeStatusForm.value) {
                    this.directChangeStatusForm.reset(employee.EmployeeStatus, { emitEvent: false });
                }
            }),
            this.UserFromEmployee$.subscribe((user) => {
                this.User = user;
                if (user && !this.LicensesForm.value) {
                    this.LicensesForm.setValue(user.ProductIds);
                }
            }),
            this.Products$.subscribe((products) => {
                this.Products = products;
            }),
            this.LicenseList$.subscribe((licenses) => {
                this.LicenseList = licenses;
            }),
        );

        this.RolesFormated$ = combineLatest([this.store.select(getRoles), this.Employee$]).pipe(
            map(([roles, employee]) =>
                roles
                    .filter((r) => r.ProductId == null || this.User?.ProductIds.find((pId) => pId === r.ProductId))
                    .map((r) => {
                        return {
                            key: r.Name,
                            name: r.Description,
                            info: r.Permissions.map((p) => p.Description).join(',\n\n'),
                            selected: employee.UserRolesList?.includes(r.Name),
                        };
                    }),
            ),
        );

        this.RolesFormatedFromEmployee$ = combineLatest([this.RolesFormated$, this.Employee$]).pipe(map(([roles, employee]) => roles.filter((r) => employee.UserRolesList?.includes(r.key))));

        this.Users$ = combineLatest([this.store.select(getUser), this.store.select(getManagedUsers), this.Employee$, this.store.select(getEmployeesSortedByNameStartWithMe)]).pipe(
            map(([user, users, employee, employees]) => {
                const UsersIds = users.filter((u) => u.PartnerId === employee.PartnerId && employee.UserIds.includes(u.Id)).map((u) => u.Id);
                return employees
                    .filter((e) => UsersIds.includes(e.UserId))
                    .map((e) => {
                        return {
                            User: e,
                            ItsMe: e.UserId === (user ? user.Id : 0),
                        };
                    });
            }),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public PermissionToggle(role: string) {
        this.LS.isLoading = true;
        this.Employee$.pipe(first()).subscribe((e) => {
            if (e.UserRolesList.includes(role)) {
                this.store.dispatch(
                    PermissionsActionTypes.RemoveUser2Role({
                        Payload: {
                            UserId: e.UserId,
                            Role: role,
                        },
                    }),
                );
            } else {
                this.store.dispatch(
                    PermissionsActionTypes.AddUser2Role({
                        Payload: {
                            UserId: e.UserId,
                            Role: role,
                        },
                    }),
                );
            }
            merge(this.actions$.pipe(ofType(EmployeeActionTypes.UpdateOne)), this.actions$.pipe(ofType(BaseActionTypes.ErrorAction)))
                .pipe(take(1))
                .subscribe(() => (this.LS.isLoading = false));
        });
    }

    public OpenTimeTracker(employee: EmployeeEntity) {
        this.dialog.open<HourListComponent, HourListComponentDialogData>(HourListComponent, {
            ...HourListComponent.DefaultConfig,
            data: {
                EmployeeId: employee.Id,
            },
        });
    }

    public OpenEdit(employee: EmployeeEntity) {
        this.store
            .select(getJobSpecificationById({ id: employee.JobSpecificationId }))
            .pipe(take(1))
            .subscribe((js) => {
                console.log(employee, js);

                this.Editing = true;
                this.EmployeeForm.setValue({
                    Firstname: employee.Firstname,
                    Lastname: employee.Lastname,
                    JobSpecificationId: js ? Object.assign(js.Clone(), { optionLabel: js.Name }) : null,
                    Email: employee.Email,
                    PhoneNumber: employee.Phonenumber,
                    Mobile: employee.Mobile,
                    FaxNumber: employee.Faxnumber,
                    LocationForm: {
                        Street: employee.Street,
                        PostalCode: employee.PostalCode,
                        City: employee.City,
                        Country: employee.Country,
                    },
                    EmployeeId: employee.EmployeeNo,
                    RemainingVacationDaysFromPastYears: employee.RemainingVacationDaysFromPastYears,
                    RemainingVacationDaysFromCurrentYear: employee.RemainingVacationDaysFromCurrentYear,
                    MinimumVoteCount: employee.MinimumVoteCount,
                    AnnualVacationDays: employee.AnnualVacationDays,
                    Overtime: employee.Overtime,
                    WorkingDays: employee.WeeklyWorkingHours ? employee.WeeklyWorkingHours : [0, 0, 0, 0, 0, 0, 0],
                    SalaryType: employee.SalaryType,
                    EmploymentType: employee.EmploymentType,
                    EmployeeStatus: employee.EmployeeStatus,
                    Salary: employee.Salary,
                    Department: employee.Department,
                    IBAN: employee.IBAN,
                    BIC: employee.BIC,
                    CancellationPeriod: employee.CancellationPeriod,
                    DateOfEmployment: employee.DateOfEmployment,
                    Birthdate: employee.Birthdate,
                    EndOfContract: employee.EndOfContract,
                    DurationTrial: employee.DurationTrial,
                });
                if (employee.UserId && this.User) {
                    this.LicensesForm.setValue(this.User.ProductIds);
                }
            });
    }

    public CloseEdit() {
        this.Editing = false;
        this.EmployeeForm.controls.SalaryType.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }

    public Save(oldEmployee: EmployeeEntity) {
        if (this.EmployeeForm.errors || this.EmployeeForm.invalid) {
            return;
        }
        // if ((this.User && !this.compareArrays(this.User.ProductIds.sort(), this.LicensesForm.value.sort()) ||
        // !this.User && this.LicensesForm.value && this.LicensesForm.value.length)) {
        //    this.SaveLicense(this.LicensesForm.value, oldEmployee.Id);
        // }
        const address =
            '' +
            (this.StreetForm.value ? this.StreetForm.value + '+' : '') +
            (this.PostalCodeForm.value ? this.PostalCodeForm.value + '+' : '') +
            (this.CityForm.value ? this.CityForm.value + '+' : '') +
            (this.CountryForm.value ? this.CountryForm.value + '+' : '');

        this.googleGeocoderService.Geocode(address).subscribe(({ Latitude, Longitude }) => {
            this.store.dispatch(
                EmployeeActionTypes.ModifyEmployeeRequest({
                    Payload: {
                        id: oldEmployee.Id,
                        lastname: this.LastnameForm.value,
                        firstname: this.FirstnameForm.value,
                        phonenumber: this.PhoneNumberForm.value,
                        mobilenumber: this.MobileForm.value,
                        faxnumber: this.FaxNumberForm.value,
                        email: this.EmailForm.value,
                        street: this.StreetForm.value,
                        postalCode: this.PostalCodeForm.value,
                        city: this.CityForm.value,
                        country: this.CountryForm.value,
                        longitude: Longitude != null ? `${Longitude}` : null,
                        latitude: Latitude != null ? `${Latitude}` : null,
                        partnerId: oldEmployee.PartnerId,
                        userId: oldEmployee.UserId,
                        employeeNo: this.EmployeeIdForm.value,
                        annualVacationDays: this.EmployeeForm.value.AnnualVacationDays,
                        remainingVacationDaysFromCurrentYear: this.EmployeeForm.value.RemainingVacationDaysFromCurrentYear,
                        remainingVacationDaysFromPastYears: this.EmployeeForm.value.RemainingVacationDaysFromPastYears,
                        minimumVoteCount: this.EmployeeForm.value.MinimumVoteCount,
                        overtime: this.EmployeeForm.value.Overtime,
                        weeklyWorkingHours: this.EmployeeForm.value.WorkingDays,
                        salaryType: this.EmployeeForm.value.SalaryType,
                        employmentType: this.EmployeeForm.value.EmploymentType,
                        jobSpecificationId: this.EmployeeForm.value.JobSpecificationId?.Id,
                        employeeStatus: this.EmployeeForm.value.EmployeeStatus,
                        salary: this.EmployeeForm.value.Salary,
                        department: this.EmployeeForm.value.Department,
                        iBAN: this.EmployeeForm.value.IBAN,
                        bIC: this.EmployeeForm.value.BIC,
                        cancellationPeriod: this.EmployeeForm.value.CancellationPeriod,
                        dateOfEmployment: this.EmployeeForm.value.DateOfEmployment ? FrontendDate(this.EmployeeForm.value.DateOfEmployment) : null,
                        birthdate: this.EmployeeForm.value.Birthdate ? FrontendDate(this.EmployeeForm.value.Birthdate) : null,
                        endOfContract: this.EmployeeForm.value.EndOfContract ? FrontendDate(this.EmployeeForm.value.EndOfContract) : null,
                        durationTrial: this.EmployeeForm.value.DurationTrial ? FrontendDate(this.EmployeeForm.value.DurationTrial) : null,
                    },
                }),
            );
            this.CloseEdit();
        });
    }
    public CompareById = (a: SelectSearchOption<{ Id: number }>, b: SelectSearchOption<{ Id: number }>) => a.Id === b.Id;
    public SaveLicense(employeeId: number) {
        if ((this.User && !this.compareArrays(this.User.ProductIds.slice().sort(), this.LicensesForm.value.sort())) || (!this.User && this.LicensesForm.value && this.LicensesForm.value.length)) {
            const oldUserHasProducts = !!this.User?.ProductIds?.length;
            if (this.User) {
                this.store.dispatch(
                    UsersActionTypes.ModifyManageUser({
                        Payload: this.User.Clone({
                            ProductIds: this.LicensesForm.value,
                        }),
                    }),
                );
            } else {
                this.store.dispatch(
                    UsersActionTypes.AddManageUser({
                        Payload: {
                            productIds: this.LicensesForm.value,
                            employeeId,
                        },
                    }),
                );
            }
            if (!oldUserHasProducts && this.LastnameForm.value?.length) {
                this.dialog.open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, {
                    data: {
                        paragraph: 'Weisen Sie dem neuen Mitarbeiter nun seine Befugnisse unter "Rollen" zu.',
                    },
                    maxWidth: '50rem',
                });
            }
        }
    }

    DeleteEmployee(employee: EmployeeEntity): void {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `Mitarbeiter ${employee.DisplayName} wirklich löschen?`,
                styleDelete: true,
            })
            .subscribe(([result]) => result && this.delete(employee));
    }

    private delete(employee: EmployeeEntity) {
        this.store.dispatch(
            EmployeeActionTypes.DeleteEmployeeRequest({
                Payload: { EmployeeId: { id: employee.Id } },
            }),
        );
        this.routingHistoryService.NavigateBackwards();
    }

    public HasProduct(id: number) {
        return this.LicensesForm.value && this.LicensesForm.value.some((pId: number) => pId === id);
    }
    public SetLicense(license: ProductEntity, email: string, employeeId: number) {
        if (this.LicensesForm.value && this.LicensesForm.value.find((lId) => lId === license.Id)) {
            //this.Products[this.Products.indexOf(this.Products.find(p => p.Id === license.Id))].Available++;

            if (availableIsNumber(this.Products[this.Products.indexOf(this.Products.find((p) => p.Id === license.Id))].Available)) {
                const tmpNumber = this.Products[this.Products.indexOf(this.Products.find((p) => p.Id === license.Id))].Available as number;
                this.Products[this.Products.indexOf(this.Products.find((p) => p.Id === license.Id))].Available = tmpNumber + 1;
            }
            this.LicensesForm.setValue(
                this.LicensesForm.value ? (this.LicensesForm.value.find((lId) => lId === license.Id) ? this.LicensesForm.value.filter((lId) => lId !== license.Id) : [...this.LicensesForm.value, license.Id]) : [license.Id],
            );
            this.SaveLicense(employeeId);
        } else {
            const changeLicense = this.LicenseList.find((l) => l.ProductId === license.Id);
            const dialogText = 'Wenn Sie diesem Mitarbeiter eine Lizenz zuordnen, ' + 'bekommt er eine E-Mail an die hinterlegte Adresse (' + email + ') mit einem Aktivierungslink für sein c2go-Konto.';
            let newCost = null;
            let oldCost = null;
            let costPerUser = null;
            if (changeLicense) {
                oldCost = changeLicense.MonthlyCost;
                newCost = LicenseCost(changeLicense.BasePrice, changeLicense.PricePerUser, changeLicense.ActiveUser + 1, changeLicense.MinUser, changeLicense.DiscountInPercent);
                costPerUser = PricePerUser(changeLicense.BasePrice, changeLicense.PricePerUser, changeLicense.ActiveUser + 1, changeLicense.MinUser);
            }
            this.dialog
                .open(User2ProductDialogComponent, {
                    autoFocus: false,
                    width: '90%',
                    maxWidth: '400px',
                    data: {
                        name: this.User ? '' : dialogText,
                        warn: email !== this.EmailForm.value ? 'Sie haben die E-Mailaddresse geändert, diese Änderung sollte zuerst gespeichert werden!' : null,
                        newCost,
                        oldCost,
                        costPerUser,
                    } as User2ProductDialogData,
                })
                .afterClosed()
                .subscribe((answer) => answer && this.setLicense(license, employeeId));
        }
    }

    private setLicense(license: ProductEntity, employeeId: number) {
        //this.Products[this.Products.indexOf(this.Products.find(p => p.Id === license.Id))].Available--;
        if (availableIsNumber(this.Products[this.Products.indexOf(this.Products.find((p) => p.Id === license.Id))].Available)) {
            const tmpNumber = this.Products[this.Products.indexOf(this.Products.find((p) => p.Id === license.Id))].Available as number;
            this.Products[this.Products.indexOf(this.Products.find((p) => p.Id === license.Id))].Available = tmpNumber - 1;
        }
        this.LicensesForm.setValue(
            this.LicensesForm.value ? (this.LicensesForm.value.find((lId) => lId === license.Id) ? this.LicensesForm.value.filter((lId) => lId !== license.Id) : [...this.LicensesForm.value, license.Id]) : [license.Id],
        );
        this.cdr.detectChanges();
        this.SaveLicense(employeeId);
    }

    private compareArrays(array1: any[], array2: any[]) {
        if (!array1 || !array2) {
            return false;
        }

        if (array1.length !== array2.length) {
            return false;
        }

        for (let i = 0, l = array1.length; i < l; i++) {
            // Check if we have nested arrays
            if (array1[i] instanceof Array && array2[i] instanceof Array) {
                // recurse into the nested arrays
                if (!this.compareArrays(array1[i], array2[i])) {
                    return false;
                }
            } else if (array1[i] !== array2[i]) {
                // Warning - two different object instances will never be equal: {x:20} != {x:20}
                return false;
            }
        }
        return true;
    }

    public OpenUserSelect() {
        this.Employee$.pipe(take(1)).subscribe((employee) => {
            this.dialog.open<SelectUserEmployeeDialogComponent, SelectUserEmployeeDialogComponentDialogData>(SelectUserEmployeeDialogComponent, {
                ...SelectUserEmployeeDialogComponent.DefaultConfig,
                data: { employeeId: employee.Id },
            });
        });
    }
    public OpenVacationPopup(entry: { vacation: EmployeeToVacationEntity } = null, employeeId: number = null, isVacation = false) {
        this.dialog.open<NewVacationComponent, NewVacationComponentDialogData>(NewVacationComponent, {
            ...NewVacationComponent.DefaultConfig,
            data: {
                employeeToVacationId: entry?.vacation.Id || null,
                employeeId: employeeId || entry?.vacation.EmployeeId,
                vacation: isVacation,
            },
        });
    }
    ChangeUserSign(event) {
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
            combineLatest([this.store.select(getUser), this.SigneFile$.pipe(startWith(null))])
                .pipe(take(1))
                .subscribe(([user, oldSign]) => {
                    const [file] = event.target.files;
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        fetch(reader.result as string)
                            .then((res) => res.blob())
                            .then((file) => {
                                this.store.dispatch(
                                    FilesActionTypes.UploadFile({
                                        Payload: { file, hidden: true },
                                    }),
                                );
                                if (oldSign && !oldSign.DeletedAt) {
                                    this.store.dispatch(
                                        FilesActionTypes.DeleteFileRequest({
                                            Payload: { DocumentId: oldSign.Id.toString() },
                                        }),
                                    );
                                }
                                this.store
                                    .select(getFiles)
                                    .pipe(
                                        skip(oldSign && !oldSign.DeletedAt ? 2 : 1),
                                        take(1),
                                        tap((files) => {
                                            this.store.dispatch(
                                                UsersActionTypes.ModifyManageUser({
                                                    Payload: user.Clone({
                                                        DocumentId: files.map((f) => f.Id).reduce((highestId, id) => (id > highestId ? id : highestId), -1),
                                                    }),
                                                }),
                                            );
                                        }),
                                    )
                                    .subscribe();
                            });
                    };
                });
        }
    }
    public OpenUploadDialog() {
        this.dialog.open<DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData>(DaveFileUploadDialogComponent, {
            ...DaveFileUploadDialogComponent.DefaultConfig,
            data: {
                FolderId: this.UploadFolderId,
            },
        });
    }
    public SetSrc(img, src: string) {
        if (img.src !== src) {
            img.src = src;
        }
    }
    NewJobSpecificationClick() {
        OpenNewJobspecificationPopup(this.dialog, this.store).then((created) => {
            if (created) {
                firstValueFrom(this.actions$.pipe(ofType(JobSpecificationActionTypes.UpdateOne, BaseActionTypes.ErrorAction))).then((action) => {
                    if (action.type === JobSpecificationActionTypes.UpdateOne.type) {
                        // @ts-ignore
                        this.EmployeeForm.controls.JobSpecificationId.setValue(action.Payload.Id);
                    }
                });
            }
        });
    }
    openDialog(info: string) {
        this.dialog.open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, {
            data: {
                heading: "Zugeteilte Berechtigungen",
                paragraph: info,
            },
            maxWidth: '50rem',
        });
    }
    EditJobspecification = (jsEntity: JobSpecificationEntity) => OpenEditJobspecificationPopup(jsEntity, this.dialog, this.store, this.quantityTypeRes);
}
