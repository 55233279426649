import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { AfterViewInit, Component, DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { ResolveData } from '@angular/router';
import { Store } from '@ngrx/store';
import { TableVirtualScrollDataSource } from 'ng-table-virtual-scroll';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { distinctUntilChanged, first, map, shareReplay, tap } from 'rxjs/operators';
import { MaterialGroupEntity } from '../../../dave-data-module/entities/material-group.entity';
import { MaterialGroupResolver } from '../../../dave-data-module/guards/material-group.resolver';
import { MaterialResolver } from '../../../dave-data-module/guards/material.resolver';
import { QuantityTypeResolver } from '../../../dave-data-module/guards/quantity-type.resolver';
import { TokenResolver } from '../../../dave-data-module/guards/token.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { State } from '../../../dave-data-module/State';
import { MATERIAL_GROUP_KEY } from '../../../dave-data-module/State/reducers/material-group.reducers';
import { Material_KEY } from '../../../dave-data-module/State/reducers/material.reducer';
import { getQuantityTypeDictionary, getQuantityTypesFetched } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getMaterialGroupDictionary, getMaterialGroups, getMaterialGroupsFetched } from '../../../dave-data-module/State/selectors/material-group.selectors';
import { getMaterials, getMaterialsFetched } from '../../../dave-data-module/State/selectors/material.selector';
import { DaveListTableData } from '../../../dave-list/components/dave-list/dave-list.component';
import { FilterOption, FILTER_TYPE_SEARCH_MULTI_SELECT, IFilterTypeSearchMultiSelectValue } from '../../../dave-utils-module/app-filter-module/app-filter/app-filter.component';
import { MathRound, TableColumnConfig } from '../../../helper/helper';
import { MaterialPageMeta, NewMaterialPageMeta } from '../../../helper/page-metadata';
import { MatDialog } from '@angular/material/dialog';
import {
    DatanormUploadDialogComponent,
    DatanormUploadDialogComponentDialogData, DatanormUploadDialogComponentDialogReturnData,
} from '../../../datanorm-upload-dialog/datanorm-upload-dialog.component';
import { Material } from '@dave/types';

interface TableData extends DaveListTableData {
    Name: string;
    Kostenstelle: string;
    ArticleNumber: string;
    MainProductGroup: string;
    ProductGroup: string;
    routerLink: string | string[];
    id: number;
    cssClass?: string | string[] | Set<string> | { [klass: string]: any };
    clickable: boolean;
    Cost: string;
    QuantityType: string;
    Manufacturer: string;
    SellPrice: string;
    ProductGroupId: number;
    MainProductGroupId: number;
}
interface FilterValues {
    Hauptwarengruppe: IFilterTypeSearchMultiSelectValue<number>[];
    Warengruppe: IFilterTypeSearchMultiSelectValue<number>[];
}

@Component({
    selector: 'app-material-list-view',
    templateUrl: './material-list-view.component.html',
    styleUrls: ['./material-list-view.component.scss'],
})
export class MaterialListViewComponent {
    public static readonly RequiredResolvers: ResolveData = {
        token: TokenResolver,
        [Material_KEY]: MaterialResolver,
        [MATERIAL_GROUP_KEY]: MaterialGroupResolver,
    };
    public PageMetaMaterial = MaterialPageMeta;
    public NewMaterialPageMeta = NewMaterialPageMeta;

    public TableColumns: TableColumnConfig<TableData>[] = [
        { header: 'Name', name: 'Name' },
        { header: 'Kostenstelle', name: 'Kostenstelle' },
        { header: 'Artikelnummer', name: 'ArticleNumber' },
        { header: 'Verkaufseinheit', name: 'QuantityType' },
        { header: 'Hauptwarengruppe', name: 'MainProductGroup' },
        { header: 'Warengruppe', name: 'ProductGroup' },
        { header: 'Hersteller', name: 'Manufacturer' },
        { header: 'VK-Preis', name: 'SellPrice' },
    ];

    public MaterialDisplayedColumns: Array<keyof TableData> = ['ArticleNumber', 'Name', 'QuantityType', 'MainProductGroup', 'ProductGroup', 'Kostenstelle', 'SellPrice'];
    public DisplayedColumnsSmall: Array<keyof TableData> = ['ArticleNumber', 'Name'];

    public MaterialDataSource$: Observable<TableVirtualScrollDataSource<TableData>>;

    public FilterValues$: BehaviorSubject<FilterValues> = new BehaviorSubject({
        Hauptwarengruppe: [],
        Warengruppe: [],
    });
    public FilterSettings$: Observable<FilterOption[]> = this.store.select(getMaterialGroups).pipe(
        map((groups) => {
            const mainGroupFilterValues: IFilterTypeSearchMultiSelectValue<number>[] = groups
                .filter((g) => !g.ParentId)
                .map((r) => ({
                    label: r.Name,
                    id: r.Id,
                }));
            const subGroups = groups.filter((g) => !!g.ParentId);
            return { subGroups, mainGroupFilterValues };
        }),
        switchMap(({ subGroups, mainGroupFilterValues }) => {
            return this.FilterValues$.pipe(
                distinctUntilChanged((a,b) => JSON.stringify(a?.Hauptwarengruppe) === JSON.stringify(b?.Hauptwarengruppe)),
                map(filterValues => {
                    const subGroupFilterValues: IFilterTypeSearchMultiSelectValue<number>[] = subGroups.filter(g => filterValues?.Hauptwarengruppe?.length ? filterValues.Hauptwarengruppe.some((f) => f.id === g.ParentId) : true).map((r) => ({
                        label: r.Name,
                        id: r.Id,
                    }));
                    return [
                        {
                            Name: 'Hauptwarengruppe',
                            Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                            Label: 'Hauptwarengruppe',
                            Values: mainGroupFilterValues,
                        },

                        {
                            Name: 'Warengruppe',
                            Type: FILTER_TYPE_SEARCH_MULTI_SELECT,
                            Label: 'Warengruppe',
                            Values: subGroupFilterValues,
                        },
                    ];
                })
            )
        }),
    );
    public MaterialId$: Observable<number>;
    constructor(private store: Store<State>, quantityTypeResolver: QuantityTypeResolver, materialResolver: MaterialResolver, @Inject(LOCALE_ID) private local: string, @Inject(DEFAULT_CURRENCY_CODE) private defaultCurrencyCode: string, private dialog: MatDialog) {
        firstValueFrom(this.store.select(getQuantityTypesFetched)).then((fetched) => {
            if (!fetched) {
                quantityTypeResolver.resolve();
            }
        });
        firstValueFrom(this.store.select(getMaterialsFetched)).then((fetched) => {
            if (!fetched) {
                materialResolver.resolve();
            }
        });
        this.MaterialDataSource$ = combineLatest([
            this.store.select(getMaterials).pipe(map((mat) => mat.filter((m) => !m.DeletedAt))),
            // this.store.select(getMaterialDictionary),
            getFetched$(this.store, getQuantityTypesFetched, getQuantityTypeDictionary),
            getFetched$(this.store, getMaterialGroupsFetched, getMaterialGroupDictionary),
        ]).pipe(
            map(([material, qtypes, materialGroupDic]) => {
                return new TableVirtualScrollDataSource<TableData>(
                    material.map(
                        /*<TableData>*/ (mat) => {
                            const mainProductGroup: MaterialGroupEntity = materialGroupDic[materialGroupDic[mat?.ProductGroupId]?.ParentId];
                            return {
                                Name: mat.Name,
                                ArticleNumber: mat.ArticleNumber,
                                id: mat.Id,
                                MainProductGroup: mainProductGroup?.Name,
                                ProductGroup: materialGroupDic[mat?.ProductGroupId]?.Name,
                                routerLink: mat.Id + '',
                                clickable: true,
                                tooltip: null,
                                Cost: mat.Cost ? formatCurrency(MathRound(mat.Cost / 100, true), this.local, getCurrencySymbol(this.defaultCurrencyCode, 'narrow', this.local), this.defaultCurrencyCode) : '',
                                QuantityType: mat?.QuantityTypeId ? qtypes[mat.QuantityTypeId]?.Name : null,
                                ProductGroupId: mat.ProductGroupId,
                                MainProductGroupId: mainProductGroup?.Id,
                                Manufacturer: '',
                                SellPrice: mat.SellPrice ? formatCurrency(MathRound(mat.SellPrice / 100, true), this.local, getCurrencySymbol(this.defaultCurrencyCode, 'narrow', this.local), this.defaultCurrencyCode) : '',
                                Kostenstelle: mat.Kostenstelle,
                            };
                        },
                    ),
                );
            }),
            switchMap((tableData) => {
                const initialData = tableData.data.slice();
                return this.FilterValues$.pipe(
                    map((filterValues) => {
                        let filteredData = initialData.slice();
                        if (filterValues?.Hauptwarengruppe?.length) {
                            filteredData = filteredData.filter((d) => filterValues.Hauptwarengruppe.some((f) => f.id === d.MainProductGroupId));
                        }
                        if (filterValues?.Warengruppe?.length) {
                            filteredData = filteredData.filter((d) => filterValues.Warengruppe.some((f) => f.id === d.ProductGroupId));
                        }
                        tableData.data = filteredData;
                        return tableData;
                    }),
                );
            }),
        );
    }

    onDatanormClick() {
        this.dialog.open<DatanormUploadDialogComponent, DatanormUploadDialogComponentDialogData, DatanormUploadDialogComponentDialogReturnData>(DatanormUploadDialogComponent, {
            ...DatanormUploadDialogComponent.DefaultConfig,
        })
    }

    protected readonly Material = Material;
}
