import { CommonModule } from '@angular/common';
import { Component,Inject } from '@angular/core';
import { MatDialogConfig,MatDialogModule,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { combineLatest,Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountsReceivableLedgerTypeEnum } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { LedgerImportDocumentTypes } from '../../../dave-data-module/entities/ledger-import.entity';
import { MaterialEntity } from '../../../dave-data-module/entities/material.entity';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { MaterialToSupplierDataService } from '../../../dave-data-module/services/material-to-supplier-data.service';
import { State } from '../../../dave-data-module/State';
import { getMaterialById } from '../../../dave-data-module/State/selectors/material.selector';
import { getSurchargeRateByArlType,getSurchargeRateFetched } from '../../../dave-data-module/State/selectors/surcharge-rate.selector';
import { appMatDialogDefaultConfig } from '../../../helper/helper';
import { MaterialLedgerImportListCardComponent } from '../../../resource-dispo/components/material-ledger-import-list-card/material-ledger-import-list-card.component';
import {
MaterialToSupplierListCardComponent
} from '../../../resource-dispo/components/material-to-supplier-list-card/material-to-supplier-list-card.component';

export interface MaterialPriceDetailsPopupComponentDialogData {
    materialId: number;
    supplierId?: number;
    ledgerImportDocumentType: LedgerImportDocumentTypes.Offer | LedgerImportDocumentTypes.Order;
    showOtherSuppliersList: boolean;
}
export type MaterialPriceDetailsPopupComponentDialogReturnData = undefined;

@Component({
    selector: 'app-material-price-details-popup',
    standalone: true,
    imports: [CommonModule, MatDialogModule, MaterialLedgerImportListCardComponent, MaterialToSupplierListCardComponent],
    templateUrl: './material-price-details-popup.component.html',
    styleUrls: ['./material-price-details-popup.component.scss'],
})
export class MaterialPriceDetailsPopupComponent {
    public static DefaultConfig: MatDialogConfig = {
        ...appMatDialogDefaultConfig,
        minWidth: '40vw',
    };

    protected marge$: Observable<number>;
    protected margeFromEntity$: Observable<number>;
    protected defaultSurcharge$: Observable<number>;
    protected sellPrice$: Observable<number>;
    protected cost$: Observable<number>;
    protected costFromMaterial$: Observable<number>;
    protected listPrice$: Observable<number>;
    protected material$: Observable<MaterialEntity>;

    protected cardTitle: string;

    protected ledgerImportDocumentType: LedgerImportDocumentTypes;
    protected materialId: number;
    protected supplierId: number;
    protected showOtherSuppliersList: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) data: MaterialPriceDetailsPopupComponentDialogData, store: Store<State>, materialToSupplierDataService: MaterialToSupplierDataService) {
        this.ledgerImportDocumentType = data.ledgerImportDocumentType;
        this.materialId = data.materialId;
        this.supplierId = data.supplierId;
        this.showOtherSuppliersList = data.showOtherSuppliersList;

        const m2s$ = materialToSupplierDataService.getMaterialToSupplierByMaterialId$(data.materialId).pipe(map((m2s) => m2s.find((m) => m.SupplierId === data.supplierId)));
        this.material$ = store.select(getMaterialById({ id: data.materialId }));

        this.sellPrice$ =this.material$.pipe(map((material) => (material.SellPrice || 0) / 100));
        this.costFromMaterial$ = this.material$.pipe(map((material) => (material.Cost || 0) / 100));
        this.marge$ = combineLatest([m2s$, this.sellPrice$]).pipe(map(([m2s, sellPrice]) => (sellPrice - m2s.Cost / 100) / sellPrice));
        this.margeFromEntity$ = combineLatest([this.material$, this.sellPrice$]).pipe(map(([m, sellPrice]) => (sellPrice - m.Cost / 100) / sellPrice));
        this.defaultSurcharge$ = getFetched$(store, getSurchargeRateFetched, getSurchargeRateByArlType({ arlType: AccountsReceivableLedgerTypeEnum.MaterialArl })).pipe(map((s) => (s?.PercentageValue || 0) / 100));
        this.cost$ = m2s$.pipe(map((m2c) => (m2c.Cost || 0) / 100));
        this.listPrice$ = m2s$.pipe(map((m2c) => (m2c.SellPrice || 0) / 100));
        // this.title = data.supplierId ? 'Preise des Lieferanten' : 'Preise';
        this.cardTitle = [data.ledgerImportDocumentType === LedgerImportDocumentTypes.Offer ? 'Angebotshistorie' : 'Bestellhistorie', 'des', data.supplierId ? 'Lieferanten' : 'Materials'].join(' ');
    }
}
