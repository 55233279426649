import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, startWith, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { BusinessVolumeEntity } from '../../../dave-data-module/entities/business-volume.entity';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { DocumentUserEntity } from '../../../dave-data-module/entities/document-user.entity';
import { EventTypeEntity } from '../../../dave-data-module/entities/event-type.entity';
import { EventEntity } from '../../../dave-data-module/entities/event.entity';
import {
    DocumentState,
    FileEntity,
    FileEntityDocumentStateNames,
    FileEntityFromGateway,
    getGQLDocumentStateFromDocumentState,
} from '../../../dave-data-module/entities/file.entity';
import { FolderEntity } from '../../../dave-data-module/entities/folder.entity';
import { TagEntity } from '../../../dave-data-module/entities/tag.entity';
import { UserEntity } from '../../../dave-data-module/entities/user.entity';
import { DocumentDetectionDocumentTypeNames, VersionEntity } from '../../../dave-data-module/entities/version.entity';
import { CommissionResolver } from '../../../dave-data-module/guards/commission.resolver';
import { CustomerResolver } from '../../../dave-data-module/guards/customer.resolver';
import { GeneratedDocumentsResolver } from '../../../dave-data-module/guards/generatedDocuments.resolver';
import { TagResolver } from '../../../dave-data-module/guards/tag.resolver';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { HttpService } from '../../../dave-data-module/services/http.service';
import { State } from '../../../dave-data-module/State';
import { BusinessVolumeActionTypes } from '../../../dave-data-module/State/actions/business-volume.actions';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { GeneratedDocumentsActionTypes } from '../../../dave-data-module/State/actions/generatedDocuments.actions';
import { getToken } from '../../../dave-data-module/State/selectors/base.selectors';
import { getBusinessVolume, getBusinessVolumeById } from '../../../dave-data-module/State/selectors/business-volume.selector';
import { getCommissionById, getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCustomers, getCustomersFetched } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getEventDictionary, getEventFetched, getEvents } from '../../../dave-data-module/State/selectors/events.selectors';
import { getGeneratedDocuments } from '../../../dave-data-module/State/selectors/generatedDocuments.selectors';
import { getPartner } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getTags } from '../../../dave-data-module/State/selectors/tag.selectors';
import { getUserDictionary, getUsers } from '../../../dave-data-module/State/selectors/users.selectors';
import { EmailEditorComponent, EmailEditorComponentDialogConfig, EmailEditorComponentDialogData } from '../../../dave-email-module/components/email-editor/email-editor.component';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { EventListEntryComponent } from '../../../dave-event-list-entry/components/event-list-entry/event-list-entry.component';
import { SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData } from '../../../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component';
import { FullscreenDialogComponent, FullscreenDialogComponentConfig, FullscreenDialogData } from '../../../dave-fullscreen-dialog/components/fullscreen-dialog.component';
import { DaveImageSliderComponent, DaveImageSliderComponentDialogData } from '../../../dave-image-slider/dave-image-slider.component';
import {
    DaveSelectFileFromDmsComponent,
    DaveSelectFileFromDmsComponentDialogData,
    DaveSelectFileFromDmsComponentReturnData,
} from '../../../dave-select-file-from-dms/components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import {
    IDetailListTemplateData,
    IDetailListTemplateDataProperty,
} from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { FileSizePipe } from '../../../dave-utils-module/dave-shared-components-module/pipes/filesize.pipe';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { BusinessVolumeDialogComponent, BusinessVolumeDialogComponentDialogData, getDefaultValuesFromDocumentDetection } from '../../../detail-list-template-dialog/business-volume-dialog/business-volume-dialog.component';
import { DocumentDetectionResultDataModalComponent } from '../../../document-detection-result-data-modal/document-detection-result-data-modal.component';
import { isNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import {
    AllDocumentEditorPageMeta,
    CommissionMeta,
    CustomerAdministrationMeta,
    DMSPageMeta,
    DocumentEditorMeta,
    FileLinkIcon,
    GeneratedDocumentsPageMeta,
    HistoryMeta, InvoiceEditorMeta,
    ProcessMeta,
    TaskPageMeta,
    VideodokumentationPageMeta,
} from '../../../helper/page-metadata';
import { NewFileLinkComponent, NewFileLinkComponentDialogData } from '../../../new-file-link/new-file-link.component';
import { StartProcessDialogComponent, StartProcessDialogComponentDialogData } from '../../../process/components/start-process-dialog/start-process-dialog.component';
import { CustomLabelService } from '../../../services/custom-label.service';
import { LoadingService } from '../../../services/loading.service';
import { MoveFileFolderService } from '../../../services/move-file-folder.service';
import { ProcessService } from '../../../services/process.service';
import { SfdtConverterService } from '../../../services/sfdt-converter.service';
import { SortFilesDialogComponent, SortFilesDialogComponentDialogData } from '../../../sort-files-dialog/sort-files-dialog.component';
import { EditDocumentUserComponent, EditDocumentUserComponentDialogData } from '../edit-document-user/edit-document-user.component';
import {
    SelectSearchOption
} from '../../../dave-utils-module/select-search/components/select-search/select-search.component';
import {
    DocumentType as DocumentDetectionDocumentType,
} from '@dave/types/dist/proto/documentDetection/documentDetection';
import { AppGatewayService } from '../../../dave-data-module/services/app-gateway.service';
import { ToastrService } from 'ngx-toastr';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';

interface UserElement {
    user: UserEntity;
    documentUsers: DocumentUserEntity[];
    edit: boolean;
    inherited: boolean;
}

interface VersionInfo {
    version: VersionEntity;
    user: UserEntity | unknown;
}

@Component({
    selector: 'app-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit, OnDestroy {
    @Input() FileId$: BehaviorSubject<number>;
    @Input() EmailEditorDialogData: Partial<EmailEditorComponentDialogData>;
    @Input() fileExplorerContext: { directoryFileIds: number[] } | undefined;
    @Input() addBusinessVolumeDefaultValues: BusinessVolumeDialogComponentDialogData['AddBusinessVolumeDefaultValues'];
    @Output() ClosePreview = new EventEmitter<void>();
    @ViewChild('documentDetectionResultDataModalComponent') documentDetectionResultDataModalComponent: DocumentDetectionResultDataModalComponent;

    public OpenMailDialog$: Subject<void> = new Subject<void>();
    public CustomerPath = CustomerAdministrationMeta.Path;
    public CustomerIcon = CustomerAdministrationMeta.Icon;
    public DMSPath = DMSPageMeta.Path;
    public DMSIcon = DMSPageMeta.Icon;
    public ChronikPath = HistoryMeta.Path;
    public ChronikIcon = HistoryMeta.Icon;
    public CommissionPath = CommissionMeta.Path;
    public CommissionIcon = CommissionMeta.Icon;
    public VideoPath = VideodokumentationPageMeta.Path;
    public VideoIcon = VideodokumentationPageMeta.Icon;
    public HasEditPermissions$: Observable<boolean>;
    public File$: Observable<FileEntity>;
    public Folder$: Observable<FolderEntity>;
    public Commission$: Observable<CommissionEntity>;
    public Partner$ = this.store.select(getPartner);
    public Versions$: Observable<VersionInfo[]>;
    public FileDetails$: Observable<IDetailListTemplateData>;
    public FileTags$: Observable<TagEntity[]>;
    public Editing$ = new BehaviorSubject<boolean>(false);
    public Delete$ = new Subject<FileEntity>();
    public DeleteVersion$ = new Subject<VersionEntity>();
    public Upload$ = new Subject<[File[], FileEntity]>();
    public ResetForms$: Subject<void> = new Subject<void>();
    public SelectedVersion$ = new BehaviorSubject<VersionEntity>(null);
    public FileIsLoaded: boolean;
    public ThumbnailIsLoaded: boolean;
    public FileForm = new FormGroup({
        Name: new FormControl<string | null>(null),
        Customer: new FormControl<SelectSearchData | null>(null),
        Commission: new FormControl<SelectSearchData | null>(null),
        Tags: new FormControl<TagEntity[] | null>(null),
        Description: new FormControl<string | null>(null),
        Shared: new FormControl<boolean | null>(null),
        State: new FormControl<DocumentState | null>(null),
        DocumentDetectionDocumentType: new FormControl<SelectSearchOption<{key: DocumentDetectionDocumentType}> | null>(null),
    });
    public TokenValue$ = new BehaviorSubject<string>('');
    public EventData$: Observable<
        {
            event: EventEntity;
            eventType: EventTypeEntity;
        }[]
    >;
    // public TimeLineItemData$: Observable<TimelineItemData>;
    public ClickMove$: Subject<void> = new Subject<void>();
    public destroyed$: Subject<void> = new Subject<void>();
    private subscriptions: Subscription[] = [];
    public IsInDms$ = this.router.events.pipe(
        // todo add initial route
        filter((event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => {
            return event.url.includes(DMSPageMeta.Path);
        }),
    );
    public Events$: Observable<EventEntity[]>;
    private resolversForEventListEntryTriggered = false;
    protected fileUser$: Observable<UserElement[]>;
    public AssignedBvs$: Observable<BusinessVolumeEntity[]>;
    protected canForceDetectDocumentDataAs$: Observable<boolean>;
    protected disableForceDetectDocumentData = false;
    constructor(
        private appDialog: AppDialogService,
        private store: Store<State>,
        private matDialog: MatDialog,
        private fileSizePipe: FileSizePipe,
        public Api: HttpService,
        public CS: CustomerNameService,
        public PS: PermissionService,
        private ngDatePipe: DatePipe,
        private router: Router,
        private sfdtConverterService: SfdtConverterService,
        tagResolver: TagResolver,
        private customerResolver: CustomerResolver,
        commissionsResolver: CommissionResolver,
        private actions$: Actions,
        private generatedDocumentsResolver: GeneratedDocumentsResolver,
        private moveFileFolderService: MoveFileFolderService,
        private dialog: MatDialog,
        private fileDataService: FileDataService,
        private injector: Injector,
        protected cls: CustomLabelService,
        private processService: ProcessService,
        protected ls: LoadingService,
        private folderDataService: FolderDataService,
        private gatewayService: AppGatewayService,
        private toastrService: ToastrService,
    ) {
        tagResolver.resolve();
        customerResolver.resolve();
        commissionsResolver.resolve();

        this.store.dispatch(BusinessVolumeActionTypes.Load({}));
        this.AssignedBvs$ = this.store.select(getBusinessVolume).pipe(
            map((bv) =>
                bv.filter((bve) => {
                    return bve.DocumentIds.includes(this.FileId$.value);
                }),
            ),
        );
    }

    ngOnInit(): void {
        this.File$ = this.FileId$.pipe(
            filter(isNotNullOrUndefined),
            distinctUntilChanged(),
            switchMap((id) => this.fileDataService.GetFileById$(id)),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.canForceDetectDocumentDataAs$ = this.File$.pipe(
            map(f => ["image/jpeg", "image/gif", "image/png", "application/pdf"].includes(f.MIMEType) && f.GetLastVersion().DocumentDetectionType !== DocumentDetectionDocumentType.DocumentTypeInvoice),
        );
        this.HasEditPermissions$ = this.File$.pipe(map((f) => f?.CanEdit));
        this.fileUser$ = this.FileId$.pipe(
            takeUntil(this.destroyed$),
            distinctUntilChanged(),
            switchMap((id) => (id ? this.fileDataService.getUserForDocument(id) : of([]))),
            switchMap((docUsers) =>
                this.store.select(getUserDictionary).pipe(
                    map((users) => {
                        const uDu = new Map<string, DocumentUserEntity[]>();
                        docUsers.slice().forEach((du) => {
                            const k = du.UserId + '|' + (du.Inherited ? '1' : '0') + '|' + (du.Edit ? '1' : '0');
                            if (uDu.has(k)) {
                                const u = uDu.get(k);
                                u.push(du);
                                uDu.set(k, u);
                            } else {
                                uDu.set(k, [du]);
                            }
                        });
                        const out: UserElement[] = [];

                        uDu.forEach((v, k) => {
                            const parts = k.split('|');
                            let user = users[+parts[0]];
                            if (!user.Deleted) {
                                out.push({
                                    user: user,
                                    edit: parts[2] === '1',
                                    inherited: parts[1] === '1',
                                    documentUsers: v,
                                });
                            }
                        });
                        out.sort((fu1, fu2) => {
                            if (fu1.inherited === fu2.inherited) {
                                return fu1.user.Id > fu2.user.Id ? 1 : -1;
                            } else {
                                return fu1.inherited ? 1 : -1;
                            }
                        });
                        return out;
                    }),
                ),
            ),
            shareReplay(1),
        );
        this.Events$ = this.File$.pipe(
            switchMap((f) =>
                f.EventIds?.length
                    ? this.store.select(getEventFetched).pipe(
                          tap((f) => {
                              if (!this.resolversForEventListEntryTriggered) {
                                  this.resolversForEventListEntryTriggered = true;
                                  Object.values(EventListEntryComponent.RequiredResolvers).forEach((r) => this.injector.get(r).resolve());
                              }
                              // if (!f) {
                              //     this.eventResolver.resolve();
                              // }
                          }),
                          filter((f) => f),
                          switchMap(() => this.store.select(getEventDictionary)),
                          map((events) => f.EventIds.map((id) => events[id]).filter(isNotNullOrUndefined)),
                      )
                    : of([]),
            ),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
        this.Folder$ = this.File$.pipe(switchMap((file) => (file.FolderId ? this.folderDataService.getFolderById(file.FolderId) : of(null))));
        this.Commission$ = this.File$.pipe(switchMap((file) => (file.ContractId ? this.store.select(getCommissionById({ id: file.ContractId })) : of(null))));

        this.ClickMove$.pipe(withLatestFrom(this.File$)).subscribe(([, file]) => {
            this.moveFileFolderService.MoveFileFolder([file.Id], []);
        });
        this.Versions$ = this.File$.pipe(
            map((f) => (f?.Versions ? f.Versions.slice().sort((a, b) => b.Number - a.Number) : [])),
            withLatestFrom(this.store.select(getUsers).pipe(filter(isNotNullOrUndefined))),
            map(([versions, users]) =>
                versions.map((v) => {
                    return {
                        version: v,
                        user: users?.find((u) => u.Id === v.UserId),
                    } as VersionInfo;
                }),
            ),
        );
        this.EventData$ = combineLatest([this.store.select(getEvents), this.store.select(getEventTypes), this.File$]).pipe(
            map(([events, eventTypes, file]) => {
                var result = [];
                const filteredEvents = events?.filter((e) => isNotNullOrUndefined(file?.EventIds?.find((i) => i === e.Id)));

                if (filteredEvents?.length > 0) {
                    for (var event of filteredEvents) {
                        const eventType = eventTypes?.find((e) => e.Id === event?.EventTypeId);
                        result.push({ event, eventType });
                    }
                }
                return result;
            }),
        );
        this.subscriptions.push(
            this.File$.pipe(
                filter(isNotNullOrUndefined),
                tap((f) => this.SelectVersion(f?.GetLastVersion())),
            ).subscribe(),
            this.store
                .select(getToken)
                .pipe(tap((t) => this.TokenValue$.next(t)))
                .subscribe(),
            this.Editing$.pipe(
                tap((value) => {
                    if (value) {
                        this.ResetForms$.next();
                    }
                }),
            ).subscribe(),
            this.Delete$.pipe(
                tap((file) => {
                    this.appDialog
                        .OpenConfirmationDialog({
                            paragraph: `Die Datei ${file.Name} wirklich löschen?`,
                            styleDelete: true,
                        })
                        .subscribe(([result]) => {
                            if (result) {
                                this.store.dispatch(
                                    FilesActionTypes.DeleteFileRequest({
                                        Payload: { DocumentId: file.Id.toString() },
                                    }),
                                );
                                this.ClosePreview.emit();
                            }
                        });
                }),
            ).subscribe(),
            this.DeleteVersion$.pipe(
                tap((version) => {
                    this.appDialog
                        .OpenConfirmationDialog({
                            paragraph: `Die Version ${version.Number} vom ${this.ngDatePipe.transform(version.CreatedAt)} wirklich löschen?`,
                        })
                        .subscribe(([result]) => {
                            if (result) {
                                this.store.dispatch(
                                    FilesActionTypes.DeleteVersion({
                                        Payload: version.Id,
                                    }),
                                );
                                this.ClosePreview.emit();
                            }
                        });
                }),
            ).subscribe(),
            this.Upload$.pipe(
                tap(([fileList, file]) => {
                    if (!(fileList && fileList[0]) || fileList[1]) {
                        return;
                    }
                    this.store.dispatch(
                        // FilesActionTypes.UploadFile({
                        //     Payload: {
                        //         file: fileList[0],
                        //         documentId: file.Id,
                        //     },
                        // }),
                        FilesActionTypes.UploadFileBackendRequest({
                            Payload: {
                                file: fileList[0],
                                params: new Map([['document_id', file.Id.toString()]]),
                            },
                        }),
                    );
                }),
            ).subscribe(),
            this.ResetForms$.pipe(
                withLatestFrom(this.File$),
                withLatestFrom(this.store.select(getTags)),
                tap(([[, file], tags]) => {
                    const customer = file.CustomerId ? {
                        Id: file.CustomerId,
                    } as SelectSearchData : null;
                    const commission = file.ContractId ? {
                        Id: file.ContractId,
                    } as SelectSearchData : null;
                    this.FileForm.reset({
                        Name: file.Name,
                        Customer: customer,
                        Commission: commission,
                        Tags: tags.filter((t) => file.TagIds.includes(t.Id)),
                        // ToDo: contract mit absicht nicht gesetzt ?
                        Description: file.Description,
                        Shared: file.Shared,
                        State: file.State,
                        DocumentDetectionDocumentType: file.GetLastVersion().DocumentDetectionType ? {key: file.GetLastVersion().DocumentDetectionType} : null
                    });
                }),
            ).subscribe(),
            this.OpenMailDialog$.pipe(
                withLatestFrom(this.File$),
                tap(([, file]) => {
                    this.matDialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                        ...EmailEditorComponentDialogConfig,
                        data: {
                            ...(this.EmailEditorDialogData || {}),
                            FileAttachmntFileIds: [...(this.EmailEditorDialogData?.FileAttachmntFileIds || []), file.Id],
                        },
                    });
                }),
            ).subscribe(),
        );
        this.FileTags$ = combineLatest([this.File$, this.store.select(getTags)]).pipe(map(([file, tags]) => tags?.filter((t) => file?.TagIds.includes(t.Id))));
        this.FileDetails$ = combineLatest([
            this.File$,
            this.store.select(getCustomers),
            this.store.select(getCommissions),
            combineLatest([this.CS.GetSingle$(), this.FileForm.controls.Customer.valueChanges.pipe(startWith(this.FileForm.controls.Customer.value), distinctUntilChanged())]),
            this.cls.getSingle$('Commission'),
        ]).pipe(
            map(([file, customers, commissions, [customerNameSingle], commissionLabel]) => {
                const commission = file?.ContractId && commissions?.find((c) => c.Id === file.ContractId);
                if (!isNotNullOrUndefined(this.FileForm.controls.Customer.value?.Id)) {
                    this.FileForm.get('Commission').disable();
                } else {
                    this.FileForm.get('Commission').enable();
                }
                let coms = commissions
                    ?.filter((c) => isNotNullOrUndefined(this.FileForm.controls.Customer.value?.Id) && this.FileForm.controls.Customer.value?.Id == c.CustomerId)
                    .map((c) => ({
                        optionValue: c.Id,
                        optionLabel: c.GetDisplayName(),
                    }));
                coms = isNotNullOrUndefined(coms)
                    ? coms
                    : [
                          {
                              optionValue: null,
                              optionLabel: '--',
                          },
                      ];
                let path = '';
                let folderId = file.FolderId;
                if (isNotNullOrUndefined(folderId)) {
                    let folderData = this.folderDataService.getFolderAndParentsById(file.FolderId, true);
                    folderData.subscribe((folders) => {
                        folders.sort((a, b) => b.ParentId - a.ParentId);
                        let folderName = folders.map(({ Name }) => Name);
                        for(let i=0; i < folders.length; i++) {
                            path = folderName[i] + '/' + path;
                        }
                    })
                }
                path = 'Hauptordner/' + path;
                const detectionDocTypeOptions: SelectSearchOption<{key: DocumentDetectionDocumentType}>[] = [];
                DocumentDetectionDocumentTypeNames.forEach((optionLabel, key) => detectionDocTypeOptions.push({
                    key,
                    optionLabel,
                }))
                const prop: IDetailListTemplateDataProperty[] = [
                    {
                        key: 'Name',
                        formControl: this.FileForm.controls.Name,
                        options: {
                            specialInput: {
                                fileName: true,
                            },
                        },
                    },
                    {
                        key: 'Beschreibung',
                        formControl: this.FileForm.controls.Description,
                        value: file?.Description ? file?.Description : null,
                    },
                    {
                        key: 'Dokumentenart',
                        formControl: this.FileForm.controls.DocumentDetectionDocumentType,
                        value: file?.GetLastVersion().DocumentDetectionDocumentTypeName || null,
                        options: {
                            specialInput: {
                                singleSelectSearch: {
                                    options: detectionDocTypeOptions,
                                    compareOptions: (a, b) => a.key === b.key,
                                }
                            }
                        }
                    },
                    {
                        key: 'Status',
                        formControl: this.FileForm.controls.State,
                        value: file?.State ? FileEntityDocumentStateNames.get(file.State) : null,
                        options: {
                            specialInput: {
                                select: Object.values(DocumentState).map((state) => ({ optionValue: state, optionLabel: FileEntityDocumentStateNames.get(state) })),
                            },
                        },
                    },
                    {
                        key: customerNameSingle,
                        value: file?.CustomerId ? customers?.find((c) => c.Id === file.CustomerId)?.DisplayInformation : null,
                        formControl: this.FileForm.controls.Customer,
                        options: {
                            specialInput: {
                                selectSearch: customers?.map((c) => ({
                                    optionValue: c.Id,
                                    optionLabel: c.DisplayInformation,
                                })),
                            },
                        },
                    },
                    {
                        key: commissionLabel,
                        value: commission?.GetDisplayName(),
                        formControl: this.FileForm.get('Commission'),
                        options: {
                            specialInput: {
                                selectSearch: coms,
                            },
                        },
                    },
                    {
                        // Placeholder
                        key: '',
                        value: '',
                    },
                    {
                        key: '',
                        value: '',
                    },
                    {
                        key: '',
                        value: '',
                    },
                    {
                        key: 'Größe',
                        value: `${file ? this.fileSizePipe.transform(file?.GetLastVersion()?.Size) : ''}`,
                    },
                    {
                        key: 'Hochgeladen am',
                        value: file?.CreatedAt,
                        options: {
                            type: CustomPropertyType.Datum,
                        },
                    },
                    {
                        key: 'Aktualisiert am',
                        value: file?.UpdatedAt,
                        options: {
                            type: CustomPropertyType.Datum,
                        },
                    },
                    {
                        key: 'Versionen',
                        value: `${file ? file.Versions?.length : ''}`,
                    },
                    {
                        key: 'Geteilt',
                        value: file?.Shared,
                        formControl: this.FileForm.controls.Shared,
                        options: {
                            type: CustomPropertyType.Boolean,
                            specialInput: {
                                boolean: true,
                            },
                        },
                    },
                    {
                        key: 'Pfad',
                        value: path,
                        options: {
                            type: CustomPropertyType.RouterLink,
                            routerLinkPayload: {
                                routerLink: ['/' + DMSPageMeta.Path],
                                queryParams: {folderId},
                                queryParamsHandling: 'merge',
                            }
                        },
                    },
                ];
                if (file.MetaData?.document_type) {
                    prop.push({
                        formControl: undefined,
                        key: 'Dokumentenart',
                        value: file?.GetDocumentType(),
                    });
                }

                return {
                    Headline: file?.Name,
                    Properties: prop,
                };
            }),
        );
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    public SelectVersion(version: VersionEntity) {
        if (this.SelectedVersion$.value?.Id === version.Id) {
            return;
        }
        this.FileIsLoaded = false;
        this.ThumbnailIsLoaded = false;
        this.SelectedVersion$.next(version);
    }

    public DeleteFile(file: FileEntity) {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `Die Datei ${file.Name} wirklich löschen?`,
            })
            .subscribe(([result]) => result && this.store.dispatch(FilesActionTypes.DeleteFileRequest({ Payload: { DocumentId: file.Id.toString() } })));
    }

    public OpenFullScreen(file: FileEntity) {
        const fileIds$ = this.fileExplorerContext
            ? of(this.fileExplorerContext.directoryFileIds)
            : this.fileDataService.GetFilesStateFromFolder$(file.FolderId || 0).pipe(
                  take(1),
                  map((files) => files.filter((f) => f.FolderId === file.FolderId && f.MIMEType.indexOf('image/') > -1).map((f) => f.Id)),
              );

        firstValueFrom(fileIds$).then((fileIds) => {
            this.matDialog.open<DaveImageSliderComponent, DaveImageSliderComponentDialogData>(DaveImageSliderComponent, {
                data: { fileIds, fileId: file.Id },
                height: '100%',
                width: '100%',
                maxWidth: '100%',
                panelClass: 'custom-dialog-class-without-padding',
                autoFocus: false,
            });
        });
    }

    public OpenInEditor(file: FileEntity) {
        this.matDialog
            .open<FullscreenDialogComponent, FullscreenDialogData>(FullscreenDialogComponent, {
                ...FullscreenDialogComponentConfig,
                data: { file, version: this.SelectedVersion$.value },
                id: 'fullScreenDialogId',
            })
            .backdropClick()
            .subscribe(async () => {
                // dies ist nötig da zuerst der pdf viewer aufgeräumt werden muss, die funktionirt onDestroy nicht mehr

                await this.matDialog.getDialogById('fullScreenDialogId').componentInstance.Close();
                this.matDialog.getDialogById('fullScreenDialogId').close();
            });
    }

    public OpenReport(file: FileEntity) {
        this.generatedDocumentsResolver.resolve();
        this.store
            .select(getGeneratedDocuments)
            .pipe(filter(isNotNullOrUndefined), take(1), withLatestFrom(this.store.select(getToken)))
            .subscribe(([documents, token]) => {
                const doc = documents.find((d) => d.AdditionalData?.FileId === file.Id);
                if (doc) {
                    this.navigateToGenDoc(doc.Id);
                } else {
                    this.Api.download(file.GetLastVersion(), { token })
                        .pipe(take(1))
                        .subscribe((v) => {
                            this.sfdtConverterService.ConvertToSfdt(v, (req) => {
                                this.store.dispatch(
                                    GeneratedDocumentsActionTypes.AddGeneratedDocuments({
                                        Payload: {
                                            state: getGQLDocumentStateFromDocumentState(DocumentState.DocumentStateNew),
                                            name: file.Name,
                                            generatedFile: req.response,
                                            additionalData: JSON.stringify({ FileId: file.Id }),
                                            customerId: file.CustomerId,
                                            commissionId: file.ContractId,
                                        },
                                    }),
                                );
                                this.actions$.pipe(ofType(GeneratedDocumentsActionTypes.UpdateOne), take(1)).subscribe(({ Payload }) => this.navigateToGenDoc(Payload.Id));
                            });
                        });
                }
            });
    }

    NewDocumentUser() {
        this.dialog.open<EditDocumentUserComponent, EditDocumentUserComponentDialogData>(EditDocumentUserComponent, {
            data: {
                DocumentId: this.FileId$.getValue(),
            } as EditDocumentUserComponentDialogData,
            minWidth: '25rem',
            width: '30vw',
            maxWidth: '90vw',
        });
    }

    ViewDocumentUser(du: DocumentUserEntity[]) {
        if (du.length === 1 && !du[0].Inherited) {
            this.dialog.open<EditDocumentUserComponent, EditDocumentUserComponentDialogData>(EditDocumentUserComponent, {
                data: {
                    DocumentUser: du[0],
                    DocumentId: this.FileId$.getValue(),
                } as EditDocumentUserComponentDialogData,
                minWidth: '25rem',
                width: '30vw',
                maxWidth: '90vw',
            });
        } else {
            this.dialog.open<EditDocumentUserComponent, EditDocumentUserComponentDialogData>(EditDocumentUserComponent, {
                data: {
                    DocumentUsers: du,
                    DocumentId: this.FileId$.getValue(),
                } as EditDocumentUserComponentDialogData,
                minWidth: '25rem',
                width: '30vw',
                maxWidth: '90vw',
            });
        }
    }

    private navigateToGenDoc(docId: number) {
        this.router.navigate(['/', GeneratedDocumentsPageMeta.Path, AllDocumentEditorPageMeta.Path, DocumentEditorMeta.Path, docId]);
    }
    protected onAddToProcessClick() {
        this.processService.openAddFileToProcessDialog([this.FileId$.getValue()]);
    }
    protected onStartProcessClick() {
        this.dialog.open<StartProcessDialogComponent, StartProcessDialogComponentDialogData>(StartProcessDialogComponent, {
            ...StartProcessDialogComponent.DefaultConfig,
            data: {
                fileId: this.FileId$.getValue(),
            },
        });
    }

    protected readonly ProcessMeta = ProcessMeta;
    protected readonly FileLinkIcon = FileLinkIcon;

    createLink(file: FileEntity) {
        this.dialog.open<NewFileLinkComponent, NewFileLinkComponentDialogData>(NewFileLinkComponent, {
            ...NewFileLinkComponent.DefaultConfig,
            data: {
                defaultName: file.Name,
                folderId: file.FolderId,
                fileId: file.Id,
            },
        });
    }

    private openBvDialog(data: BusinessVolumeDialogComponentDialogData) {
        return this.matDialog.open<BusinessVolumeDialogComponent, BusinessVolumeDialogComponentDialogData>(BusinessVolumeDialogComponent, {
            ...BusinessVolumeDialogComponent.DefaultConfig,
            data,
        });
    }
    openEditBvDialog(id?: number) {
        firstValueFrom(this.store.select(getBusinessVolumeById({ id: id }))).then((bv) => {
            const dialogRef = this.openBvDialog({
                BvId: bv.Id,
                ShowCommissionForm: true,
                ShowCustomerForm: true,
                // AddBusinessVolumeDefaultValues: { documentIds: documentId ? [documentId] : undefined },
                // disableUploadFiles: true,
                // disableFileClick: true
            });
        });
    }
    openCreateBvDialog(documentId?: number) {
        firstValueFrom(this.fileDataService.GetFileById$(documentId, false, { withDocumentDetectionResult: true })).then((document) => {
            const data: BusinessVolumeDialogComponentDialogData = {
                BvId: null,
                ShowCommissionForm: true,
                ShowCustomerForm: true,
                AddBusinessVolumeDefaultValues: { ...this.addBusinessVolumeDefaultValues, documentIds: uniqArray([...(this.addBusinessVolumeDefaultValues?.documentIds || []), documentId]) },
            };
            if (document.GetLastVersion().DocumentDetectionData?.Invoice) {
                firstValueFrom(getFetched$(this.store, getCustomersFetched, getCustomers, this.customerResolver)).then((customers) => {
                    data.AddBusinessVolumeDefaultValues = { ...getDefaultValuesFromDocumentDetection(document.GetLastVersion().DocumentDetectionData.Invoice, customers), ...data.AddBusinessVolumeDefaultValues };
                    this.openBvDialog(data);
                });
            } else {
                this.openBvDialog(data);
            }
        });
    }

    addPdfs(documentIds: number[]) {
        if (!documentIds.length) {
            return;
        }
        const dialogRef = this.dialog.open<SortFilesDialogComponent, SortFilesDialogComponentDialogData>(SortFilesDialogComponent, {
            ...SortFilesDialogComponent.DefaultConfig,
            data: {
                headline: 'PDFs zusammenführen',
                documentIds: [this.FileId$.value, ...documentIds],
                showFileNameForm: false,
            },
        });
        dialogRef.componentInstance.submitClick.pipe(takeUntil(dialogRef.beforeClosed())).subscribe(() => {
            this.ls.startLoading('file-ppreview--merge-pdf');
            firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.MergePdfVersionsFailure, FilesActionTypes.MergePdfVersionsSuccess))).then((action) => {
                this.ls.endLoading('file-ppreview--merge-pdf');
                if (action.type === FilesActionTypes.MergePdfVersionsSuccess.type) {
                    dialogRef.close();
                }
            });
            this.store.dispatch(
                FilesActionTypes.MergePdfVersionsRequest({
                    Payload: {
                        // FolderId: this.SelectedFolderId$.value ? this.SelectedFolderId$.value.toString() : null,
                        VersionIds: dialogRef.componentInstance.documentsSorted.map((d) => d.GetLastVersion().Id.toString()),
                        DocumentId: this.FileId$.value.toString(),
                    },
                }),
            );
        });
    }
    openFileSelectDialog() {
        firstValueFrom(this.File$).then((file) => {
            this.dialog
                .open<DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData, DaveSelectFileFromDmsComponentReturnData>(DaveSelectFileFromDmsComponent, {
                    ...DaveSelectFileFromDmsComponent.DefaultConfig,
                    data: {
                        moveWarning: '',
                        preSelectedFiles: [],
                        folderId: file.FolderId,
                        acceptedMimeTypes: 'application/pdf',
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result?.documents?.length) {
                        this.addPdfs(result.documents.map((f) => f));
                    }
                });
        });
    }
    onUploadClick() {
        // if ((event.target as HTMLInputElement)?.files?.length === 1) {
        const dialogRef = this.dialog.open<SelectFolderDialogComponent, SelectFolderDialogComponentDialogData, SelectFolderDialogComponentDialogReturnData>(SelectFolderDialogComponent, {
            ...SelectFolderDialogComponent.DefaultConfig,
            data: {
                ButtonText: 'HOCHLADEN',
                matTreeNodePaddingIndent: 10,
                uploadMode: true,
                synchronUpload: true,
                maxFilesUpload: 1,
                acceptedFiles: 'application/pdf',
            },
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res?.synchronUploadedDocuments?.length) {
                this.addPdfs(res.synchronUploadedDocuments.map((f) => f.Id));
            }
        });
    }
    protected readonly TaskPageMeta = TaskPageMeta;
    createTaskDialog(document: FileEntity) {
        this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
            ...DetailTasksComponent.DefaultConfig,
            data: {
                InitialValues: {
                    DocumentIds: [document.Id],
                    CommissionId: document.ContractId,
                    CustomerId: document.CustomerId,
                },
            },
        });
    }
    async Submit() {
        const file = await firstValueFrom(this.File$);

        if (this.FileForm.status !== 'VALID') {
            console.warn('Fehlerhafte Eingabe');
            console.log(this.FileForm);
            console.log(this.FileForm.value);
        } else {
            const { State, Name, Customer, Commission, Tags, Description, Shared, DocumentDetectionDocumentType } = this.FileForm.value; // ToDO "Commission" gelöscht, weil Fehler
            this.documentDetectionResultDataModalComponent.freeze();
            this.store.dispatch(
                FilesActionTypes.ModifyFileRequest({
                    Payload: {
                        DocumentId: file.Id.toString(),
                        Description: Description,
                        CustomerIds: Customer?.Id ? [Customer.Id.toString()] : null,
                        SetCustomerIdsNull: !Customer?.Id,
                        EventIds: file.EventIds?.map((i) => i.toString()),
                        SetEventIdsNull: !file.EventIds || file.EventIds.length === 0,
                        CommissionIds: Commission?.Id ? [Commission.Id.toString()] : null,
                        SetCommissionIdsNull: !Commission?.Id,
                        TransmissionIds: file.VideoId ? [file.VideoId.toString()] : null,
                        SetTransmissionIdsNull: !file.VideoId,
                        Name: Name,
                        TagIds: Tags?.map((t) => t.Id.toString()),
                        SetTagIdsNull: !Tags || Tags.length === 0,
                        Shared: Shared,
                        State: State,
                        // ResourceIds: [],
                    },
                }),
            );
            const result = await firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.ModifyFileSuccess, FilesActionTypes.ModifyFileFailure)));
            if (this.documentDetectionResultDataModalComponent) {
                this.documentDetectionResultDataModalComponent.Submit();
                this.documentDetectionResultDataModalComponent.unFreeze();
            } else {
                console.error('documentDetectionResultDataModalComponent not found');
            }

            if (this.FileForm.controls.DocumentDetectionDocumentType.dirty) {
                this.store.dispatch(FilesActionTypes.ModifyVersion({Payload: {Id: file.GetLastVersion().Id+'', DocumentDetectionType: DocumentDetectionDocumentType?.key}, parentDocumentId: file.Id}));
            }
            if (result.type === FilesActionTypes.ModifyFileSuccess.type) {
                this.Editing$.next(false);
            }
        }
    }

    protected readonly InvoiceEditorMeta = InvoiceEditorMeta;
    protected readonly DocumentDetectionDocumentType = DocumentDetectionDocumentType;

    onForceDetectDocumentDataAs(type: DocumentDetectionDocumentType) {
        this.disableForceDetectDocumentData = true;
        firstValueFrom(this.File$).then(file => {
            this.gatewayService.Request({FileFileForceDetectDocumentDataAs: {
                    VersionId: file.GetLastVersion().Id+'',
                    Type: type,
                }})
                .then((res) => {
                    if (Object.keys(res?.Errors || {}).length === 0) {
                        this.toastrService.info('Die Dauer beträgt bis zu 5 Minuten.', 'Das Dokument wird ausgelesen.');
                    } else {
                        throw res?.Errors;
                    }
                })
                .catch((err) => this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: {Err: err, ToasterMessage: 'Dokument auslesen fehlgeschlagen'}})))
        })
    }
}
