<form [formGroup]="CustomerForm" (ngSubmit)="Submit()">
    <app-select-search-legacy
        *ngIf="(PartnerOffices$ | async)?.length > 1"
        class="hover-form"
        [Values]="PartnerOffices$ | async"
        [Control]="CustomerForm.controls.PartnerOfficeId"
        Placeholder="Unternehmen"
        Required
    >
    </app-select-search-legacy>
    <app-textfield class="hover-form" [Key]="(CustomerForm.value.IsSupplier ? 'Lieferanten' : (cls.getMultiple$('Customer') | async)) + 'nummer'" [Value]="CustomerIdForm" [Hint]="(OtherCustomerWithSameNumber$ | async)?.length ? 'Diese ' + (CustomerForm.value.IsSupplier ? 'Lieferanten' : (cls.getMultiple$('Customer') | async)) + 'nummer wurde schon vergeben' : null"></app-textfield>
    <div>
        <mat-slide-toggle
            class="hover-form"
            (toggleChange)="this.CustomerForm.get('AutomaticCustomerId').setValue(!this.CustomerForm.get('AutomaticCustomerId').value)"
            [checked]="this.CustomerForm.get('AutomaticCustomerId').value"
        >
            {{ (CustomerForm.value.IsSupplier ? 'Lieferanten' : (cls.getMultiple$('Customer') | async)) + 'nummer' }} automatisch vergeben
        </mat-slide-toggle>
        <app-hint-icon [hint]="'Erzeugt eine ' + (CustomerForm.value.IsSupplier ? 'Lieferanten' : (cls.getMultiple$('Customer') | async)) + 'nummer aus Jahr, Monat und einer fortlaufenden Nummer nach dem Muster K202001-1.'"  style="margin: 1rem"></app-hint-icon>
    </div>
    <!-- D277-4107 - Toggle Lieferant Doppel vorhanden
    <mat-slide-toggle
        class="hover-form"
        (toggleChange)="this.CustomerForm.controls.IsSupplier.setValue(!this.CustomerForm.controls.IsSupplier.value)"
        [checked]="this.CustomerForm.controls.IsSupplier.value"
    >
        Lieferant
    </mat-slide-toggle>
    -->
    <mat-radio-group [formControl]="CustomerForm.controls.IsSupplier" class="radio-group">
        <mat-radio-button [value]="false" color="primary">
            Kunde
        </mat-radio-button>
        <mat-radio-button [value]="true" color="primary">
            Lieferant
        </mat-radio-button>
    </mat-radio-group>
    <app-select-search-legacy
        *ngIf="CustomerSpecificationtypes$ | async as values"
        class="hover-form"
        [Values]="values"
        [Control]="CustomerForm.controls.CustomerSpecificationType"
        [Placeholder]="(CustomerForm.value.IsSupplier ? 'Lieferanten' : (cls.getMultiple$('Customer') | async)) + 'art'"
        Required
    >
    </app-select-search-legacy>
    <app-customer-type-chip-autocomplete class="hover-form" [FormControl]="CustomerTypesForm"></app-customer-type-chip-autocomplete>



    <!--<app-textfield class="hover-form" Key="Gerichtsort" [Value]="CustomerForm.get('Gerichtsort')"> </app-textfield>-->

    <app-textfield *ngIf="!isPrivatePerson || isSupplier" class="hover-form" Key="Firma" [Value]="NameForm" [Required]="true"></app-textfield>
    <app-textfield *ngIf="isPrivatePerson && !isSupplier" class="hover-form" Key="Name 1" [Value]="FirstnameForm" ></app-textfield>
    <app-textfield *ngIf="isPrivatePerson && !isSupplier" class="hover-form" Key="Name 2" [Value]="LastNameForm" ></app-textfield>

    <app-textfield class="hover-form" Key="Firma Zusatz" [Value]="DescriptionForm"></app-textfield>

    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Adressinformationen
                <fa-icon *ngIf="StreetForm.invalid || PostalCodeForm.invalid || CityForm.invalid || CountryForm.invalid" icon="exclamation-triangle" class="gold-gelb">
                </fa-icon>
            </mat-panel-title>
            <mat-panel-description> Straße, PLZ ... </mat-panel-description>
        </mat-expansion-panel-header>
        <app-textfield class="hover-form" Key="Straße" [Value]="StreetForm"></app-textfield>
        <app-textfield class="hover-form" Key="PLZ" [Value]="PostalCodeForm"></app-textfield>
        <app-textfield class="hover-form" Key="Stadt" [Value]="CityForm"></app-textfield>
        <app-textfield class="hover-form" Key="Land" [Value]="CountryForm"></app-textfield>
    </mat-expansion-panel>


    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Kontaktinformationen
                <fa-icon
                    *ngIf="EmailForm.invalid || PhoneNumberForm.invalid || MobileNumberForm.invalid || FaxNumberForm.invalid"
                    icon="exclamation-triangle"
                    class="gold-gelb"
                >
                </fa-icon>
            </mat-panel-title>
            <mat-panel-description> E-Mail, Telefon ... </mat-panel-description>
        </mat-expansion-panel-header>
        <app-textfield class="hover-form" Key="Telefonnummer" [Value]="PhoneNumberForm"></app-textfield>
        <app-textfield class="hover-form" Key="Faxnummer" [Value]="FaxNumberForm"></app-textfield>
        <app-textfield class="hover-form" Key="E-Mail" [Value]="EmailForm"></app-textfield>
        <app-textfield class="hover-form" Key="Rechnungs E-Mail" [Value]="InvoiceEmailForm"></app-textfield>
        <app-textfield class="hover-form" Key="Homepage" [Value]="HomepageForm"></app-textfield>
        <app-textfield class="hover-form" Key="Mobilnummer" [Value]="MobileNumberForm"></app-textfield>
    </mat-expansion-panel>
    <mat-form-field class="hover-form width100">
        <mat-label>Steuerart</mat-label>
        <mat-select [formControl]="CustomerForm.controls.TaxType">
            <mat-option *ngFor="let taxType of TaxTypes" [value]="taxType.value">{{ taxType.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="hover-form" *ngIf="Data$ | async as Data">
        <mat-label>Mandantentyp</mat-label>

        <mat-select [formControl]="MandateTypeIdForm">
            <mat-option [value]="null">--</mat-option>

            <mat-option [value]="mandateType.Id" *ngFor="let mandateType of Data.MandateTypes">
                {{ mandateType.Name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <app-textfield
        class="hover-form"
        [Key]="MandateTypeIdForm.value === 2 ? 'Kreditorennummer' : 'Debitorennummer'"
        [Value]="CustomerForm.controls.DebitorNo">
    </app-textfield>
    <app-textfield class="hover-form" Key="Umsatzsteueridentifikationsnummer" [Value]="UstIdForm"></app-textfield>
    <app-textfield class="hover-form" Key="Steuernummer" [Value]="SteuernummerForm"></app-textfield>
    <app-numberfield class="hover-form" Key="Umsatz" [FormControl]="CustomerForm.controls.Sales"></app-numberfield>

<!--    <app-numberfield class="hover-form" Key="Sachkonto" [FormControl]="CustomerForm.controls.Sachkonto"></app-numberfield>-->
    <div class="selectChartOfAccounts">
        <mat-form-field>
            <app-select-search
                [placeholder]="'Sachkonto'"
                [formControl]="CustomerForm.controls.Sachkonto"
                [Options]="SachkontochartOfAccounts"
                [OptionTemplate]="sachkontoOptionTemplate"
                [CompareOptions]="CompareSelectSearchChartOfAccountsOptions"
                [SearchFunction]="SelectSearchChartOfAccountsOptionsFunction"
            >
            </app-select-search>
        </mat-form-field>
        <button app-round-button matTooltip="neues Konto anlegen" type="button" (click)="newChartOfAccountPopUp( null, AccountTypeEnum.Contra)"><fa-icon icon="plus"></fa-icon></button>
    </div>

<!--    <app-numberfield class="hover-form" Key="Konto" [FormControl]="CustomerForm.controls.Konto"></app-numberfield>-->
    <div class="selectChartOfAccounts">
        <mat-form-field>
            <app-select-search
                [placeholder]="'Konto'"
                [formControl]="CustomerForm.controls.Konto"
                [Options]="KontochartOfAccounts"
                [OptionTemplate]="sachkontoOptionTemplate"
                [CompareOptions]="CompareSelectSearchChartOfAccountsOptions"
                [SearchFunction]="SelectSearchChartOfAccountsOptionsFunction"
            >
            </app-select-search>
        </mat-form-field>
        <button app-round-button matTooltip="neues Konto anlegen" type="button" (click)="newChartOfAccountPopUp(null, AccountTypeEnum.Normal)"><fa-icon icon="plus"></fa-icon></button>
    </div>
    <app-numberfield class="hover-form" Key="Zahlungsziel" [FormControl]="CustomerForm.controls.PaymentTargetInDays"><span textfieldSuffix>Tage</span></app-numberfield>
    <app-numberfield class="hover-form" Key="Skonto" [FormControl]="CustomerForm.controls.PaymentDiscount"><span textfieldSuffix>%</span></app-numberfield>
    <app-numberfield class="hover-form" Key="Anzahl Mitarbeiter" [FormControl]="CustomerForm.controls.EmployeeNumber"></app-numberfield>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Bankdaten
                <fa-icon
                    *ngIf="
                                    IBANForm.invalid ||
                                    SwiftForm.invalid ||
                                    BankNameForm.invalid ||
                                    BankLocationForm.invalid ||
                                    BankPostalCodeForm.invalid ||
                                    BankSortCodeForm.invalid
                                "
                    icon="exclamation-triangle"
                    class="gold-gelb"
                >
                </fa-icon>
            </mat-panel-title>
            <mat-panel-description> IBAN, BIC ... </mat-panel-description>
        </mat-expansion-panel-header>
        <app-textfield class="hover-form" Key="IBAN" [Value]="IBANForm"></app-textfield>
        <app-textfield class="hover-form" Key="Swift" [Value]="SwiftForm"></app-textfield>
        <app-textfield class="hover-form" Key="Bankname" [Value]="BankNameForm"></app-textfield>
        <app-textfield class="hover-form" Key="Bank Ort" [Value]="BankLocationForm"></app-textfield>
        <app-textfield class="hover-form" Key="Bank PLZ" [Value]="BankPostalCodeForm"></app-textfield>
        <app-textfield class="hover-form" Key="BLZ" [Value]="BankSortCodeForm"></app-textfield>

    </mat-expansion-panel>
</form>
<mat-slide-toggle class="px-4" [(ngModel)]="createCustomerAndSupplier">{{CustomerForm.value.IsSupplier ? 'Möchten Sie den Lieferanten auch als Kunden anlegen?' : 'Möchten Sie den Kunden auch als Lieferanten anlegen?'}}</mat-slide-toggle>

<ng-template #sachkontoOptionTemplate let-option>
    <app-edit-gegenkonto [ChartOfAccount]="option" (editEvent)="newChartOfAccountPopUp($event)"></app-edit-gegenkonto>
</ng-template>
