import { DataSource } from '@angular/cdk/collections';
import { formatCurrency, formatNumber, getCurrencySymbol } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith, tap } from 'rxjs/operators';
import { AccountsReceivableLedgerTemplateEntity } from '../../../../dave-data-module/entities/accounts-receivable-ledger-template.entity';
import { AccountsReceivableLedgerTypeEnum, NettoCost } from '../../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { ARLTemplateTypeResolver } from '../../../../dave-data-module/guards/a-r-l-template-type.resolver';
import { AccountsReceivableLedgerTemplateResolver } from '../../../../dave-data-module/guards/accounts-receivable-ledger-template.resolver';
import { CommissionTypeResolver } from '../../../../dave-data-module/guards/commissionType.resolver';
import { QuantityTypeResolver } from '../../../../dave-data-module/guards/quantity-type.resolver';
import { getFetched$ } from '../../../../dave-data-module/helper/helper';
import { State } from '../../../../dave-data-module/State';
import {
    getAccountsReceivableLedgerTemplateById,
    getAccountsReceivableLedgerTemplates,
    getAccountsReceivableLedgerTemplatesFetched,
    getARLTemplateTypes,
    getARLTemplateTypesFetched,
    getQuantityTypes,
    getQuantityTypesFetched,
} from '../../../../dave-data-module/State/selectors/accounting.selector';
import { getCommissionTypes, getCommissionTypesFetched } from '../../../../dave-data-module/State/selectors/commissionType.selectors';
import { uniqArray } from '../../../../helper/helper';
import { ArlTemplateFormDataService, arlTemplateTableDataArlForm } from '../arl-template-form-data.service';

export interface ArlTemplateTableItem {
    dontEditHint: string | null;
    BookingText: string;
    Information: string;
    Quantity: string;
    QuantityAsFloat: number;
    QuantityType: string;
    // SumNetto: string;
    // SumNettoAsFloat: number;
    TemplateTypeName: string;
    sumNetto$: Observable<string>;
    sumNettoAsFloat$: Observable<number>;
    BaseCost: string;
    BaseCostAsFloat: number;
    Hint: string;
    Id: number;
    CommissionTypeId: number;
    ARLTemplateTypeId: number;
    CurrencyCode: string;
    form: arlTemplateTableDataArlForm;
    entity$: Observable<AccountsReceivableLedgerTemplateEntity>;
}

/**
 * Data source for the ArlTemplateTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class ArlTemplateTableDataSource extends DataSource<ArlTemplateTableItem> {
    data$: Observable<ArlTemplateTableItem[]> = combineLatest([
        getFetched$(this.store, getAccountsReceivableLedgerTemplatesFetched, getAccountsReceivableLedgerTemplates, this.resAccountsReceivableLedgerTemplates),
        getFetched$(this.store, getQuantityTypesFetched, getQuantityTypes, this.resQuantityTypes),
        getFetched$(this.store, getARLTemplateTypesFetched, getARLTemplateTypes, this.resARLTemplateTypes),
        getFetched$(this.store, getCommissionTypesFetched, getCommissionTypes, this.resCommissionTypes),
    ]).pipe(
        map(([val, qt, type, commissiontype]) =>
            val
                .filter((v) => !v.ParentId && !v.CustomProperties?.jveg)
                .sort((a, b) => a.Id - b.Id)
                .map((v) => {
                    const TemplateType = v.ARLTemplateTypeId ? type.find((arl) => arl.Id === v.ARLTemplateTypeId) : null;

                    const form: arlTemplateTableDataArlForm =
                        this.arlTemplateFormDataService.getForm(v.Id) ||
                        new FormGroup({
                            // Amount: new FormControl<number>(e.Amount, {nonNullable: true}),
                            Information: new FormControl<string>(v.Information, { nonNullable: true }),
                            BookingText: new FormControl<string>(v.BookingText, { nonNullable: true }),
                            BaseCost: new FormControl<number>(v.BaseCost, { nonNullable: true }),
                            Quantity: new FormControl<number>(v.Quantity, { nonNullable: true }),
                            QuantityTypeId: new FormControl<number>(v.QuantityTypeId, { nonNullable: true }),
                            // wird von der arl-calculation-component verwaltet
                            childrenVisible: new FormControl<boolean>(null, { nonNullable: true }),
                            InheritFromChildren: new FormControl<boolean>(v.InheritFromChildren, { nonNullable: true }),
                            ParentId: new FormControl<number | null>(v.ParentId, { nonNullable: true }),
                            ARLIds: new FormControl<number[]>(uniqArray(v.ArlTemplateIds) || [], { nonNullable: true }),
                            ZuschlaegeResourcen: new FormControl<number>(v.CustomProperties?.ZuschlaegeResourcen || null, { nonNullable: true }),
                            ZuschlaegeDefault: new FormControl<number>(v.CustomProperties?.ZuschlaegeDefault || null, { nonNullable: true }),
                            Type: new FormControl<AccountsReceivableLedgerTypeEnum>(v.Type || null, { nonNullable: true }),
                            ResourceId: new FormControl<number>(v.ResourceId || null, { nonNullable: true }),
                            Longtext: new FormControl<string>(v.Longtext || null, { nonNullable: true }),
                            ShowLongtext: new FormControl<boolean>(v.ShowLongtext || false, { nonNullable: true }),
                            TemplateTypeName: new FormControl<string>(TemplateType?.Name || null, { nonNullable: true }),
                            Tax: new FormControl<number>(v.Tax || null, { nonNullable: true }),
                            CurrencyCode: new FormControl<string>(v.CurrencyCode || null, { nonNullable: true }),
                        });
                    if (form.controls.ARLIds.value.length === 0) {
                        form.controls.childrenVisible.disable();
                        form.controls.childrenVisible.markAsPristine();
                    }
                    if (!this.arlTemplateFormDataService.getForm(v.Id)) {
                        this.arlTemplateFormDataService.setForm(v.Id, form);
                    }
                    // console.log('ARLIds', form.value.ARLIds);
                    const calculatedAmount = new FormControl<number>(null);

                    const SumNettoAsFloat = NettoCost(v.Quantity, v.BaseCost);
                    const sumNettoAsFloat$ = combineLatest([
                        form.controls.InheritFromChildren.valueChanges.pipe(startWith(null)),
                        form.controls.Quantity.valueChanges.pipe(startWith(null)),
                        form.controls.BaseCost.valueChanges.pipe(startWith(null)),
                    ]).pipe(
                        map(() => {
                            return NettoCost(form.controls.Quantity.value, /*form.controls.InheritFromChildren.value ? calculatedAmount.value : */ form.controls.BaseCost.value);
                        }),
                        tap((a) => console.log('sumNetto$', a, v)),
                        shareReplay({ bufferSize: 1, refCount: true }),
                    );
                    return {
                        Id: v.Id,
                        CommissionTypeId: v.CommissionTypeId,
                        ARLTemplateTypeId: v.ARLTemplateTypeId,
                        CurrencyCode: v.CurrencyCode,
                        BookingText: v.BookingText || '',
                        Information: v.Information || '',
                        // SumNetto: formatCurrency(SumNettoAsFloat, this.locale, getCurrencySymbol(v.CurrencyCode, 'narrow'), v.CurrencyCode),
                        // SumNettoAsFloat,
                        BaseCost: formatCurrency(v.BaseCost, this.locale, getCurrencySymbol(v.CurrencyCode, 'narrow'), v.CurrencyCode),
                        BaseCostAsFloat: v.BaseCost,
                        Quantity: formatNumber(v.Quantity, this.locale),
                        QuantityAsFloat: v.Quantity,
                        QuantityType: qt?.find((q) => q.Id === v.QuantityTypeId)?.Name || '',
                        Hint: v.CustomProperties?.hint,
                        dontEditHint: v.CustomProperties?.timeTrackingTemplate
                            ? 'Diese Vorlage ist für die automatische Zeiterfassung.'
                            : v.CommissionTypeId
                            ? 'Diese Vorlage gehört zu einer Auftragsart.'
                            : v.EventTypeId
                            ? 'Diese Vorlage gehört zu einer Ereignisart.'
                            : null,
                        // TemplateType: v.ARLTemplateTypeId ? type.find((arl) => arl.Id === v.ARLTemplateTypeId)?.Name : v.CommissionTypeId ? commissiontype.find((c) => c.Id === v.CommissionTypeId)?.Name : 'Kein Typ angegeben',
                        TemplateTypeName: TemplateType ? TemplateType.Name : v.CommissionTypeId ? commissiontype.find((c) => c.Id === v.CommissionTypeId)?.Name : 'Kein Typ angegeben',
                        // ARLTemplate: v,
                        form,
                        entity$: this.store.select(getAccountsReceivableLedgerTemplateById({ id: v.Id })),
                        sumNettoAsFloat$,
                        sumNetto$: sumNettoAsFloat$.pipe(map((sum) => formatCurrency(sum, this.locale, getCurrencySymbol(v.CurrencyCode, 'narrow'), v.CurrencyCode))),
                    };
                }),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    paginator: MatPaginator | undefined;
    sort: MatSort | undefined;
    filter: BehaviorSubject<string> | undefined;
    commissionTypeIdFilter: BehaviorSubject<number> | undefined;
    arlTemplateTypeIdFilter: BehaviorSubject<number> | undefined;
    noCommissionTypeAndNoArlTemplateType: BehaviorSubject<boolean> | undefined;
    constructor(
        private store: Store<State>,
        private locale: string,
        private arlTemplateFormDataService: ArlTemplateFormDataService,
        private resAccountsReceivableLedgerTemplates: AccountsReceivableLedgerTemplateResolver,
        private resQuantityTypes: QuantityTypeResolver,
        private resARLTemplateTypes: ARLTemplateTypeResolver,
        private resCommissionTypes: CommissionTypeResolver,
    ) {
        super();
    }

    /**
     * Connect this data source to the table. The table will only update when
     * the returned stream emits new items.
     * @returns A stream of the items to be rendered.
     */
    connect(): Observable<ArlTemplateTableItem[]> {
        console.log('connect');
        if (this.paginator && this.sort && this.filter && this.commissionTypeIdFilter && this.arlTemplateTypeIdFilter && this.noCommissionTypeAndNoArlTemplateType) {
            // Combine everything that affects the rendered data into one update
            // stream for the data-table to consume.
            // return merge(this.data$, this.paginator.page, this.sort.sortChange).pipe(
            return this.data$.pipe(
                switchMap((data) =>
                    this.sort.sortChange.pipe(
                        startWith(null),
                        map(() => this.getSortedData([...data])),
                    ),
                ),
                // tap(data => console.log(data)),
                switchMap((data) =>
                    combineLatest([this.commissionTypeIdFilter, this.arlTemplateTypeIdFilter, this.noCommissionTypeAndNoArlTemplateType]).pipe(
                        debounceTime(10), // these filters are set asynchronous by only one form, so they may change all on one user interaction
                        map(() => this.getFilteredDataByTypesMustBeNull(this.getFilteredDataByArlTemplateType(this.getFilteredDataByCommissionType(data)))),
                    ),
                ),
                switchMap((data) =>
                    this.filter.pipe(
                        debounceTime(300),
                        // startWith(null),
                        distinctUntilChanged(),
                        map(() => this.getFilteredData(data)),
                    ),
                ),
                tap(() => {
                    this.paginator.firstPage();
                    // this.paginator.getNumberOfPages();
                }),
                switchMap((data) =>
                    this.paginator.page.pipe(
                        startWith(null),
                        map(() => this.getPagedData(data)),
                    ),
                ),
            );
        } else {
            throw Error('Please set the paginator, filter, commissionTypeIdFilter, arlTemplateTypeIdFilter, noCommissionTypeAndNoArlTemplateType, and sort on the data source before connecting.');
        }
    }

    /**
     *  Called when the table is being destroyed. Use this function, to clean up
     * any open connections or free any held resources that were set up during connect.
     */
    disconnect(): void {}

    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getFilteredData(data: ArlTemplateTableItem[]): ArlTemplateTableItem[] {
        if (this.filter && this.filter.value) {
            const query = this.filter.value.toLowerCase();
            const retFiltered = data.filter((d) => {
                return [d.BookingText, d.Information, d.Hint].filter((v) => !!v).some((v) => v.toLowerCase().includes(query));
            });
            console.log({ retFiltered, query });
            return retFiltered;
        } else {
            return data;
        }
    }
    private getFilteredDataByCommissionType(data: ArlTemplateTableItem[]): ArlTemplateTableItem[] {
        if (this.commissionTypeIdFilter && this.commissionTypeIdFilter.value) {
            return data.filter((d) => d.CommissionTypeId === this.commissionTypeIdFilter.value);
        } else {
            return data;
        }
    }
    private getFilteredDataByArlTemplateType(data: ArlTemplateTableItem[]): ArlTemplateTableItem[] {
        if (this.arlTemplateTypeIdFilter && this.arlTemplateTypeIdFilter.value) {
            return data.filter((d) => d.ARLTemplateTypeId === this.arlTemplateTypeIdFilter.value);
        } else {
            return data;
        }
    }
    private getFilteredDataByTypesMustBeNull(data: ArlTemplateTableItem[]): ArlTemplateTableItem[] {
        if (this.noCommissionTypeAndNoArlTemplateType && this.noCommissionTypeAndNoArlTemplateType.value) {
            return data.filter((d) => !d.ARLTemplateTypeId && !d.CommissionTypeId);
        } else {
            return data;
        }
    }

    /**
     * Paginate the data (client-side). If you're using server-side pagination,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getPagedData(data: ArlTemplateTableItem[]): ArlTemplateTableItem[] {
        if (this.paginator) {
            this.paginator.length = data.length;
            const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
            return data.slice().splice(startIndex, this.paginator.pageSize);
        } else {
            return data;
        }
    }

    /**
     * Sort the data (client-side). If you're using server-side sorting,
     * this would be replaced by requesting the appropriate data from the server.
     */
    private getSortedData(data: ArlTemplateTableItem[]): ArlTemplateTableItem[] {
        if (!this.sort || !this.sort.active || this.sort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            const isAsc = this.sort?.direction === 'asc';
            const sortKey = this.sort?.active;
            switch (sortKey as keyof ArlTemplateTableItem) {
                case 'BookingText':
                case 'TemplateTypeName':
                case 'Information':
                case 'QuantityType':
                case 'Hint':
                    return compare(a[sortKey], b[sortKey], isAsc);
                // case 'SumNetto':
                // case 'SumNettoAsFloat':
                //     return compare(a.SumNettoAsFloat, b.SumNettoAsFloat, isAsc);
                case 'Quantity':
                case 'QuantityAsFloat':
                    return compare(a.QuantityAsFloat, b.QuantityAsFloat, isAsc);
                default:
                    return 0;
            }
        });
    }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
