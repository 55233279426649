import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { State } from '../dave-data-module/State';
import { ChangeSettings } from '../dave-data-module/State/actions/settings.actions';
import { getSetting, getSettingFetched } from '../dave-data-module/State/selectors/users.selectors';

export enum FilterApps {
    ReportsList = 'ReportsListFilter',
    CustomerList = 'CustomerListFilter',
    CommissionList = 'CommissionListFilter',
    ShiftPlan = 'ShiftPlanFilter',
    TaskPage = 'TaskPageFilter',
    DMS = 'DMSFilter',
    EmailList = 'EmailListFilter',
    ContactList = 'ContactListFilter',
    Timeline = 'TimelineFilter',
    ResourceList = 'ResourceList',
    SelectUserForCommissionPopup = 'SelectUserForCommissionPopup',
    SelectUserForCustomerPopup = 'SelectUserForCustomerPopup',
    SelectUserForEventPopup = 'SelectUserForEventPopup',
    SelectUserForEmployeePopup = 'SelectUserForEmployeePopup',
    SelectGroupMemberPopup = 'SelectGroupMemberPopup',
    SelectCommissionPopup = 'SelectCommissionPopup',
    HistoryBookmarkComponentTasks = 'HistoryBookmarkComponentTasks',
    /** To be extended */
}

export enum FilterTypes {
    Status = 'status',
    Type = 'type',
    TypeIds = 'typeIds',
    AssignedUser = 'assignedUser',
    AssignedCommission = 'assignedCommission',
    PartnerOfficeId = 'partnerOfficeId',
    Editor = 'editor',
    CommissionId = 'commissionId',
    Commissions = 'commissions',
    UserId = 'userId',
    Users = 'users',
    JobSpecificationIds = 'jobSpecificationIds',
    AutorId = 'autorId',
    ShowNotApprovedVacations = 'showNotApprovedVacations',
    ShowAutoGenerated = 'showAutoGenerated',
    ResourceIds = 'ResourceIds',
    HideKollision = 'HideKollision',
    CommissionType = 'commissionType',
    Priority = 'priority',
    Date = 'date',
    EndDate = 'endDate',
    Persons = 'persons',
    EventType = 'eventType',
    Verantwortlich = 'verantwortlich',
    Bearbeiter = 'bearbeiter',
    Timespan = 'Timespan',
    Employees = 'employees',
    Emails = 'Emails',
    QuantityType = 'QuantityType',
    EventTypes = 'eventTypes',
    IsTask = 'IsTask',
    IsEvent = 'IsEvent',
    Customers = 'Customers',
    Supplier = 'Supplier',
    CustomerSpecificationTypeId = 'CustomerSpecificationTypeId',
    /** To be extended */
}

@Injectable({
    providedIn: 'root',
})
export class DefaultFilterService {
    constructor(private store: Store<State>) {}

    /** returning an obsevale<object> with set filter values, it completees after one value (id doesn't need to be unsubscribed) */
    GetFilterByApp$(app: FilterApps) {
        return this.store.select(getSettingFetched).pipe(
            filter((v) => !!v),
            switchMap(() => this.store.select(getSetting)),
            take(1),
            map((setting) => setting?.ListFilters?.get(app) || {}),
        );
    }

    SetFilterByApp(app: FilterApps, filterObject: any) {
        this.store
            .select(getSettingFetched)
            .pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getSetting)),
                take(1),
            )
            .subscribe((setting) => {
                if (!setting.ListFilters) {
                    this.store.dispatch(
                        new ChangeSettings({
                            ListFilters: new Map().set(app, filterObject),
                        }),
                    );
                } else {
                    this.store.dispatch(
                        new ChangeSettings({
                            ListFilters: setting.ListFilters.set(app, filterObject),
                        }),
                    );
                }
            });
    }
}
