import { isNotNullOrUndefined, MathRound } from '../../helper/helper';
import { AccountsReceivableLedgerType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import { ARLCustomProperties } from './accounts-receivable-ledger-template.entity';

export enum AccountsReceivableLedgerTypeEnum {
    Arl = 'Arl',
    EmployeeArl = 'EmployeeArl',
    ExternalService = 'ExternalService',
    Group = 'Group',
    Other = 'Other',
    ResourceArl = 'ResourceArl',
    MaterialArl = 'MaterialArl',
    Text = 'Text'
}
export const availableBookingPositionTypeEnums = [
    AccountsReceivableLedgerTypeEnum.Arl,
    AccountsReceivableLedgerTypeEnum.ResourceArl,
    AccountsReceivableLedgerTypeEnum.MaterialArl,
    // ARLTypeEnum.EmployeeArl,
]
export const isBookingPositionTypeEnum = (type: AccountsReceivableLedgerTypeEnum) => {
    return type !== AccountsReceivableLedgerTypeEnum.Group && type !== AccountsReceivableLedgerTypeEnum.Text;
}
export const ARLTypeEnumNames: Map<AccountsReceivableLedgerTypeEnum, string> = new Map<AccountsReceivableLedgerTypeEnum, string>([
    [AccountsReceivableLedgerTypeEnum.Text, 'Text'],
    [AccountsReceivableLedgerTypeEnum.Group, 'Titel'],
    [AccountsReceivableLedgerTypeEnum.Arl, 'Leistung'],
    [AccountsReceivableLedgerTypeEnum.ResourceArl, 'Gerät'],
    [AccountsReceivableLedgerTypeEnum.MaterialArl, 'Material'],
    [AccountsReceivableLedgerTypeEnum.EmployeeArl, 'Lohn'],
    [AccountsReceivableLedgerTypeEnum.ExternalService, 'Fremdleistung'],
    [AccountsReceivableLedgerTypeEnum.Other, 'Sonstiges'],
]);

export enum AccountsReceivableLedgerStatusEnum {
    Neu = 'NEU',
    Gebucht = 'GEBUCHT',
    Verrechnet = 'VERRECHNET',
    Storniert = 'STORNIERT',
    AngebotAkzeptiert = 'ANGEBOT_AKZEPTIERT',
}
export const AccountsReceivableLedgerStatusEnumNames: Map<AccountsReceivableLedgerStatusEnum, string> = new Map<AccountsReceivableLedgerStatusEnum, string>([
    [AccountsReceivableLedgerStatusEnum.Neu, 'Neu'],
    [AccountsReceivableLedgerStatusEnum.Gebucht, 'Gebucht'],
    [AccountsReceivableLedgerStatusEnum.Verrechnet, 'bezahlt'],
    [AccountsReceivableLedgerStatusEnum.Storniert, 'Storniert'],
    [AccountsReceivableLedgerStatusEnum.AngebotAkzeptiert, 'Bestätigt'],
]);
export const CalcDiscount = (amount: number, discountPercentage: number) => +(MathRound(Number((amount * 100 * ((100 - (discountPercentage || 0)) / 100)) / 100 + 'e+2'), true) + 'e-2');
export const CalcAccountsReceivableLedgerAmount = (Quantity: number, Tax: number, BaseCost: number) => {
    if (isNotNullOrUndefined(Quantity) && isNotNullOrUndefined(Tax) && isNotNullOrUndefined(BaseCost)) {
        return parseFloat((NettoCost(Quantity, BaseCost) + CalcAccountsReceivableLedgerTaxAmount(Quantity, Tax, BaseCost)).toFixed(10));
    }
    return undefined;
};
export const NettoCost = (Quantity: number, BaseCost: number) => +(MathRound(Number(Quantity * BaseCost + 'e+2'), true) + 'e-2');
export const CalcAccountsReceivableLedgerTaxAmount = (Quantity: number, Tax: number, BaseCost: number) => +(MathRound(Number(Quantity * BaseCost * (Tax / 100) + 'e+2'), true) + 'e-2');
export class AccountsReceivableLedgerEntity {
    constructor(
        public Id: number = null,
        public Date: Date = null, // - date (für Datev Format: yyyy-mm-dd) (Rechnungsdatum)
        public Amount: number = null, // - float (Gesamtbetrag) Quantity * BaseCost * (100 + Tax)
        public AccountNo: string = null, // (im Kommentar beschrieben) -> http://jira.omnia5.local/browse/DBASE-1385
        public BuCode: string = null, // - string (Buchungsschlüssel) nullable (default: 3 -> 19%) https://apps.datev.de/dnlexka/document/1008613
        public CostCategoryId: number = null, // - int (standard: 10) (kostenstellen beim steuerberater anfragen)
        public CostCategoryId2: number = null, // - int (standard: 20)
        public Tax: number = null, // - float (MWST default: 19%)
        public Information: string = null, // - string nullable (Evtl. Buchungstext für Datev)
        public CurrencyCode: string = null, // - char(3) (default: EUR)
        public InvoiceId: string = null, // - string (Rechnungsnr als sTring)
        public BookingText: string = null, // - string nullable (buchungstext evtl. Datev) max 30 Zeichen
        public PartyId: string = null, // - string (Kunden für beleg)
        public PaidAt: Date = null, // - date nullable (in Datev im format yyyy-mm-dd)
        public Iban: string = null, // - string nullable
        public DueDate: Date = null, // - date (Fälligkeitsdatum) nullable
        public CustomerName: string = null, // - string nullable
        public CustomerCity: string = null, // - string nullable

        // Dinge die man ausklappen kann im FE:
        public CostAmount: number = null, // - float nullable (Was ist das?)
        public DiscountAmount: number = null, // - float (Skontobetrag) nullable
        public InternalInvoiceId: string = null, // - string nullable (interne Rechnungsnummer)
        public VatId: string = null, // - string nullable (UmsatzsteuerId des Geschäftspartner)
        public ExchangeRate: number = null, // - float nullable (Umrechnungskurs)
        public BankCode: string = null, // - string nullable (BLZ)
        public BankAccount: string = null, // - string nullable (Bankkontonummer)
        public BankCountry: string = null, // - char 2 nullable
        public SwiftCode: string = null, // - string nullable
        public AccountName: string = null, // - Bezeichnung des Sachkontos
        public PaymentConditionsId: string = null, // - string nullable (Zahlungsbedingungsnummer)
        public DiscountPercentage: number = null, //
        public DiscountPaymentDate: Date = null, //
        public DiscountAmount2: number = null, //
        public DiscountPercentage2: number = null, //
        public DiscountPaymentDate2: Date = null, //
        public BpAccountNo: string = null, // - string nullable (default: Datev 10.000)
        public DeliveryDate: Date = null, // (wenn gesetzt, dann auch in LedgerImport setzen)
        public OrderId: string = null, // (wenn gesetzt, dann auch in LedgerImport setzen)y
        public CanBeEdited: boolean = null, // - bit default true
        public LedgerImportId: number = null, // - bigint nullable

        public PartnerId: number = null,
        public UserId: number = null,
        public CustomerId: number = null,
        public CommissionId: number = null,
        public EventId: number = null,
        public CreatedAt: Date = null,
        public UpdatedAt: Date = null,
        public DeletedAt: Date = null,

        public BaseCost: number = null,
        public Quantity: number = null,
        public QuantityTypeId: number = null,

        public Multiplier: number = null,
        public CustomProperties: ARLCustomProperties = null,
        public Status: AccountsReceivableLedgerStatusEnum = null,
        public PartnerOfficeId: number = null,
        public DocumentType: string = null,
        public GeneratedARLId: number = null,
        public AmountCalculation: any = null,
        public InheritFromChildren: boolean = null,
        public IsVisible: boolean = null,
        public ParentId: number = null,
        public ResourceId: number = null,
        public MaterialId:number =null,
        public ArlIds: number[] = null,
        public Type: AccountsReceivableLedgerTypeEnum = null,
        public Longtext: string = null,
        public ShowLongtext: boolean = null,
        public JobSpecificationId: number = null,
        public AlreadyPayed: number = null, // Bereits gezahlter amount, also das gleiche wie im Businessvolume (welche es nur für den ganzen ledgerImport gibt), wird aber nicht automatisch aktualisiert
        public RepresentsLedgerImportId: number = null, // repräsentiert einen LedgerImport, z.B.: eine Teilrechnung, welche dann in der Schlussrechnung angezeigt wird
    ) {}
    get isBookingPosition() {
        return isBookingPositionTypeEnum(this.Type);
    }
    public GetBackgroundColor() {
        switch (this.Status) {
            case AccountsReceivableLedgerStatusEnum.Verrechnet:
                return 'rgba(0,255,0,0.2)';
            case AccountsReceivableLedgerStatusEnum.Gebucht:
                if (this.DueDate < new Date()) {
                    return 'rgba(255,0,0,0.2)';
                } else {
                    return 'rgba(255,255,0,0.2)';
                }
            case AccountsReceivableLedgerStatusEnum.Storniert:
                return 'rgba(0,0,0,0.2)';
            case AccountsReceivableLedgerStatusEnum.AngebotAkzeptiert:
                return '#44aa22';
            default:
                return '';
        }
    }
    GetBackgroundColorClass() {
        switch (this.Status) {
            case AccountsReceivableLedgerStatusEnum.Verrechnet:
                return 'app-color-invoice-verrechnet';
            case AccountsReceivableLedgerStatusEnum.Gebucht:
                if (this.DueDate < new Date()) {
                    return 'app-color-late';
                } else {
                    return 'app-color-invoice-booked';
                }
            case AccountsReceivableLedgerStatusEnum.Storniert:
                return 'app-color-invoice-storniert';
            case AccountsReceivableLedgerStatusEnum.AngebotAkzeptiert:
                return 'app-color-invoice-approved';
        }
    }
    public get CompleteCost() {
        return CalcAccountsReceivableLedgerAmount(this.Quantity, this.Tax, this.BaseCost);
    }
    public get CompleteTaxCost() {
        if (isNotNullOrUndefined(this.Quantity) && isNotNullOrUndefined(this.Tax) && isNotNullOrUndefined(this.BaseCost)) {
            return CalcAccountsReceivableLedgerTaxAmount(this.Quantity, this.Tax, this.BaseCost);
        }
        return undefined;
    }
    public static readonly GqlFields: Array<keyof AccountsReceivableLedgerType> = [
        'accountName',
        'accountNo',
        'amount',
        'bankAccount',
        'bankCode',
        'bankCountry',
        'baseCost',
        'bookingText',
        'bpAccountNo',
        'buCode',
        'canBeEdited',
        'commissionId',
        'costAmount',
        'costCategoryId',
        'costCategoryId2',
        'createdAt',
        'currencyCode',
        'customerCity',
        'customerId',
        'customerName',
        'date',
        'deletedAt',
        'deliveryDate',
        'discountAmount',
        'discountAmount2',
        'discountPaymentDate',
        'discountPaymentDate2',
        'discountPercentage',
        'discountPercentage2',
        'dueDate',
        'eventId',
        'exchangeRate',
        'generatedDocumentId',
        'iban',
        'id',
        'information',
        'internalInvoiceId',
        'invoiceId',
        'ledgerImportId',
        'orderId',
        'paidAt',
        'partnerId',
        'partyId',
        'paymentConditionsId',
        'quantity',
        'quantityTypeId',
        'swiftCode',
        'tax',
        'updatedAt',
        'userId',
        'vatId',
        'multiplikator',
        'customProperties',
        'partnerOfficeId',
        'documentType',
        'generatedARLId',
        'status',
        'amountCalculation',
        'inheritFromChildren',
        'isVisible',
        'parentId',
        'resourceId',
        'materialId',
        'arlIds',
        'type',
        'longInformation',
        'showLongInformation',
        'jobSpecificationId',
        'payedAmount',
    ];
    public Clone(override: Partial<AccountsReceivableLedgerEntity> = {}): AccountsReceivableLedgerEntity {
        const attr = { ...this, ...override };
        return Object.assign(new AccountsReceivableLedgerEntity(), attr);
    }
}

export function AccountsReceivableLedgerEntityFromBackend(res: AccountsReceivableLedgerType): AccountsReceivableLedgerEntity {
    return new AccountsReceivableLedgerEntity(
        res.id,
        res.date && BackendDate(res.date),
        res.amount && res.amount / 100,
        res.accountNo,
        res.buCode,
        res.costCategoryId,
        res.costCategoryId2,
        res.tax,
        res.information,
        res.currencyCode,
        res.invoiceId,
        res.bookingText,
        res.partyId,
        res.paidAt && BackendDate(res.paidAt),
        res.iban,
        res.dueDate && BackendDate(res.dueDate),
        res.customerName,
        res.customerCity,

        res.costAmount && res.costAmount / 100,
        res.discountAmount && res.discountAmount / 100,
        res.internalInvoiceId,
        res.vatId,
        res.exchangeRate,
        res.bankCode,
        res.bankAccount,
        res.bankCountry,
        res.swiftCode,
        res.accountName,
        null, // todo einkommentieren res.paymentConditionsId,
        res.discountPercentage,
        res.discountPaymentDate && BackendDate(res.discountPaymentDate),
        res.discountAmount2 && res.discountAmount2 / 100,
        res.discountPercentage2,
        res.discountPaymentDate2 && BackendDate(res.discountPaymentDate2),
        res.bpAccountNo,
        res.deliveryDate && BackendDate(res.deliveryDate),
        res.orderId,

        res.canBeEdited,
        res.ledgerImportId,
        res.partnerId,
        res.userId,
        res.customerId,
        res.commissionId,
        res.eventId,
        res.createdAt && BackendDate(res.createdAt),
        res.updatedAt && BackendDate(res.updatedAt),
        res.deletedAt && BackendDate(res.deletedAt),
        res.baseCost / 100,
        res.quantity,
        res.quantityTypeId,
        res.multiplikator,
        res.customProperties as any,
        res.status,
        res.partnerOfficeId,
        res.documentType,
        res.generatedARLId,
        res.amountCalculation,
        res.inheritFromChildren,
        res.isVisible,
        res.parentId,
        res.resourceId,
        res.materialId,
        res.arlIds,
        (res.type as unknown as AccountsReceivableLedgerTypeEnum) || AccountsReceivableLedgerTypeEnum.Arl,
        res.longInformation,
        res.showLongInformation,
        res.jobSpecificationId,
        res.payedAmount / 100,
        null, // todo einbauen
    );
}
