import { LedgerImportType } from '../graphql-types';
import { BackendDate } from '../helper/backend-frontend-conversion.helper';
import { PartnerOfficeAdditionalData } from "./partnerOffice.entity";
import { AccountsReceivableLedgerEntity } from "./accounts-receivable-ledger.entity";
export enum LedgerImportTaxTypes {
    nineteen = "nineteen",
    sixteen = "sixteen",
    seven = "seven",
    five = "five",
    reverseCharge = "reverse_charge",
    photovoltaik = "photovoltaik",
    kleinunternehmer = "kleinunternehmer",
}
export const getLedgerImportTaxTypeName = (taxType: LedgerImportTaxTypes) => {
    switch (taxType) {
        case LedgerImportTaxTypes.nineteen:
            return '19%';
        case LedgerImportTaxTypes.sixteen:
            return '16%';
        case LedgerImportTaxTypes.seven:
            return '7%';
        case LedgerImportTaxTypes.five:
            return '5%';
        case LedgerImportTaxTypes.reverseCharge:
            return '0% Reverse-Charge (§ 13b UStG)';
        case LedgerImportTaxTypes.photovoltaik:
            return '0% Photovoltaikanlagen (§ 12 Abs. 3 UStG)';
        case LedgerImportTaxTypes.kleinunternehmer:
            return '0% Kleinunternehmer (§ 19 UStG)';
        default:
            throw 'taxType not implemented' + taxType
    }
}
export const getLedgerImportTaxTypeTaxValue = (taxType: LedgerImportTaxTypes) => {
    switch (taxType) {
        case LedgerImportTaxTypes.nineteen:
            return 19;
        case LedgerImportTaxTypes.sixteen:
            return 16;
        case LedgerImportTaxTypes.seven:
            return 7;
        case LedgerImportTaxTypes.five:
            return 5;
        case LedgerImportTaxTypes.reverseCharge:
        case LedgerImportTaxTypes.photovoltaik:
        case LedgerImportTaxTypes.kleinunternehmer:
            return 0;
        default:
            throw 'taxType not implemented' + taxType
    }
}
export const LedgerImportTaxTypeOptions: Array<{name: string, value: LedgerImportTaxTypes}> = Object.keys(LedgerImportTaxTypes).map(key => ({
    value: LedgerImportTaxTypes[key],
    name: getLedgerImportTaxTypeName(LedgerImportTaxTypes[key]),
}))

interface groupInterface {
    name?: string;
    uniqId?: number;
    type: 'group' | 'group-end' | 'text' | 'arl';
}
const DEFAULT_INVOICE_NUMBER_START_COUNTER = 8;
const DEFAULT_INVOICE_NUMBER_END_COUNTER = 20;
export interface LedgerImportAdditionalData {
    twigTemplateId: number;
    notes?: string;
    assignedUserId: number;
    ourSign?: string;
    customerNumber?: string;
    tempBookingPosition?: any;
}
// Key und Value muss gleich sein
export enum LedgerImportStatusEnum {
    Neu = 'NEU',
    Gebucht = 'GEBUCHT',
    Verrechnet = 'VERRECHNET',
    Storniert = 'STORNIERT',
    AngebotAkzeptiert = 'ANGEBOT_AKZEPTIERT',
}
//enum('invoice','offer','order','orderConfirmation','partial_invoice','closing_invoice','offerConfirmation','measurement','deliveryNote','cancellation','credit')
export enum LedgerImportDocumentTypes {
    Invoice = 'invoice',
    Offer = 'offer',
    OfferConfirmation = 'offerConfirmation',
    Order = 'order',
    OrderConfirmation = 'orderConfirmation',
    PartialInvoice = 'partial_invoice',
    ClosingInvoice = 'closing_invoice',
    Measurement = 'measurement',
    DeliveryNote = 'deliveryNote',
    Cancellation = 'cancellation',
    Credit = 'credit',
}
const ledgerImportStatusValues: string[] = Object.values(LedgerImportStatusEnum).filter(x => typeof x === "string");
const ledgerImportDocumentTypesValues: string[] = Object.values(LedgerImportDocumentTypes).filter(x => typeof x === "string");
export const LedgerImportStatusNames: Map<LedgerImportStatusEnum, string> = new Map<LedgerImportStatusEnum, string>([
    [LedgerImportStatusEnum.Neu, 'Neu'],
    [LedgerImportStatusEnum.Gebucht, 'Gebucht'],
    [LedgerImportStatusEnum.Verrechnet, 'Bezahlt'],
    [LedgerImportStatusEnum.Storniert, 'Storniert'],
    [LedgerImportStatusEnum.AngebotAkzeptiert, 'Bestätigt'],
]);
export const LedgerImportDocumentTypeNames: Map<LedgerImportDocumentTypes, string> = new Map<LedgerImportDocumentTypes, string>([
    [LedgerImportDocumentTypes.Invoice, 'Rechnung'],
    [LedgerImportDocumentTypes.Offer, 'Angebot'],
    [LedgerImportDocumentTypes.OfferConfirmation, 'Auftragsbestätigung'],
    [LedgerImportDocumentTypes.Order, 'Bestellung'],
    [LedgerImportDocumentTypes.OrderConfirmation, 'Bestellbestätigung'],
    [LedgerImportDocumentTypes.PartialInvoice, 'Abschlagsrechnung'],
    [LedgerImportDocumentTypes.ClosingInvoice, 'Schlussrechnung'],
    [LedgerImportDocumentTypes.Measurement, 'Aufmaß'],
    [LedgerImportDocumentTypes.DeliveryNote, 'Lieferschein'],
    [LedgerImportDocumentTypes.Cancellation, 'Storno'],
    [LedgerImportDocumentTypes.Credit, 'Gutschrift'],
]);
export const LedgerImportDocumentTypeShortNames: Map<LedgerImportDocumentTypes, string> = new Map<LedgerImportDocumentTypes, string>([
    [LedgerImportDocumentTypes.Invoice, 'Re'],
    [LedgerImportDocumentTypes.Offer, 'An'],
    [LedgerImportDocumentTypes.OfferConfirmation, 'AB'],
    [LedgerImportDocumentTypes.Order, 'BE'],
    [LedgerImportDocumentTypes.OrderConfirmation, 'BEB'],
    [LedgerImportDocumentTypes.PartialInvoice, 'Re'],
    [LedgerImportDocumentTypes.ClosingInvoice, 'Re'],
    [LedgerImportDocumentTypes.Measurement, 'AUF'],
    [LedgerImportDocumentTypes.DeliveryNote, 'LS'],
    [LedgerImportDocumentTypes.Cancellation, 'ST'],
    [LedgerImportDocumentTypes.Credit, 'GS'],
]);
export const LedgerImportDocumentTypeNumberLabel: Map<LedgerImportDocumentTypes, string> = new Map<LedgerImportDocumentTypes, string>([
    [LedgerImportDocumentTypes.Invoice, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Invoice) + '-Nr.'],
    [LedgerImportDocumentTypes.Offer, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Offer) + '-Nr.'],
    [LedgerImportDocumentTypes.OfferConfirmation, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.OfferConfirmation) + '-Nr.'],
    [LedgerImportDocumentTypes.Order, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Order) + '-Nr.'],
    [LedgerImportDocumentTypes.OrderConfirmation, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.OrderConfirmation) + '-Nr.'],
    [LedgerImportDocumentTypes.PartialInvoice, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.PartialInvoice) + '-Nr.'],
    [LedgerImportDocumentTypes.ClosingInvoice, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.ClosingInvoice) + '-Nr.'],
    [LedgerImportDocumentTypes.Measurement, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Measurement) + '-Nr.'],
    [LedgerImportDocumentTypes.DeliveryNote, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.DeliveryNote) + '-Nr.'],
    [LedgerImportDocumentTypes.Cancellation, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Cancellation) + '-Nr.'],
    [LedgerImportDocumentTypes.Credit, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Credit) + '-Nr.'],
]);
export const LedgerImportDocumentTypeDateLabel: Map<LedgerImportDocumentTypes, string> = new Map<LedgerImportDocumentTypes, string>([
    [LedgerImportDocumentTypes.Invoice, 'Rechnungsdatum'],
    [LedgerImportDocumentTypes.Offer, 'Angebotsdatum'],
    [LedgerImportDocumentTypes.OfferConfirmation, 'Auftragsbestätigungsdatum'],
    [LedgerImportDocumentTypes.Order, 'Bestellungsdatum'],
    [LedgerImportDocumentTypes.OrderConfirmation, 'Bestellbestätigungsdatum'],
    [LedgerImportDocumentTypes.PartialInvoice, 'Rechnungsdatum'],
    [LedgerImportDocumentTypes.ClosingInvoice, 'Rechnungsdatum'],
    [LedgerImportDocumentTypes.Measurement, 'Aufmaßdatum'],
    [LedgerImportDocumentTypes.DeliveryNote, 'Lieferscheindatum'],
    [LedgerImportDocumentTypes.Cancellation, 'Stornodatum'],
    [LedgerImportDocumentTypes.Credit, 'Gutschriftdatum'],
]);
export const LedgerImportDocumentTypeShortDateLabel: Map<LedgerImportDocumentTypes, string> = new Map<LedgerImportDocumentTypes, string>([
    [LedgerImportDocumentTypes.Invoice, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Invoice) + '-Datum.'],
    [LedgerImportDocumentTypes.Offer, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Offer) + '-Datum.'],
    [LedgerImportDocumentTypes.OfferConfirmation, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.OfferConfirmation) + '-Datum.'],
    [LedgerImportDocumentTypes.Order, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Order) + '-Datum.'],
    [LedgerImportDocumentTypes.OrderConfirmation, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.OrderConfirmation) + '-Datum.'],
    [LedgerImportDocumentTypes.PartialInvoice, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.PartialInvoice) + '-Datum.'],
    [LedgerImportDocumentTypes.ClosingInvoice, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.ClosingInvoice) + '-Datum.'],
    [LedgerImportDocumentTypes.Measurement, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Measurement) + '-Datum.'],
    [LedgerImportDocumentTypes.DeliveryNote, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.DeliveryNote) + '-Datum.'],
    [LedgerImportDocumentTypes.Cancellation, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Cancellation) + '-Datum.'],
    [LedgerImportDocumentTypes.Credit, LedgerImportDocumentTypeShortNames.get(LedgerImportDocumentTypes.Credit) + '-Datum.'],
]);
export const getDueDateFromLedgerImportDocumentType = (type: LedgerImportDocumentTypes) => type === LedgerImportDocumentTypes.Offer ? 'Gültig bis' : 'Fälligkeitsdatum'
export class LedgerImportEntity {
    public static GetRegex(invoiceNumber: string, additionaldata: PartnerOfficeAdditionalData, documentType: LedgerImportDocumentTypes) {
        //when there's no invoicenumber, just return 0
        if (!invoiceNumber) {
            return 0;
        }

        let startCounter = null;
        let endCoutner = null;
        let sortingNumber = null;

        //default case when no regex is supplied
        if (!additionaldata) {
            startCounter = DEFAULT_INVOICE_NUMBER_START_COUNTER;
            endCoutner = DEFAULT_INVOICE_NUMBER_END_COUNTER;
            sortingNumber = invoiceNumber.substr(startCounter, endCoutner);
            return sortingNumber;
        }

        let regexHelper;
        switch (documentType) {
            case LedgerImportDocumentTypes.Invoice:
                regexHelper = additionaldata.rechnungsnNummer;
                break;
            case LedgerImportDocumentTypes.PartialInvoice:
                regexHelper = additionaldata.Abschlagsnummer;
                break;
            case LedgerImportDocumentTypes.ClosingInvoice:
                regexHelper = additionaldata.SchlussNummer;
                break;
            case LedgerImportDocumentTypes.Offer:
                regexHelper = additionaldata.angebotsNummer;
                break;
            case LedgerImportDocumentTypes.OfferConfirmation:
                regexHelper = additionaldata.BestellbestätigungNummer;
                break;
            case LedgerImportDocumentTypes.Order:
                regexHelper = additionaldata.BestellNummer;
                break;
            case LedgerImportDocumentTypes.OrderConfirmation:
                regexHelper = additionaldata.AuftragsbestaetigungsNummer;
                break;
            case LedgerImportDocumentTypes.Measurement:
                regexHelper = additionaldata.AufmassNummer;
                break;
            case LedgerImportDocumentTypes.DeliveryNote:
                regexHelper = additionaldata.LieferscheinNummer;
                break;
            case LedgerImportDocumentTypes.Cancellation:
                regexHelper = additionaldata.StornoNummer;
                break;
            case LedgerImportDocumentTypes.Credit:
                regexHelper = additionaldata.GutschriftNummer;
                break;

        }
        startCounter = regexHelper ? regexHelper.startCounter : DEFAULT_INVOICE_NUMBER_START_COUNTER;
        endCoutner = regexHelper ? regexHelper.endCoutner : DEFAULT_INVOICE_NUMBER_END_COUNTER;

        //extracting sorting number based on start/end values
        sortingNumber = invoiceNumber.substr(startCounter, endCoutner);

        return +sortingNumber;
    }
    constructor(
        public Id: number = null,
        // public Xmlns: string = null, // - string (XSD - Version - default: "http://xml.datev.de/bedi/tps/ledger/v050"
        // public XmlnsXsi: string = null, // - string (default: "http://www.w3.org/2001/XMLSchema-instance"
        // public XsiSchemaLocation: string = null, // - string (default: "http://xml.datev.de/bedi/tps/ledger/v050 Belegverwaltung_online_ledger_import_v050.xsd"
        // public GeneratorInfo: string = null, // - string (default: dave - company cloud GmbH)
        // public Version: string = null, // - string (default: 5.0)
        // public XmlData: string = null, // - string (default: "Kopie nur zur Verbuchung berechtigt nicht zum Vorsteuerabzug"
        // public GeneratingSystem: string = null, // - string (default: dave)
        public ConsolidatedAmount: number = null, // - float (Gesamtbetrag aller accountsreceivableLedger)
        public ConsolidatedDate: Date = null, // - date (rechungsdatum -> Datev Format: yyyy-mm-dd)
        public ConsolidatedInvoiceId: string = null, // - string (Rechnungsnr)
        public ConsolidatedCurrencyCode: string = null, // - char 3 (default: EUR)
        public ConsolidatedDeliveryDate: Date = null, // - date nullable (Leistungsdatum Datev Format: yyyy-mm-dd)
        public ConsolidatedOrderId: string = null, // - date nullable (TransaktionsId für Zahlungsreferenz)

        public PartnerId: number = null,
        public UserId: number = null,
        public CustomerId: number = null,
        public CreatedAt: Date = null,

        public AccountNumber: string = null,
        public Bankname: string = null,
        public Bic: string = null,
        public Blz: string = null,
        public Iban: string = null,
        public TextBottom: string = null,
        public TextTop: string = null,
        public UstId: string = null,

        public AccountsReceivableLedgerIds: number[] = null,
        public CommissionId: number = null,
        public CustomerCity: string = null,
        public CustomerCountry: string = null,
        public CustomerEmail: string = null,
        public CustomerName: string = null,
        public CustomerPostalCode: string = null,
        public CustomerStreet: string = null,
        public DocumentId: number = null,
        public PartnerCity: string = null,
        public PartnerCountry: string = null,
        public PartnerEmail: string = null,
        public PartnerPhoneNumber: string = null,
        public PartnerPostalCode: string = null,
        public PartnerStreet: string = null,
        // public SFDT: string = null, auskommentiert damit wir das nicht immer mit ins FE laden
        public Status: LedgerImportStatusEnum = null,
        public UpdatedAt: Date = null,
        public UserEmail: string = null,
        public UserName: string = null,
        public PartnerName: string = null,
        public DocumentType: LedgerImportDocumentTypes = null,
        public RootId: number = null,
        public GuaranteedDiscountPercentage: number = null,
        public IsTemplate: boolean = null,
        public DiscountPaymentDate: Date = null,
        public DiscountPaymentDate2: Date = null,
        public DiscountPercentage: number = null,
        public DiscountPercentage2: number = null,
        public DueDate: Date = null,
        public PartnerOfficeId: number = null,
        public DocumentIdXRechnung: number = null,
        public AdditionalData: LedgerImportAdditionalData = null,
        public AutomaticInvoiceId: boolean = null,
        public CeoName: string = null,
        public RegistryCourt: string = null,
        public CommercialRegister: string = null,
        /**
         * This is a special property that is true if someone has modified an arl from the ledger import without updating the sfdt.
         */
        public IsDirty: boolean = null,
        public CommissionNumber: string = null,
        public EventId: number = null,
        public HideNetto: boolean = null,
        public AccountsReceivableLedgerIdsFromLedgerImports: number[] = null,
        public AlreadyPayed: number = null, // Bereits gezahlter amount, also das gleiche wie im Businessvolume, wird aber nicht automatisch aktualisiert
        public PartialInvoiceCounter: number = null,
        public ConsolidatedNettoAmount: number = null,
        public TaxType: LedgerImportTaxTypes = null,
    ) {}
    public GetBackgroundColor() {
        switch (this.Status) {
            case LedgerImportStatusEnum.Verrechnet:
                return 'rgba(0,255,0,0.2)';
            case LedgerImportStatusEnum.Gebucht:
                if (this.DueDate < new Date()) {
                    return 'rgba(255,0,0,0.2)';
                } else {
                    return 'rgba(255,255,0,0.2)';
                }
            case LedgerImportStatusEnum.Storniert:
                return 'rgba(0,0,0,0.2)';
            case LedgerImportStatusEnum.AngebotAkzeptiert:
                return '#44aa22';
            default:
                return '';
        }
    }
    GetBackgroundColorClass() {
        switch (this.Status) {
            case LedgerImportStatusEnum.Verrechnet:
                return 'app-color-invoice-verrechnet';
            case LedgerImportStatusEnum.Gebucht:
                if (this.DueDate < new Date()) {
                    return 'app-color-late';
                } else {
                    return 'app-color-invoice-booked';
                }
            case LedgerImportStatusEnum.Storniert:
                return 'app-color-invoice-storniert';
            case LedgerImportStatusEnum.AngebotAkzeptiert:
                return 'app-color-invoice-approved';
        }
    }
    // public get IsInvoiceOrOffer(): 'invoice' | 'offer' {
    //     switch (this.DocumentType) {
    //         case LedgerImportDocumentTypes.Invoice:
    //             return 'invoice';
    //         case LedgerImportDocumentTypes.PartialInvoice:
    //             return 'invoice';
    //         case LedgerImportDocumentTypes.ClosingInvoice:
    //             return 'invoice';
    //         case LedgerImportDocumentTypes.Offer:
    //             return 'offer';
    //         default:
    //             return undefined;
    //     }
    // }
    public static readonly GqlFields = `
    accountNumber
    accountsReceivableLedgerIds
    bankname
    bic
    blz
    commissionId
    consolidatedAmount
    consolidatedCurrencyCode
    consolidatedDate
    consolidatedDeliveryDate
    consolidatedInvoiceId
    consolidatedOrderId
    createdAt
    customerCity
    customerCountry
    customerEmail
    customerId
    customerName
    customerPostalCode
    customerStreet
    discountPaymentDate
    discountPaymentDate2
    discountPercentage
    discountPercentage2
    documentId
    documentType
    guaranteedDiscountPercentage
    iban
    id
    isTemplate
    partnerCity
    partnerCountry
    partnerEmail
    partnerId
    partnerName
    partnerPhoneNumber
    partnerPostalCode
    partnerStreet
    rootId
    status
    textBottom
    textTop
    updatedAt
    userEmail
    userId
    userName
    ustId
    dueDate
    partnerOfficeId
    documentIdXRechnung
    additionalData
    automaticInvoiceId
    cEOName
    registryCourt
    commercialCourt
    isDirty
    commissionNumber
    eventId
    hideNetto
    accountsReceivableLedgerIdsFromLedgerImports
    partialInvoiceCounter
    consolidatedNettoAmount
    taxType
        `; // sFDT
    public Clone(override: Partial<LedgerImportEntity> = {}): LedgerImportEntity {
        const attr = { ...this, ...override };
        return Object.assign(new LedgerImportEntity(), attr);
    }
    get AvailableStates(): {optionLabel: string, optionValue: LedgerImportStatusEnum}[]  {
        const statusListIfCancled = [
            {
                optionLabel: LedgerImportStatusNames.get(LedgerImportStatusEnum.Storniert),
                optionValue: LedgerImportStatusEnum.Storniert,
            },
        ];
        const statusListIfOfferAccepted = [
            {
                optionLabel: LedgerImportStatusNames.get(LedgerImportStatusEnum.AngebotAkzeptiert),
                optionValue: LedgerImportStatusEnum.AngebotAkzeptiert,
            },
        ];
        const statusListIfVerrechnet = [
            {
                optionLabel: LedgerImportStatusNames.get(LedgerImportStatusEnum.Verrechnet),
                optionValue: LedgerImportStatusEnum.Verrechnet,
            },
            ...statusListIfCancled,
        ];
        const statusListIfBookedOrDone = [
            {
                optionLabel: LedgerImportStatusNames.get(LedgerImportStatusEnum.Gebucht),
                optionValue: LedgerImportStatusEnum.Gebucht,
            },
            ...statusListIfVerrechnet,
        ];
        const statusListIfNew = [
            {
                optionLabel: LedgerImportStatusNames.get(LedgerImportStatusEnum.Neu),
                optionValue: LedgerImportStatusEnum.Neu,
            },
            ...(this.DocumentType === LedgerImportDocumentTypes.Offer
                ? statusListIfOfferAccepted
                : statusListIfBookedOrDone),
        ];
        let select = [];
        switch (this.Status) {
            case LedgerImportStatusEnum.Storniert:
                select = statusListIfCancled;
                break;
            case LedgerImportStatusEnum.Neu:
                select = statusListIfNew;
                break;
            case LedgerImportStatusEnum.AngebotAkzeptiert:
                select = statusListIfOfferAccepted;
                break;
            case LedgerImportStatusEnum.Verrechnet:
                select = statusListIfVerrechnet;
                break;
            default:
                select = statusListIfBookedOrDone;
        }
        return select;
    }
}
export function determineIfIsArlEntityOrGroupInterface(toBeDetermined: AccountsReceivableLedgerEntity | groupInterface): toBeDetermined is AccountsReceivableLedgerEntity {
    return toBeDetermined instanceof AccountsReceivableLedgerEntity;
    // return !!(toBeDetermined as AccountsReceivableLedgerEntity).Id;
}
export function LedgerImportEntityFromBackend(res: LedgerImportType): LedgerImportEntity {
    return new LedgerImportEntity(
        res.id,
        res.consolidatedAmount && res.consolidatedAmount / 100,
        res.consolidatedDate ? BackendDate(res.consolidatedDate) : null,
        res.consolidatedInvoiceId,
        res.consolidatedCurrencyCode,
        res.consolidatedDeliveryDate ? BackendDate(res.consolidatedDeliveryDate) : null,
        res.consolidatedOrderId,
        res.partnerId,
        res.userId,
        res.customerId,
        res.createdAt ? BackendDate(res.createdAt) : null,
        res.accountNumber,
        res.bankname,
        res.bic,
        res.blz,
        res.iban,
        res.textBottom,
        res.textTop,
        res.ustId,
        res.accountsReceivableLedgerIds,
        res.commissionId,
        res.customerCity,
        res.customerCountry,
        res.customerEmail,
        res.customerName,
        res.customerPostalCode,
        res.customerStreet,
        res.documentId,
        res.partnerCity,
        res.partnerCountry,
        res.partnerEmail,
        res.partnerPhoneNumber,
        res.partnerPostalCode,
        res.partnerStreet,
        // res.sFDT,
        res.status,
        res.updatedAt ? BackendDate(res.updatedAt) : null,
        res.userEmail,
        res.userName,
        res.partnerName,
        ledgerImportDocumentTypesValues.includes(res.documentType) ? (res.documentType as LedgerImportDocumentTypes) : null,
        res.rootId,
        res.guaranteedDiscountPercentage,
        res.isTemplate,
        res.discountPaymentDate ? BackendDate(res.discountPaymentDate) : null,
        res.discountPaymentDate2 ? BackendDate(res.discountPaymentDate2) : null,
        res.discountPercentage,
        res.discountPercentage2,
        res.dueDate ? BackendDate(res.dueDate) : null,
        res.partnerOfficeId,
        res.documentIdXRechnung,
        res.additionalData as any,
        res.automaticInvoiceId,
        res.cEOName,
        res.registryCourt,
        res.commercialCourt,
        res.isDirty,
        res.commissionNumber,
        res.eventId,
        res.hideNetto,
        res.accountsReceivableLedgerIdsFromLedgerImports,
        null,
        res.partialInvoiceCounter,
        res.consolidatedNettoAmount,
        res.taxType as any,
    );
}
