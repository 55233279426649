import { CurrencyPipe, DatePipe, DecimalPipe, formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, Subject, Subscription } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    first,
    map,
    shareReplay,
    skip,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
    withLatestFrom
} from "rxjs/operators";
import { ShiftplanViews } from 'src/app/dave-calendar/components/scheduler.helper';
import { ITaskBoardSort } from 'src/app/dave-tasks/components/tasks-board/tasks-board.component';
import { TimelineViewComponent } from '../../../components/history/timeline/timeline-view/timeline-view.component';
import { DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData } from '../../../components/templates/new-document-view/component/dave-file-upload-dialog/dave-file-upload-dialog.component';
import { ResourcePlanComponentDialogData, ResourcePlanDialogComponent } from '../../../dave-calendar/components/resource-plan/resource-plan-dialog/resource-plan-dialog.component';
import { ResourcePlanComponent } from '../../../dave-calendar/components/resource-plan/resource-plan.component';
import { ShiftPlanComponentDialogData, ShiftPlanDialogComponent } from '../../../dave-calendar/components/shift-plan/shift-plan-dialog/shift-plan-dialog.component';
import { ShiftPlanComponent } from '../../../dave-calendar/components/shift-plan/shift-plan.component';
import { AccountsReceivableLedgerEntity, NettoCost } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { BookmarkEntity } from '../../../dave-data-module/entities/bookmark.entity';
import { CommentEntityTypeEnum } from '../../../dave-data-module/entities/comment.entity';
import { CommissionEntity } from '../../../dave-data-module/entities/commission.entity';
import { CommissionTypeNameEnum } from '../../../dave-data-module/entities/commissionType.entity';
import { CustomerEntity } from '../../../dave-data-module/entities/customer.entity';
import { DamageFlowEntity } from '../../../dave-data-module/entities/damageflow.entity';
import { EntityRolePermissionNameEnum, EntityRoleSlugEnum, EntityTypesEnum } from '../../../dave-data-module/entities/entity-role.entity';
import { EventTypeNamesEnum } from '../../../dave-data-module/entities/event-type.entity';
import { EventEntity, EventStateEnum, EventStateEnumColorMap } from "../../../dave-data-module/entities/event.entity";
import { FileEntity } from '../../../dave-data-module/entities/file.entity';
import { FolderEntity, FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { GeneratedDocumentsEntity } from '../../../dave-data-module/entities/generatedDocuments.entity';
import { GeneratedDocumentsTypeSlugs } from '../../../dave-data-module/entities/generatedDocumentsType.entity';
import { GroupEntity } from '../../../dave-data-module/entities/group.entity';
import {
    LedgerImportDocumentTypeNames, LedgerImportDocumentTypeNumberLabel,
    LedgerImportDocumentTypes,
    LedgerImportEntity,
} from "../../../dave-data-module/entities/ledger-import.entity";
import { PartnerSpecificationType, PartnerTypeEnum } from '../../../dave-data-module/entities/partner.entity';
import { PersonEntity } from '../../../dave-data-module/entities/person.entity';
import { Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { SharedCommissionsEntity } from '../../../dave-data-module/entities/sharedCommissions.entity';
import { ViewStyleConfig, ViewStyleSettingViewEnum } from '../../../dave-data-module/entities/viewStyleSetting.entity';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { PersonResolver } from '../../../dave-data-module/guards/person.resolver';
import { FrontendDate } from '../../../dave-data-module/helper/backend-frontend-conversion.helper';
import { State } from '../../../dave-data-module/State';
import { LedgerImportActionTypes } from '../../../dave-data-module/State/actions/accounting.actions';
import { BookmarkActionTypes } from '../../../dave-data-module/State/actions/bookmarks.actions';
import { CommissionActionTypes } from '../../../dave-data-module/State/actions/commission.actions';
import { EventsActionTypes } from '../../../dave-data-module/State/actions/events.actions';
import { FilesActionTypes, FileUploadParams } from "../../../dave-data-module/State/actions/files.actions";
import { Person2EntityActionTypes } from '../../../dave-data-module/State/actions/person2entity.action';
import { getAccountsReceivableLedgers, getARLsWithGeneratedARLId, getQuantityTypes } from '../../../dave-data-module/State/selectors/accounting.selector';
import { getBookmarks } from '../../../dave-data-module/State/selectors/bookmarks.selectors';
import { getCommissionById, getCommissions } from '../../../dave-data-module/State/selectors/commission.selector';
import { getCommissionTypeById, getCommissionTypes } from '../../../dave-data-module/State/selectors/commissionType.selectors';
import { getComplaintTypes } from '../../../dave-data-module/State/selectors/complaintType.selectors';
import { getNotDeletedCustomers } from '../../../dave-data-module/State/selectors/customers.selectors';
import { getCustomerSpecificationTypes } from '../../../dave-data-module/State/selectors/customerSpecificationType.selectors';
import { getDamageFlows } from '../../../dave-data-module/State/selectors/damageflow.selector';
import { getDamageFlowTypes } from '../../../dave-data-module/State/selectors/damageFlowType.selectors';
import { getEmployeeDictionary, getEmployeesSortedByNameStartWithMe } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getEntityRole, getEntityRoleFetched } from '../../../dave-data-module/State/selectors/entity-role.selector';
import { getEventTypes } from '../../../dave-data-module/State/selectors/event-type.selector';
import { getEventById, getEventsSortedByCreatedAt } from '../../../dave-data-module/State/selectors/events.selectors';
import { getFiles } from '../../../dave-data-module/State/selectors/files.selectors';
import { getGeneratedDocuments, getGeneratedDocumentsSortedByCreatedAt } from '../../../dave-data-module/State/selectors/generatedDocuments.selectors';
import { getGeneratedDocumentsTypes } from '../../../dave-data-module/State/selectors/generatedDocumentsType.selectors';
import { getLedgerImports } from '../../../dave-data-module/State/selectors/ledger-import.selector';
import { getOffices } from '../../../dave-data-module/State/selectors/offices.selectors';
import { getOwnPartnerOffices, getPartner, getPartners } from '../../../dave-data-module/State/selectors/partners.selectors';
import { getPersonDictionary, getPersons, getPersonsFetched } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getFilterdPersonTypesForBeteiligtePersonen } from '../../../dave-data-module/State/selectors/personType.selectors';
import { getQMStatus } from '../../../dave-data-module/State/selectors/qMStatus.selectors';
import { getSharedCommissions } from '../../../dave-data-module/State/selectors/sharedCommissions.selectors';
import { getStatusFromSV } from '../../../dave-data-module/State/selectors/status-from-sv.selectors';
import { getStatusFromBackoffice } from '../../../dave-data-module/State/selectors/statusFromBackoffice.selectors';
import { getUserToCommissionFetched, getUserToCommissions } from '../../../dave-data-module/State/selectors/user-to-commission.selector';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { DocumentEditorDialogComponent, DocumentEditorDialogComponentCloseData, DocumentEditorDialogComponentDialogData } from '../../../dave-document-editor-module/components/document-editor-dialog/document-editor-dialog.component';
import { EmailEditorComponent, EmailEditorComponentDialogConfig, EmailEditorComponentDialogData } from '../../../dave-email-module/components/email-editor/email-editor.component';
import { ListCardDialogComponent, ListCardDialogComponentDialogData } from '../../../dave-list-card/components/list-card-dialog/list-card-dialog.component';
import { DaveListCardcolumnProperties } from '../../../dave-list-card/dave-list-card.component';
import { SelectPersonsComponent, SelectPersonsComponentDialogData } from '../../../dave-person-module/components/select-persons/select-persons.component';
import { ModifyBookingOptionDialogComponent, ModifyBookingOptionDialogComponentData } from '../../../dave-reports/components/modify-booking-option/modify-booking-option-dialog/modify-booking-option-dialog.component';
import { NewBookingOptionComponent, NewOptionDialogData } from '../../../dave-reports/components/new-booking-option/new-booking-option.component';
import { SelectCommissionDialogComponent, SelectCommissionDialogComponentDialogData } from '../../../dave-select-user/components/select-user-dialogs/select-commission-dialog/select-commission-dialog.component';
import { SelectUserCommissionDialogComponent, SelectUserCommissionDialogComponentDialogData } from '../../../dave-select-user/components/select-user-dialogs/select-user-commission-dialog/select-user-commission-dialog.component';
import { HourListComponent, HourListComponentDialogData } from '../../../dave-time-tracker/components/hour-list/hour-list.component';
import { AppDialogService } from '../../../dave-utils-module/app-dialog-module/app-dialog.service';
import { IDetailListTemplateData, IDetailListTemplateDataProperty } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/detail-list-template/detail-list-template.component';
import { CustomPropertyType } from '../../../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component';
import { MapDialogComponent } from '../../../dave-utils-module/dave-shared-components-module/components/dialogs/map-dialog/map-dialog.component';
import { CommissionNameService } from '../../../dave-utils-module/dave-shared-components-module/services/commission-name.service';
import { CustomerNameService } from '../../../dave-utils-module/dave-shared-components-module/services/customer-name.service';
import { PermissionService } from '../../../dave-utils-module/dave-shared-components-module/services/permission.service';
import { RoutingHistoryService } from '../../../dave-utils-module/dave-shared-components-module/services/routing-history.service';
import { SelectSearchData } from '../../../dave-utils-module/select-search/components/select-search-legacy/select-search-legacy.component';
import { Address, DaveHeaderHeight, getAddressString, isNotNullOrUndefined, Mehrwertsteuer, removeFromViewStyle, selectViewStyleSetting } from '../../../helper/helper';
import {
    AllCommissionMeta,
    AllEventsMeta,
    AllGeneratedDocumentsMeta,
    AllReportsMeta,
    BookingPositionMetaIcon,
    CommissionMeta,
    ConstructionDiaryIcon,
    ContactBookMeta,
    CustomerAdministrationMeta,
    DMSPageMeta,
    DocumentEditorMeta,
    DocumentEditorPageMeta,
    GeneratedDocumentsPageMeta,
    HistoryMeta,
    InvoiceEditorMeta,
    PersonMeta,
    PieceworkCalculationPageMeta,
    ReportsPageMeta,
    StatisticsPageMeta,
} from '../../../helper/page-metadata';
import { CustomLabelService } from '../../../services/custom-label.service';
import { FilterTypes } from '../../../services/default-filter.service';
import { LoadingService } from '../../../services/loading.service';
import { NewCallComponent, NewCallComponentDialogData } from '../new-call/new-call.component';
import { NewEventAndTransmissionComponent, NewEventAndTransmissionComponentDialogData } from '../new-event-and-transmission/new-event-and-transmission.component';
import { NewOnSiteEventComponent, NewOnSiteEventComponentDialogData } from '../new-on-site-event/new-on-site-event.component';
import { AccountsReceivableLedgerResolver } from './../../../dave-data-module/guards/accounts-receivable-ledger.resolver';
import { LedgerImportResolver } from './../../../dave-data-module/guards/ledger-import.resolver';
import { ConstructionDiaryActionTypes } from './../../../dave-data-module/State/actions/construction-diary.actions';
import { getConstructionDiaries } from './../../../dave-data-module/State/selectors/construction-diary.selectors';
import { getViewStyleSetting } from './../../../dave-data-module/State/selectors/viewStyleSetting.selector';
import { sameDay } from './../../../helper/helper';
import { AddEventDialogComponent, AppAddEventDialogData, AppAddEventDialogDataReturn } from './add-event-dialog/add-event-dialog.component';
import {
    createCommissionFormGroup,
    getCommissionFormAddressSearchSubscriptions,
    getCommissionFormSubscriptions, getCommissionLocationOptions,
    ICommissionDetailListTemplateEntrys,
    resetCommissionFormGroup,
    saveCommission,
    setCommissionDetailListTemplateEntries,
} from './helper';
import { SelectDiaryTimespanDialogComponent, SelectDiaryTimespanDialogComponentDialogData } from './select-diary-timespan-dialog/select-diary-timespan-dialog.component';
import { SelectPartnerComponent } from './select-partner/select-partner.component';
import { EntityType } from '@dave/types';

import {
    DetailListDialogReturn,
    DetailListTemplateDialogComponent, DetailListTemplateDialogData
} from "../../../detail-list-template-dialog/components/detail-list-template-dialog.component";
import { CustomFieldTypesNamesMap } from "../../../custom-form-fields/custom-form-fields.helper";
import {
    ResourceScheduleActionTypes
} from "../../../dave-data-module/State/actions/resource-dispo/resource-schedule.actions";
import {
    EmployeeResourceScheduleActionTypes
} from "../../../dave-data-module/State/actions/resource-dispo/employee-resource-schedule.actions";
import { DiaryDataService } from "../../../services/diary-data.service";
import { Permission } from '../../../helper/permission.helper';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { StatusFromBackofficeEntity } from '../../../dave-data-module/entities/statusFromBackoffice.entity';
import {
    NewPartialInvoiceComponent,
    NewPartialInvoiceComponentDialogData, NewPartialInvoiceComponentDialogReturnData,
} from '../../../dave-reports/components/new-partial-invoice/new-partial-invoice.component';
import {
    NewClosingInvoiceComponent, NewClosingInvoiceComponentDialogData, NewClosingInvoiceComponentDialogReturnData,
} from '../../../dave-reports/components/new-closing-invoice/new-closing-invoice.component';
function sortViewStyleConfig(conf: ViewStyleConfig[]): ViewStyleConfig[] {
    return conf
        .slice()
        .sort((a, b) => a.order - b.order)
        .map((c) => {
            if (c.elements) {
                return { ...c, elements: sortViewStyleConfig(c.elements) };
            }
            return c;
        });
}

export function getCommissionEmailHtml(commission: CommissionEntity, commissionLabels: { Nominativ: { singular: { value: string } }; wordBinding: string }, versicherungsnehmer?: PersonEntity) {
    const tabledata: { key: string; value: string }[] = [];
    tabledata.push({ key: commissionLabels.wordBinding + 'Nr', value: commission.Auftragsnummer });
    tabledata.push({ key: 'InterneNr', value: commission.InterneNummer });
    tabledata.push({ key: 'SchadenNr', value: commission.Schadennummer });
    let versicherungsNehmerHtml = '';
    if (versicherungsnehmer) {
        versicherungsNehmerHtml += '<h3>Versicherungsnehmer</h3><p>';
        versicherungsNehmerHtml += versicherungsnehmer.Firstname || versicherungsnehmer.Lastname ? `${(versicherungsnehmer.Firstname || '') + ' ' + (versicherungsnehmer.Lastname || '')}</br>` : '';
        versicherungsNehmerHtml += versicherungsnehmer.Street ? `${versicherungsnehmer.Street}</br>` : '';
        versicherungsNehmerHtml += versicherungsnehmer.PostalCode || versicherungsnehmer.City ? `${(versicherungsnehmer.PostalCode || '') + ' ' + (versicherungsnehmer.City || '')}</br>` : '';
        versicherungsNehmerHtml += versicherungsnehmer.PhoneNumber ? `Tel: ${versicherungsnehmer.PhoneNumber}</br>` : '';
        versicherungsNehmerHtml += versicherungsnehmer.Email ? `E-Mail: ${versicherungsnehmer.Email}</br>` : '';
        versicherungsNehmerHtml += '</p>';
    }
    return `<h3>${commissionLabels.Nominativ.singular.value}</h3><p>${tabledata
        .filter((t) => t.value)
        .map((t) => `${t.key}: ${t.value}</br>`)
        .join('')}</p>
${versicherungsNehmerHtml}
`;
}

@Component({
    selector: 'app-commission',
    templateUrl: './detail-commission.component.html',
    styleUrls: ['./detail-commission.component.scss'],
    providers: [CurrencyPipe, DecimalPipe, DatePipe],
})
export class DetailCommissionComponent implements OnInit, OnDestroy, ComponentCanDeactivate {
    @ViewChild('commissionOverviewTemplate') private commissionOverviewTemplate;
    @ViewChild('detailListTemplateCommission') private detailListTemplateCommissionTemplate;
    @ViewChild('group') private groupTemplate;
    @ViewChild('shiftPlanTemplate') private shiftPlanTemplate;
    @ViewChild('resourcePlanTemplate') private resourcePlanTemplate;
    @ViewChild('taskTemplate') private taskTemplate;
    @ViewChild('fileExplorerTemplate') private fileExplorerTemplate;
    @ViewChild('eventListTemplate') private eventListTemplate;
    @ViewChild('personListTemplate') private personListTemplate;
    @ViewChild('bookingPositionListTemplate') private bookingPositionListTemplate;
    // @ViewChild('invoiceListTemplate') private invoiceListTemplate;
    // @ViewChild('offerListTemplate') private offerListTemplate;
    @ViewChild('ledgerImportListTemplate') private ledgerImportListTemplate;
    @ViewChild('bookedBookingPositionListTemplate') private bookedBookingPositionListTemplate;
    @ViewChild('hiddenBookingPositionListTemplate') private hiddenBookingPositionListTemplate;
    @ViewChild('judgeListTemplate') private judgeListTemplate;
    @ViewChild('reportsListDiary') private reportsListDiary;
    @ViewChild('reportsListZusatz') private reportsListZusatz;
    @ViewChild('reportsListBedenken') private reportsListBedenken;
    @ViewChild('reportsListMangel') private reportsListMangel;
    @ViewChild('reportsListOther') private reportsListOther;
    @ViewChild('klaegerListTemplate') private klaegerListTemplate;
    @ViewChild('beklagteListTemplate') private beklagteListTemplate;
    @ViewChild('selectedPartnerListTemplate') private selectedPartnerListTemplate;
    @ViewChild('selectedUsersListTemplate') private selectedUsersListTemplate;
    @ViewChild('tabGroupTemplate') private tabGroupTemplate;
    @ViewChild('nachnahmenListTemplate') private nachnahmenListTemplate;
    @ViewChild('erschwernisListTemplate') private erschwernisListTemplate;
    @ViewChild('mangelanzeigeListTemplate') private mangelanzeigeListTemplate;
    @ViewChild('bedenkenanzeigeListTemplate') private bedenkenanzeigeListTemplate;
    @ViewChild('commentsTemplate') private commentsTemplate;
    @ViewChild('commissionTitleTabGroupTemplate') private commissionTitleTabGroupTemplate;
    @ViewChild('commissionTitleTabGroupTemplateTabGroup') private commissionTitleTabGroupTemplateTabGroup: MatTabGroup;
    @ViewChild('entityHistoryTemplate') private entityHistoryTemplate;
    @ViewChild('resourceListTemplate') private resourceListTemplate;
    @ViewChild('resourceScheduleListeTemplate') private resourceScheduleListeTemplate;
    @ViewChild('materialListTemplate') private materialListTemplate;
    @ViewChild('groupListTemplate') private groupListTemplate;
    @ViewChild('childCommissionsTemplate') private childCommissionsTemplate;
    @ViewChild('reportsTabGroupTemplate') private reportsTabGroupTemplate;
    @ViewChild('CommissionBusinessVolumeList') private CommissionBusinessVolumeList;
    @ViewChild('blueprintTemplate') private blueprintTemplate;
    @ViewChild('shiftPlanComponent') private shiftplan: ShiftPlanComponent;
    @ViewChild('resourcePlanComponent') private resourceplan: ResourcePlanComponent;
    public EntityType = EntityType;
    public CommentEntityTypeEnum = CommentEntityTypeEnum;
    public HeaderHeight = DaveHeaderHeight;
    public PersonMeta = PersonMeta;
    public EreignissCount: number = 0;
    public ConstructionDiaryIcon = ConstructionDiaryIcon;
    public LedgerImportDocumentTypes = LedgerImportDocumentTypes;
    public BookmarkList: BookmarkEntity[] = null;
    public PersonList: PersonEntity[] = null;
    public ShownButtons = true;
    public ShiftPlanActiveView$ = new BehaviorSubject<ShiftplanViews>('Timeline3Month');
    public ResourcePlanActiveView$ = new BehaviorSubject<ShiftplanViews>('Timeline3Month');
    public OpenShiftPlan$ = new Subject<void>();
    public OpenResourcePlan$ = new Subject<void>();
    public NachnahmeType$ = this.store.select(getEventTypes).pipe(map((ets) => ets?.find((e) => e.Name === EventTypeNamesEnum.Zusatzarbeit)));
    public MangelType$ = this.store.select(getEventTypes).pipe(map((ets) => ets?.find((e) => e.Name === EventTypeNamesEnum.Erschwernis)));
    public MangelanzeigeType$ = this.store.select(getEventTypes).pipe(map((ets) => ets?.find((e) => e.Name === EventTypeNamesEnum.Mangelanzeige)));
    public BedenkenanzeigeType$ = this.store.select(getEventTypes).pipe(map((ets) => ets?.find((e) => e.Name === EventTypeNamesEnum.Bedenkenanzeige)));
    public AdditionalShiftPlanButtons = [
        {
            icon: 'arrows-alt-h' as IconProp,
            iconTransform: { rotate: 135 },
            onClick: (clickEvent) => this.OpenShiftPlan$.next(),
            tooltip: 'vergrößern',
        },
    ];
    public AdditionalResourcePlanButtons = [
        {
            icon: 'arrows-alt-h' as IconProp,
            iconTransform: { rotate: 135 },
            onClick: (clickEvent) => this.OpenResourcePlan$.next(),
            tooltip: 'vergrößern',
        },
    ];
    private fileDialog: MatDialogRef<DaveFileUploadDialogComponent>;
    private onInitTimestamp: Date;
    public TaskBoardSort: ITaskBoardSort = { sortBy: 'endDate', direction: 'dsc' };

    constructor(
        private appDialog: AppDialogService,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private store: Store<State>,
        private activatedRoute: ActivatedRoute,
        private routingHistoryService: RoutingHistoryService,
        private router: Router,
        private route: ActivatedRoute,
        public CS: CustomerNameService,
        private personResolver: PersonResolver,
        private currencyPipe: CurrencyPipe,
        private decimalPipe: DecimalPipe,
        private datePipe: DatePipe,
        public PS: PermissionService,
        ledgerImportResolver: LedgerImportResolver,
        arlResolver: AccountsReceivableLedgerResolver,
        public LS: LoadingService,
        private actions$: Actions,
        public CNS: CommissionNameService,
        private cdr: ChangeDetectorRef,
        protected cls: CustomLabelService,
        private diaryService: DiaryDataService,
        private folderDataService: FolderDataService
    ) {
        this.subscriptions.push(
            // this.actions$.pipe(ofType(CommissionActionTypes.UpdateMany, CommissionActionTypes.UpdateOne)).subscribe((action) => {
            //     if (action.type === CommissionActionTypes.UpdateMany.type && action.Payload.length === 1) {
            //         this.setCommissionSeen(action.Payload[0].Id);
            //     } else if (action.type === CommissionActionTypes.UpdateOne.type) {
            //         this.setCommissionSeen(action.Payload.Id);
            //     }
            // }),
            ...getCommissionFormAddressSearchSubscriptions(this.CommissionForm, this.store, this.CommissionLocationForm, this.cdr),
        );
        this.bookmarks$ = store.select(getBookmarks);
        this.persons$ = store.select(getPersons);
        PS.Has(Permission.GetLedgerImports)
            .pipe(filter(isNotNullOrUndefined), take(1))
            .subscribe((p) => {
                if (p) {
                    ledgerImportResolver.resolve();
                }
            });
        PS.Has(Permission.GetAccountsReceivableLedgers)
            .pipe(filter(isNotNullOrUndefined), take(1))
            .subscribe((p) => {
                if (p) {
                    arlResolver.resolve();
                }
            });
    }
    public getCountEreignisse(event) {
        this.EreignissCount = event;
    }
    public DetailListTemplateEntrys: ICommissionDetailListTemplateEntrys = {};

    public StatusData: Observable<IDetailListTemplateData>;
    public ShowStatus = false;
    public CommentFileUploadParams$: Observable<FileUploadParams>;
    public UploadFolderId: number;
    public InvoiceEditorMeta = InvoiceEditorMeta;
    public BookingPositionMetaIcon = BookingPositionMetaIcon;
    public statusDropdown = new FormControl<number>(null);
    public SearchLocationForm = new FormControl<SelectSearchData>(null);
    public CommissionForm = createCommissionFormGroup();
    public ChronikPath = HistoryMeta.Path;
    public ChronikIcon = HistoryMeta.Icon;
    public StatisticPath = StatisticsPageMeta.Path;
    public DMSPath = DMSPageMeta.Path;
    public DMSIcon = DMSPageMeta.Icon;
    public CustomerPath = CustomerAdministrationMeta.Path;
    public AkkordMeta = PieceworkCalculationPageMeta;
    public CustomerIcon = CustomerAdministrationMeta.Icon;
    public Editing$ = new BehaviorSubject<boolean>(false);
    public Save$ = new Subject<number | undefined>();
    public SaveStatus$ = new Subject<void>();
    public AllEventsMetaPath = AllEventsMeta.Path;
    public SharedCommissions$ = this.store.select(getSharedCommissions);
    public Partner$ = this.store.select(getPartner);
    protected isTsb$ = this.Partner$.pipe(map(p => p.PartnerTypeId === PartnerTypeEnum.TSB));
    protected childCommissionColumns$: Observable<Array<keyof CommissionEntity | 'status' | 'createdAt'>> = this.isTsb$.pipe(map(isTsb => isTsb ? [
        'InterneNummer',
        'Description',
        'createdAt',
        'status',
    ] : [
        'Auftragsnummer',
        'InterneNummer',
        'Description',
        'createdAt',
        'status',
    ]));
    public Commission$: Observable<CommissionEntity> = this.activatedRoute.paramMap.pipe(
        map((paramMap) => +paramMap.get('commissionId')),
        distinctUntilChanged(),
        switchMap((commissionId) => this.store.select(getCommissionById({ id: commissionId }))),
        tap((commission) => {
            if (!commission) {
                console.error('Could not find CommissionEntity');
            }
        }),
        filter(isNotNullOrUndefined),
        tap(() => this.cdr.detectChanges()), // ugly fix, repariert das in left und right content vom app-detail-view-template beim start was drin steht
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    protected showWizard$ = this.Commission$.pipe(
        switchMap(c => isNotNullOrUndefined(c.AdditionalData?.wizardPage) ? this.store.select(getCommissionTypeById({id: c.CommissionTypeId})).pipe(
            map(ct => ct.AdditionalData?.WizardSteps ? ct.AdditionalData.WizardSteps.length >= c.AdditionalData.wizardPage : true)) : of(false)
        )
    );
    public ChildCommissions$: Observable<MatTableDataSource<DaveListCardcolumnProperties<Partial<CommissionEntity>>>> = this.Commission$.pipe(
        distinctUntilChanged((a, b) => a.Id === b.Id),
        switchMap((c) =>
            combineLatest([this.store.select(getCommissions), this.store.select(getStatusFromBackoffice)]).pipe(
                map(([commissions, backofficeStates]) =>
                    commissions
                        .filter((commission) => commission.ParentId === c.Id)
                        .map((c) => {
                            const StatusBackoffice: StatusFromBackofficeEntity = backofficeStates.find((s) => s.Id === c.StatusBackofficeId);
                            return {
                                ...c,
                                status: StatusBackoffice.Name,
                                createdAt: formatDate(c.CreatedAt, 'mediumDate',  'de-DE'),
                                cssStyle: `--app-clickable-background-color: ${c.GetBackgroundColor(StatusBackoffice?.IsCompleted, StatusBackoffice?.Color)};`,
                                cssClass: 'app-clickable-background',
                            };
                        }),
                ),
            ),
        ),
        distinctUntilChanged((a, b) => a.length === b.length && a.every((v, i) => v.Id === b[i].Id && v.UpdatedAt.getTime() === b[i].UpdatedAt.getTime())),
        map((commissions) => new MatTableDataSource(commissions)),
    );
    public GetRouteForCommissionImport = ({ Id }) => ['/', CommissionMeta.Path, AllCommissionMeta.Path, Id];
    public CommissionAddress$ = this.Commission$.pipe(map((c) => getAddressString(c)));
    public CommissionMeta = CommissionMeta;
    private eventsByType$ = (eventTypeName: EventTypeNamesEnum) =>
        of(new MatTableDataSource([])).pipe(
            switchMap((tableDataSource) =>
                combineLatest([this.store.select(getEventsSortedByCreatedAt), this.Commission$, this.store.select(getEventTypes), this.store.select(getGeneratedDocuments)]).pipe(
                    filter((v) => v.every(isNotNullOrUndefined)),
                    map(([events, commission, eventTypes, genDocs]) => {
                        const mangelType = eventTypes.find((e) => e.Name === eventTypeName);
                        const canBeExported: string[] = [EventTypeNamesEnum.Mangelanzeige];
                        return events
                            .filter((e) => e.EventTypeId === mangelType.Id && e.CommissionId === commission.Id)
                            .map<DaveListCardcolumnProperties<EventEntity>>((e) => {
                                const docs = genDocs.filter((gd) => gd.AdditionalData?.ConstructionDiary?.EventIds?.includes(e.Id));
                                return {
                                    ...e,
                                    cssClass: e.State ? 'event-list-entry-custom-state-style ' + (EventStateEnumColorMap.get(e.State) || '') : '',
                                    suffixButton: docs.length
                                        ? {
                                              icon: GeneratedDocumentsPageMeta.Icon,
                                              tooltip: 'Bericht anzeigen',
                                              onClick: (v) => {
                                                  if (docs.length === 1) {
                                                      this.dialog.open<DocumentEditorDialogComponent, DocumentEditorDialogComponentDialogData, DocumentEditorDialogComponentCloseData>(DocumentEditorDialogComponent, {
                                                          ...DocumentEditorDialogComponent.DefaultConfig,
                                                          data: {
                                                              generatedDocumentId: docs[0].Id,
                                                          },
                                                      });
                                                  } else {
                                                      const dialogRef = this.dialog.open<ListCardDialogComponent<GeneratedDocumentsEntity>, ListCardDialogComponentDialogData<GeneratedDocumentsEntity>>(ListCardDialogComponent, {
                                                          ...ListCardDialogComponent.DefaultConfig,
                                                          data: {
                                                              Headline: 'Export auswählen',
                                                              EmptyText: 'Keine Exporte vorhanden',
                                                              Data: docs,
                                                              DisplayedCollums: ['DisplayName'],
                                                              Clickable: true,
                                                              HasSuffixButton: false,
                                                          },
                                                      });
                                                      dialogRef.componentInstance.EntryClicked$.pipe(takeUntil(dialogRef.afterClosed())).subscribe((gd) => {
                                                          dialogRef.close();
                                                          this.dialog.open<DocumentEditorDialogComponent, DocumentEditorDialogComponentDialogData, DocumentEditorDialogComponentCloseData>(DocumentEditorDialogComponent, {
                                                              ...DocumentEditorDialogComponent.DefaultConfig,
                                                              data: {
                                                                  generatedDocumentId: gd.entry.Id,
                                                              },
                                                          });
                                                      });
                                                  }
                                              },
                                          }
                                        : canBeExported.includes(mangelType.Name) && mangelType.GeneratedDocumentsTypeSlugsForDiary ? {
                                            icon: GeneratedDocumentsPageMeta.Icon,
                                            icon2: 'plus',
                                            tooltip: 'Bericht hinzufügen',
                                            onClick: (e) => {
                                                const lsKey = 'generate-event-export--'+e.Id;
                                                this.LS.startLoading(lsKey);
                                                this.diaryService.generateEvents(mangelType.GeneratedDocumentsTypeSlugsForDiary, [e.Id]).then(res => {
                                                    if (res?.length === 1) {
                                                        this.dialog.open<DocumentEditorDialogComponent, DocumentEditorDialogComponentDialogData, DocumentEditorDialogComponentCloseData>(DocumentEditorDialogComponent, {
                                                            ...DocumentEditorDialogComponent.DefaultConfig,
                                                            data: {
                                                                generatedDocumentId: res[0].id,
                                                            },
                                                        });
                                                    }
                                                } ).finally(() => this.LS.endLoading(lsKey));
                                            }
                                        } : undefined,
                                };
                            });
                    }),
                    distinctUntilChanged((a, b) => a.length === b.length && a.every((v, i) => v === b[i])),
                    tap((events) => (tableDataSource.data = events)),
                    map(() => tableDataSource),
                    distinctUntilChanged(),
                ),
            ),
        );
    public Mangel$ = this.eventsByType$(EventTypeNamesEnum.Erschwernis);
    public Mangelanzeigen$ = this.eventsByType$(EventTypeNamesEnum.Mangelanzeige);
    public Bedenkenanzeigen$ = this.eventsByType$(EventTypeNamesEnum.Bedenkenanzeige);
    public Nachnahmen$ = this.eventsByType$(EventTypeNamesEnum.Zusatzarbeit);

    public ViewStyleConfig$: Observable<{ left: ViewStyleConfig[]; right: ViewStyleConfig[] }> = combineLatest([
        this.Commission$.pipe(
            map((c) => ({ CommissionTypeId: c.CommissionTypeId, CommissionId: c.Id })),
            distinctUntilChanged((a, b) => a.CommissionId === b.CommissionId && a.CommissionTypeId === b.CommissionTypeId),
            switchMap(({ CommissionTypeId, CommissionId }) => this.store.select(getCommissionTypeById({ id: CommissionTypeId })).pipe(map((ct) => ({ CommissionType: ct, CommissionId })))),
        ),

        this.store.select(getViewStyleSetting).pipe(
            filter(isNotNullOrUndefined),
            map((vss) => vss.filter((v) => v.View === ViewStyleSettingViewEnum.Commission)),
        ),
        combineLatest([
            this.store.select(getUserToCommissionFetched).pipe(
                filter((v) => !!v),
                switchMap(() =>
                    combineLatest([
                        this.Commission$.pipe(
                            map((c) => c.Id),
                            distinctUntilChanged(),
                        ),
                        this.store.select(getUser).pipe(
                            map((c) => c.Id),
                            distinctUntilChanged(),
                        ),
                    ]),
                ),
                switchMap(([commissionId, userId]) => this.store.select(getUserToCommissions).pipe(map((u2c) => u2c.filter((u) => u.CommissionId === commissionId && u.UserId === userId)))),
            ),
            this.store.select(getEntityRoleFetched).pipe(
                filter((v) => !!v),
                switchMap(() => this.store.select(getEntityRole)),
                map((roles) => roles.filter((r) => r.Entity === EntityTypesEnum.Commission)),
            ),
        ]).pipe(map(([u2c, roles]) => roles.filter((r) => u2c.some((u) => u.RoleId === r.Id)))),
    ]).pipe(
        map(([{ CommissionId, CommissionType }, vss, roles]) => {
            let settings = selectViewStyleSetting(vss, ViewStyleSettingViewEnum.Commission, CommissionType?.Id, CommissionId);
            const v = CommissionType?.AdditionalData?.CanHaveChildCommissions ? settings.Config.config : removeFromViewStyle(settings.Config.config, 'childCommissionsTemplate');
            const overviewRole = roles.some((r) => r.Name === EntityRoleSlugEnum.Projektleiter || r.Name === EntityRoleSlugEnum.Admin);
            return overviewRole ? v : removeFromViewStyle(v, 'commissionOverviewTemplate');
        }),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        map((conf) => {
            return {
                left: sortViewStyleConfig(conf.filter((c) => !c.right).slice()),
                right: sortViewStyleConfig(conf.filter((c) => c.right).slice()),
            };
        }),
    );
    public NavigateChronikFilter$ = this.Commission$.pipe(
        map((com) =>
            TimelineViewComponent.getFilterQueryParam({
                [FilterTypes.Commissions]: [
                    {
                        id: com.Id,
                        label: com.DisplayName,
                    },
                ],
            }),
        ),
    );
    public IsGericht$ = combineLatest([this.Commission$, this.store.select(getCommissionTypes)]).pipe(
        map(([commission, comissionTypes]) =>
            comissionTypes
                .find((ct) => ct.Id === commission.CommissionTypeId)
                ?.Name.toLowerCase()
                .includes('gericht'),
        ),
    );
    public CommissionType$ = this.Commission$.pipe(switchMap((c) => (c?.CommissionTypeId ? this.store.select(getCommissionTypeById({ id: c.CommissionTypeId })) : null)));
    public HasDiary$ = combineLatest([this.Commission$, this.store.select(getCommissionTypes)]).pipe(
        map(([commission, comissionTypes]) => !!comissionTypes.find((ct) => ct.Id === commission.CommissionTypeId)?.AdditionalData?.ConstructionDiary),
    );
    public Diarys$ = combineLatest([this.Commission$, this.store.select(getGeneratedDocumentsSortedByCreatedAt)]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([commission, docs]) => {
            return docs
                .filter((doc) => doc.CommissionId === commission.Id && doc.AdditionalData?.ConstructionDiary)
                .map((d) =>
                    Object.assign(d.Clone(), {
                        suffixButton: d.DocumentIdFromPdf
                            ? {
                                  icon: DMSPageMeta.Icon,
                                  tooltip: 'Zum Dokument',
                                  onClick: (args: any) => {
                                      this.PreviewedFileId$.next(d.DocumentIdFromPdf);
                                      this.PreviewOpen = true;
                                  },
                              }
                            : null,
                    }),
                );
        }),
    );
    public Versicherungsnehmer$: Observable<PersonEntity> = combineLatest([this.store.select(getPersons), this.store.select(getComplaintTypes), this.store.select(getPerson2Entities), this.Commission$]).pipe(
        map(([persons, complaintTypes, p2e, commission]) => {
            const personTypeId = complaintTypes.find((ct) => ct.Name.toLowerCase().includes('versicherungsnehmer'))?.Id;
            const pId = p2e?.find((p) => p.EntityType === Person2EntityEntityTypeEnum.Commission && p.EntityId === commission.Id && personTypeId === p.ComplaintTypeId)?.PersonId;
            return persons.find((p) => p.Id === pId);
            // return persons?.find(p => p.CommissionId === commission.Id && p.PersonTypeId === personTypeId);
        }),
    );
    public ShowBeteiligteList$: Observable<PersonEntity[]> = combineLatest([
        this.store.select(getPersons),
        this.store.select(getFilterdPersonTypesForBeteiligtePersonen),
        this.Commission$,
        this.store.select(getComplaintTypes),
        this.store.select(getPerson2Entities),
    ]).pipe(
        map(([persons, personTypes, commission, complaintTypes, p2e]) => {
            // hier muss auf getFilterdPersonTypesForBeteiligtePersonen geprüft werden, es kann nicht auf den complaint type geprüft werden da der nicht immer gesetzt ist
            const complainTypeId = complaintTypes.find((ct) => ct.Name.toLowerCase().includes('beteiligte person'))?.Id;
            const pIds = p2e?.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Commission && p.EntityId === commission.Id && p.ComplaintTypeId === complainTypeId).map((p) => p.PersonId);
            return persons?.filter((p) => pIds.includes(p.Id) && !p.Deleted);
        }),
    );
    public ShownJudgeList$: Observable<PersonEntity[]> = combineLatest([this.store.select(getPersons), this.Commission$, this.store.select(getComplaintTypes), this.store.select(getPerson2Entities)]).pipe(
        map(([persons, commission, complaintTypes, p2e]) => {
            // hier muss auf getFilterdPersonTypesForBeteiligtePersonen geprüft werden, es kann nicht auf den complaint type geprüft werden da der nicht immer gesetzt ist
            const complainTypeId = complaintTypes.find((ct) => ct.Name.toLowerCase().includes('vorsitzender'))?.Id;
            const pIds = p2e?.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Commission && p.EntityId === commission.Id && p.ComplaintTypeId === complainTypeId).map((p) => p.PersonId);
            return persons?.filter((p) => pIds.includes(p.Id) && !p.Deleted);
        }),
    );
    //     = combineLatest([this.store.select(getPersons), this.store.select(getPersonTypes), this.Commission$, this.store.select(getPerson2Entities)]).pipe(
    //     map(([persons, personTypes, commission, p2es]) => {
    //         const pId: Person2EntityEntity[] = p2es?.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Commission && p.EntityId === commission.Id) || [];
    //         return persons?.filter(
    //             (p) =>
    //                 pId.some(p2e => p.Id === p2e.PersonId) &&
    //                 personTypes
    //                     .find((pt) => pt.Id === p.PersonTypeId)
    //                     ?.Name.toLowerCase()
    //                     .includes('vorsitzender'),
    //         );
    //     }),
    // );

    public ShowGenFilesList$ /*: Observable<{ type: GeneratedDocumentsTypeEntity; Name: string; Id: number; date: string; label: string;  }[]>*/ = combineLatest([
        this.store.select(getGeneratedDocumentsSortedByCreatedAt),
        this.store.select(getGeneratedDocumentsTypes),
        this.Commission$,
    ]).pipe(
        filter((v) => v.every(isNotNullOrUndefined)),
        map(([genFiles, genFilesType, commission]) => {
            const ret = genFiles
                .filter((e) => e.CommissionId === commission.Id)
                .map((e) => ({
                    type: genFilesType?.find((gft) => gft.Id === e.GeneratedDocumentsTypeId),
                    date: formatDate(e.CreatedAt, 'shortDate', 'de-DE'),
                    Name: e.Name || 'Platzhalter',
                    Id: e.Id,
                    suffixButton: e.DocumentIdFromPdf
                        ? {
                              icon: DMSPageMeta.Icon,
                              tooltip: 'Zum Dokument',
                              onClick: (args: any) => {
                                  this.PreviewedFileId$.next(e.DocumentIdFromPdf);
                                  this.PreviewOpen = true;
                              },
                          }
                        : null,
                }))
                .map((v) => Object.assign(v, { label: `${v.type?.Name || 'Typ'} ${v.Name} ${v.date}` }));
            const diaryReports = [],
                zusatzReports = [],
                bedenkenReports = [],
                mangelReports = [],
                otherReports = [];
            ret.forEach((e) => {
                if (e.type?.Slug === GeneratedDocumentsTypeSlugs.constructionDiary) {
                    diaryReports.push(e);
                } else if (e.type?.Slug === GeneratedDocumentsTypeSlugs.zusatzarbeit) {
                    zusatzReports.push(e);
                } else if (e.type?.Slug === GeneratedDocumentsTypeSlugs.bedenken) {
                    bedenkenReports.push(e);
                } else if (e.type?.Slug === GeneratedDocumentsTypeSlugs.mangel) {
                    mangelReports.push(e);
                } else {
                    otherReports.push(e);
                }
            });
            return {
                diaryReports,
                zusatzReports,
                bedenkenReports,
                mangelReports,
                otherReports,
            };
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    DiaryReports$ = this.ShowGenFilesList$.pipe(map((v) => v.diaryReports));
    ZusatzReports$ = this.ShowGenFilesList$.pipe(map((v) => v.zusatzReports));
    BedenkenReports$ = this.ShowGenFilesList$.pipe(map((v) => v.bedenkenReports));
    MangelReports$ = this.ShowGenFilesList$.pipe(map((v) => v.mangelReports));
    OtherReports$ = this.ShowGenFilesList$.pipe(map((v) => v.otherReports));
    public ShowKlaeger$ = combineLatest([this.store.select(getPersons), this.store.select(getComplaintTypes), this.Commission$, this.store.select(getPerson2Entities)]).pipe(
        map(([persons, ctypes, commission, p2e]) => {
            const complainTypeId = ctypes.find((ct) => ct.Name.toLowerCase().match('klagender'))?.Id;
            const pIds = p2e?.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Commission && p.EntityId === commission.Id && p.ComplaintTypeId === complainTypeId).map((p) => p.PersonId);

            return persons?.filter((p) => pIds.includes(p.Id) && !p.Deleted);
        }),
    );
    public ShowBeklagte$: Observable<PersonEntity[]> = combineLatest([this.store.select(getPersons), this.store.select(getComplaintTypes), this.Commission$, this.store.select(getPerson2Entities)]).pipe(
        map(([persons, ctypes, commission, p2e]) => {
            const complainTypeId = ctypes.find((ct) => ct.Name.toLowerCase().match('beklagter'))?.Id;
            const pIds = p2e?.filter((p) => p.EntityType === Person2EntityEntityTypeEnum.Commission && p.EntityId === commission.Id && p.ComplaintTypeId === complainTypeId).map((p) => p.PersonId);
            return persons?.filter((p) => pIds.includes(p.Id) && !p.Deleted);
        }),
    );
    public ShowSelectedPartners$ = combineLatest([this.store.select(getSharedCommissions), this.store.select(getPartners), this.Commission$]).pipe(
        map(([sharedCommissions, partners, commission]) =>
            partners?.filter((p) => isNotNullOrUndefined(sharedCommissions?.filter((sC) => sC.CommissionId === commission.Id)?.find((sC) => sC.PartnerLinkedId === p.Id))).filter((p) => p !== undefined),
        ),
    );
    public Folder$ = this.Commission$.pipe(
        distinctUntilChanged((a, b) => a.Id === b.Id),
        switchMap(commission => this.folderDataService.getFolderFromEntity(commission.Id, FolderTypes.commission)),
        filter(isNotNullOrUndefined),
        shareReplay({refCount:true, bufferSize: 1})
    );
    public FolderId$: Observable<number> = this.Folder$.pipe(
        filter(isNotNullOrUndefined),
        map(folder => folder.Id),
        distinctUntilChanged(),
    );
    private userToCommissionFromCommission$ = this.Commission$.pipe(
        switchMap((c) => this.store.select(getUserToCommissions).pipe(map((u2cs) => u2cs.filter((u2c) => u2c.CommissionId === c.Id)))),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public IamAssignee$ = combineLatest([this.userToCommissionFromCommission$, this.store.select(getUser)]).pipe(map(([u2cs, user]) => u2cs.some((u2c) => u2c.UserId === user.Id && u2c.Assignee)));
    public CommissionEntityRoles$ = combineLatest([this.userToCommissionFromCommission$, this.store.select(getUser)]).pipe(
        map(([u2cs, user]) => u2cs?.filter((u) => u.UserId === user.Id)),
        switchMap((u2c) => this.store.select(getEntityRoleFetched).pipe(
            filter((v) => !!v),
            switchMap(() => this.store.select(getEntityRole)),
            map((er) => er.filter((e) => u2c.some((u) => u.RoleId === e.Id))))),
    );
    public CommissionEntityRolePermissions$ = this.CommissionEntityRoles$.pipe(
        map((er) => er?.map((e) => e?.Permissions).flat()),
        shareReplay(),
    );
    public DisableStatusButton$ = this.CommissionEntityRolePermissions$.pipe(map((permissions) => !permissions?.some((p) => p.name === EntityRolePermissionNameEnum.GetStatusButtonCommission)));
    public DisableMailButton$ = this.CommissionEntityRolePermissions$.pipe(map((permissions) => !permissions?.some((p) => p.name === EntityRolePermissionNameEnum.GetSendEmailButtonCommission)));
    public DisableAddEventButton$ = this.CommissionEntityRolePermissions$.pipe(map((permissions) => !permissions?.some((p) => p.name === EntityRolePermissionNameEnum.GetAddEventButtonCommission)));
    public CurrentUser$ = this.store.select(getUser)
    public ShowSelectedUsers$ = combineLatest([this.store.select(getEmployeesSortedByNameStartWithMe), this.Commission$, this.store.select(getEntityRole), this.userToCommissionFromCommission$]).pipe(
        map(([employees, commission, er, u2c]) => {
            return employees
                .filter((e) => u2c.some((u) => u.UserId === e.UserId))
                .map((e) => {
                    return {
                        User: e,
                        Assignee: u2c.some((u) => u.UserId === e.UserId && u.Assignee),
                        roles: u2c
                            .filter((u2) => u2.UserId === e.UserId && commission.Id === u2.CommissionId)
                            .map((item) => {
                                return er?.find((x) => x?.Id === item?.RoleId)?.DisplayName;
                            }),
                    };
                });
        }),
    );
    public Customers$ = this.store.select(getNotDeletedCustomers);
    public NetworkPartners$ = this.store.select(getPartners);
    public VersicherungsData$ = combineLatest([this.Commission$]).pipe(
        map(
            ([commission]): IDetailListTemplateData => ({
                HeaderIcon: 'university' as IconProp,
                Headline: 'Versicherungsdaten',
                Properties: [
                    {
                        key: 'Name',
                        value: commission.VersicherungsName,
                        formControl: this.CommissionForm.controls.VersicherungsName,
                    },
                    {
                        key: 'Zusatz',
                        value: commission.VersicherungsZusatzName,
                        formControl: this.CommissionForm.controls.VersicherungsZusatzName,
                    },
                    {
                        key: 'Arbeitsgebiet',
                        value: commission.VersicherungsArbeitsgebiet,
                        formControl: this.CommissionForm.controls.VersicherungsArbeitsgebiet,
                    },
                    {
                        key: 'Freitext',
                        value: commission.VersicherungsDescription,
                        formControl: this.CommissionForm.controls.VersicherungsDescription,
                    },
                    {
                        key: 'Telefonnummer',
                        value: commission.VersicherungsPhoneNumber,
                        formControl: this.CommissionForm.controls.VersicherungsPhoneNumber,
                    },
                    {
                        key: 'Mobil',
                        value: commission.VersicherungsMobileNumber,
                        formControl: this.CommissionForm.controls.VersicherungsMobileNumber,
                    },
                    {
                        key: 'Straße',
                        value: commission.VersicherungsStreet,
                        formControl: this.CommissionForm.controls.VersicherungsStreet,
                    },
                    {
                        key: 'PLZ',
                        value: commission.VersicherungsPostalCode,
                        formControl: this.CommissionForm.controls.VersicherungsPostalCode,
                    },
                    {
                        key: 'Stadt',
                        value: commission.VersicherungsCity,
                        formControl: this.CommissionForm.controls.VersicherungsCity,
                    },
                ],
            }),
        ),
    );
    private onInit$ = new BehaviorSubject(false);
    public IsBauAuftrag$ = combineLatest([merge(this.Commission$.pipe(map((commission) => ({ Id: commission.CommissionTypeId }))), this.CommissionForm.controls.CommissionTypeId.valueChanges), this.store.select(getCommissionTypes)]).pipe(
        map(([commissionType, commissionTypes]) => commissionType?.Id && commissionType.Id === commissionTypes.find((cT) => cT?.Name === CommissionTypeNameEnum.Bauprojekt)?.Id),
        distinctUntilChanged(),
    );
    public CommissionSubData = combineLatest([this.Commission$, this.store.select(getStatusFromBackoffice), this.store.select(getStatusFromSV), this.IsBauAuftrag$, this.Partner$, this.cls.getLabels$('Commission'), this.CommissionEntityRoles$]).pipe(
        map(([commission, backofficeStatus, statusSV, isBauAuftrag, partner, commissionLabels, entityRoles]): IDetailListTemplateData => {
            // 1#####
            setCommissionDetailListTemplateEntries(
                commission,
                this.DetailListTemplateEntrys,
                ['AbgabeterminAG', 'AbgabeterminQM', 'LetzteFrist', 'NachlassZulage', 'VorarbeiterBonus', 'CostCenter'],
                this.CommissionForm,
                entityRoles,
                {},
                commissionLabels.Nominativ.singular.value,
            );
            const Properties = [
                this.DetailListTemplateEntrys.AbgabeterminAG,
                this.DetailListTemplateEntrys.AbgabeterminQM,
                {
                    value: commission.CreatedAt,
                    key: 'Erstellt am',
                    options: { type: CustomPropertyType.Datum },
                },
                this.DetailListTemplateEntrys.LetzteFrist,
                {
                    value: commission?.StatusBackofficeId !== null ? backofficeStatus?.find((bs) => bs.Id === commission?.StatusBackofficeId)?.Name : null,
                    key: 'Status Backoffice',
                    formControl: this.CommissionForm.controls.StatusBackofficeId,
                    options: {
                        specialInput: {
                            selectSearch: backofficeStatus?.map((b) => ({
                                optionValue: b.Id,
                                optionLabel: b.Name,
                            })),
                        },
                    },
                },
                this.DetailListTemplateEntrys.CostCenter,
            ];
            if (isBauAuftrag) {
                Properties.push(this.DetailListTemplateEntrys.VorarbeiterBonus, this.DetailListTemplateEntrys.NachlassZulage);
            }
            if (partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser) {
                Properties.push(
                    {
                        value: commission?.StatusfromSVId !== null ? statusSV?.find((ssv) => ssv.Id === commission?.StatusfromSVId).Name : null,
                        key: 'Status SV',
                        formControl: this.CommissionForm.get('StatusfromSVId'),
                        options: {
                            specialInput: {
                                selectSearch: statusSV?.map((c) => ({
                                    optionValue: c.Id,
                                    optionLabel: c.Name,
                                })),
                            },
                        },
                    },
                    {
                        value: commission.Versicherungsbedingung,
                        key: 'Versicherungsbedingung',
                        formControl: this.CommissionForm.get('Versicherungsbedingung'),
                    },
                );
            }
            return {
                HeaderIcon: 'file-contract' as IconProp,
                Headline: commissionLabels.wordBinding + 'daten',
                Properties: Properties.filter(isNotNullOrUndefined),
            };
        }),
    );
    public DamageFlowData$ = new Observable<IDetailListTemplateData>();
    public Title: string;
    public DamageFlowLocationForm = this.fb.group({
        Street: new FormControl<string | null>(null),
        PostalCode: new FormControl<string | null>(null),
        City: new FormControl<string | null>(null),
        Country: new FormControl<string | null>(null),
    });
    public CommissionLocationForm = new FormControl<Address>({
        Street: '',
        PostalCode: '',
        City: '',
        Country: '',
    })
        // this.fb.group({
    //     Street: new FormControl<string | null>(null),
    //     PostalCode: new FormControl<string | null>(null),
    //     City: new FormControl<string | null>(null),
    //     Country: new FormControl<string | null>(null),
    // });
    public DamageFlowForm = this.fb.group({
        AbgabeterminBerichtReal: new FormControl<Date | null>(null),
        DeadlineVerlaengerung: new FormControl<string | null>(null),
        Description: new FormControl<string | null>(null),
        DamageFlowTypeId: new FormControl<SelectSearchData | null>(null),
        ErsterBerichtEingegangen: new FormControl<boolean | null>(null),
        ErsterOrtstermin: new FormControl<Date | null>(null),
        KontaktMitVersicherungsnehmer: new FormControl<Date | null>(null),
        ObjektiveSchadensumme: new FormControl<string | null>(null),
        QMDurchgefuehrtAm: new FormControl<Date | null>(null),
        QMStatusId: new FormControl<SelectSearchData | null>(null),
        ReportCount: new FormControl<number | null>(null),
        SchadenannahmeDurchSV: new FormControl<Date | null>(null),
        WeiterleitungAnVu: new FormControl<Date | null>(null),
    });
    public PreviewOpen = false;
    public PreviewedFileId$ = new BehaviorSubject<number | null>(null);
    public ImageIds: number[];
    public DocumentEditorPageMeta = DocumentEditorPageMeta;
    public NewBookingPositionClick$ = new Subject();
    public BookingPositions$ = combineLatest([this.route.paramMap]).pipe(
        map(([paramMap]) => +paramMap.get('commissionId')),
        switchMap((commissionId) =>
            this.store.select(getAccountsReceivableLedgers).pipe(
                filter(isNotNullOrUndefined),
                map((BookingPositions) => {
                    return BookingPositions.filter((b) => !b.ParentId && b.CommissionId === commissionId && !b.LedgerImportId);
                }),
                map((b) => {
                    return b;
                }),
            ),
        ),
    );
    public BookedBookingPositions$ = combineLatest([this.route.paramMap]).pipe(
        map(([paramMap]) => +paramMap.get('commissionId')),
        switchMap((commissionId) =>
            combineLatest([
                this.store.select(getAccountsReceivableLedgers).pipe(filter(isNotNullOrUndefined)),
                this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
                this.store.select(getLedgerImports).pipe(filter(isNotNullOrUndefined)),
            ]).pipe(
                map(([bookingPositions, quantityTypes, ledgerImports]) =>
                    bookingPositions
                        .filter((b) => b.CommissionId === commissionId && b.LedgerImportId && b.DocumentType != 'offer')
                        .map((b) => {
                            return {
                                ...b,
                                Unit: quantityTypes.find((q) => q.Id === b.QuantityTypeId)?.Name,
                                CompleteCost: this.currencyPipe.transform(NettoCost(b.Quantity, b.BaseCost), b.CurrencyCode),
                                Quantity: b.Quantity && this.decimalPipe.transform(b.Quantity),
                                InvoiceNumber: ledgerImports.find((l) => l.Id === b.LedgerImportId)?.ConsolidatedInvoiceId || '',
                                cssClass: b.GetBackgroundColorClass(),
                            };
                        })
                        .sort((a, b) => (a.CreatedAt < b.CreatedAt ? 1 : -1)),
                ),
                distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
            ),
        ),
    );
    public HiddenBookingPositions$ = combineLatest([this.route.paramMap]).pipe(
        map(([paramMap]) => +paramMap.get('commissionId')),
        switchMap((commissionId) =>
            combineLatest([
                this.store.select(getARLsWithGeneratedARLId).pipe(filter(isNotNullOrUndefined)),
                this.store.select(getQuantityTypes).pipe(filter(isNotNullOrUndefined)),
                this.store.select(getLedgerImports).pipe(filter(isNotNullOrUndefined)),
            ]).pipe(
                map(([bookingPositions, quantityTypes, ledgerImports]) =>
                    bookingPositions
                        .filter((b) => b.CommissionId === commissionId && b.DocumentType != 'offer')
                        .map((b) => {
                            return {
                                ...b,
                                Unit: quantityTypes.find((q) => q.Id === b.QuantityTypeId)?.Name,
                                CompleteCost: this.currencyPipe.transform(NettoCost(b.Quantity, b.BaseCost), b.CurrencyCode),
                                Quantity: b.Quantity && this.decimalPipe.transform(b.Quantity),
                                InvoiceNumber: ledgerImports.find((l) => l.Id === b.LedgerImportId)?.ConsolidatedInvoiceId || '',
                            };
                        }),
                ),
            ),
        ),
    );

    public LedgerImports$ = combineLatest([this.route.paramMap]).pipe(
        map(([paramMap]) => +paramMap.get('commissionId')),
        switchMap((commissionId) =>
            this.store.select(getLedgerImports).pipe(
                filter(isNotNullOrUndefined),
                map((LedgerImports) => LedgerImports.filter((b) => b.CommissionId === commissionId && !b.IsTemplate)),
            ),
        ),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    private getLedgerImportsByDocTypes$ = (...documentTypes: LedgerImportDocumentTypes[]) => this.LedgerImports$.pipe(
        map((LedgerImports) =>
            LedgerImports.filter((li) => documentTypes.includes(li.DocumentType))
                .map((b) => {
                    return {
                        ...b,
                        Sum: this.currencyPipe.transform(b.ConsolidatedAmount / (1 + Mehrwertsteuer), b.ConsolidatedCurrencyCode),
                        DueDate: this.datePipe.transform(b.DueDate),
                        suffixButton: b.DocumentId
                            ? {
                                  icon: DMSPageMeta.Icon,
                                  tooltip: 'Zum Dokument',
                                  hide: (args: LedgerImportEntity) => {
                                      return !args.DocumentId;
                                  },
                                  onClick: (args: LedgerImportEntity) => {
                                      this.PreviewedFileId$.next(args.DocumentId);
                                      this.PreviewOpen = true;
                                  },
                              }
                            : null,
                        cssClass: b.GetBackgroundColorClass(),
                    };
                })
                .sort((a, b) => (a.CreatedAt < b.CreatedAt ? 1 : -1)),
        ),
    );

    private bookmarks$: Observable<Array<BookmarkEntity>>;
    private persons$: Observable<Array<PersonEntity>>;
    private customerList: CustomerEntity[];
    public Customer$ = combineLatest([this.store.select(getNotDeletedCustomers), this.Commission$]).pipe(
        map(([customers, commission]) => {
            this.customerList = customers;
            return customers.find((c) => c.Id === commission.CustomerId);
        }),
    );
    public IsVersicherung$ = combineLatest([
        merge(
            this.onInit$.pipe(
                withLatestFrom(this.Commission$),
                map(([, commission]) => ({ Id: commission.CustomerId })),
            ),
            this.CommissionForm.controls.Customer.valueChanges,
        ),
        this.store.select(getNotDeletedCustomers),
        this.store.select(getCustomerSpecificationTypes),
    ]).pipe(
        map(([customer, customers, customerSpecificationTypes]) =>
            customerSpecificationTypes
                .find((cst) => cst.Id === customers.find((c) => c.Id === customer?.Id)?.CustomerSpecificationTypeId)
                ?.Name.toLowerCase()
                .includes('versicherung'),
        ),
        distinctUntilChanged(),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public IsConstructionPartner$ = new BehaviorSubject<boolean>(false);
    public CommissionMainData = combineLatest([
        this.Commission$,
        this.store.select(getCommissionTypes),
        this.Customers$,
        this.CS.GetSingle$(),
        merge(
            this.onInit$.pipe(
                withLatestFrom(this.Commission$),
                map(([, commission]) => ({ Id: commission.CommissionTypeId })),
            ),
            this.CommissionForm.controls.CommissionTypeId.valueChanges,
        ),
        combineLatest([
            this.store.select(getPersons),
            this.IsVersicherung$,
            this.store.select(getOffices),
            this.store.select(getOwnPartnerOffices),
            this.store.select(getPerson2Entities).pipe(
                filter(isNotNullOrUndefined),
                map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Customer)),
            ),
            this.store.select(getPartner),
        ]),
        this.cls.getSingle$('Commission'),
        this.CommissionEntityRoles$,
        getCommissionLocationOptions(this.store)
    ]).pipe(
        map(([commission, commissionTypes, customers, CustomerName, currentCommissionType, [persons, isVersicherung, offices, ownParterOffices, personEntities, partner], commissionLabel, entityRoles, commissionLocationOptions]): IDetailListTemplateData => {
            const commissionTypeGericht = commissionTypes.find((c) => c.Name === CommissionTypeNameEnum.Gericht);
            const address = commission
                ? {
                      Street: commission.Street,
                      PostalCode: commission.PostalCode,
                      City: commission.City,
                      Country: commission.Country,
                  }
                : (null as Address);
            const commissionType = commission?.CommissionTypeId !== null ? commissionTypes?.find((c) => c.Id === commission?.CommissionTypeId) : null;
            const currentCommissionTypeEntity = currentCommissionType?.Id ? commissionTypes?.find((c) => c.Id === currentCommissionType.Id) : null;
            const person = commission?.PersonId !== null && isVersicherung ? persons?.find((cP) => cP.Id === commission?.PersonId) : null;
            // const customer = person?.CustomerId ? customers.find(o => o.Id === person.CustomerId) : null;
            // ####2
            setCommissionDetailListTemplateEntries(
                commission,
                this.DetailListTemplateEntrys,
                [
                    'Customer',
                    'CommissionType',
                    'Sache',
                    'CommissonNumber',
                    'CommissonNumberAuto',
                    'InterneNummer',
                    'InterneNummerAuto',
                    // 'CustomerLocation',
                    'Location',
                    'Auftragseingang',
                    'StartDate',
                    'PlanedStartDate',
                    'EndDate',
                    'PlanedEndDate',
                    'Schadensdatum',
                    'Versicherungssumme',
                    'Schadennummer',
                    'Versicherungsnummer',
                    'AutomaticAccountsReceivableLedger',
                    'AutomaticAccountsReceivableLedgerByClockIns',
                    'ProjectInfo',
                    'OrderNoCustomer',
                    'CompleteBusinessVolume',
                    'CostsCompleteBusinessVolume',
                    'PlanedWorkingHours',
                    'HandoverDate',
                ],
                this.CommissionForm,
                entityRoles,
                {
                    CustomerName,
                    CommissionName: this.CNS.getName,
                    customers,
                    offices,
                    commissionType,
                    commissionTypes,
                    partner,
                    currentCommissionTypeEntity,
                    currentCommissionType,
                    // SearchLocationForm: this.SearchLocationForm,
                    CommissionLocationForm: this.CommissionLocationForm,
                    commissionLocationOptions,
                },
                commissionLabel,
            );
            const Properties: IDetailListTemplateDataProperty[] = [this.DetailListTemplateEntrys.Customer, this.DetailListTemplateEntrys.CommissionType];
            if (ownParterOffices?.length > 1) {
                Properties.push({
                    value: commission?.PartnerOfficeId !== null ? ownParterOffices?.find((po) => po.Id === commission?.PartnerOfficeId)?.Name : null,
                    key: 'Unternehmen',
                    formControl: this.CommissionForm.controls.PartnerOffice,
                    options: {
                        specialInput: {
                            selectSearch: ownParterOffices?.map((c) => ({
                                optionValue: c.Id,
                                optionLabel: c.Name,
                            })),
                        },
                    },
                });
            }
            Properties.push(
                this.DetailListTemplateEntrys.InterneNummer,
                this.DetailListTemplateEntrys.InterneNummerAuto,
                this.DetailListTemplateEntrys.CommissonNumber,
                this.DetailListTemplateEntrys.CommissonNumberAuto,
                this.DetailListTemplateEntrys.Sache,
                this.DetailListTemplateEntrys.OrderNoCustomer,
                this.DetailListTemplateEntrys.ProjectInfo,
                // this.DetailListTemplateEntrys.CustomerLocation,
                this.DetailListTemplateEntrys.Location,
                this.DetailListTemplateEntrys.Auftragseingang,
                this.DetailListTemplateEntrys.HandoverDate,
                this.DetailListTemplateEntrys.StartDate,
                this.DetailListTemplateEntrys.PlanedStartDate,
                this.DetailListTemplateEntrys.EndDate,
                this.DetailListTemplateEntrys.PlanedEndDate,
                this.DetailListTemplateEntrys.Schadensdatum,
                this.DetailListTemplateEntrys.Versicherungssumme,
                partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? this.DetailListTemplateEntrys.Schadennummer : null,
                partner.PartnerSpecificationTypeId === PartnerSpecificationType.Appraiser ? this.DetailListTemplateEntrys.Versicherungsnummer : null,
                {
                    value: commission.FreeTextField2,
                    key: 'Freitext 2',
                    formControl: this.CommissionForm.controls.FreeTextField2,
                    options: {
                        specialInput: {
                            textArea: {
                                Fill: false,
                            },
                        },
                    },
                },
                {
                    value: commission.FreeTextField3,
                    key: 'Freitext 3',
                    formControl: this.CommissionForm.controls.FreeTextField3,
                    options: {
                        specialInput: {
                            textArea: {
                                Fill: false,
                            },
                        },
                    },
                },
                {
                    hideFormControl: currentCommissionType.Id !== commissionTypeGericht.Id,
                    value: currentCommissionType.Id === commissionTypeGericht.Id ? commission.Aktenzeichen : null,
                    key: 'Aktenzeichen.',
                    formControl: this.CommissionForm.controls.Aktenzeichen,
                    options: {
                        showHint: 'Um einer Verwechslungen vorzubeugen, werden bei der automatisierten Zuordnung nur Nummern mit fünf oder mehr Zeichen berücksichtigt.',
                    },
                },
                {
                    hideFormControl: !isVersicherung,
                    value: person?.DisplayName,
                    key: 'Sachbearbeiter',
                    formControl: this.CommissionForm.controls.Person,
                    options: {
                        specialInput: {
                            selectSearch: persons
                                .filter((p) => personEntities.find((pe) => pe.PersonId === p.Id && pe.EntityId === this.CommissionForm.controls.Customer.value?.Id) !== null)
                                // ?.filter(cP => cP.CustomerId === this.CommissionForm.controls.Customer.value?.Id)
                                .map((c) => ({
                                    optionValue: c.Id,
                                    optionLabel: isNotNullOrUndefined(c.DisplayName)
                                        ? c.DisplayName + (isNotNullOrUndefined(c.Email) ? ' ' + c.Email : '') + (isNotNullOrUndefined(c.Description) ? ' ' + c.Description : '')
                                        : 'unbennanter Sachbearbeiter',
                                })),
                        },
                    },
                },
                {
                    value: commission.UpdatedAt,
                    key: 'Aktualisiert am',
                    options: { type: CustomPropertyType.Datum },
                },
                this.DetailListTemplateEntrys.AutomaticAccountsReceivableLedger,
                this.DetailListTemplateEntrys.AutomaticAccountsReceivableLedgerByClockIns,
                this.DetailListTemplateEntrys.CompleteBusinessVolume,
                this.DetailListTemplateEntrys.CostsCompleteBusinessVolume,
                this.DetailListTemplateEntrys.PlanedWorkingHours,
            );
            return {
                HeaderIcon: 'hashtag' as IconProp,
                Headline: commissionType?.Name === CommissionTypeNameEnum.Projekt ? commission.Auftragsnummer || '' : commission.InterneNummer ? commission.InterneNummer : 'InterneNr',
                Properties: Properties.filter(isNotNullOrUndefined),
            };
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    private subscriptions: Subscription[] = [];

    get AreFormsInvalid() {
        const invalid = this.CommissionForm.status === 'INVALID' || this.DamageFlowForm.status === 'INVALID';
        return invalid;
    }

    GetRouteForLedgerImport = (ledgerImport: LedgerImportEntity) => ['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, ledgerImport.Id];
    GetRouteForDiary = (diary: GeneratedDocumentsEntity) => ['/', GeneratedDocumentsPageMeta.Path, AllReportsMeta.Path, DocumentEditorMeta.Path, diary.Id];
    GetRouteForEvent = (event: EventEntity) => ['/', HistoryMeta.Path, this.AllEventsMetaPath, event.Id];
    GetRouteForGenDoc = (doc: { Id: number }) => ['/', GeneratedDocumentsPageMeta.Path, AllGeneratedDocumentsMeta.Path, DocumentEditorMeta.Path, doc.Id];
    // public logCache = {}

    GetTemplateByType(type: string): TemplateRef<any> {
        // todo jedes template wird x33 abgerufen
        // this.logCache[type] = this.logCache[type] ? this.logCache[type] +1 : 1;

        switch (type) {
            case 'commissionOverviewTemplate':
                return this.commissionOverviewTemplate;
            case 'detailListTemplateCommission':
                return this.detailListTemplateCommissionTemplate;
            case 'group':
                return this.groupTemplate;
            case 'shiftplan':
                return this.shiftPlanTemplate;
            case 'resourceplan':
                return this.resourcePlanTemplate;
            case 'taskBoard':
                return this.taskTemplate;
            case 'fileExplorer':
                return this.fileExplorerTemplate;
            case 'eventList':
                return this.eventListTemplate;
            case 'personList':
                return this.personListTemplate;
            case 'bookingPositionList':
                return this.bookingPositionListTemplate;
            // case 'invoiceList':
            //     return this.invoiceListTemplate;
            // case 'offerList':
            //     return this.offerListTemplate;
            case 'ledgerImportListTemplate':
                return this.ledgerImportListTemplate;
            case 'bookedBookingPositionList':
                return this.bookedBookingPositionListTemplate;
            case 'hiddenBookingPositionList':
                return this.hiddenBookingPositionListTemplate;
            case 'judgeList':
                return this.judgeListTemplate;
            case 'reportsListDiary':
                return this.reportsListDiary;
            case 'reportsListZusatz':
                return this.reportsListZusatz;
            case 'reportsListBedenken':
                return this.reportsListBedenken;
            case 'reportsListMangel':
                return this.reportsListMangel;
            case 'reportsListOther':
                return this.reportsListOther;
            case 'klaegerList':
                return this.klaegerListTemplate;
            case 'beklagteList':
                return this.beklagteListTemplate;
            case 'selectedPartnerList':
                return this.selectedPartnerListTemplate;
            case 'selectedUsersList':
                return this.selectedUsersListTemplate;
            case 'tabGroup':
                return this.tabGroupTemplate;
            case 'nachnahmenListTemplate':
                return this.nachnahmenListTemplate;
            case 'mangelListTemplate':
                return this.erschwernisListTemplate;
            case 'commentsTemplate':
                return this.commentsTemplate;
            case 'commissionTitleTabGroupTemplate':
                return this.commissionTitleTabGroupTemplate;
            case 'entityHistoryTemplate':
                return this.entityHistoryTemplate;
            case 'resourceScheduleListeTemplate':
                return this.resourceScheduleListeTemplate;
            case 'resourceListTemplate':
                return this.resourceListTemplate;
            case 'materialListTemplate':
                return this.materialListTemplate;
            case 'groupListTemplate':
                return this.groupListTemplate;
            case 'bedenkenanzeigeListTemplate':
                return this.bedenkenanzeigeListTemplate;
            case 'mangelanzeigeListTemplate':
                return this.mangelanzeigeListTemplate;
            case 'childCommissionsTemplate':
                return this.childCommissionsTemplate;
            case 'reportsTabGroupTemplate':
                return this.reportsTabGroupTemplate;
            case 'CommissionBusinessVolumeList':
                return this.CommissionBusinessVolumeList;
            case 'blueprintTemplate':
                return this.blueprintTemplate;
            default:
                console.error('View nicht gefunden', type);
        }
    }
    // private setCommissionSeen(id: number) {
    //     this.changesService.setAllChangesFromEntitySeen(id, EntityType.Commission);
    // }
    ngOnDestroy(): void {
        // firstValueFrom(this.Commission$).then((commission) => {
        //     if (commission) {
        //         this.setCommissionSeen(commission.Id);
        //     }
        // });
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    ngOnInit(): void {
        this.onInitTimestamp = new Date();
        this.onInit$.next(true);
        this.subscriptions.push(
            this.Commission$.subscribe((c) => this.statusDropdown.reset(c.StatusBackofficeId)),
            this.OpenShiftPlan$.pipe(withLatestFrom(this.Commission$, this.ShiftPlanActiveView$)).subscribe(([, commission, activeView]) => {
                this.dialog
                    .open<ShiftPlanDialogComponent, ShiftPlanComponentDialogData>(ShiftPlanDialogComponent, {
                        ...ShiftPlanDialogComponent.DefaultConfig,
                        data: {
                            CommissionId: commission.Id,
                            View: activeView,
                            DefaultFilter: this.shiftplan?.FilterValues$.value,
                        },
                    })
                    .afterClosed()
                    .subscribe((viewStyle) => this.ShiftPlanActiveView$.next(viewStyle));
            }),
            this.OpenResourcePlan$.pipe(withLatestFrom(this.Commission$, this.ResourcePlanActiveView$)).subscribe(([, commission, activeView]) => {
                this.dialog
                    .open<ResourcePlanDialogComponent, ResourcePlanComponentDialogData>(ResourcePlanDialogComponent, {
                        ...ResourcePlanDialogComponent.DefaultConfig,
                        data: {
                            CommissionId: commission.Id,
                            View: activeView,
                            DefaultFilter: this.resourceplan?.FilterValues$.value,
                        },
                    })
                    .afterClosed()
                    .subscribe((viewStyle) => this.ResourcePlanActiveView$.next(viewStyle));
            }),
            this.bookmarks$.subscribe((value) => {
                this.BookmarkList = value;
            }),

            this.persons$.subscribe((value) => {
                this.PersonList = value;
            }),
        );
        this.PreviewedFileId$.subscribe((id) => {
            if (id && id > -1) {
                this.PreviewOpen = true;
                this.fileDialog?.close();
            } else if (id === null) {
                this.PreviewOpen = false;
            }
        });
        this.StatusData = this.Commission$.pipe(
            withLatestFrom(this.store.select(getStatusFromBackoffice)),
            map(([c, boS]) => {
                return {
                    Properties: [
                        {
                            key: 'Status',
                            value: '',
                            formControl: this.statusDropdown,
                            options: {
                                specialInput: {
                                    select: boS.map((b) => ({
                                        optionLabel: b.Name,
                                        optionValue: b.Id,
                                    })),
                                },
                            },
                        },
                    ],
                };
            }),
        );
        this.DamageFlowData$ = combineLatest([this.store.select(getDamageFlows), this.Commission$, this.store.select(getQMStatus), this.store.select(getDamageFlowTypes)]).pipe(
            map(([damageFlows, commission, qMStatus, damageFlowTypes]) => {
                const damageFlow = damageFlows.find((d) => d.CommissionId === commission.Id);

                const address = damageFlow
                    ? {
                          Street: damageFlow.Street,
                          PostalCode: damageFlow.PostalCode,
                          City: damageFlow.City,
                          Country: damageFlow.Country,
                      }
                    : (null as Address);

                return damageFlow
                    ? {
                          HeaderIcon: 'house-damage' as IconProp,
                          Headline: 'Schadensdaten',
                          Properties: [
                              {
                                  key: 'Adresse',
                                  options: {
                                      type: CustomPropertyType.Location,
                                      specialInput: {
                                          location: {
                                              value: address,
                                              formGroup: this.DamageFlowLocationForm,
                                          },
                                      },
                                  },
                                  value: getAddressString(address),
                              },
                              {
                                  value: damageFlow.CreatedAt,
                                  key: 'Erstellt am',
                                  options: { type: CustomPropertyType.Datum },
                              },
                              {
                                  value: damageFlow.UpdatedAt,
                                  key: 'Aktualisiert am',
                                  options: { type: CustomPropertyType.Datum },
                              },
                              {
                                  value: damageFlow.AbgabeterminBerichtReal,
                                  key: 'AbgabeterminBerichtReal',
                                  formControl: this.DamageFlowForm.get('AbgabeterminBerichtReal'),
                                  options: {
                                      type: CustomPropertyType.Datum,
                                      specialInput: { date: true },
                                  },
                              },
                              {
                                  value: damageFlow?.DamageFlowTypeId !== null ? damageFlowTypes?.find((dtypes) => dtypes.Id === damageFlow?.DamageFlowTypeId).Name : null,
                                  key: 'Schadensart',
                                  formControl: this.DamageFlowForm.get('DamageFlowTypeId'),
                                  options: {
                                      specialInput: {
                                          selectSearch: damageFlowTypes?.map((q) => ({
                                              optionValue: q.Id,
                                              optionLabel: q.Name,
                                          })),
                                      },
                                  },
                              },
                              {
                                  value: damageFlow.Description,
                                  key: 'Freitext',
                                  formControl: this.DamageFlowForm.get('Description'),
                              },
                              {
                                  value: damageFlow.DeadlineVerlaengerung,
                                  key: 'Frist Verlängerung',
                                  formControl: this.DamageFlowForm.get('DeadlineVerlaengerung'),
                              },
                              {
                                  value: damageFlow.ErsterBerichtEingegangen,
                                  key: 'Erster Bericht eingegangen',
                                  formControl: this.DamageFlowForm.get('ErsterBerichtEingegangen'),
                                  options: {
                                      type: CustomPropertyType.Boolean,
                                      specialInput: { boolean: true },
                                  },
                              },
                              {
                                  value: damageFlow.ErsterOrtstermin,
                                  key: 'Erster Ortstermin',
                                  formControl: this.DamageFlowForm.get('ErsterOrtstermin'),
                                  options: {
                                      type: CustomPropertyType.Datum,
                                      specialInput: { date: true },
                                  },
                              },
                              {
                                  value: damageFlow.KontaktMitVersicherungsnehmer,
                                  key: 'Kontakt mit Versicherungsnehmer',
                                  formControl: this.DamageFlowForm.get('KontaktMitVersicherungsnehmer'),
                                  options: {
                                      type: CustomPropertyType.Datum,
                                      specialInput: { date: true },
                                  },
                              },
                              {
                                  value: damageFlow.ObjektiveSchadensumme,
                                  key: 'Objektive Schadensumme',
                                  formControl: this.DamageFlowForm.get('ObjektiveSchadensumme'),
                              },
                              {
                                  value: damageFlow.QMDurchgefuehrtAm,
                                  key: 'QM durchgefuehrt am',
                                  formControl: this.DamageFlowForm.get('QMDurchgefuehrtAm'),
                                  options: {
                                      type: CustomPropertyType.Datum,
                                      specialInput: { date: true },
                                  },
                              },
                              {
                                  value: damageFlow?.QMStatusId !== null ? qMStatus?.find((qm) => qm.Id === damageFlow?.QMStatusId).Name : null,
                                  key: 'QM Status',
                                  formControl: this.DamageFlowForm.get('QMStatusId'),
                                  options: {
                                      specialInput: {
                                          selectSearch: qMStatus?.map((q) => ({
                                              optionValue: q.Id,
                                              optionLabel: q.Name,
                                          })),
                                      },
                                  },
                              },
                              {
                                  value: damageFlow.ReportCount,
                                  key: 'Anzahl Berichte erstellt',
                                  formControl: this.DamageFlowForm.get('ReportCount'),
                              },
                              {
                                  value: damageFlow.SchadenannahmeDurchSV,
                                  key: 'Schadenannahme durch SV',
                                  formControl: this.DamageFlowForm.get('SchadenannahmeDurchSV'),
                                  options: {
                                      type: CustomPropertyType.Datum,
                                      specialInput: { date: true },
                                  },
                              },
                              {
                                  value: damageFlow.WeiterleitungAnVu,
                                  key: 'Weiterleitung an VU',
                                  formControl: this.DamageFlowForm.get('WeiterleitungAnVu'),
                                  options: {
                                      type: CustomPropertyType.Datum,
                                      specialInput: { date: true },
                                  },
                              },
                          ],
                      }
                    : null;
            }),
        );
        this.CommentFileUploadParams$ = this.Folder$.pipe(map(folder => folder && new Map([['folder_id', folder.Id.toString()], ['hidden', 'true']])));
        this.subscriptions.push(
            this.Partner$.subscribe((p) => {
                if (p.PartnerSpecificationTypeId === PartnerSpecificationType.Construction) {
                    this.IsConstructionPartner$.next(true);
                } else {
                    this.IsConstructionPartner$.next(false);
                }
            }),
            this.bookmarks$.subscribe((value) => {
                this.BookmarkList = value;
                // this.isReadyToShow(); copied from timeline, prob. not necessary
            }),
            // Workaround um die forms am anfang ein mal zu füllen damit diese vom wizard verwendet werden können
            combineLatest([this.Commission$, this.store.select(getDamageFlows)])
                .pipe(
                    filter((v) => v.every(isNotNullOrUndefined)),
                    take(1),
                )
                .subscribe(([commission, damageFlows]) => {
                    if (commission.AdditionalData?.wizardPage) {
                        this.setFormControls(commission, damageFlows);
                    }
                }),
            this.NewBookingPositionClick$.pipe(
                withLatestFrom(this.Commission$),
                tap(([, commission]) => {
                    this.dialog.open<NewBookingOptionComponent, NewOptionDialogData>(NewBookingOptionComponent, {
                        panelClass: 'custom-dialog-class-without-padding',
                        data: commission
                            ? {
                                  newOptionDefaultValues: {
                                      CommissionId: commission.Id,
                                  },
                                  accountsReceivableLedgerTemplateFilter: {
                                      CommissionTypeId: commission.CommissionTypeId,
                                  },
                              }
                            : null,
                    });
                }),
            ).subscribe(),
            this.Editing$.pipe(withLatestFrom(this.Commission$, this.store.select(getDamageFlows), this.ViewStyleConfig$)).subscribe(([editing, commission, damageFlows, vsc]) => {
                if (editing) {
                    console.log('setFormControls')
                    this.setFormControls(commission, damageFlows);
                    if (this.commissionTitleTabGroupTemplateTabGroup) {
                        let index = null;
                        this.commissionTitleTabGroupTemplateTabGroup._tabs.forEach((t, i) => {
                            if (t.content.viewContainerRef.element.nativeElement.id === 'detailListTemplateCommission') {
                                index = i;
                            }
                        });
                        if (index != null) {
                            this.commissionTitleTabGroupTemplateTabGroup.selectedIndex = index;
                        }
                    }
                }
            }),
            ...getCommissionFormSubscriptions(this.CommissionForm, this.store),
            this.statusDropdown.valueChanges.pipe(distinctUntilChanged(), withLatestFrom(this.Commission$)).subscribe(([v, commission]) => {
                this.ShowStatus = false;
                if (commission.StatusBackofficeId !== +v) {
                    this.store.dispatch(
                        CommissionActionTypes.ModifyCommission({
                            Payload: {
                                id: commission.Id,
                                statusBackofficeId: +this.statusDropdown.value,
                            },
                        }),
                    );
                }
            }),

            this.Save$.pipe(
                withLatestFrom(this.Commission$),
                withLatestFrom(this.store.select(getDamageFlows)),
                tap(([[wizardPage, commission], damageFlows]) => {
                    if (this.CommissionForm.status === 'VALID' && this.DamageFlowForm.status === 'VALID' && this.CommissionLocationForm.status === 'VALID' && this.DamageFlowLocationForm.status === 'VALID') {
                        this.Editing$.next(false);
                        saveCommission(this.store, commission, wizardPage, this.CommissionForm, this.CommissionLocationForm, this.DamageFlowForm, this.DamageFlowLocationForm, damageFlows);

                        this.CommissionForm.controls.AutomaticAuftragsnummer.setValue(false);
                        this.CommissionForm.controls.AutomaticInterneNummer.setValue(false);
                    }
                }),
            ).subscribe(),
        );
    }

    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> | boolean {
        const pendingChanges = this.Editing$.value && (this.CommissionForm.dirty || this.DamageFlowLocationForm.dirty || this.DamageFlowForm.dirty || this.CommissionLocationForm.dirty);
        return !pendingChanges;
    }

    public BookingPositionClicked(s: AccountsReceivableLedgerEntity, element: HTMLElement) {
        console.log({ s, element });
        element?.classList.add('is-active');
        this.dialog
            .open(ModifyBookingOptionDialogComponent, {
                ...ModifyBookingOptionDialogComponent.DefaultConfig,
                data: { BookingOptionId: s.Id } as ModifyBookingOptionDialogComponentData,
            })
            .afterClosed()
            .subscribe(() => document.addEventListener('click', () => element?.classList.remove('is-active'), { once: true }));
    }

    public OpenPartnerSelect() {
        this.SharedCommissions$.pipe(
            withLatestFrom(this.Commission$),
            take(1),
            tap(([sc, c]) => {
                const shared = sc.filter((sc2) => sc2.CommissionId === c.Id);
                if (shared.length == 0) {
                    const s = new SharedCommissionsEntity();
                    s.CommissionId = c.Id;
                    shared.push(s);
                }
                this.dialog.open(SelectPartnerComponent, {
                    width: '500px',
                    data: shared,
                });
            }),
        ).subscribe();
    }

    public OpenUserSelect() {
        // this.ShowSelectedUsers$.pipe(
        //     withLatestFrom(this.Commission$),
        //     take(1),
        //     tap(([sc, c]) => {
        //         this.dialog.open(SelectUserCommissionComponent, {
        //             ...SelectUserCommissionComponent.DefaultConfig,
        //             data: { commissionId: c.Id },
        //         });
        //     }),
        // ).subscribe();
        firstValueFrom(this.Commission$).then((commission) => {
            this.dialog.open<SelectUserCommissionDialogComponent, SelectUserCommissionDialogComponentDialogData>(SelectUserCommissionDialogComponent, {
                ...SelectUserCommissionDialogComponent.DefaultConfig,
                data: {
                    commissionId: commission.Id,
                    hideStartDate:true,
                },
            });
        });
    }

    public DeleteCommission() {
        firstValueFrom(combineLatest([this.Commission$, this.cls.getLabels$('Commission')])).then(([commission, commissionLabel]) => {
            this.appDialog
                .OpenConfirmationDialog({
                    paragraph: 'Soll ' + commissionLabel.Nominativ.singular.article + ' ' + commissionLabel.Nominativ.singular.value + ' wirklich gelöscht werden.',
                    styleDelete: true,
                })
                .subscribe(([result]) => {
                    result &&
                        this.store.dispatch(
                            CommissionActionTypes.DeleteCommission({
                                Payload: commission.Id,
                            }),
                        );
                    result && this.routingHistoryService.NavigateBackwards();
                });
        });
    }

    public DeleteFile(file: FileEntity) {
        this.appDialog
            .OpenConfirmationDialog({
                paragraph: `Die Datei ${file.Name} wirklich löschen?`,
            })
            .subscribe(([result]) => result && this.store.dispatch(FilesActionTypes.DeleteFileRequest({ Payload: { DocumentId: file.Id.toString() } })));
    }

    public CreateEventAndTransmission() {
        this.Commission$.pipe(take(1)).subscribe((c) => {
                const dialogRef = this.dialog.open(NewEventAndTransmissionComponent, {
                    ...NewEventAndTransmissionComponent.DefaultConfig,
                    data: {
                        CommissionId: c.Id,
                        CustomerId: c.CustomerId,
                        CommissionTypeId: c.CommissionTypeId,
                    } as NewEventAndTransmissionComponentDialogData,
                });
                dialogRef.componentInstance.AddPersonClicked.pipe(takeUntil(dialogRef.afterClosed())).subscribe( () => this.OpenPersonDialog('Beteiligte Personen', 'beteiligte person'));
            }
        );
    }

    public CreateCall() {
        this.Commission$.pipe(take(1)).subscribe((c) => {
                const dialogRef = this.dialog.open<NewCallComponent, NewCallComponentDialogData>(NewCallComponent, {
                    ...NewCallComponent.DefaultConfig,
                    data: {
                        CommissionId: c.Id,
                        CustomerId: c.CustomerId,
                    },
                });
                dialogRef.componentInstance.AddPersonClicked.pipe(takeUntil(dialogRef.afterClosed())).subscribe( () => this.OpenPersonDialog('Beteiligte Personen', 'beteiligte person'));
            }
        );
    }

    public CreateOnSiteEvent() {
        this.Commission$.pipe(take(1)).subscribe((c) => {
            const dialogRef = this.dialog.open(NewOnSiteEventComponent, {
                ...NewOnSiteEventComponent.DefaultConfig,
                data: {
                    CommissionId: c.Id,
                    CustomerId: c.CustomerId,
                } as NewOnSiteEventComponentDialogData,
            });
            dialogRef.componentInstance.AddPersonClicked.pipe(takeUntil(dialogRef.afterClosed())).subscribe( () => this.OpenPersonDialog('Beteiligte Personen', 'beteiligte person'));
        });
    }

    public CreateEvent() {
        this.Commission$.pipe(take(1)).subscribe((c) => {
            const dialogRef = this.dialog.open(NewOnSiteEventComponent, {
                ...NewOnSiteEventComponent.DefaultConfig,
                data: {
                    CommissionId: c.Id,
                    CustomerId: c.CustomerId,
                    IsNotOrtstermin: true,
                    Street: c.Street,
                    PostalCode: c.PostalCode,
                    City: c.City,
                    Country: c.Country,
                } as NewOnSiteEventComponentDialogData,
            });
            dialogRef.componentInstance.AddPersonClicked.pipe(takeUntil(dialogRef.afterClosed())).subscribe( () => this.OpenPersonDialog('Beteiligte Personen', 'beteiligte person'));
        });
    }

    public BookmarkEvent(e, event: EventEntity) {
        if (this.IsBookmarked(event.Id)) {
            this.store.dispatch(
                BookmarkActionTypes.RemoveBookmark({
                    BookmarkIds: [this.BookmarkList.find((b) => b.EventId === event.Id).Id],
                }),
            );
        } else {
            this.store.dispatch(BookmarkActionTypes.AddBookmark({ EventId: event.Id }));
        }
        // setTimeout(() => this.cdr.detectChanges(), 1000);
        e.stopPropagation();
    }

    public IsBookmarked(eventId: number) {
        return this.BookmarkList.find((b) => b.EventId === eventId) !== undefined;
    }

    public GetContactPersonByContactPersonId(contactPersonId: number) {
        return this.PersonList.find((value) => value.Id === contactPersonId);
    }

    public OpenTimeTracker() {
        this.Commission$.pipe(first()).subscribe((commission) => {
            this.dialog.open<HourListComponent, HourListComponentDialogData>(HourListComponent, {
                ...HourListComponent.DefaultConfig,
                data: {
                    CommissionId: commission.Id,
                },
            });
        });
    }

    public OpenPersonDialog(buttonText: string, complainTypeText: 'beteiligte person' | 'beklagter' | 'klagender' | 'vorsitzender') {
        this.store
            .select(getPerson2Entities)
            .pipe(
                take(1),
                withLatestFrom(this.Commission$),
                map(([pes, commission]) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Commission && pe.EntityId === commission.Id)),
                withLatestFrom(this.Commission$, this.store.select(getComplaintTypes).pipe(map((ct) => ct.find((c) => c.Name.toLowerCase().includes(complainTypeText))))),
            )
            .subscribe(([p2es, commission, complaintType]) => {
                let selectedIds = p2es.filter((p) => p.ComplaintTypeId === complaintType.Id).map((p2e) => p2e.PersonId);
                let selectedP2EIds = p2es.filter((p) => p.ComplaintTypeId === complaintType.Id).map((p2e) => p2e.Id);
                this.dialog
                    .open<SelectPersonsComponent, SelectPersonsComponentDialogData, PersonEntity[]>(SelectPersonsComponent, {
                        ...SelectPersonsComponent.DefaultConfig,
                        data: {
                            EntityId: commission.Id,
                            ButtonText: buttonText,
                            SelectedPersonIds: selectedIds,
                        },
                    })
                    .afterClosed()
                    .pipe(
                        filter(isNotNullOrUndefined),
                        withLatestFrom(
                            this.store.select(getPerson2Entities).pipe(
                                filter(isNotNullOrUndefined),
                                map((pes) => pes.filter((pe) => pe.EntityType === Person2EntityEntityTypeEnum.Commission && pe.EntityId === commission.Id)),
                            ),
                        ),
                        withLatestFrom(this.store.select(getComplaintTypes)),
                    )
                    .subscribe(([[selectedPersons, personEntities], complaintTypes]) => {
                        let addIds: number[] = [];
                        let delIds: number[] = [];
                        const complainTypeId = complaintTypes.find((ct) => ct.Name.toLowerCase().includes(complainTypeText.toLowerCase()))?.Id;

                        selectedPersons.forEach((p) => {
                            if (!selectedIds.includes(p.Id)) {
                                addIds.push(p.Id);
                            }
                        });
                        personEntities.forEach((pe) => {
                            if (selectedPersons.filter((p) => p.Id === pe.PersonId && pe.ComplaintTypeId === complainTypeId).length === 0) {
                                delIds.push(pe.Id);
                            }
                        });

                        addIds.forEach((pId) => {
                            this.store.dispatch(
                                Person2EntityActionTypes.AddPerson2Entity({
                                    Payload: {
                                        personId: pId,
                                        entityId: commission.Id,
                                        entityType: Person2EntityEntityTypeEnum.Commission,
                                        complaintTypeId: complainTypeId,
                                    },
                                }),
                            );
                        });

                        delIds.forEach((id) => {
                            this.store.dispatch(
                                Person2EntityActionTypes.DeletePerson2Entity({
                                    Payload: {
                                        id: id,
                                    },
                                }),
                            );
                        });
                    });
            });
    }

    GetLastNumbers(val: string) {
        const lastNumberOfString = val.match(/\d+$/);

        if (lastNumberOfString) {
            return lastNumberOfString[0];
        }
        return '0';
    }

    OpenSelectDiaryDate() {
        this.Commission$.pipe(take(1)).subscribe((commission) => {
            this.appDialog.OpenConstructionDiaryDatepickerDialog(commission.Id);
        });
    }

    // GenerateDaiary() {
    //     this.Commission$.pipe(take(1)).subscribe((commission) => {
    //         this.dialog.open<SelectDiaryTimespanDialogComponent, SelectDiaryTimespanDialogComponentDialogData, Moment | null>(SelectDiaryTimespanDialogComponent, {
    //             data: { CommissionId: commission.Id },
    //         });
    //     });
    // }

    public OpenEditEventDialog(event: Partial<EventEntity>, element?: HTMLElement) {
        let userId: number;
        let deletePermission: boolean = false;
        this.CurrentUser$.subscribe(user => {
            userId = user.Id
        })
        if(event.UserId === userId) {
            deletePermission = true;
        }
        else {
            this.ShowSelectedUsers$.subscribe(user => {
                let permission = user.filter((u) => u.User.UserId === userId && (u.roles.includes(String(EntityRoleSlugEnum.Admin))|| u.roles.includes(String(EntityRoleSlugEnum.Projektleiter))) && isNotNullOrUndefined);
                if(permission.length > 0){
                    deletePermission = true;
                }
            })
        }
        element?.classList.add('is-active');
        this.dialog
            .open<AddEventDialogComponent, AppAddEventDialogData, AppAddEventDialogDataReturn>(AddEventDialogComponent, {
                ...AddEventDialogComponent.DefaultConfig,
                data: {
                    eventId: event.Id,
                    hideDate: false,
                    canDelete: deletePermission,
                },
            })
            .afterClosed()
            .subscribe(() => document.addEventListener('click', () => element?.classList.remove('is-active'), { once: true }));
    }

    public OpenAddEventDialog(eventTypeId: number, eventTypeName: string) {
        this.store
            .select(getConstructionDiaries)
            .pipe(
                filter(isNotNullOrUndefined),
                take(1),
                withLatestFrom(this.Commission$),
                tap(([diaries, commission]) => {
                    const date = new Date();
                    const diary = diaries.find((d) => d.CommissionId === commission.Id && sameDay(d.Date, date));
                    this.store.dispatch(
                        EventsActionTypes.AddEvent({
                            Payload: {
                                isTask: false,
                                eventTypeId,
                                commissionId: commission.Id,
                                customerId: commission.CustomerId,
                                eventDate: FrontendDate(date),
                                state: EventStateEnum.New,
                                name: eventTypeName + ' ' + formatDate(date, 'dd.MM.yyyy', 'de-DE'),
                            },
                        }),
                    );
                    this.actions$.pipe(ofType(EventsActionTypes.UpdateEvent), take(1)).subscribe((action) => {
                        if (diary) {
                            this.store.dispatch(
                                ConstructionDiaryActionTypes.Modify({
                                    Payload: {
                                        id: diary.Id,
                                        eventIds: diary.EventIds ? [...diary.EventIds, action.Payload.Id] : [action.Payload.Id],
                                    },
                                }),
                            );
                        }
                        this.dialog
                            .open<AddEventDialogComponent, AppAddEventDialogData, AppAddEventDialogDataReturn>(AddEventDialogComponent, {
                                data: {
                                    eventId: action.Payload.Id,
                                    headline: eventTypeName,
                                    hideDate: false,
                                },
                                ...AddEventDialogComponent.DefaultConfig,
                            })
                            .afterClosed()
                            .pipe(withLatestFrom(this.store.select(getEventById({ id: action.Payload.Id })), this.store.select(getFiles)))
                            .subscribe(([type, event, files]) => {
                                if (type === 'abort' || (type === undefined && !event.Name && !event.Description && !files.some((f) => f.EventIds.includes(action.Payload.Id)))) {
                                    this.store.dispatch(EventsActionTypes.DeleteEvent({ Payload: action.Payload.Id }));
                                }
                            });
                    });
                }),
            )
            .subscribe();
    }

    private setFormControls(commission: CommissionEntity, damageFlows: DamageFlowEntity[]) {
        const damageFlow = damageFlows.find((d) => d.CommissionId === commission.Id);
        this.DamageFlowLocationForm.reset({
            Street: damageFlow?.Street,
            PostalCode: damageFlow?.PostalCode,
            City: damageFlow?.City,
            Country: damageFlow?.Country,
        });
        this.CommissionLocationForm.reset({
            Street: commission.Street,
            PostalCode: commission.PostalCode,
            City: commission.City,
            Country: commission.Country,
        });
        console.log(commission)
        resetCommissionFormGroup(commission, this.CommissionForm);
        if (damageFlow) {
            this.DamageFlowForm.reset({
                AbgabeterminBerichtReal: damageFlow.AbgabeterminBerichtReal,
                DeadlineVerlaengerung: damageFlow.DeadlineVerlaengerung,
                Description: damageFlow.Description,
                DamageFlowTypeId: {
                    Name: '',
                    Id: damageFlow.DamageFlowTypeId,
                },
                ErsterBerichtEingegangen: damageFlow.ErsterBerichtEingegangen,
                ErsterOrtstermin: damageFlow.ErsterOrtstermin,
                KontaktMitVersicherungsnehmer: damageFlow.KontaktMitVersicherungsnehmer,
                ObjektiveSchadensumme: damageFlow.ObjektiveSchadensumme,
                QMDurchgefuehrtAm: damageFlow.QMDurchgefuehrtAm,
                QMStatusId: {
                    Name: '',
                    Id: damageFlow.QMStatusId,
                },
                ReportCount: damageFlow.ReportCount,
                SchadenannahmeDurchSV: damageFlow.SchadenannahmeDurchSV,
                WeiterleitungAnVu: damageFlow.WeiterleitungAnVu,
            });
        }
        this.statusDropdown.reset(commission.StatusBackofficeId);
    }

    public OpenUploadDialog() {
        this.Commission$.pipe(take(1)).subscribe((c) => {
            this.fileDialog?.close();
            this.fileDialog = this.dialog.open<DaveFileUploadDialogComponent, DaveFileUploadDialogComponentDialogData>(DaveFileUploadDialogComponent, {
                ...DaveFileUploadDialogComponent.DefaultConfig,
                data: {
                    CommissionId: c.Id,
                    CustomerId: c.CustomerId,
                    FolderId: this.UploadFolderId,
                },
            });
        });
    }
    public OpenMapDialog(location: Address) {
        this.dialog.open(MapDialogComponent, {
            ...MapDialogComponent.DefaultConfig,
            data: location,
        });
    }
    ArraySum(arr: Array<number>) {
        return arr.reduce((prev, curr) => (curr ? prev + curr : prev), 0);
    }
    addChildCommissionClick() {
        firstValueFrom(this.Commission$).then((c) => {
            this.dialog.open<SelectCommissionDialogComponent, SelectCommissionDialogComponentDialogData>(SelectCommissionDialogComponent, {
                ...SelectCommissionDialogComponent.DefaultConfig,
                data: {
                    commissionId: c.Id,
                },
            });
        });
    }

    protected readonly PartnerSpecificationType = PartnerSpecificationType;
    protected readonly ContactBookMeta = ContactBookMeta;
    GetRouteForPerson = ({ Id }: { Id: number }) => ['/', ContactBookMeta.Path, Id];
    protected readonly GeneratedDocumentsTypeSlugs = GeneratedDocumentsTypeSlugs;

    public OpenMailDialog(group?: GroupEntity, toType: 'to' | 'cc' | 'bcc' = 'to') {
        firstValueFrom(
            combineLatest([
                this.Commission$,
                this.store.select(getPersonsFetched).pipe(
                    tap((f) => {
                        if (!f) {
                            this.personResolver.resolve();
                        }
                    }),
                    filter((f) => f),
                    switchMap(() => this.store.select(getPersonDictionary)),
                ),
                this.Versicherungsnehmer$,
                this.cls.getLabels$('Commission'),
                this.store.select(getEmployeeDictionary),
            ]),
        ).then(([commission, persons, person, commissionLabels, employees]) => {
            const targetEmail = (group ? group.GroupMembers.map((member) => (member.EmployeeId ? employees[member.EmployeeId] : persons[member.PersonId])?.Email) : commission.PersonId && [persons[commission.PersonId]?.Email])?.filter(
                (v) => v,
            );
            this.dialog.open<EmailEditorComponent, EmailEditorComponentDialogData>(EmailEditorComponent, {
                ...EmailEditorComponentDialogConfig,
                data: {
                    EmailSubject: commission.GetDisplayName(),
                    TargetEmails: toType === 'to' && targetEmail || [],
                    BCCEmails: toType === 'bcc' && targetEmail || [],
                    CCEmails: toType === 'cc' && targetEmail || [],
                    CustomerId: commission.CustomerId || null,
                    CommissionId: commission.Id,
                    EmailHtml: getCommissionEmailHtml(commission, commissionLabels, person),
                },
            });
        });
    }
    onAddResourcesButtonClick() {
        firstValueFrom(combineLatest([this.cls.getSingle$('RessourcePlan'), this.Commission$])).then(([planLabel, commission]) => {
            const form = new FormControl<Moment>(moment(commission.StartDate || commission.PlannedStartDate || new Date()), Validators.required);
            this.dialog
                .open<DetailListTemplateDialogComponent, DetailListTemplateDialogData, DetailListDialogReturn>(DetailListTemplateDialogComponent, {
                    ...DetailListTemplateDialogComponent.DefaultConfig,
                    data: {
                        Editing: true,
                        DisableSaveButton$: form.statusChanges.pipe(
                            startWith(form.status),
                            map((state) => state !== 'VALID'),
                        ),
                        Data: {
                            Headline: 'Automatisch ' + planLabel + ' anlegen',
                            Subline: 'Wir nutzen die hinterlegten Bedarfe, um automatisch den ' + planLabel + ' anzulegen',
                            Properties: [
                                {
                                    key: 'Startdatum',
                                    formControl: form,
                                    options: {
                                        specialInput: {
                                            date: true,
                                        }
                                    }
                                },
                            ],
                        },
                    },
                })
                .afterClosed()
                .subscribe((ret) => {
                    if (ret?.Action === 'save') {
                        this.store.dispatch(EmployeeResourceScheduleActionTypes.CreateFromCommission({Payload: { commissionId: commission.Id, commissionTypeId: commission.CommissionTypeId, startDate: FrontendDate(form.value.toDate()) }}))
                    }
                });
        })
    }

    protected readonly LedgerImportDocumentTypeNames = LedgerImportDocumentTypeNames;
    protected readonly LedgerImportDocumentTypeNumberLabel = LedgerImportDocumentTypeNumberLabel;
    onCreateLodgerImportClock(docType: LedgerImportDocumentTypes) {
        firstValueFrom(combineLatest([this.Commission$, this.BookingPositions$]))
            .then(([ commission, bookingPositions]) => {
                switch (docType) {
                    case LedgerImportDocumentTypes.PartialInvoice:
                        this.dialog.open<NewPartialInvoiceComponent, NewPartialInvoiceComponentDialogData, NewPartialInvoiceComponentDialogReturnData>(NewPartialInvoiceComponent, {
                            ...NewPartialInvoiceComponent.DefaultConfig,
                            data: {
                                FilterLedgerImports: {
                                    CommissionId: commission.Id,
                                }
                            }
                        }).afterClosed().subscribe(ret => {
                            console.log(ret);
                            if (ret?.LedgerImportCreated) {
                                this.router.navigate(['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, ret.LedgerImportCreated]);
                            }
                        });
                        break;
                    case LedgerImportDocumentTypes.ClosingInvoice:
                        this.dialog.open<NewClosingInvoiceComponent, NewClosingInvoiceComponentDialogData, NewClosingInvoiceComponentDialogReturnData>(NewClosingInvoiceComponent, {
                            ...NewClosingInvoiceComponent.DefaultConfig,
                            data: {
                                FilterLedgerImports: {
                                    CommissionId: commission.Id,
                                }
                            }
                        }).afterClosed().subscribe(ret => {
                            console.log(ret);
                            if (ret?.LedgerImportCreated) {
                                this.router.navigate(['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, ret.LedgerImportCreated]);
                            }
                        });
                        break;
                    default:
                        this.store.dispatch(
                            LedgerImportActionTypes.AddLedgerImportWithDefaults({
                                Payload: {
                                    accountsReceivableLedgerIds: docType !== LedgerImportDocumentTypes.Offer ? bookingPositions?.map((b) => b.Id) : [],
                                    customerId: commission.CustomerId,
                                    commissionId: commission.Id,
                                    documentType: docType || LedgerImportDocumentTypes.Invoice,
                                    partnerOfficeId: commission?.PartnerOfficeId,
                                },
                            }),
                        );
                        firstValueFrom(this.store.select(getLedgerImports).pipe(
                            skip(1),
                        ),).then((ledgerImports) => {
                            let id = 0;
                            ledgerImports.forEach((l) => {
                                id = id < l.Id ? l.Id : id;
                            });
                            this.router.navigate(['/', ReportsPageMeta.Path, AllReportsMeta.Path, InvoiceEditorMeta.Path, id]);
                        })

                }

        })
    }
}
