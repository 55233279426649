import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    AnnotationService,
    BookmarkViewService,
    FormDesignerService,
    FormFieldsService, InkAnnotationSettings,
    LinkAnnotationService,
    MagnificationService,
    NavigationService,
    PdfViewerComponent,
    PrintService,
    TextSearchService,
    TextSelectionService,
    ThumbnailViewService,
    ToolbarService,
} from '@syncfusion/ej2-angular-pdfviewer';

import { ToolbarSettingsModel } from '@syncfusion/ej2-pdfviewer';
import { AllowedInteraction, SignStampItem } from '@syncfusion/ej2-pdfviewer/src/pdfviewer/base/types';
import { CustomStampSettings } from '@syncfusion/ej2-pdfviewer/src/pdfviewer/pdfviewer';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, firstValueFrom, merge, Subject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { EventEntity } from '../../../../dave-data-module/entities/event.entity';
import { VersionEntity } from '../../../../dave-data-module/entities/version.entity';
import { ComponentCanDeactivate } from '../../../../dave-data-module/guards/pending-changes.guard';
import { FileDataService } from '../../../../dave-data-module/services/file-data.service';
import { HttpService } from '../../../../dave-data-module/services/http.service';
import { State } from '../../../../dave-data-module/State';
import { BaseActionTypes } from '../../../../dave-data-module/State/actions/base.actions';
import { getToken } from '../../../../dave-data-module/State/selectors/base.selectors';
import { BreakpointObserverService } from '../../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { isNotNullOrUndefined, ProductName } from '../../../../helper/helper';
import { LoadingService } from '../../../../services/loading.service';
// L10n.load(TranslationObject);

@Component({
    selector: 'app-pdf-editor',
    templateUrl: './pdf-editor.component.html',
    styleUrls: ['./pdf-editor.component.scss'],
    providers: [
        LinkAnnotationService,
        BookmarkViewService,
        MagnificationService,
        ThumbnailViewService,
        ToolbarService,
        NavigationService,
        AnnotationService,
        TextSearchService,
        TextSelectionService,
        PrintService,
        FormFieldsService,
        FormDesignerService,
    ],
})
export class PdfEditorComponent implements AfterViewInit, OnDestroy, ComponentCanDeactivate {
    public Service = this.api.GetUrl('pdfviewer', 'sfdt');
    @Output() FileLoaded = new EventEmitter<void>();
    @Input() Version: VersionEntity;
    @Input() SigningMode = false;
    @Input() SetMapMarkerMode = false;
    @ViewChild('pdfViewer') PdfViewer: PdfViewerComponent;
    private afterViewInit$: Subject<void> = new Subject<void>();
    public Editing = false;
    public SaveButton = false;
    private loadedVersion: VersionEntity = null;

    protected inkAnnotationSettings: InkAnnotationSettings = {
        thickness: 3,
        // strokeColor: '#2a9916',
        strokeColor: '#00796b',
    } as InkAnnotationSettings;
    public ToolbarSettings: ToolbarSettingsModel = {
        toolbarItems: [
            'OpenOption',
            'PageNavigationTool',
            'MagnificationTool',
            'PanTool',
            'SelectionTool',
            'SearchOption',
            'PrintOption',
            // 'DownloadOption',
            'UndoRedoTool',
            'AnnotationEditTool',
            'CommentTool',
            // 'SubmitForm'
        ],
        annotationToolbarItems: [
            'HighlightTool',
            'UnderlineTool',
            'StrikethroughTool',
            // 'ShapeTool',
            'CalibrateTool',
            'ColorEditTool',
            'StrokeColorEditTool',
            'ThicknessEditTool',
            'OpacityEditTool',
            'AnnotationDeleteTool',
            // 'StampAnnotationTool',
            // 'HandWrittenSignatureTool', //  does not work in BE
            'InkAnnotationTool', // does not work in BE
            'FreeTextAnnotationTool',
            'FontFamilyAnnotationTool',
            'FontSizeAnnotationTool',
            'FontStylesAnnotationTool',
            'FontAlignAnnotationTool',
            'FontColorAnnotationTool',
            'CommentPanelTool',
        ],
    };
    constructor(private store: Store<State>, private api: HttpService, private fs: FileDataService, public LS: LoadingService, private ts: ToastrService, private elementRef: ElementRef, public BS: BreakpointObserverService) {
        combineLatest([store.select(getToken).pipe(filter(isNotNullOrUndefined)), this.afterViewInit$])
            .pipe(
                take(1),
                tap(([token]) => {
                    this.PdfViewer.ajaxRequestSettings.ajaxHeaders = [
                        {
                            headerName: 'Authorization',
                            headerValue: 'Bearer ' + token,
                        },
                    ];
                    this.loadedVersion = this.Version;
                    this.PdfViewer.load(this.loadedVersion.Id + '', null);
                    // this.FileLoaded.emit();
                    if (this.SigningMode) {
                        firstValueFrom(this.PdfViewer.documentLoad).then(() => {
                            if (!this.Editing) {
                                this.Edit();
                            }
                            this.OpenSignaturePopup();
                            const scroller = this.elementRef.nativeElement.querySelector('.e-pv-viewer-container');
                            scroller.scrollTop = scroller.childNodes[0].offsetHeight;
                        });
                    }
                    if (this.SetMapMarkerMode) {
                        setTimeout(() => {
                            this.startSetMarkerMode();
                        }, 1000);
                    }
                    this.setEventListener();
                }),
            )
            .subscribe();
    }
    ngAfterViewInit(): void {
        this.afterViewInit$.next();
    }
    ngOnDestroy() {
        this.Close();
    }
    Close() {
        this.fs.GetFileById$(this.Version.DocumentId, true).pipe(take(1)).subscribe();
        try {
            this.PdfViewer.unload();
        } catch (e) {
            console.error('PdfViewer.unload failed', e);
        }
        try {
            this.PdfViewer.destroy();
        } catch (e) {
            console.error('PdfViewer.destroy failed', e);
        }
    }
    Edit() {
        this.SaveButton = true;
        this.Editing = !this.Editing;
        this.PdfViewer.toolbar.showAnnotationToolbar(this.Editing);
        // console.log(this.PdfViewer.toolbar)
        // console.log(this.PdfViewer.toolbar.annotationToolbarModule)
        // console.log(this.PdfViewer.toolbar.annotationItem)
        // this.PdfViewer.toolbar.annotationItem.click()
    }
    Save() {
        // @ts-ignore
        // const hashId = this.PdfViewer.navigationModule.pdfViewerBase.hashId;
        // console.log('save \nversionId: ' + this.loadedVersion.Id, '\nhashId: ' + hashId);
        this.LS.startLoading('save-pdf-on-server-side');
        this.PdfViewer.annotation.setAnnotationMode('None');
        firstValueFrom(
            merge(
                this.PdfViewer.downloadEnd as EventEmitter<{ fileName: string; downloadDocument: string; name: 'downloadEnd' }>,
                (this.PdfViewer.ajaxRequestFailed as EventEmitter<{ name: 'ajaxRequestFailed'; documentName: string; errorStatusCode: number; errorMessage: string; action: string }>).pipe(filter((event) => event.action === 'Download')),
            ),
        ).then((event) => {
            this.LS.endLoading('save-pdf-on-server-side');
            if (event?.name === 'downloadEnd') {
                this.ts.success('PDF erfolgreich gespeichert');
            } else {
                this.store.dispatch(BaseActionTypes.ErrorAction({ Payload: { ToasterMessage: 'PDF speichern fehlgeschlagen', Err: event.errorMessage } }));
            }
        });
        this.PdfViewer.download();
        setTimeout(() => this.fs.GetFileById$(this.Version.DocumentId, true).pipe(take(1)).subscribe(), 3500);
        setTimeout(() => this.fs.GetFileById$(this.Version.DocumentId, true).pipe(take(1)).subscribe(), 10000);
    }
    OpenSignaturePopup() {
        // let addSignatureButton = document.querySelector('#pdfViewer_annotation_signature-popup [aria-label="ADD SIGNATURE"] button');
        // if (!addSignatureButton) {
        //     if (!this.PdfViewer.toolbarModule.annotationToolbarModule.toolbarCreated) {
        //         this.PdfViewer.toolbarModule.annotationItem.click();
        //     }
        //     this.PdfViewer.toolbarModule.annotationToolbarModule.handWrittenSignatureItem.click();
        //     addSignatureButton = document.querySelector('#pdfViewer_annotation_signature-popup [aria-label="ADD SIGNATURE"] button');
        // }
        // (addSignatureButton as HTMLElement).click();
        this.PdfViewer.annotationModule.setAnnotationMode('HandWrittenSignature');
    }
    // tslint:disable-next-line:naming-convention
    canDeactivate(): boolean /* | Observable<boolean>*/ {
        return !this.SaveButton;
    }

    public setEventListener() {
        // annotationAdd
        // annotationDoubleClick
        // annotationMouseLeave
        // annotationMouseover
        // annotationMove
        // annotationMoving
        // annotationPropertiesChange
        // annotationRemove
        // annotationResize
        // annotationSelect
        // annotationUnSelect
        this.PdfViewer.addEventListener('annotationSelect', (event) => {
            const anotation = this.PdfViewer.annotationCollection.find((a) => a.annotationId === event.annotationId);
            if (((anotation?.customStampName as string) || '').startsWith(PROGRAMMATICALLY_ANNOTATION_PREFIX)) {
                const regex = /eventId:([1-9]*);/g;
                const match = regex.exec(anotation.customStampName);
                const eventId = +match[1];
                alert('event Annotation clicked, eventId: ' + eventId);
            }
        });
    }
    public checkModeActive = false;
    public toggleAddCheckAnnotations() {
        if (this.checkModeActive) {
            this.stopAddCheckAnnotations();
        } else {
            this.startAddCheckAnnotations();
        }
    }

    public onAnnotationAdd(event) {
        if (this.checkModeActive && event.name === 'annotationAdd' && event.annotationType === 'Stamp') {
            setTimeout(() => this.startAddCheckAnnotations(), 300);
        }
    }
    public stopAddCheckAnnotations() {
        this.checkModeActive = false;

        // this.PdfViewer.annotationModule.clearSelection();
        // this.PdfViewer.annotationModule.deleteAnnotationById(this.PdfViewer.annotations[length -1].properties.id)
        // console.log(this.PdfViewer.annotations);
        // console.log(this.PdfViewer.annotations[this.PdfViewer.annotations.length -1]);
        // const id = this.PdfViewer.annotations[this.PdfViewer.annotations.length -1]['properties']['id'];
        // // this.PdfViewer.annotationModule.deleteAnnotation()
        // console.log(this.PdfViewer.annotationAdd) <-- works on desktop
        // console.log(this.PdfViewer.annotationModule.stampAnnotationModule.currentStampAnnotation)
        // this.PdfViewer.annotationModule.deleteAnnotationById(id)
        this.PdfViewer.annotationModule.setAnnotationMode('None', null, null, null);
    }
    public startAddCheckAnnotations() {
        this.checkModeActive = true;
        this.PdfViewer.annotationModule.setAnnotationMode('Stamp', null, SignStampItem.Accepted, null);
        console.log(this.PdfViewer.annotationModule.stampAnnotationModule.currentStampAnnotation)
        this.PdfViewer.annotationModule.stampAnnotationModule.currentStampAnnotation.width = this.PdfViewer.annotationModule.stampAnnotationModule.currentStampAnnotation.width * 0.7;
        this.PdfViewer.annotationModule.stampAnnotationModule.currentStampAnnotation.height = this.PdfViewer.annotationModule.stampAnnotationModule.currentStampAnnotation.height * 0.7;
    }
    public startSetMarkerMode() {
        // this.PdfViewer.annotationModule.setAnnotationMode('Image');
        this.PdfViewer.toolbarModule.annotationToolbarModule.createCustomStampElement();
        urltoFile(MAP_MARKER_BASE64, 'test.svg', 'image/svg+xml').then((file) => {
            this.PdfViewer.toolbarModule.annotationToolbarModule.addStampImage({ target: { files: [file] }, currentTarget: {} });
        });
        // this.PdfViewer.toolbarModule.annotationToolbarModule.

        // this.PdfViewer.annotationModule.triggerAnnotationAdd({
        //     data:
        //     MAP_MARKER_BASE64        })
    }
    getAnnotation() {
        return this.PdfViewer.annotationCollection;
    }

    addEventAnnotation(event: EventEntity, canMove = false) {
        const marker = event.AdditionalData?.mapMarker ? event.AdditionalData.mapMarker[this.Version.DocumentId] : undefined;
        if (marker) {
            marker.forEach((m) => this.addAnnotation(m.left, m.top, /*marker.page,*/ 'eventId:' + event.Id + ';', canMove));
        }
    }

    public addAnnotation(/*x : number, y : number, page: number,*/ left: number, top: number, uniqId: string, canMove = false) {
        const allowedInteractions = [AllowedInteraction.Delete];
        if (canMove) {
            allowedInteractions.push(AllowedInteraction.Move);
        }
        const options: Partial<CustomStampSettings> = {
            offset: { x: left, y: top },
            // left,
            // top,
            width: 16,
            author: ProductName,
            height: 32,
            isLock: true,
            allowedInteractions,
            // pageNumber: page,
            customStamps: [
                {
                    customStampName: PROGRAMMATICALLY_ANNOTATION_PREFIX + uniqId,
                    customStampImageSource: MAP_MARKER_BASE64,
                },
            ],
        };
        this.PdfViewer.annotation.addAnnotation('Stamp', options as CustomStampSettings);
    }
    public removeAnnotation() {
        this.PdfViewer.annotationCollection
            .filter((annotation) => (annotation.customStampName as string).startsWith(PROGRAMMATICALLY_ANNOTATION_PREFIX))
            .forEach((annotation) => {
                this.PdfViewer.annotationModule.deleteAnnotationById(annotation.annotationId);
            });
    }
    public resize() {
        this.PdfViewer.updateViewerContainer();
    }

    documentLoad() {
        this.FileLoaded.emit();
        // prevent chrome autoscroll on start drawing
        const wrapper: HTMLDivElement = document.querySelector('.e-pv-viewer-container');
        const pages = document.querySelectorAll<HTMLDivElement>('.e-pv-text-layer');
        if (wrapper && pages?.length) {
            pages.forEach((page) => {
                page.addEventListener('mousedown', (event) => {
                    if (document.activeElement !== wrapper) {
                        wrapper.focus({ preventScroll: true });
                    }
                });
            });
        }
    }
}
const PROGRAMMATICALLY_ANNOTATION_PREFIX = '#_programmatically_added_annotation_#';
const MAP_MARKER_BASE64 =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODQgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMjE1LjcgNDk5LjJDMjY3IDQzNSAzODQgMjc5LjQgMzg0IDE5MkMzODQgODYgMjk4IDAgMTkyIDBTMCA4NiAwIDE5MmMwIDg3LjQgMTE3IDI0MyAxNjguMyAzMDcuMmMxMi4zIDE1LjMgMzUuMSAxNS4zIDQ3LjQgMHpNMTkyIDEyOGE2NCA2NCAwIDEgMSAwIDEyOCA2NCA2NCAwIDEgMSAwLTEyOHoiLz48L3N2Zz4=';
// return a promise that resolves with a File instance
async function urltoFile(url: string, filename: string, mimeType?: string) {
    if (url.startsWith('data:')) {
        var arr = url.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        var file = new File([u8arr], filename, { type: mime || mimeType });
        return Promise.resolve(file);
    }
    let res = await fetch(url);
    let buf: ArrayBuffer = await res.arrayBuffer();
    return new File([buf], filename, { type: mimeType });
}
