import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy } from '@angular/core';
import { FormControl, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, startWith, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { SimplebarAngularModule } from 'simplebar-angular';
import { CkEditorComponent } from '../../../ck-editor/ck-editor.component';
import { EmailConnectionEntity } from '../../../dave-data-module/entities/email-connection.entity';
import { FolderTypes } from '../../../dave-data-module/entities/folder.entity';
import { GroupEntity } from '../../../dave-data-module/entities/group.entity';
import { PartnerOfficeEntity } from '../../../dave-data-module/entities/partnerOffice.entity';
import { Person2EntityEntity, Person2EntityEntityTypeEnum } from '../../../dave-data-module/entities/person2entity.entity';
import { EmailConnectionResolver } from '../../../dave-data-module/guards/email-connection.resolver';
import { EmployeeResolver } from '../../../dave-data-module/guards/employee.resolver';
import { GeneratedDocumentsResolver } from '../../../dave-data-module/guards/generatedDocuments.resolver';
import { GroupResolver } from '../../../dave-data-module/guards/group.resolver';
import { PartnerOfficeResolver } from '../../../dave-data-module/guards/partner-office.resolver';
import { PartnerResolver } from '../../../dave-data-module/guards/partner.resolver';
import { ComponentCanDeactivate } from '../../../dave-data-module/guards/pending-changes.guard';
import { PersonResolver } from '../../../dave-data-module/guards/person.resolver';
import { Person2EntityResolver } from '../../../dave-data-module/guards/person2Entity.resolver';
import { EmailDataService } from '../../../dave-data-module/services/email-data.service';
import { FileDataService } from '../../../dave-data-module/services/file-data.service';
import { FolderDataService } from '../../../dave-data-module/services/folder-data.service';
import { State } from '../../../dave-data-module/State';
import { DaveActions } from '../../../dave-data-module/State/actions/actions';
import { BaseActionTypes } from '../../../dave-data-module/State/actions/base.actions';
import { EmailActionTypes } from '../../../dave-data-module/State/actions/email.actions';
import { FilesActionTypes } from '../../../dave-data-module/State/actions/files.actions';
import { GeneratedDocumentsActionTypes } from '../../../dave-data-module/State/actions/generatedDocuments.actions';
import { getEmailConnections, getUndeletedEmailConnections } from '../../../dave-data-module/State/selectors/email-connection.selectors';
import { getEmployeeDictionary, getEmployees, getEmployeesFetched } from '../../../dave-data-module/State/selectors/employees.selectors';
import { getEventById } from '../../../dave-data-module/State/selectors/events.selectors';
import { getGeneratedDocuments, getGeneratedDocumentsDictionary } from '../../../dave-data-module/State/selectors/generatedDocuments.selectors';
import { getGroups, getGroupsFetched } from '../../../dave-data-module/State/selectors/group.selectors';
import {
    getOwnPartnerOffices,
    getPartner,
    getPartnerFetched,
} from '../../../dave-data-module/State/selectors/partners.selectors';
import { getPersonDictionary, getPersons } from '../../../dave-data-module/State/selectors/person.selectors';
import { getPerson2Entities } from '../../../dave-data-module/State/selectors/person2entity.selectors';
import { getUser } from '../../../dave-data-module/State/selectors/users.selectors';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { IcsService } from '../../../dave-history-module/services/ics.service';
import { DaveLoadingModule } from '../../../dave-loading/dave-loading.module';
import { DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData } from '../../../dave-select-file-from-dms/components/dave-select-file-from-dms/dave-select-file-from-dms.component';
import { AppButtonModule } from '../../../dave-utils-module/app-button-module/app-button.module';
import { InfoDialogComponent } from '../../../dave-utils-module/dave-shared-components-module/components/dialogs/info-dialog/info-dialog.component';
import { DaveSharedComponentsModule } from '../../../dave-utils-module/dave-shared-components-module/dave-shared-components.module';
import { BreakpointObserverService } from '../../../dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service';
import { SelectSearchModule } from '../../../dave-utils-module/select-search/select-search.module';
import {
    CKEditorDefaultConfigEmail,
    CKEditorDefaultConfigEmailFontFamilyOptions,
    CKEditorDefaultConfigEmailFontSizeOptions,
} from '../../../helper/ckeditor.helper';
import { appMatDialogDefaultConfig, compareArrays, isNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import { DMSPageMeta, GroupsIcon, ReportsPageMeta } from '../../../helper/page-metadata';
import { LoadingService } from '../../../services/loading.service';
import { MailContentService } from '../../mail-content.service';
import { CreateEmailEventComponent, CreateEmailEventComponentDialogData } from '../create-email-event/create-email-event.component';
import { EmailEditorCloseDialogComponent, EmailEditorCloseDialogComponentDialogData, EmailEditorCloseDialogComponentReturn } from '../email-editor-close-dialog/email-editor-close-dialog.component';
import {
    EmailFileUploadFileSystemComponent,
    EmailFileUploadFileSystemComponentDialogData,
    EmailFileUploadFileSystemComponentDialogReturnData,
} from '../email-file-upload/email-file-upload-file-system/email-file-upload-file-system.component';
import { EmailFileUploadReportsComponent, EmailFileUploadReportsComponentDialogData, EmailFileUploadReportsComponentReturnDialogData } from '../email-file-upload/email-file-upload-reports/email-file-upload-reports.component';
import { EmailAutocompleteOption, EmailFormComponent } from '../email-form/email-form.component';
import { getFetched$ } from '../../../dave-data-module/helper/helper';
import { PartnerTypeEnum } from '../../../dave-data-module/entities/partner.entity';

const MAX_EMAIL_SIZE = 52428800;
const EDITOR_WIDTH = '1400px'; // $editor-width in .scss
export const EmailEditorComponentDialogConfig: MatDialogConfig = {
    ...appMatDialogDefaultConfig,
    hasBackdrop: false,
    autoFocus: false,
    maxHeight: '100vh',
    width: EDITOR_WIDTH,
    maxWidth: '100vw',
    panelClass: [...appMatDialogDefaultConfig.panelClass, 'custom-dialog-class-without-padding'],
};

export interface EmailEditorComponentDialogData {
    Id?: number;
    TargetEmails?: string[];
    CCEmails?: string[];
    BCCEmails?: string[];
    EmailSubject?: string;
    EmailHtml?: string;
    EmailId?: number;
    EmailSourceConnectionId?: number;
    FileAttachmntFileIds?: number[];
    GeneratedFileAttachmntFileIds?: number[];
    CommissionId?: number;
    CustomerId?: number;
    ReferredEventId?: number;
    PartnerOfficeId?: number;
    FolderIdForSelectFiles?: number;
    ProcessId?: number;
}
interface IFileAttachment {
    Id: number;
    Name: string;
    documentId?: number;
    type: 'file' | 'generatedDocument';
}
const getHtmlForEditorContent = (v: string) => {
    const reg = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
    let html = '';

    let regRes = v?.match(reg);
    if (regRes) {
        html = regRes[1];
    } else {
        html = v;
    }
    return html;
};
@Component({
    selector: 'app-email-editor',
    templateUrl: './email-editor.component.html',
    styleUrls: ['./email-editor.component.scss'],
    providers: [DatePipe],
    standalone: true,
    imports: [
        CommonModule,
        FontAwesomeModule,
        DragDropModule,
        AppButtonModule,
        MatTooltipModule,
        MatInputModule,
        ReactiveFormsModule,
        SelectSearchModule,
        MatMenuModule,
        // DaveSharedComponentsModule,
        CkEditorComponent,
        MatExpansionModule,
        MatSelectModule,
        SimplebarAngularModule,
        DaveLoadingModule,
        EmailFormComponent,
        CreateEmailEventComponent,
        DaveSharedComponentsModule,
    ],
})
export class EmailEditorComponent implements OnDestroy, ComponentCanDeactivate {
    public static readonly DefaultConfig = EmailEditorComponentDialogConfig;
    public MaxEmailSize = MAX_EMAIL_SIZE;
    public DMSMeta = DMSPageMeta;
    public ReportsMeta = ReportsPageMeta;
    public GroupsIcon = GroupsIcon;
    public EditorContentForm = new FormControl<string>('');
    public ReferredEventId: number = null;
    public CommissionId: number = null;
    public CustomerId: number = null;
    public EmailId: number = null;
    public InitTargetEmails: string[];
    public InitCCEmails: string[];
    public InitBCCEmails: string[];
    public ExpandEmailForms = true;
    public SourceEmailForm = new FormControl<EmailConnectionEntity & { optionLabel: string }>(null, Validators.required);
    public PartnerOfficeForm: FormControlTyped<PartnerOfficeEntity> = new UntypedFormControl(null);
    public PartnerOffices$ = this.store.select(getOwnPartnerOffices);
    public EmailSubjectForm = new UntypedFormControl(this.Dialogdata?.EmailSubject);
    public FileAttachment$ = new BehaviorSubject<number[]>(this.Dialogdata?.FileAttachmntFileIds || []);
    public GeneratedFileAttachment$ = new BehaviorSubject<number[]>(this.Dialogdata?.GeneratedFileAttachmntFileIds || []);
    protected GeneratedFileAttachmentEntities$ = this.GeneratedFileAttachment$.pipe(
        switchMap((ids) =>
            this.store.select(getGeneratedDocumentsDictionary).pipe(
                map((docs) => {
                    ids.map((id) => docs[id]);
                }),
            ),
        ),
    );
    public Send$: Subject<void> = new Subject<void>();
    public Save$: Subject<void> = new Subject<void>();
    public EditorChangeContent$ = new Subject<null>();
    public TargetEmails$ = new BehaviorSubject<string[]>([]);
    public DataInitialized$: Observable<boolean>;
    public CCEmails$ = new BehaviorSubject<string[]>([]);
    public BCCEmails$ = new BehaviorSubject<string[]>([]);
    public SourceEmailList: Array<EmailConnectionEntity & { optionLabel: string }>;
    public SourceEmailList$ = this.store.select(getUndeletedEmailConnections);
    public IsMobile$ = this.breakpointService.MobileQuery;
    protected attachmentIncludesIcsFileFromReferredEvent = false;
    public ShownFileAttachment$: Observable<IFileAttachment[]> = this.FileAttachment$.pipe(
        switchMap((fileAttachment) => combineLatest([this.fileDataService.GetFilesById(fileAttachment), this.store.select(getGeneratedDocuments), this.GeneratedFileAttachment$])),
        tap(([files]) => (this.attachmentIncludesIcsFileFromReferredEvent = files.some((f) => f.MetaData?.isCalendarFromEventId))), //todo auf richtige eventId prüfen
        map(([files, genFiles, genFileAttachment]) =>
            files && genFiles
                ? [
                      ...files.map<IFileAttachment>((f) => ({ Name: f.Name, Id: f.Id, documentId: f.Id, type: 'file' })),
                      ...genFiles.filter((f) => genFileAttachment.includes(f.Id)).map<IFileAttachment>((gd) => ({ Id: gd.Id, Name: gd.Name, documentId: gd.DocumentIdFromPdf || gd.AdditionalData?.FileId, type: 'generatedDocument' })),
                  ]
                : null,
        ),
        shareReplay({ bufferSize: 1, refCount: true }),
    );
    public EmailList$: Observable<EmailAutocompleteOption[]> = combineLatest([
        this.store.select(getPersons).pipe(
            filter(isNotNullOrUndefined),
            map((array) => array.filter((v) => v.Email)),
        ),
        this.Dialogdata.CommissionId === undefined
            ? (of([]) as Observable<Person2EntityEntity[]>)
            : this.store.select(getPerson2Entities).pipe(map((p2es) => p2es.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Commission && p2e.EntityId === this.Dialogdata.CommissionId))),
        this.Dialogdata.CustomerId === undefined
            ? (of([]) as Observable<Person2EntityEntity[]>)
            : this.store.select(getPerson2Entities).pipe(map((p2es) => p2es.filter((p2e) => p2e.EntityType === Person2EntityEntityTypeEnum.Customer && p2e.EntityId === this.Dialogdata.CustomerId))),
    ]).pipe(
        map(([persons, p2esCommission, p2esCustomer]) => {
            return [
                ...persons.filter((p) => p2esCommission.find((p2e) => p2e.PersonId === p.Id) !== undefined),
                ...persons.filter((p) => p2esCustomer.find((p2e) => p2e.PersonId === p.Id) !== undefined),
                ...persons.filter((p) => p2esCommission.find((p2e) => p2e.PersonId === p.Id) === undefined && p2esCustomer.find((p2e) => p2e.PersonId === p.Id) === undefined),
            ];
        }),
    );
    protected lastSignature: string;
    protected lastEmailSignature: string;
    private subscriptions: Subscription[] = [];
    private filePreviewDialog: MatDialogRef<any>;
    public MailSize$: Observable<number> = this.FileAttachment$.pipe(
        switchMap((filesIds) => combineLatest([this.fileDataService.GetFilesById(filesIds), this.store.select(getGeneratedDocuments), this.GeneratedFileAttachment$])),
        map(([files, genFiles, genFileAttachment]) => ({
            files,
            genFiles: genFiles
                .filter((f) => genFileAttachment.includes(f.Id))
                .map((f) => f.DocumentIdFromPdf)
                .filter(isNotNullOrUndefined),
        })),
        switchMap(({ files, genFiles }) => combineLatest([of(files), this.fileDataService.GetFilesById(genFiles), this.EditorChangeContent$.pipe(startWith(null))])),
        map(([files, genFiles, _]) => {
            let fileSize = files.reduce((sum, f) => sum + f.GetLastVersion().Size, 0);
            let genFilesSize = genFiles.reduce((sum, f) => sum + f.GetLastVersion().Size, 0);
            let testSize = new TextEncoder().encode(this.EditorContentForm.value).length;
            return fileSize + genFilesSize + testSize;
        }),
    );
    public Groups$: Observable<GroupEntity[]>;
    private genDocsWithoutPDF$ = this.ShownFileAttachment$.pipe(
        map((atts) => atts.filter((t) => t.type === 'generatedDocument' && !t.documentId).map((t) => t.Id)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    );
    private partnerTypeId$ =  getFetched$(this.store, getPartnerFetched, getPartner).pipe(
        map(partner => partner.PartnerTypeId),
        distinctUntilChanged(),
    );
    protected defaultFontValues$: Observable<{size: CKEditorDefaultConfigEmailFontSizeOptions, family: CKEditorDefaultConfigEmailFontFamilyOptions}> = getFetched$(this.store, getPartnerFetched, getPartner).pipe(map(partner => {
        return {
            size: partner.AdditionalData?.emailEditorDefaultFontSize || 'default',
            family: partner.AdditionalData?.emailEditorDefaultFontFamily || 'default',
        };
    }));
    constructor(
        private ics: IcsService,
        private dialog: MatDialog,
        public DialogRef: MatDialogRef<EmailEditorComponent>,
        private store: Store<State>,
        @Inject(MAT_DIALOG_DATA)
        public Dialogdata: EmailEditorComponentDialogData,
        private generatedDocsResolver: GeneratedDocumentsResolver,
        private emailConnectionResolver: EmailConnectionResolver,
        protected MailContentService: MailContentService,
        protected datePipe: DatePipe,
        personResolver: PersonResolver,
        partnerOfficeResolver: PartnerOfficeResolver,
        partnerResolver: PartnerResolver,
        person2EntityResolver: Person2EntityResolver,
        private breakpointService: BreakpointObserverService,
        private actions$: Actions<DaveActions>,
        public LS: LoadingService,
        private fileDataService: FileDataService,
        private emailDataService: EmailDataService,
        groupResolver: GroupResolver,
        employeeResolver: EmployeeResolver,
        private folderDataService: FolderDataService,
    ) {
        this.Groups$ = this.Dialogdata.CommissionId
            ? combineLatest([
                  this.store.select(getGroupsFetched).pipe(
                      tap((fetched) => {
                          if (!fetched) {
                              groupResolver.resolve();
                          }
                      }),
                      filter((v) => v),
                      switchMap(() => this.store.select(getGroups)),
                  ),
                  this.store.select(getUser).pipe(
                      switchMap((user) =>
                          this.store.select(getEmployeesFetched).pipe(
                              tap((fetched) => {
                                  if (!fetched) {
                                      employeeResolver.resolve();
                                  }
                              }),
                              filter((v) => v),
                              switchMap(() => this.store.select(getEmployees)),
                              map((employees) => employees.find((e) => e.UserId === user.Id)),
                          ),
                      ),
                  ),
              ]).pipe(
                  map(([groups, employee]) => groups.filter((g) => g.CommissionId === this.Dialogdata.CommissionId).map((g) => g.Clone({ GroupMembers: g.GroupMembers.filter((m) => m.EmployeeId !== employee.Id) }))),
                  shareReplay({ refCount: true, bufferSize: 1 }),
              )
            : of([]);
        this.ExpandEmailForms = Dialogdata?.CCEmails?.length > 0 || Dialogdata?.BCCEmails?.length > 0 ? false : this.ExpandEmailForms;
        this.DataInitialized$ = combineLatest([generatedDocsResolver.resolve(), emailConnectionResolver.resolve(), personResolver.resolve(), partnerOfficeResolver.resolve(), partnerResolver.resolve(), person2EntityResolver.resolve()]).pipe(
            map(() => true),
        );
        const alreadySubmittedGenDocsForPdf: number[] = [];
        this.subscriptions.push(
            this.SourceEmailList$.pipe(filter(isNotNullOrUndefined)).subscribe((em) => (this.SourceEmailList = em.map((e) => Object.assign(e.Clone(), { optionLabel: e.Address })))),
            this.genDocsWithoutPDF$.subscribe((ids) => {
                ids.forEach((id) => {
                    if (!alreadySubmittedGenDocsForPdf.includes(id)) {
                        alreadySubmittedGenDocsForPdf.push(id);
                        this.store.dispatch(GeneratedDocumentsActionTypes.ModifyGeneratedDocuments({ Payload: { id, uploadPdf: true } }));
                    }
                });
            }),
            this.genDocsWithoutPDF$
                .pipe(
                    filter((a) => !!a.length),
                    switchMap((ids) => timer(1000, 20000)),
                )
                .subscribe(() => this.generatedDocsResolver.pollUpdated()),
            this.store
                .select(getEmailConnections)
                .pipe(
                    filter(isNotNullOrUndefined),
                    map((cons) => cons.filter((c) => !c?.Deleted)),
                    filter(isNotNullOrUndefined),
                    take(1),
                    tap((ec) => {
                        if (ec.length === 0) {
                            this.dialog.open(InfoDialogComponent, {
                                data: {
                                    heading: 'Keine Email-Einstellungen vorhanden',
                                    paragraph: 'Es wurden noch keine Email-Einstellungen angelegt',
                                    routingButtonRoute: ['/', 'einstellungen', 'email'],
                                    routingButtonText: 'Zu den Einstellungen',
                                },
                            });
                        }
                    }),
                )
                .subscribe(),
            combineLatest([this.SourceEmailForm.valueChanges, this.PartnerOfficeForm.valueChanges]).subscribe(([v, po]) => {
                const initialHtml = this.Dialogdata?.EmailHtml || '';

                /**attempt to re-insert editor content after signature change
                 * not possible so far, since initialhtml differs as soon as content gets typed in
                 */
                // let editorMainContent = this.EditorContent.replace(v?.Signature, '');
                // editorMainContent = editorMainContent.replace(po?.EmailSignature, '');
                // editorMainContent = editorMainContent.replace(initialHtml, '');
                // editorMainContent = editorMainContent.replace(this.lastEmailSignature, '');

                if (this.lastSignature === null || this.EditorContentForm.value === initialHtml + this.lastSignature || this.EditorContentForm.value === initialHtml || this.EditorContentForm.value === '') {
                    this.EditorContentForm.reset(initialHtml + (v?.Signature || '') + (po?.EmailSignature || ''));
                }
                this.lastSignature = (v?.Signature || '') + (po?.EmailSignature || '');
                //this.lastEmailSignature = po?.EmailSignature;
                //this.EditorContent = initialHtml + (v?.Signature || '') + (po?.EmailSignature || '');
            }),
            this.Send$.pipe(
                withLatestFrom(this.TargetEmails$, this.CCEmails$, this.BCCEmails$, this.FileAttachment$, this.GeneratedFileAttachment$),
                tap(([, target, cc, bcc, fileAttachment, generatedFileAttachment]) => {
                    if (this.EmailId) {
                        const lKey = 'send-email--' + this.EmailId;
                        this.LS.startLoading(lKey);
                        this.store.dispatch(
                            EmailActionTypes.ModifyAndSendRequest({
                                Payload: {
                                    id: this.EmailId,
                                    body: '',
                                    htmlBody: this.EditorContentForm.value,
                                    textBody: '',
                                    subject: this.EmailSubjectForm.value,
                                    to: target,
                                    emailConnectionId: this.SourceEmailForm.value?.Id,
                                    // userId: 1,
                                    carbonCopy: cc?.length ? cc : null,
                                    blindCarbonCopy: bcc?.length ? bcc : null,
                                    documentIds: fileAttachment,
                                    generatedDocumentIds: generatedFileAttachment,
                                    commissionId: this.CommissionId || null,
                                    customerId: this.CustomerId || null,
                                    from: this.SourceEmailForm.value?.Address,
                                },
                            }),
                        );
                        this.actions$.pipe(ofType(EmailActionTypes.ModifyAndSendFailure, EmailActionTypes.ModifyAndSendSuccess), take(1)).subscribe((action) => {
                            this.LS.endLoading(lKey);
                            if (action.type === EmailActionTypes.ModifyAndSendSuccess.type) {
                                this.close();
                            }
                        });
                    } else {
                        const lKey = 'send-email--new';
                        this.LS.startLoading(lKey);
                        this.store.dispatch(
                            EmailActionTypes.CreateAndSendRequest({
                                Payload: {
                                    body: '',
                                    htmlBody: this.EditorContentForm.value,
                                    textBody: '',
                                    subject: this.EmailSubjectForm.value,
                                    to: target,
                                    emailConnectionId: this.SourceEmailForm.value?.Id,
                                    // userId: 1,
                                    carbonCopy: cc?.length ? cc : null,
                                    blindCarbonCopy: bcc?.length ? bcc : null,
                                    documentIds: fileAttachment,
                                    generatedDocumentIds: generatedFileAttachment,
                                    commissionId: this.CommissionId || null,
                                    customerId: this.CustomerId || null,
                                    from: this.SourceEmailForm.value?.Address,
                                },
                            }),
                        );
                        this.actions$.pipe(ofType(EmailActionTypes.CreateAndSendSuccess, EmailActionTypes.CreateAndSendFailure), take(1)).subscribe((action) => {
                            this.LS.endLoading(lKey);
                            if (action.type === EmailActionTypes.CreateAndSendSuccess.type) {
                                this.close();
                            }
                        });
                    }
                }),
            ).subscribe(),
            this.Save$.pipe(
                withLatestFrom(this.TargetEmails$, this.CCEmails$, this.BCCEmails$, this.FileAttachment$, this.GeneratedFileAttachment$),
                tap(([, target, cc, bcc, fileAttachment, generatedFileAttachment]) => {
                    if (this.EmailId) {
                        this.store.dispatch(
                            EmailActionTypes.ModifyEmail({
                                Payload: {
                                    id: this.EmailId,
                                    body: '',
                                    htmlBody: this.EditorContentForm.value,
                                    textBody: '',
                                    subject: this.EmailSubjectForm.value,
                                    to: target,
                                    emailConnectionId: this.SourceEmailForm.value?.Id,
                                    // userId: 1,
                                    carbonCopy: cc,
                                    blindCarbonCopy: bcc,
                                    documentIds: fileAttachment,
                                    generatedDocumentIds: generatedFileAttachment,
                                    commissionId: this.CommissionId || null,
                                    customerId: this.CustomerId || null,
                                    from: this.SourceEmailForm.value?.Address,
                                },
                            }),
                        );
                    } else {
                        this.actions$.pipe(ofType(EmailActionTypes.UpdateOneEmail), take(1)).subscribe(({ Payload }) => {
                            this.EmailId = Payload.Id;
                        });
                        this.store.dispatch(
                            EmailActionTypes.AddEmail({
                                Payload: {
                                    body: '',
                                    htmlBody: this.EditorContentForm.value,
                                    textBody: '',
                                    subject: this.EmailSubjectForm.value,
                                    to: target,
                                    emailConnectionId: this.SourceEmailForm.value?.Id,
                                    // userId: 1,
                                    carbonCopy: cc,
                                    blindCarbonCopy: bcc,
                                    documentIds: fileAttachment,
                                    generatedDocumentIds: generatedFileAttachment,
                                    commissionId: this.CommissionId,
                                    customerId: this.CustomerId,
                                    processId: this.Dialogdata?.ProcessId || null,
                                    referredEventId: this.Dialogdata?.ReferredEventId || null,
                                    seen: true,
                                    isFromDave: true,
                                    from: this.SourceEmailForm.value?.Address,
                                },
                            }),
                        );
                    }
                }),
            ).subscribe(),
            // this.EditorChangeContent$.pipe(debounceTime(2000)).subscribe(() => this.Save$.next()),
            combineLatest([
                this.Dialogdata?.Id ? this.emailDataService.GetEmailById$(this.Dialogdata.Id).pipe(filter(isNotNullOrUndefined)) : of(null),
                this.Dialogdata?.EmailId ? this.emailDataService.GetEmailById$(this.Dialogdata.EmailId).pipe(filter(isNotNullOrUndefined)) : of(null),
                this.emailConnectionResolver.resolve().pipe(map((cons) => cons.filter((c) => !c?.Deleted))),
                this.store.select(getOwnPartnerOffices).pipe(filter(isNotNullOrUndefined)),
            ])
                .pipe(
                    take(1),
                    tap(([email, email2, emailConnections, partnerOffices]) => {
                        this.PartnerOfficeForm.setValue(this.Dialogdata?.PartnerOfficeId ? partnerOffices?.find((p) => p.Id === this.Dialogdata?.PartnerOfficeId) : partnerOffices?.find((p) => p.MainOffice));
                        if (email) {
                            this.MailContentService.TransformToMail(email, 'base64')
                                .pipe(
                                    take(1),
                                    withLatestFrom(this.store.select(getEmailConnections)),
                                    tap(([v]) => {
                                        //TODO der editor löscht hr
                                        // let insertText = '<br><div style="width: 100%;height: 1px;background: #f00;"</div> <hr>' +
                                        let html = getHtmlForEditorContent(v.html);

                                        this.EditorContentForm.reset(html || v.Text);
                                        this.Dialogdata.EmailHtml = html;
                                    }),
                                )
                                .subscribe();
                        }
                        if (this.Dialogdata?.Id && email) {
                            this.EditorContentForm.reset(''); // email.HTMLBody;
                            this.InitBCCEmails = email.BlindCarbonCopy;
                            this.BCCEmails$.next(email.BlindCarbonCopy);
                            this.InitCCEmails = email.CarbonCopy;
                            this.CCEmails$.next(email.CarbonCopy);
                            this.InitTargetEmails = email.To;
                            this.TargetEmails$.next(email.To);
                            this.FileAttachment$.next(email.DocumentIds || []);
                            this.GeneratedFileAttachment$.next(email.GeneratedDocumentIds || []);
                            this.EmailId = email.Id;
                            this.CustomerId = email.CustomerId;
                            this.CommissionId = email.CommissionId;
                            this.ReferredEventId = email.ReferredEventId;
                            this.SourceEmailForm.setValue(Object.assign(emailConnections?.find((e) => e.Id === email.EmailConnectionId).Clone(), { optionLabel: emailConnections?.find((e) => e.Id === email.EmailConnectionId).Address }));
                        } else {
                            this.CustomerId = this.Dialogdata?.CustomerId;
                            this.CommissionId = this.Dialogdata?.CommissionId;
                            this.EditorContentForm.reset(this.Dialogdata?.EmailHtml || '');
                            this.ReferredEventId = this.Dialogdata?.ReferredEventId;
                            if (this.Dialogdata?.EmailId !== undefined) {
                                this.MailContentService.TransformToMail(email2, 'base64')
                                    .pipe(
                                        take(1),
                                        withLatestFrom(this.store.select(getEmailConnections)),
                                        tap(([v, connections]) => {
                                            const connection = connections.find((c) => c.Id === email2.EmailConnectionId);
                                            //TODO der editor löscht hr
                                            // let insertText = '<br><div style="width: 100%;height: 1px;background: #f00;"</div> <hr>' +
                                            let insertText = '<br>' + '<p>' + 'von: ' + email2.From + '<br>' + 'an: ' + email2.To.join(', ') + '<br>';

                                            if (email2.CarbonCopy.length) {
                                                insertText += 'cc: ' + email2.CarbonCopy.join(', ') + '<br>';
                                            }

                                            // if (email2.BlindCarbonCopy.length) {
                                            //     insertText += 'bcc: ' + email2.BlindCarbonCopy.join(', ') + '<br>';
                                            // }

                                            insertText += 'am: ' + this.datePipe.transform(email2.SendDate) + '<br>';
                                            insertText += 'Betreff: ' + email2.Subject + '<br>';
                                            insertText += '</p>';

                                            let bodyReg = /<body[^>]*>((.|[\n\r])*)<\/body>/im;
                                            let styleReg = /<style[^>]*>((.|[\n\r])*?)<\/style[^>]*>/g;

                                            let html = '';

                                            let regRes = v.html?.match(bodyReg);
                                            if (regRes) {
                                                html = regRes[1];
                                            } else {
                                                html = v.html;
                                            }
                                            if (html) {
                                                html = html.replace(styleReg, '');
                                            }

                                            this.EditorContentForm.reset(connection?.Signature + insertText + (html || v.Text || ''));
                                            // wofür soll das sein ?
                                            this.Dialogdata.EmailHtml = '<br>' + insertText + html;
                                        }),
                                    )
                                    .subscribe();
                            }
                            this.InitBCCEmails = this.Dialogdata?.BCCEmails;
                            this.BCCEmails$.next(this.Dialogdata?.BCCEmails);
                            this.InitCCEmails = this.Dialogdata?.CCEmails;
                            this.CCEmails$.next(this.Dialogdata?.CCEmails);
                            this.InitTargetEmails = this.Dialogdata?.TargetEmails;
                            this.TargetEmails$.next(this.Dialogdata?.TargetEmails);
                            this.FileAttachment$.next(this.Dialogdata?.FileAttachmntFileIds || []);
                            this.GeneratedFileAttachment$.next(this.Dialogdata?.GeneratedFileAttachmntFileIds || []);
                            this.SourceEmailForm.setValue(
                                Object.assign(
                                    this.Dialogdata?.EmailSourceConnectionId ? emailConnections?.find((e) => e.Id === this.Dialogdata?.EmailSourceConnectionId).Clone() : emailConnections?.length ? emailConnections[0].Clone() : null,
                                    {
                                        optionLabel: this.Dialogdata?.EmailSourceConnectionId
                                            ? emailConnections?.find((e) => e.Id === this.Dialogdata?.EmailSourceConnectionId).Address
                                            : emailConnections?.length
                                            ? emailConnections[0].Address
                                            : '',
                                    },
                                ),
                            );
                            // sorgt dafür das beim senden schon eine entity im be ist auf die man modifyAndSend anwenden kann
                            // this.Save$.next();
                        }
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    public OpenCreateEvent() {
        firstValueFrom(
            this.dialog
                .open<CreateEmailEventComponent, CreateEmailEventComponentDialogData>(CreateEmailEventComponent, {
                    ...CreateEmailEventComponent.DefaultConfig,
                    data: {
                        defaultValues: {
                            commissionId: this.CommissionId,
                            customerId: this.CustomerId,
                        },
                    },
                })
                .afterClosed(),
        ).then((a) => {
            const val = this.FileAttachment$.value.slice();
            val.push(a);
            this.FileAttachment$.next(val);
        });
    }

    public OpenFileUpload(type: 'dms' | 'reports' | 'filesystem') {
        if (type === 'dms') {
            const getFolderId$ = (): Observable<number | null> => {
                if (this.Dialogdata?.FolderIdForSelectFiles) {
                    return of(this.Dialogdata?.FolderIdForSelectFiles);
                }
                if (this.Dialogdata?.CommissionId) {
                    return this.folderDataService.getFolderFromEntity(this.Dialogdata.CommissionId, FolderTypes.commission).pipe(
                        first(),
                        map((f) => f?.Id || null),
                    );
                }
                if (this.Dialogdata?.CustomerId) {
                    return this.folderDataService.getFolderFromEntity(this.Dialogdata.CustomerId, FolderTypes.customer).pipe(
                        first(),
                        map((f) => f?.Id || null),
                    );
                }
                return of(null);
            };
            getFolderId$()
                .pipe(take(1))
                .subscribe((folderId) => {
                    this.dialog
                        .open<DaveSelectFileFromDmsComponent, DaveSelectFileFromDmsComponentDialogData>(DaveSelectFileFromDmsComponent, {
                            ...DaveSelectFileFromDmsComponent.DefaultConfig,
                            data: {
                                preSelectedFiles: this.FileAttachment$.value,
                                folderId,
                            },
                        })
                        .afterClosed()
                        .pipe(
                            take(1),
                            filter((v) => !!v),
                            tap((selectedFiles) => {
                                this.FileAttachment$.next(selectedFiles.documents);
                            }),
                        )
                        .subscribe();
                });
        } else if (type === 'reports') {
            this.dialog
                .open<EmailFileUploadReportsComponent, EmailFileUploadReportsComponentDialogData, EmailFileUploadReportsComponentReturnDialogData>(EmailFileUploadReportsComponent, {
                    ...EmailFileUploadReportsComponent.DefaultConfig,
                })
                .afterClosed()
                .pipe(
                    take(1),
                    filter((v) => !!v),
                    tap((selectedFiles) => {
                        const val2 = this.GeneratedFileAttachment$.value.slice();
                        val2.push(...selectedFiles);
                        this.GeneratedFileAttachment$.next(val2);
                    }),
                )
                .subscribe();
        } else {
            this.dialog
                .open<EmailFileUploadFileSystemComponent, EmailFileUploadFileSystemComponentDialogData, EmailFileUploadFileSystemComponentDialogReturnData>(EmailFileUploadFileSystemComponent, {
                    ...EmailFileUploadFileSystemComponent.DefaultConfig,
                })
                .afterClosed()
                .pipe(
                    take(1),
                    filter((v) => !!v),
                    tap((selectedFiles) => {
                        const val = this.FileAttachment$.value.slice();
                        val.push(...selectedFiles);
                        this.FileAttachment$.next(val);
                    }),
                )
                .subscribe();
        }
    }

    OpenFilePreview(fileId: number) {
        if (this.filePreviewDialog?.getState() === MatDialogState.OPEN) {
            this.filePreviewDialog.close();
        }
        this.filePreviewDialog = this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
            },
        });
    }

    public RemoveFileFromAttachment(file: IFileAttachment) {
        if (file.type === 'file') {
            const selectedFiles = this.FileAttachment$.value.slice();
            if (selectedFiles.indexOf(file.Id) >= 0) {
                selectedFiles.splice(selectedFiles.indexOf(file.Id), 1);
            }
            this.FileAttachment$.next(selectedFiles);
        } else if (file.type === 'generatedDocument') {
            const selectedFiles = this.GeneratedFileAttachment$.value.slice();
            if (selectedFiles.indexOf(file.Id) >= 0) {
                selectedFiles.splice(selectedFiles.indexOf(file.Id), 1);
            }
            this.GeneratedFileAttachment$.next(selectedFiles);
        }
    }
    onGroupClick(group: GroupEntity) {
        firstValueFrom(combineLatest([this.store.select(getEmployeeDictionary), this.store.select(getPersonDictionary), this.store.select(getUser)])).then(([employees, persons, user]) => {
            this.TargetEmails$.next(uniqArray([...this.TargetEmails$.value, ...group.GroupMembers.map((m) => (m.EmployeeId ? employees[m.EmployeeId]?.Email : persons[m.PersonId]?.Email)).filter((v) => v && v !== user.Email)]));
        });
    }
    compareById(a, b) {
        return a?.Id === b?.Id;
    }
    addIcsFromEvent() {
        if (this.ReferredEventId) {
            this.LS.startLoading('add-ics-for-email');
            firstValueFrom(this.store.select(getEventById({ id: this.ReferredEventId }))).then((event) => {
                if (!event || !event.EventDate || !event.EventEndDate) {
                    this.store.dispatch(
                        BaseActionTypes.ErrorAction({
                            Payload: {
                                ToasterMessage: event ? 'Das Ereignis muss ein Startdatum und ein Enddatum haben' : 'Ereignis wurde nicht gefunden',
                            },
                        }),
                    );
                    this.LS.endLoading('add-ics-for-email');
                    return;
                }
                this.ics
                    .GetICSFileFromEvent(event)
                    .then((file) => {
                        this.store.dispatch(FilesActionTypes.UploadFileBackendRequest({ Payload: { file, params: new Map([['metadata', JSON.stringify({ isCalendarFromEventId: event.Id })]]) } }));
                        return firstValueFrom(this.actions$.pipe(ofType(FilesActionTypes.UploadFileBackendFailure, FilesActionTypes.UploadFileBackendSuccess)));
                    })
                    .then((action) => {
                        if (action.type === FilesActionTypes.UploadFileBackendSuccess.type) {
                            this.FileAttachment$.next([...this.FileAttachment$.value, action.Payload.Id]);
                        }
                        this.LS.endLoading('add-ics-for-email');
                    });
            });
        }
    }
    protected readonly EditorConfig = CKEditorDefaultConfigEmail;
    @HostListener('window:beforeunload')
    // tslint:disable-next-line:naming-convention
    canDeactivate(): Observable<boolean> {
        if (!this.EmailId) {
            return of(false);
        }
        return this.emailDataService.GetEmailById$(this.EmailId).pipe(
            withLatestFrom(this.TargetEmails$, this.CCEmails$, this.BCCEmails$, this.FileAttachment$, this.GeneratedFileAttachment$),
            map(([email, target, cc, bcc, fileAttachment, generatedFileAttachment]) => {
                // htmlBody: this.EditorContent,
                // textBody: '',
                // subject: this.EmailSubjectForm.value,
                // to: target,
                // emailConnectionId: this.SourceEmailForm.value?.Id,
                // // userId: 1,
                // carbonCopy: cc,
                // blindCarbonCopy: bcc,
                // documentIds: fileAttachment,
                // generatedDocumentIds: generatedFileAttachment,
                // commissionId: this.CommissionId || null,
                // customerId: this.CustomerId || null,
                // from: this.SourceEmailForm.value?.Address,
                return (
                    !this.EditorContentForm.dirty &&
                    !this.EmailSubjectForm.dirty &&
                    compareArrays(email.To, target) &&
                    !this.SourceEmailForm.dirty &&
                    compareArrays(email.CarbonCopy, cc) &&
                    compareArrays(email.BlindCarbonCopy, bcc) &&
                    compareArrays(email.DocumentIds, fileAttachment) &&
                    compareArrays(email.GeneratedDocumentIds, generatedFileAttachment) &&
                    !this.SourceEmailForm.dirty
                );
            }),
        );
    }

    private saveAndClose() {
        this.Save$.next();
        this.close();
    }
    private close() {
        this.DialogRef.close();
    }
    onCloseClick() {
        firstValueFrom(this.canDeactivate()).then((canDeactivate) => {
            if (canDeactivate) {
                this.close();
            } else {
                firstValueFrom(
                    this.dialog
                        .open<EmailEditorCloseDialogComponent, EmailEditorCloseDialogComponentDialogData, EmailEditorCloseDialogComponentReturn>(EmailEditorCloseDialogComponent, {
                            ...EmailEditorCloseDialogComponent.DefaultConfig,
                            data: {
                                emailIsNew: !this.EmailId,
                            },
                        })
                        .afterClosed(),
                ).then((res) => {
                    if (res === 'revert') {
                        this.close();
                    } else if (res === 'save') {
                        this.saveAndClose();
                    }
                });
            }
        });
    }
    onEditorContentChange($event: string) {
        this.EditorContentForm.setValue($event);
        this.EditorContentForm.markAsDirty();
        this.EditorChangeContent$.next(null);
    }
}
