import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AccountsReceivableLedgerEntity, AccountsReceivableLedgerTypeEnum } from '../../../dave-data-module/entities/accounts-receivable-ledger.entity';
import { isNotNullOrUndefined, uniqArray } from '../../../helper/helper';
import { FormControlCache } from './formcontrol-cache';
import moment, { Moment } from 'moment';

export type arlTableDataArlForm = FormGroup<{
    // Amount: FormControl<number>;
    Information: FormControl<string>;
    BookingText: FormControl<string>;
    BaseCost: FormControl<number>;
    Quantity: FormControl<number>;
    QuantityTypeId: FormControl<number>;
    childrenVisible: FormControl<boolean>;
    InheritFromChildren: FormControl<boolean>;
    ParentId: FormControl<number | null>;
    ARLIds: FormControl<number[]>;
    ZuschlaegeResourcen: FormControl<number>;
    ZuschlaegeDefault: FormControl<number>;
    ZuschlaegeEmployee: FormControl<number>;
    ZuschlaegeExternalService: FormControl<number>;
    ZuschlaegeOther: FormControl<number>;
    Type: FormControl<AccountsReceivableLedgerTypeEnum>;
    ResourceId: FormControl<number>;
    MaterialId: FormControl<number>;
    Longtext: FormControl<string>;
    ShowLongtext: FormControl<boolean>;
    Tax: FormControl<number>;
    AlreadyPayed: FormControl<number>;
    Date: FormControl<Moment>;
}>;
@Injectable()
export class ArlFormDataService extends FormControlCache<arlTableDataArlForm> {
    /**
     *
     * @param id Id from arl to remove
     * @return Ids from all childArls including id
     */
    public removeArlWithChildren(id: number) {
        this.arlFormMap.forEach((form) => {
            if (form.value.ARLIds.includes(id)) {
                form.controls.ARLIds.setValue(form.value.ARLIds.filter((arlId) => arlId !== id));
                form.controls.ARLIds.markAsDirty();
            }
        });
        const childIds = this.getChildIds(id);
        childIds.forEach((arlId) => this.arlFormMap.delete(arlId));
        return childIds;
    }
    /**
     *
     * @param id Id from arl
     * @return Ids from all childArls including id
     */
    public getChildIds = (id: number): number[] => {
        if (!this.arlFormMap.has(id)) {
            return [];
        }
        if (this.arlFormMap.get(id).value.ARLIds?.length) {
            return [
                id,
                ...this.arlFormMap
                    .get(id)
                    .value.ARLIds.map((arlId) => this.getChildIds(arlId))
                    .flat(),
            ];
        } else {
            return [id];
        }
    };
    public setFormByEntity(e: AccountsReceivableLedgerEntity) {
        this.setForm(
            e.Id,
            new FormGroup({
                // Amount: new FormControl<number>(e.Amount, {nonNullable: true}),
                Information: new FormControl<string>(e.Information, { nonNullable: true }),
                BookingText: new FormControl<string>(e.BookingText, { nonNullable: true }),
                BaseCost: new FormControl<number>(e.BaseCost, { nonNullable: true }),
                Quantity: new FormControl<number>(e.Quantity, { nonNullable: true }),
                QuantityTypeId: new FormControl<number>(e.QuantityTypeId, { nonNullable: true }),
                // wird von der arl-calculation-component verwaltet
                childrenVisible: new FormControl<boolean>(null, { nonNullable: true }),
                InheritFromChildren: new FormControl<boolean>(e.InheritFromChildren, { nonNullable: true }),
                ParentId: new FormControl<number | null>(e.ParentId, { nonNullable: true }),
                ARLIds: new FormControl<number[]>(uniqArray(e.ArlIds) || [], { nonNullable: true }),
                ZuschlaegeResourcen: new FormControl<number>(e.CustomProperties?.ZuschlaegeResourcen || null, { nonNullable: true }),
                ZuschlaegeDefault: new FormControl<number>(e.CustomProperties?.ZuschlaegeDefault || null, { nonNullable: true }),
                ZuschlaegeEmployee: new FormControl<number>(e.CustomProperties?.ZuschlaegeEmployee || null, { nonNullable: true }),
                ZuschlaegeExternalService: new FormControl<number>(e.CustomProperties?.ZuschlaegeExternalService || null, { nonNullable: true }),
                ZuschlaegeOther: new FormControl<number>(e.CustomProperties?.ZuschlaegeOther || null, { nonNullable: true }),
                Type: new FormControl<AccountsReceivableLedgerTypeEnum>(e.Type || null, { nonNullable: true }),
                ResourceId: new FormControl<number>(e.ResourceId || null, { nonNullable: true }),
                Longtext: new FormControl<string>(e.Longtext || null, { nonNullable: true }),
                ShowLongtext: new FormControl<boolean>(e.ShowLongtext || false, { nonNullable: true }),
                Tax: new FormControl<number>(e.Tax || null, { nonNullable: true }),
                AlreadyPayed: new FormControl<number>(isNotNullOrUndefined(e.AlreadyPayed) ? e.AlreadyPayed : null, { nonNullable: true }),
                Date: new FormControl<Moment>(isNotNullOrUndefined(e.Date) ? moment(e.Date) : null, { nonNullable: true }),
                MaterialId: new FormControl<number>(e.MaterialId || null, { nonNullable: true }),
            }),
        );
    }
}
