import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTable, MatTableModule } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { State } from '../../../dave-data-module/State';
import { LengthPipe } from '../../../helper/length.pipe';
import { LedgerImportSelectTableDataSource, LedgerImportSelectTableItem } from './ledger-import-select-table-datasource';

@Component({
    selector: 'app-ledger-import-select-table',
    templateUrl: './ledger-import-select-table.component.html',
    styleUrls: ['./ledger-import-select-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, MatTableModule, MatSortModule, MatPaginatorModule, LengthPipe, MatCheckboxModule],
})
export class LedgerImportSelectTableComponent implements AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatTable) table!: MatTable<LedgerImportSelectTableItem>;
    dataSource: LedgerImportSelectTableDataSource;

    /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
    displayedColumns: Array<keyof LedgerImportSelectTableItem | 'checkbox'> = ['checkbox', 'ConsolidatedInvoiceId', 'DueDate', 'ConsolidatedAmountWithoutTax', 'notes', 'Type'];
    autoDisplayedColumns: Array<keyof LedgerImportSelectTableItem> = ['ConsolidatedInvoiceId', 'ConsolidatedAmount', 'ConsolidatedAmountWithoutTax', 'notes', 'DueDate', 'Type'];
    columnHeaders: { [key in keyof LedgerImportSelectTableItem]: string } = {
        ConsolidatedInvoiceId: 'Nr.',
        ConsolidatedAmount: 'Summe',
        ConsolidatedAmountWithoutTax: 'Summe (Netto)',
        notes: 'Notiz',
        CommissionId: '',
        isSelected$: '',
        Id: '',
        DueDate: 'Frist',
        Type: 'Typ',
    };
    protected emptyText = 'keine Einträge vorhanden';
    protected multiple$ = new BehaviorSubject<boolean>(false);
    protected disableUncheckedCheckboxes$: Observable<boolean>;
    private selectableLedgerImportIds$ = new BehaviorSubject<number[]>([])
    @Input() set selectableLedgerImportIds(ids: number[]) {
        if (JSON.stringify(ids) !== JSON.stringify(this.selectableLedgerImportIds$.value)) {
            this.selectableLedgerImportIds$.next([...ids]);
        }
    }
    @Output() selectedLedgerImports = new EventEmitter<number[]>();

    @Input()
    set multiple(value: boolean) {
        this.multiple$.next(coerceBooleanProperty(value));
    }
    // tslint:disable-next-line: member-ordering naming-convention
    static ngAcceptInputType_multiple: BooleanInput;
    changeSubscription: Subscription | undefined;
    constructor(store: Store<State>, @Inject(LOCALE_ID) locale: string) {
        this.dataSource = new LedgerImportSelectTableDataSource(store, locale, this.selectableLedgerImportIds$);
        this.changeSubscription = this.dataSource.selectedLedgerImports$.subscribe((val) => this.selectedLedgerImports.emit([...val]));
        this.disableUncheckedCheckboxes$ = combineLatest([this.multiple$, this.dataSource.selectedLedgerImports$]).pipe(
            map(([multi, selected]) => !multi && selected.length > 0),
            distinctUntilChanged(),
            shareReplay({ refCount: true, bufferSize: 1 }),
        );
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.table.dataSource = this.dataSource;
    }
    toggleSelection(liId: number) {
        const isSelected = this.dataSource.selectedLedgerImports$.value.includes(liId);
        if (isSelected) {
            this.dataSource.selectedLedgerImports$.next(this.dataSource.selectedLedgerImports$.value.filter((id) => id !== liId));
        } else if (this.multiple$.value || !this.dataSource.selectedLedgerImports$.value.length) {
            this.dataSource.selectedLedgerImports$.next([...this.dataSource.selectedLedgerImports$.value, liId]);
        }
    }

    ngOnDestroy(): void {
        this.changeSubscription?.unsubscribe();
    }
}
