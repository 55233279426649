import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, skip, take, takeUntil } from 'rxjs/operators';
import { ITaskPriority } from '../../../dave-data-module/entities/event.entity';
import { DetailTasksComponent, DetailTasksComponentDialogData } from '../../../dave-event-card/components/detail-tasks/detail-tasks.component';
import { DaveFilePreviewComponent, DaveFilePreviewComponentDialogData } from '../../../dave-file-preview-dialog/components/dave-file-preview/dave-file-preview.component';
import { DefaultFilterService, FilterApps, FilterTypes } from '../../../services/default-filter.service';
import { ITaskBoardSort, TaskBoardFilter } from '../tasks-board/tasks-board.component';
import {isArray} from "chart.js/helpers";
import {clacEventFilterAmount, getEventFilterValues, IEventFilterType} from "../../../helper/event.helper";
import { CustomLabelService } from "../../../services/custom-label.service";
import { TaskFilterService } from "../../services/task-filter.service";
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-task-page',
    templateUrl: './task-page.component.html',
    styleUrls: ['./task-page.component.scss'],
})
export class TaskPageComponent implements OnInit, OnDestroy {
    @ViewChild('input') private inputRef?: ElementRef<HTMLInputElement>;
    public SearchBarActive = false;
    public SearchForm = new FormControl<string>('');
    public FilterValues$: BehaviorSubject<IEventFilterType> = getEventFilterValues();
    private onDestroy$: Subject<void> = new Subject<void>();
    public TaskId$ = this.route.paramMap.pipe(
        map((paramMap) => +paramMap.get('taskId')),
        filter((v) => !!v),
        distinctUntilChanged(),
    );
    public TaskFilter$: Observable<TaskBoardFilter> = this.FilterValues$.pipe(
        map((v) => ({
            CommissionIds: v[FilterTypes.CommissionId]?.map(u => +u.id) || null,
            UserId: v[FilterTypes.UserId]?.map(u => +u.id) || null,
            AutorId: v[FilterTypes.AutorId]?.map(u => +u.id) || null,
            Priority: v.priority?.length ? v.priority.map((p) => +p.id as ITaskPriority) : null,
            MinEventEndDate: v[FilterTypes.EndDate]?.from ? v[FilterTypes.EndDate].from.toDate() : null,
            MaxEventEndDate: v[FilterTypes.EndDate]?.to ? v[FilterTypes.EndDate].to.toDate() : null,
        })),
    );
    public FilterSettings$ = this.filterService.filterSettings$;
    public FilterAmount$ = this.FilterValues$.pipe(map(clacEventFilterAmount), shareReplay({ refCount: true, bufferSize: 1 }));
    public TaskBoardSort: ITaskBoardSort = { sortBy: 'endDate', direction: 'dsc' };
    constructor(private filterService: TaskFilterService, private dialog: MatDialog, private route: ActivatedRoute, private defaultFilterService: DefaultFilterService, protected cls: CustomLabelService) {
        this.TaskId$.pipe(takeUntil(this.onDestroy$)).subscribe((taskId) => {
            this.dialog.open<DetailTasksComponent, DetailTasksComponentDialogData>(DetailTasksComponent, {
                ...DetailTasksComponent.DefaultConfig,
                data: {
                    EventId: taskId || null,
                },
            });
        });
        this.FilterValues$.pipe(takeUntil(this.onDestroy$), skip(2)).subscribe((val) => {
            if (Object.keys(val).length !== 0) {
                this.defaultFilterService.SetFilterByApp(FilterApps.TaskPage, val);
            }
        });
    }

    ngOnInit(): void {
        combineLatest([this.FilterSettings$, this.defaultFilterService.GetFilterByApp$(FilterApps.TaskPage)])
            .pipe(take(1))
            .subscribe(([val, filterValues]) => {
                this.FilterValues$.next({
                    [FilterTypes.Priority]: isArray(filterValues[FilterTypes.Priority]) ? filterValues[FilterTypes.Priority] : null,
                    [FilterTypes.Date]: (filterValues[FilterTypes.Date] && isArray(filterValues[FilterTypes.Date]))
                        ? { from: filterValues[FilterTypes.Date].from && moment(filterValues[FilterTypes.Date].from), to: filterValues[FilterTypes.Date].to && moment(filterValues[FilterTypes.Date].to) }
                        : null,
                    [FilterTypes.CommissionId]: isArray(filterValues[FilterTypes.CommissionId]) ? filterValues[FilterTypes.CommissionId] : null,
                    [FilterTypes.UserId]: isArray(filterValues[FilterTypes.UserId]) ? filterValues[FilterTypes.UserId] : null,
                    [FilterTypes.AutorId]: isArray(filterValues[FilterTypes.AutorId]) ? filterValues[FilterTypes.AutorId] : null,
                    [FilterTypes.EndDate]: null,
                    [FilterTypes.Persons]: null,
                    [FilterTypes.EventType]: null,
                });
            });
    }
    ngOnDestroy() {
        this.onDestroy$.next();
    }
    OpenFile(fileId: number) {
        this.dialog.open<DaveFilePreviewComponent, DaveFilePreviewComponentDialogData>(DaveFilePreviewComponent, {
            ...DaveFilePreviewComponent.DefaultConfig,
            data: {
                fileId,
            },
        });
    }
    SearchButtonClicked() {
        if (!this.SearchBarActive) {
            this.SearchBarActive = true;
            setTimeout(() => this.inputRef?.nativeElement.focus(), 30);
        } else {
            if (this.SearchForm.value.length === 0) {
                this.SearchBarActive = false;
            }
        }
    }
}
