import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Address } from '../../helper/helper';
import { EventType } from '../graphql-types';
import { BackendDate, BackendDescription } from '../helper/backend-frontend-conversion.helper';
import { DailyToDoAdditionalData } from './commissionType.entity';
import { markerColor } from "../../leaflet-wrapper/leaflet-wrapper.component";

export enum EventStateEnum {
    New = 'OPEN',
    InProgress = 'IN_PROGRESS',
    Done = 'DONE',
    Released = 'RELEASED',
}
export const EventStateEnumNameMap: Map<EventStateEnum, string> = new Map<EventStateEnum, string>([
    [EventStateEnum.New, 'Offen'],
    [EventStateEnum.InProgress, 'In Bearbeitung'],
    [EventStateEnum.Done, 'Erledigt'],
    [EventStateEnum.Released, 'Freigemeldet'],
]);
export const EventStateEnumColorMap: Map<EventStateEnum, markerColor> = new Map<EventStateEnum, markerColor>([
    [EventStateEnum.New, 'grey'],
    [EventStateEnum.InProgress, '#fac600'],
    [EventStateEnum.Done, 'green'],
    [EventStateEnum.Released, 'blue'],
]);
// für Aufgaben
export const taskEventStates: EventStateEnum[] = [
    EventStateEnum.New,
    EventStateEnum.InProgress,
    EventStateEnum.Done,
];
//  für Störungen
export const issueEventStates: EventStateEnum[] = [
    EventStateEnum.New,
    EventStateEnum.InProgress,
    EventStateEnum.Done,
    EventStateEnum.Released,
];
export type ITaskPriority = -20 | -10 | 0 | 10 | 20;
export const TaskPriorities: ITaskPriority[] = [-20, -10, 0, 10, 20];
export const TaskPriorityNameColorIconMap: Map<ITaskPriority, { name: string; color: string; icon: IconProp }> = new Map<ITaskPriority, { name: string; color: string; icon: IconProp }>([
    [20, { name: 'sehr wichtig', color: 'red', icon: 'arrow-up' }],
    [10, { name: 'wichtig', color: 'orange', icon: 'arrow-up' }],
    [0, { name: 'normal', color: 'grey', icon: 'minus' }],
    [-10, { name: 'niedrig', color: 'darkseagreen', icon: 'arrow-down' }],
    [-20, { name: 'sehr niedrig', color: 'green', icon: 'arrow-down' }],
]);
export interface AdditionalDataEvent extends DailyToDoAdditionalData {
    Priority?: ITaskPriority;
    IsDailyToDo?: boolean;
    ParentEventId?: number;
    UnicId?: number; // fird nur vom fe genutzt (im Bautagebuch)
    // key = FileId
    mapMarker?: { [key: number]: { left: number; top: number; page?: number }[] };
}
export class EventEntity implements Address {
    constructor(
        public Id: number = null,
        public CommissionId: number = null,
        public CustomerId: number = null,
        public PartnerId: number = null,
        public PersonId: number = null,
        public Hint: string = null,
        public EventTypeId: number = null,
        public UserId: number = null,
        public EmailId: number = null,
        public EventDate: Date = null,
        public EventEndDate: Date = null,
        public Description: string = '',
        public Private: boolean = false,
        public Street: string = '',
        public City: string = '',
        public PostalCode: string = '',
        public Country: string = '',
        public Name: string = null,
        public IsTask: boolean = null,
        public State: EventStateEnum = null,
        public AdditionalData: AdditionalDataEvent = null,
        public UserIds: number[] = null,
        public FileCount: number = null,
        public UpdatedAt: Date = null,
        public CreatedAt: Date = null,
        public LastSeenAt: Date | null = null,
        public From: string = null,
        public To: string[] = null,
        public CarbonCopy: string[] = null,
        public BlindCarbonCopy: string[] = null,
        public ProcessId: number = null,
    ) {}
    public static readonly GQLFields: Array<keyof EventType> = [
        'commissionId',
        'description',
        'eventDate',
        'eventEndDate',
        'eventTypeId',
        'customerId',
        'commissionId',
        'partnerId',
        'hint',
        'personId',
        'id',
        'userId',
        'emailId',
        'private',
        'street',
        'city',
        'postalCode',
        'country',
        'name',
        'isTask',
        'state',
        'additionalData',
        'userIds',
        'fileCount',
        'updatedAt',
        'createdAt',
        'lastSeenAt',
        'from',
        'to',
        'carbonCopy',
        'blindCarbonCopy',
        'processId',
    ];
    public Clone(override: EventEntityAttributes = {}): EventEntity {
        const attr = { ...this, ...override };
        return Object.assign(new EventEntity(), attr);
    }
}

export function EventEntityFromBackend(res: EventType): EventEntity {
    return new EventEntity(
        res.id,
        res.commissionId,
        res.customerId,
        res.partnerId,
        res.personId,
        res.hint && BackendDescription(res.hint),
        res.eventTypeId,
        res.userId,
        res.emailId,
        res.eventDate && BackendDate(res.eventDate),
        res.eventEndDate && BackendDate(res.eventEndDate),
        res.description && BackendDescription(res.description),
        res.private,
        res.street,
        res.city,
        res.postalCode,
        res.country,
        res.name,
        res.isTask,
        res.state as any,
        res.additionalData as any,
        res.userIds,
        res.fileCount,
        BackendDate(res.updatedAt),
        BackendDate(res.createdAt),
        res.lastSeenAt && BackendDate(res.lastSeenAt),
        res.from,
        res.to,
        res.carbonCopy,
        res.blindCarbonCopy,
        res.processId,
    );
}

export interface EventEntityAttributes {
    Id?: number;
    CommissionId?: number;
    CustomerId?: number;
    PartnerId?: number;
    PersonId?: number;
    Hint?: string;
    EventTypeId?: number;
    UserId?: number;
    EmailId?: number;
    EventDate?: Date;
    EventEndDate?: Date;
    Description?: string;
    Private?: boolean;
    Street?: string;
    City?: string;
    PostalCode?: string;
    Country?: string;
    Name?: string;
    IsTask?: boolean;
    State?: EventStateEnum;
    AdditionalData?: AdditionalDataEvent;
    UserIds?: number[];
    FileCount?: number;
    UpdatedAt?: Date;
    CreatedAt?: Date;
    LastSeenAt?: Date | null;
}
